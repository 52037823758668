import { ReactElement, useEffect, useRef } from 'react';
import { useLocation } from 'react-router';

interface ScrollToTopProps {
  children: ReactElement;
}

const ScrollToTop = ({ children }: ScrollToTopProps) => {
  const { pathname } = useLocation();
  const prevLocation = useRef<string>('');

  useEffect(() => {
    if (prevLocation.current && pathname !== prevLocation?.current) {
      window.scrollTo(0, 0);
    }
    prevLocation.current = pathname;
  }, [pathname]);

  return children;
};

export default ScrollToTop;
