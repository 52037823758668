import React from 'react';
import { connect } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';

import { updateJVPUserUiSettings } from 'Containers/App/actions';
import { makeSelectConfigField } from 'Containers/App/selectors';
import { Moment } from 'Containers/MomentPage/Moment';
import { DirectlyUpdateableJVPUserAttributes } from 'Utils/apiTypes';

import { GlobalReducerState } from '../../reducers';

interface MomentPageStateProps {
  client: string;
}

interface MomentPageDispatchProps {}

export type MomentPageProps = MomentPageStateProps & MomentPageDispatchProps;

export const MomentPage = (props: MomentPageProps) => {
  const { client } = props;
  // const [booped, setBooped] = React.useState(false);
  const [searchParams] = useSearchParams();

  const topic = searchParams.get('topic');
  const momentCodename = searchParams.get('moment');

  if (!topic) {
    return null;
  }

  // this exists to get a screen interaction before loading SBR when jumping straight to this route and not getting to it via the unified landing page. obviously not the thing we would actually
  // use but the need to get a screen interaction before loading SBR is a ux problem we'll have to tackle if the expected flow is loading straight into the MTM page.
  // if(!booped) {
  //   return (
  //     <Button onClick={() => setBooped(true)}>Plz boop</Button>
  //   )
  // }

  return (
    <Moment
      codename={momentCodename}
      topic={topic}
      client={client}
      // updateJVPUserUiSettings={updateJVPUserUiSettings}
    />
  );
};

const mapStateToProps = () =>
  createStructuredSelector<GlobalReducerState, MomentPageStateProps>({
    client: makeSelectConfigField('client'),
  });

export function mapDispatchToProps(dispatch): MomentPageDispatchProps {
  return {
    updateJVPUserUiSettings: (uiSettings: Partial<DirectlyUpdateableJVPUserAttributes>) =>
      dispatch(updateJVPUserUiSettings(uiSettings)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(MomentPage);
