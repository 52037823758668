import React, { FC } from 'react';

import { Tooltip, FormattedTooltipProps } from 'DesignLibrary/atoms';
import { Paragraph } from 'DesignLibrary/atoms/typography';

export interface TooltipPlanDetailsProps {
  tooltip: string | FormattedTooltipProps | Element | React.ReactNode;
  children: JSX.Element | string;
  keyHint: string;
  color?: string;
  isRichText?: boolean;
  size?: 'normal' | 'small' | 'mini';
}

export const TooltipPlanDetails: FC<TooltipPlanDetailsProps> = ({
  color = '--colors-gray-600',
  children,
  keyHint,
  tooltip,
  isRichText = false,
  size = 'normal',
}) => (
  <Tooltip id={`tooltip-title-${keyHint}`} label={tooltip} isRichText={isRichText}>
    <Paragraph underline color={color} size={size}>
      {children}
    </Paragraph>
  </Tooltip>
);
