import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import { GlobalReducerState } from 'app/reducers';
import { chooseProduct, updateJVPUserUiSettings } from 'Containers/App/actions';
import { makeGetEnabledProducts, makeSelectJvpField } from 'Containers/App/selectors';
import { AlexProducts, EnabledProducts } from 'Containers/App/types';
import { IntroScene } from 'ContentfulDefaults/types/alexIntro';
import { useAudioContext } from 'Contexts/audioContext';
import { useFeatureFlagContext } from 'Contexts/featureFlagContext';
import { ALEX_STEPS, useIntroContext } from 'Contexts/introContext';
import { useTextContext } from 'Contexts/textContext';
import { Button, FadeUp, H4, Icon, Paragraph } from 'DesignLibrary/atoms';
import { PageLayout } from 'DesignLibrary/atoms/PageLayout';
import RichText from 'Shared/RichText';
import Text from 'Shared/Text';
import { DirectlyUpdateableJVPUserAttributes } from 'Utils/apiTypes';
import { sendInsightsEvent } from 'Utils/insights';
import { PROFILE_PATH } from 'Utils/urls';

import { RenderStep } from './RenderStep';
import { AlexIntroWrapper, ClosedCaptions, IntroHeader, SoundCheckWrapper, SoundOffWrapper } from './styled';

export interface AlexIntroDispatchProps {
  chooseProduct: (product: AlexProducts) => void;
  updateJVPUserUiSettings: (uiSettings: Partial<DirectlyUpdateableJVPUserAttributes>) => void;
}

interface AlexIntroStateProps {
  products: EnabledProducts;
  isReturnUser: boolean | null;
}

export type AlexIntroProps = AlexIntroStateProps & AlexIntroDispatchProps;

export const AlexIntro = ({
  chooseProduct,
  products,
  isReturnUser,
  updateJVPUserUiSettings,
}: AlexIntroProps) => {
  const { introState, updateStep, updateAudioHasPlayed } = useIntroContext();
  const [audioUrlMap, setAudioUrlMap] = useState({});
  const currentStepKey = introState.currentStep;
  const currentStep = introState.steps[currentStepKey];

  const { retrieveContentfulData } = useTextContext();
  const { audioState } = useAudioContext();
  const closedCaptions = audioState.areClosedCaptionsEnabled;

  const { is_rue_landing_page_enabled } = useFeatureFlagContext();
  const navigate = useNavigate();

  useEffect(() => {
    sendInsightsEvent(null, 'start_scene', {
      scene: currentStepKey,
    });
  }, [currentStepKey]);

  useEffect(() => {
    const newAudioUrlMap: { [K in ALEX_STEPS]?: string[] } = sceneData.reduce((acc, i) => {
      let audioAssetUrls: string[];

      if (isReturnUser && is_rue_landing_page_enabled && i.return_user_audio_asset_urls) {
        audioAssetUrls = [...i.return_user_audio_asset_urls];
      } else if (i.audio_asset_urls) {
        audioAssetUrls = [...i.audio_asset_urls];
      } else {
        audioAssetUrls = [];
      }

      return {
        ...acc,
        [i.key]: audioAssetUrls,
      };
    }, {});
    setAudioUrlMap(newAudioUrlMap);
  }, []);

  useEffect(() => {
    // populate audioState with appropriate number of default values based on how many files are received from Contentful
    Object.keys(audioUrlMap).forEach((key) => {
      if (audioUrlMap[key].length > 0) {
        audioUrlMap[key].forEach((k, i) => updateAudioHasPlayed(key as ALEX_STEPS, false, i));
      }
    });
  }, [audioUrlMap]);

  const sceneData = retrieveContentfulData<IntroScene[]>('alex_intro_page.scenes2', []);

  const closedCaptionsText: { [K in ALEX_STEPS]?: string } = sceneData.reduce((acc, i) => {
    let transcript: string;

    if (isReturnUser && is_rue_landing_page_enabled && i.return_user_transcript) {
      transcript = i.return_user_transcript;
    } else {
      transcript = i.transcript;
    }
    return {
      ...acc,
      [i.key]: transcript,
    };
  }, {});

  const videoUrlMap: { [K in ALEX_STEPS]?: string } = sceneData.reduce(
    (acc, i) => ({
      [i.key]: i?.video_asset_url || '',
      ...acc,
    }),
    {},
  );

  const updateRandomizedOutro = () => {
    const outros = sceneData.filter(
      (scene) => scene.key.startsWith('askQuestionsVideo') && scene.key !== ALEX_STEPS.ASK_QUESTIONS_VIDEO,
    );
    const numOutros = outros.length;
    const randomNumber = Math.floor(Math.random() * numOutros) + 1;
    const key = `ASK_QUESTIONS_VIDEO${randomNumber}`;
    updateStep(ALEX_STEPS[key]);
  };

  const handleNext = () => {
    sendInsightsEvent(null, 'get_started');
    navigate(PROFILE_PATH);
  };

  const renderClosedCaptions = () => {
    if (!closedCaptionsText[currentStepKey] || !closedCaptions) {
      return null;
    }
    return (
      <ClosedCaptions data-testid="closed-captions">
        <FadeUp isOpen={closedCaptions}>
          <PageLayout bg="--colors-opaque-60">
            <div className="cc-wrapper">
              <Paragraph color="--primary-white">
                <span className="speaker">
                  <Icon type="SpeakerHigh" color="--primary-white" />
                </span>
                {closedCaptionsText[currentStepKey]}
              </Paragraph>
            </div>
          </PageLayout>
        </FadeUp>
      </ClosedCaptions>
    );
  };

  const renderSoundCheckContent = () => {
    const soundCheckButtons = retrieveContentfulData<string[]>('alex_intro_page.sound_check_buttons');

    return (
      <SoundCheckWrapper>
        <FadeUp isOpen>
          <H4>
            <Text field="alex_intro_page.sound_check_title" />
          </H4>
          <div className="answers">
            <Button
              justify="flex-start"
              buttonType="primary"
              data-testid="can-hear-you-btn"
              onClick={() => {
                sendInsightsEvent(null, 'sound_check', { answer: 'yes' });
                updateAudioHasPlayed(ALEX_STEPS.HEAR_ME_AUDIO, true, 0);
                updateRandomizedOutro();
              }}
            >
              {soundCheckButtons?.[0]}
            </Button>
            <Button
              justify="flex-start"
              buttonType="primary"
              onClick={() => {
                sendInsightsEvent(null, 'sound_check', { answer: 'no' });
                updateAudioHasPlayed(ALEX_STEPS.HEAR_ME_AUDIO, true, 0);
                updateStep(ALEX_STEPS.SOUND_OFF_AUDIO);
              }}
            >
              {soundCheckButtons?.[1]}
            </Button>
          </div>
        </FadeUp>
      </SoundCheckWrapper>
    );
  };

  const renderSoundOffContent = () => {
    const soundOffButtons = retrieveContentfulData<string[]>('alex_intro_page.sound_off_buttons');

    return (
      <SoundOffWrapper>
        <FadeUp isOpen delay={300}>
          <div className="text">
            <Paragraph
              color="--text-black"
              data-testid={products.go ? 'sound-off-text-with-go' : 'sound-off-text-without-go'}
            >
              {products.go ? (
                <RichText field="alex_intro_page.sound_off_text" />
              ) : (
                <RichText field="alex_intro_page.sound_off_text_without_go" />
              )}
            </Paragraph>
          </div>

          <div className="answers">
            <Button
              buttonType="primary"
              justify="flex-start"
              onClick={() => {
                sendInsightsEvent(null, 'sound_off', {
                  prefered_experience: 'bc',
                });
                updateRandomizedOutro();
              }}
            >
              {soundOffButtons?.[1]}
            </Button>
            {products.go && (
              <Button
                justify="flex-start"
                testId="choose-go-button"
                onClick={() => {
                  setTimeout(() => {
                    sendInsightsEvent(null, 'sound_off', {
                      prefered_experience: 'go',
                    });
                    chooseProduct('go');
                    handleNext();
                  }, introState.steps[ALEX_STEPS.SOUND_OFF_AUDIO].delayOut);
                }}
              >
                {soundOffButtons?.[0]}
              </Button>
            )}
          </div>
        </FadeUp>
      </SoundOffWrapper>
    );
  };

  const renderAdditionalContent = () => {
    if (currentStepKey === ALEX_STEPS.HEAR_ME_AUDIO) {
      return renderSoundCheckContent();
    }
    if (currentStepKey === ALEX_STEPS.SOUND_OFF_AUDIO) {
      return renderSoundOffContent();
    }
    return null;
  };

  return (
    <AlexIntroWrapper>
      <FadeUp isOpen delay={400}>
        <PageLayout page="alex-intro" bg="--primary-white">
          <IntroHeader>
            <H4 weight="normal">
              <Text field="alex_intro_page.header" />
            </H4>
          </IntroHeader>
          <RenderStep
            step={currentStep}
            stepKey={currentStepKey}
            mediaType={currentStep.type}
            videoUrl={videoUrlMap[currentStepKey] as string}
            audioUrls={audioUrlMap[currentStepKey] as string[]}
            handleNext={handleNext}
            updateJVPUserUiSettings={updateJVPUserUiSettings}
          />
          {renderAdditionalContent()}
          {renderClosedCaptions()}
        </PageLayout>
      </FadeUp>
    </AlexIntroWrapper>
  );
};

export function mapDispatchToProps(dispatch): AlexIntroDispatchProps {
  return {
    chooseProduct: (product: AlexProducts) => dispatch(chooseProduct(product)),
    updateJVPUserUiSettings: (uiSettings: Partial<DirectlyUpdateableJVPUserAttributes>) =>
      dispatch(updateJVPUserUiSettings(uiSettings)),
  };
}

const mapStateToProps = createStructuredSelector<GlobalReducerState, AlexIntroStateProps>({
  products: makeGetEnabledProducts(),
  isReturnUser: makeSelectJvpField('isReturnUser'),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(AlexIntro);
