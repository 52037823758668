import { Box, Flex } from '@chakra-ui/react';
import React from 'react';

import { useProviderSelectionContext } from 'Contexts/providerSelectionContext';
import { useTextContext } from 'Contexts/textContext';
import { Numbers } from 'DesignLibrary/atoms';
import Plan from 'Models/plan';
import { SplitNumbers } from 'Shared/SplitNumbers';
import Text from 'Shared/Text';
import { DoctorType } from 'Types/entities';
import { toDollars } from 'Utils/helpers';

export function formatDeductibleOrOop(value: number | null, split?: boolean) {
  if (value === null) {
    return <Text field="health_section.null_plan_detail_text.deductibleAndOopMax" />;
  }

  if (split) {
    return value;
  }

  return <Numbers size="medium">{toDollars(value)}</Numbers>;
}

const ProviderTag = ({ providerDetail, testId }: { providerDetail: string; testId: string }) => (
  <Flex bg="blackAlpha.50" px={2} py={1} borderRadius="var(--border-radius-xs)">
    <Box fontSize={12} fontWeight={700} color="var(--text-gray)" data-testid={testId}>
      {providerDetail}
    </Box>
  </Flex>
);

export const renderInNetworkDeductible = (plan: Plan) => {
  const {
    inNetworkDeductible,
    inNetworkDrugDeductible,
    inNetworkMedDeductible,
    combinedNetworkMedDeductible,
  } = plan;

  // Support Indemnity network deductible
  if (plan.hasCombinedDeductible()) {
    return formatDeductibleOrOop(combinedNetworkMedDeductible);
  }
  // Determine if deductible is comprehensive (ie. one value covers both drug + medical)
  const isComprehensive = inNetworkDrugDeductible == null && inNetworkMedDeductible == null;

  if (isComprehensive) {
    return formatDeductibleOrOop(inNetworkDeductible);
  }

  // When not comprehensive, return split Medical and Drug In-Network Deductibles
  const inNetworkDedMed = formatDeductibleOrOop(inNetworkMedDeductible, true);
  const inNetworkDedDrug = formatDeductibleOrOop(inNetworkDrugDeductible, true);

  return <SplitNumbers values={[inNetworkDedMed, inNetworkDedDrug]} />;
};

export const renderInNetworkOopMax = (plan: Plan) => {
  // Determine if OOP Max is comprehensive (ie. one value covers both drug + medical)
  const { inNetworkOopMax, inNetworkDrugOopMax, inNetworkMedOopMax, combinedNetworkOopMax } = plan;
  const isComprehensive = inNetworkDrugOopMax == null && inNetworkMedOopMax == null;

  // Support Indemnity network OOP Max
  if (plan.hasCombinedOopMax()) {
    return formatDeductibleOrOop(combinedNetworkOopMax);
  }

  if (isComprehensive) {
    return formatDeductibleOrOop(inNetworkOopMax);
  }

  // When not comprehensive, return split Medical and Drug In-Network OOP Max
  const inNetworkOopLimitMed = formatDeductibleOrOop(inNetworkMedOopMax, true);
  const inNetworkOopLimitDrug = formatDeductibleOrOop(inNetworkDrugOopMax, true);

  return (
    <SplitNumbers
      values={[inNetworkOopLimitMed, inNetworkOopLimitDrug]}
      identifiers={['in-network-med-oop-limit', 'in-network-drug-oop-limit']}
    />
  );
};

export const renderOutNetworkDeductible = (plan: Plan) => {
  // Get Out Of Network Plan Values
  const { oonDeductible, oonDrugDeductible, oonMedDeductible } = plan;
  // Determine if deductible is comprehensive (ie. one value covers both drug + medical)
  const isComprehensive = oonDrugDeductible == null && oonMedDeductible == null;

  if (isComprehensive) {
    return formatDeductibleOrOop(oonDeductible);
  }

  // When not comprehensive, return split Medical and Drug Out-Of-Network Deductibles
  const outNetworkDedMed = formatDeductibleOrOop(oonMedDeductible, true);
  const outNetworkDedDrug = formatDeductibleOrOop(oonDrugDeductible, true);

  return (
    <SplitNumbers
      values={[outNetworkDedMed, outNetworkDedDrug]}
      identifiers={['out-network-med-ded', 'out-network-drug-ded']}
    />
  );
};

export const renderOutNetworkOopMax = (plan: Plan) => {
  // Get Out Of Network Plan Values
  const { oonOopMax, oonDrugOopMax, oonMedOopMax } = plan;
  // Determine if OOP Max is comprehensive (ie. one value covers both drug + medical)
  const isComprehensive = oonDrugOopMax == null && oonMedOopMax == null;

  if (isComprehensive) {
    return formatDeductibleOrOop(oonOopMax);
  }

  // When not comprehensive, return split Medical and Drug Out-Of-Network OOP Max
  const outNetworkOopLimitMed = formatDeductibleOrOop(oonMedOopMax, true);
  const outNetworkOopLimitDrug = formatDeductibleOrOop(oonDrugOopMax, true);

  return (
    <SplitNumbers
      values={[outNetworkOopLimitMed, outNetworkOopLimitDrug]}
      identifiers={['out-network-med-oop-limit', 'out-network-drug-oop-limit']}
    />
  );
};

const renderProviders = (providerList: DoctorType[], inNetwork: boolean) => {
  const type = inNetwork ? 'in-network' : 'out-of-network';
  const { retrieveContentfulData } = useTextContext();
  const none_text = retrieveContentfulData<string>('health_section.recommendation.in_network_providers_none');
  return (
    <Flex gap={1} flexWrap="wrap">
      {providerList.length > 0 ? (
        providerList.map((provider, index) => {
          const fullName = [provider.first_name, provider.middle_name, provider.last_name]
            .filter(Boolean)
            .join(' ');
          return (
            <ProviderTag providerDetail={fullName} key={provider.npi} testId={`${type}-compare-${index}`} />
          );
        })
      ) : (
        <ProviderTag providerDetail={none_text} testId={`${type}-compare-none`} />
      )}
    </Flex>
  );
};

export const renderProvidersByNetwork = (plan: Plan, inNetwork: boolean) => {
  const { filteredProviders } = useProviderSelectionContext();
  const { planId } = plan;
  const providersSelected =
    filteredProviders[planId] &&
    (filteredProviders[planId].inNetwork.length > 0 || filteredProviders[planId].outOfNetwork.length > 0);
  if (!providersSelected) return null;
  const providerList = filteredProviders[planId]
    ? inNetwork
      ? filteredProviders[planId].inNetwork
      : filteredProviders[planId].outOfNetwork
    : [];
  return renderProviders(providerList, inNetwork);
};
