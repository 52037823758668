import React from 'react';

import { ProfileSection } from 'Containers/ProfilePage/types';
import { ProgressSection, useProfileContext } from 'Contexts/profileContext';
import { useTextContext } from 'Contexts/textContext';
import { FadeIn } from 'DesignLibrary/atoms';
import { Icon } from 'DesignLibrary/atoms/Icon';
import { Tooltip } from 'DesignLibrary/atoms/Tooltip';
import { useViewport } from 'DesignLibrary/context';

import { ProfileContainer } from './styled';

export const Profile = () => {
  const { profileProgressMap, getIsFormValid, getIsFormCompleted } = useProfileContext();
  const { retrieveContentfulData } = useTextContext();
  const { device } = useViewport();

  // Check that profile questions are complete
  const isFormValid = getIsFormValid();
  const isFormUnlocked = getIsFormCompleted();
  const isFormComplete = isFormValid && isFormUnlocked;

  const renderProfileProgressIcons = () => {
    const sections = Object.entries(profileProgressMap) as [ProfileSection, ProgressSection][];

    return sections
      .map(([key, val]) => {
        const { isDisabled, isUnlocked, isValid, isDirty, isComplete } = val;

        // 1. Hide Pregnancy (when disabled)
        if (isDisabled) {
          return null;
        }

        // 2. Locked Section
        if (!isUnlocked) {
          return (
            <Icon
              type="Circle"
              size="large"
              weight="fill"
              color="--colors-gray-300"
              key={`cta-step-${key}`}
            />
          );
        }

        // ~ Define States
        const isCurrent = isUnlocked && !isDirty;
        const isError = isDirty && !isValid;

        // ~ Icon
        let iconProps;
        if (isCurrent)
          iconProps = {
            type: 'Circle',
            color: '--colors-gray-300',
            size: 'large',
            weight: 'regular',
          };
        if (isError)
          iconProps = {
            type: 'XCircle',
            color: '--primary-red',
            size: 'large',
            weight: 'fill',
          };
        if (!isComplete)
          iconProps = {
            type: 'Circle',
            color: '--colors-gray-300',
            size: 'large',
            weight: 'regular',
          };
        if (isValid)
          iconProps = {
            type: 'CheckCircle',
            color: '--primary-blue',
            size: 'large',
            weight: 'fill',
          };

        // 3. Unlocked Routes
        const routeLabel = retrieveContentfulData<string>(`call_to_action.tooltips.profile.${key}`);

        return (
          <React.Fragment key={key}>
            {device !== 'mobile' ? (
              <a
                key={`cta-step-${key}`}
                href={`#${key}-section`}
                data-testid={`cta-step-${key}`}
                // TODO: Contentful
                aria-label={`Scroll to ${routeLabel} section`}
              >
                <Tooltip
                  isInteractive
                  id={`cta-${key}-tooltip`}
                  label={routeLabel}
                  desktopOverrides={{
                    offset: [0, 24],
                  }}
                  aria-hidden
                >
                  <Icon {...iconProps} />
                </Tooltip>
              </a>
            ) : (
              <a key={`cta-step-${key}`} href={`#${key}-section`} data-testid={`cta-step-${key}`}>
                <Icon {...iconProps} />
              </a>
            )}
          </React.Fragment>
        );
      })
      .filter((i) => i);
  };

  return (
    <div style={{ zIndex: 1 }}>
      <FadeIn isOpen={device !== 'mobile' || !isFormComplete}>
        <ProfileContainer>{renderProfileProgressIcons()}</ProfileContainer>
      </FadeIn>
    </div>
  );
};

export default Profile;
