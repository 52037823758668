/**
 * app.tsx
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

// Needed for redux-saga es6 generator support
import 'core-js/stable';

// Polyfill intersection-observer for IE
import 'intersection-observer';

import 'sanitize.css/sanitize.css';

import { AppState, Auth0Provider } from '@auth0/auth0-react';
import chmln from '@chamaeleonidae/chmln';
import { extraErrorDataIntegration } from '@sentry/integrations';
import * as Sentry from '@sentry/react';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import App from 'Containers/App';
import { ContentfulType } from 'ContentfulDefaults/ContentfulType';
import FeatureFlagProvider from 'Contexts/featureFlagContext';
import { ProgressMap } from 'Contexts/profileContext';
import ScrollToTop from 'Shared/scrollToTop';

import { withResources } from './configureApp';
import { configureReduxStore } from './configureReduxStore';
import { DEPLOY_ENVIRONMENTS, SENTRY_IGNORE } from './constants';
import { GlobalReducerState } from './reducers';

declare global {
  interface Window {
    _env_: {
      INSIGHTS_URL?: string;
      INSIGHTS_API_KEY?: string;
      DEPLOY_ENV?: DEPLOY_ENVIRONMENTS;
      REACT_APP_AUTH0_CLIENT_ID?: string;
      REACT_APP_AUTH0_DOMAIN?: string;
      REACT_APP_AUTH0_AUDIENCE?: string;
      REACT_APP_DOC_FINDER_URL?: string;
      REACT_APP_CHAMELEON_TOKEN?: string;
    };
  }
}

const clientId = window._env_.REACT_APP_AUTH0_CLIENT_ID || '';
const domain = window._env_.REACT_APP_AUTH0_DOMAIN || '';
const audience = window._env_.REACT_APP_AUTH0_AUDIENCE || '';

const onRedirectCallback = (appState?: AppState): void => {
  window.onbeforeunload = null; // Removes the page exit protection
  // Do we need to do this? What exactly does the auth0 provider do on its own?
  window.location.assign(appState && appState.returnTo ? appState.returnTo : window.location.pathname);
};

const deployEnvironment = window._env_.DEPLOY_ENV || DEPLOY_ENVIRONMENTS.DEV;

Sentry.init({
  dsn: 'https://4c587ac0d37c4067b0078ef872079230@o31114.ingest.sentry.io/5711627',
  environment: deployEnvironment,
  integrations: [extraErrorDataIntegration()],
  ignoreErrors: SENTRY_IGNORE,
});

// Init Chameleon
chmln.init(window._env_.REACT_APP_CHAMELEON_TOKEN, {
  fastUrl: 'https://fast.chameleon.io/',
});

const MOUNT_NODE = document.getElementById('app');

// Create redux store with history
const appStateJson = localStorage.getItem('store');
const contentfulJson = localStorage.getItem('contentful');
const profileProgressJson = localStorage.getItem('profileMap');

const initialTextMap: ContentfulType = JSON.parse(contentfulJson || '{}');
const initialProfileMap: ProgressMap | Record<string, never> = JSON.parse(profileProgressJson || '{}');
const initialState: GlobalReducerState | Record<string, never> = JSON.parse(appStateJson || '{}');

const store = configureReduxStore(initialState, deployEnvironment);
const getStore = () => store.getState() as GlobalReducerState;

const render = () => {
  if (!MOUNT_NODE || !store) {
    return;
  }
  const root = createRoot(MOUNT_NODE);
  root.render(
    withResources(
      <BrowserRouter>
        <ScrollToTop>
          <FeatureFlagProvider searchParams={new URL(document.location.href).searchParams}>
            <Auth0Provider
              domain={domain}
              clientId={clientId}
              issuer={domain}
              authorizationParams={{
                redirect_uri: window.location.origin,
                audience,
              }}
              onRedirectCallback={onRedirectCallback}
            >
              <App
                initialTextMap={initialTextMap as never}
                initialProfileMap={initialProfileMap as never}
                getStore={getStore as never}
              />
            </Auth0Provider>
          </FeatureFlagProvider>
        </ScrollToTop>
      </BrowserRouter>,
      store,
    ),
  );
};

render();
