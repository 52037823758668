import { useDisclosure } from '@chakra-ui/react';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';

import EducationalDrawer from 'Containers/_drawers/EducationalDrawer';
import { Button, H3, H6, Spinner } from 'DesignLibrary/atoms';
import RichText from 'Shared/RichText';
import { Recommendation, SAVINGS_ACCOUNT_TYPES } from 'Types/entities';
import { BuilderGoSettings, GetHsaRecommendationResponse } from 'Utils/apiTypes';
import { toDollars } from 'Utils/helpers';

import { Interactive } from './Interactive';
import { BottomContent, Bullets, ChartContainer, TopContent, LoadingState } from './styled';
import { AccountContainer } from '../_shared/AccountContainer';
import { Bullet } from '../_shared/Bullet';
import { ForecastData, Persona } from '../types';

interface HsaSectionStateProps {
  employerHsaContribution: number;
  isEmployerMatching: boolean;
  employerHsaPercentageMatch: number | null;
  hsaData: GetHsaRecommendationResponse | Record<string, never>;
  forecastData: ForecastData | Record<string, never>;
  recommendedHsaContribution: number;
  isHsaRecommendationLoading: boolean;
  isForecastLoading: boolean;
  selectedPlan: Recommendation;
  lockedHsaContribution: number | null;
  persona: Persona;
  currentHsaBalance: number | null;
  userContributionLimit: number;
  individualHsaContributionLimit: number;
  familyHsaContributionLimit: number;
  hsaContributionLimit: number;
  catchupEligible: boolean;
  retirementAge: number;
}

interface HsaSectionDispatchProps {
  fetchHsaRecommendation: () => void;
  handleLockedHsaContribution: (contribution: number) => void;
  handleHsaForecastInputs: (balance: number, persona: Persona, retirementAge: number) => void;
}

interface HsaProps {
  builderGoSettings: BuilderGoSettings | null;
}

export type HsaSectionProps = HsaProps & HsaSectionStateProps & HsaSectionDispatchProps;

export const HsaSection = ({
  hsaData,
  forecastData,
  recommendedHsaContribution,
  isHsaRecommendationLoading,
  isForecastLoading,
  selectedPlan,
  lockedHsaContribution,
  persona,
  currentHsaBalance,
  userContributionLimit,
  hsaContributionLimit,
  individualHsaContributionLimit,
  familyHsaContributionLimit,
  catchupEligible,
  isEmployerMatching,
  employerHsaPercentageMatch,
  employerHsaContribution = 0,
  retirementAge,
  fetchHsaRecommendation,
  handleLockedHsaContribution,
  handleHsaForecastInputs,
  builderGoSettings,
}: HsaSectionProps) => {
  const { isOpen: eduOpen, onOpen: eduOnOpen, onClose: eduOnClose } = useDisclosure();

  const [contribution, setContribution] = useState(0);
  const isPlanSelected = !isEmpty(selectedPlan);

  useEffect(() => {
    if (isPlanSelected) {
      fetchHsaRecommendation();
    }
  }, []);

  useEffect(() => {
    // The initial value of HSA data is set to an empty object
    // We don't want to set contribution until we have an actual recommended_contribution value from hsaData
    if (!isEmpty(hsaData)) {
      const newContribution =
        lockedHsaContribution !== null ? lockedHsaContribution : recommendedHsaContribution;
      setContribution(newContribution as number);
    }
  }, [hsaData]);

  // HSA Contribution vs Match
  const getHsaVars = () => {
    if (employerHsaPercentageMatch) {
      return [employerHsaPercentageMatch, toDollars(employerHsaContribution)];
    }
    if (employerHsaContribution) {
      return [toDollars(employerHsaContribution)];
    }
    return null;
  };

  return (
    <>
      <AccountContainer account="hsa">
        <TopContent>
          {/* TODO: CONTENTFUL Add Field */}
          <H6 color="--colors-blue-900">HSA Contributions</H6>
          <H3 className="callout" as="p" weight="normal" lineHeight={1.5}>
            {/* TODO: CONTENTFUL Relocate/Update Field */}
            <RichText
              field="hsa.contribution_explainer.header"
              vars={[toDollars(recommendedHsaContribution / 12), toDollars(recommendedHsaContribution)]}
            />
          </H3>
          <Bullets>
            {employerHsaContribution > 0 && (
              <Bullet icon="CurrencyDollar" color="blue">
                {/* TODO: CONTENTFUL Relocate/Update Field */}
                <RichText
                  field={
                    isEmployerMatching
                      ? 'hsa.contribution_explainer.employer_match'
                      : 'hsa.contribution_explainer.employer_contribution'
                  }
                  vars={getHsaVars()}
                />
              </Bullet>
            )}

            <Bullet icon="Cursor" color="blue">
              {/* TODO: CONTENTFUL Add Field */}
              You can adjust your contribution amount by dragging the slider below.
            </Bullet>
            {catchupEligible && (
              <Bullet icon="CurrencyDollar" color="blue" dataTestId="bullet-catchup-info">
                {/* TODO: CONTENTFUL Relocate/Update Field */}
                <RichText field="hsa.catchup_info" />
              </Bullet>
            )}
          </Bullets>
        </TopContent>

        <ChartContainer>
          {isHsaRecommendationLoading ? (
            <LoadingState>
              <Spinner color="--border-gray" />
            </LoadingState>
          ) : (
            <Interactive
              userContributionLimit={userContributionLimit}
              hsaContributionLimit={hsaContributionLimit}
              hsaData={hsaData}
              forecastData={forecastData}
              selectedPlan={selectedPlan}
              employerHsaContribution={employerHsaContribution}
              isForecastLoading={isForecastLoading}
              contribution={contribution}
              currentHsaBalance={currentHsaBalance}
              persona={persona}
              retirementAge={retirementAge}
              lockedHsaContribution={lockedHsaContribution}
              handleLockedHsaContribution={handleLockedHsaContribution}
              setContribution={setContribution}
              handleHsaForecastInputs={handleHsaForecastInputs}
              recommendedHsaContribution={recommendedHsaContribution}
            />
          )}
        </ChartContainer>

        <BottomContent>
          <Bullets>
            <Bullet icon="QuestionOutline" color="blue">
              <div className="include-button">
                {/* TODO: CONTENTFUL Add Field */}
                Have any additional HSA questions?
                <Button onClick={eduOnOpen} size="small">
                  {/* TODO: CONTENTFUL Reuse 'Learn More' field */}
                  Learn More
                </Button>
              </div>
            </Bullet>
            <Bullet icon="Check" color="blue">
              {/* TODO: CONTENTFUL Add Field */}
              If not, you’re all set with your HSA contribution of {toDollars(lockedHsaContribution)}
              /yr. Your employer will have more info about enrolling on your enrollment platform.
            </Bullet>
          </Bullets>
        </BottomContent>
      </AccountContainer>
      <EducationalDrawer
        isOpen={eduOpen}
        infoType={SAVINGS_ACCOUNT_TYPES.HSA}
        individualHsaContributionLimit={individualHsaContributionLimit}
        familyHsaContributionLimit={familyHsaContributionLimit}
        employerContribution={employerHsaContribution}
        selectedPlan={selectedPlan}
        handleClose={eduOnClose}
        shouldShowEducationalVideo={!!builderGoSettings?.show_hsa_video}
      />
    </>
  );
};
