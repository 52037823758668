import { ContentfulBenefitsSection } from 'ContentfulDefaults/types/benefits';

export const benefits: {
  benefits_section: ContentfulBenefitsSection;
} = {
  benefits_section: {
    contentful_name: 'Benefits Section (DEFAULT DO NOT CHANGE)',
    title_info_bar: {
      healthPlan: '¡Genial, ha seleccionado un plan de atención médica!',
      noHealthPlan: 'Ha optado por no inscribirse en un plan de salud.',
      hsaContribution: 'Su contribución HSA ha sido bloqueada.',
    },
    button_text: {
      allDone: 'Sí, revisemos',
      undoPlan: 'Cambié de idea',
      breakdown: 'DESGLOSE DEL PLAN',
      learnMore: 'Lee mas',
      noDetails: 'PDF no disponible',
      plansFrom: 'Planes desde',
      viewPlans: 'Ver planes',
      goToReview: 'Continuar revisando',
      selectPlan: 'Seleccione este plan',
      updatePlan: 'Actualizar selección',
      addCoverage: 'Agregar más cobertura',
      planDetails: 'Detalles completos',
      learnMorePdf: 'Aprender más (PDF)',
      selectedPlan: 'Plan seleccionado',
      unselectPlan: 'Deseleccionar plan',
      hideBreakdown: 'OCULTAR EL DESGLOSE',
      notInterested: 'No interesado',
      backToBenefits: 'Volver a todos los beneficios',
      choosePlan: 'Elija un plan',
      notEligible: 'No es elegible',
    },
    benefits_heading: 'Elija Sus Beneficios',
    benefits_description: {
      data: {},
      content: [
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: 'Su empleador ofrece estos beneficios suplementarios voluntarios.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
      ],
      nodeType: 'document',
    },
    no_benefits_available:
      'Basado en su grupo de empleado, usted no es elegible para ningún beneficio adicional. Continúe a la página de revisión.',
    covered_benefits_title: 'Beneficios cubiertos',
    card_additions: {
      onePlan: '1 plan de',
      manyPlans: '{x} planes de',
      fullDetails: '... (ver detalles completos)',
      hraIncluded: 'Usted está viendo esto porque se ha inscrito en un plan de salud elegible para HRA.',
      hsaIncluded: 'Usted está viendo esto porque se ha inscrito en un plan de salud elegible para HSA.',
    },
    footer_text: {
      data: {},
      content: [
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: 'Ha elegido ',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [
                {
                  type: 'bold',
                },
                {
                  type: 'code',
                },
              ],
              value: '{x}',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [
                {
                  type: 'bold',
                },
              ],
              value: ' planes complementarios',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [],
              value: ' (incluidos los inscritos automáticamente). ¿Todo listo?',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
      ],
      nodeType: 'document',
    },
    no_plans_footer_text: 'Si estás listo, revisemos.',
    auto_enrolled_info: {
      tagText: 'Pagado por el empleador',
      tipText: 'Su empleador ofrece este beneficio sin costo adicional.',
      tipTitle: 'Auto inscrito',
      modalTitle: 'Su empleador ofrece un plan de inscripción automática',
      moreCoverage:
        'También puede optar por comprar la siguiente cobertura que proporciona un beneficio más allá de lo que cubre su plan de inscripción automática.',
    },
    reasons_why_heading: {
      title: 'Por qué podría considerar un seguro {x}',
    },
    reasons_why: {
      sports: 'Si sus hijos practican algún deporte.',
      eyeExams:
        'Los oftalmólogos recomiendan exámenes de la vista cada uno o dos años. Esta cobertura ayuda a pagar esto, junto con otros cuidados de la vista que pueda necesitar.',
      age40to50:
        'Usted dijo que usted o su pareja tienen más de 40 años de edad. Si tiene antecedentes familiares de alguna afección cubierta, es posible que corra un mayor riesgo.',
      ageOver50:
        'Usted dijo que usted o su pareja tienen más de 55 años de edad. Puede beneficiarse de este tipo de seguro debido al mayor riesgo relacionado con la edad de ser diagnosticado con una afección cubierta.',
      childcare:
        'Si planea pagar cuidado infantil (childcare) calificado, esta cuenta puede ayudarle a ahorrar en impuestos.',
      pregnancy: 'Ha indicado que está planeando un embarazo.',
      eyeGlasses: 'Esta cobertura le ayuda a pagar anteojos o lentes de contacto, si los necesita.',
      childDental:
        'Tiene 1 hijo(a). Si tiene o necesita aparatos ortopédicos, esta cobertura le ayuda a reducir el costo de la ortodoncia.',
      moderateRisk:
        'Según sus respuestas a nuestras preguntas de riesgo, determinamos que preferiría costos menos inesperados, incluso si tuviera que pagar primas más altas.',
      childActivity:
        'Tiene un hijo, y todos sabemos que los niños pueden ser propensos a sufrir accidentes, especialmente si son físicamente activos o practican deportes.',
      childrenDental:
        'Tiene {x} hijos. Si tienen o necesitan aparatos ortopédicos, esta cobertura le ayuda a reducir el costo de la ortodoncia.',
      lifeDependent:
        'Debido a que tiene dependientes, es posible que desee considerar agregar un seguro de vida para asegurarse de que las necesidades financieras de su familia estén cubiertas, incluso si usted no está presente.',
      youngDependent: 'Ya que tiene niños menores de 6 años.',
      dentalCleanings: 'Esta cobertura le ayudará a cubrir el costo de cuidados dentales de rutina.',
      extraProtection:
        'Usted dijo que podía pagar {x} por una factura médica inesperada, lo que significa que podría beneficiarse especialmente de una protección adicional contra la pérdida de salarios por discapacidad.',
      childrenActivity:
        'Usted tiene {x} hijo(s), y todos sabemos que los niños pueden ser propensos a sufrir accidentes, especialmente si son físicamente activos o practican deportes.',
      hospitalAdmission:
        'Usted dijo que usted o alguien de su familia ingresó en un hospital en los últimos 12 meses. Las personas con ingresos hospitalarios previos tienen más probabilidades que el promedio de sufrir un ingreso hospitalario en el futuro.',
      longTermDisability:
        'Es posible que desee agregar cobertura de incapacidad a largo plazo, lo cual es una manera asequible de reducir su riesgo financiero si queda incapacitado. Vale la pena darle un vistazo.',
      tobacco: 'Tobacco use increases the risk of being diagnosed with a covered condition.',
      parentalLeave: 'This coverage can help cover lost wages for any time off work following childbirth.',
      prescriptions: 'People who take a similar amount of prescriptions tend to select these policies.',
      highDeductLowCapacity:
        'Ha seleccionado un plan médico con deducible {x} y ha indicado que la factura más grande que puede pagar por cualquier atención médica inesperada es de {x}.',
      plannedProcedureInpatient:
        'Nos ha dicho que usted o un miembro de su familia planea someterse a un procedimiento en el hospital en los próximos 12 meses.',
    },
    your_plans_heading: 'Sus {x} planes',
    member_coverage_text: {
      data: {},
      content: [
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: 'Estas cubriendo ',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [
                {
                  type: 'code',
                },
                {
                  type: 'bold',
                },
              ],
              value: '{x}',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
      ],
      nodeType: 'document',
    },
    coverage_options: {
      all: 'Todos',
      allV3: 'Toda la familia',
      yourself: 'usted mismo',
      isCovered: 'Sí, cubierto',
      notCovered: 'Descubierto',
      yourselfV3: 'Usted',
      youAndSpouse: 'Tu & tu esposo / pareja',
      familyMembers: '{x} miembros de la familia',
      youAndSpouseV3: 'Tu y tu esposo / pareja',
      familyMembersV3: '{x} Miembros de la familia',
    },
    single_plan: {
      data: {},
      content: [
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: 'Hay ',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [
                {
                  type: 'bold',
                },
              ],
              value: '1 plan',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [],
              value: ' para elegir.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
      ],
      nodeType: 'document',
    },
    multiple_plans: {
      data: {},
      content: [
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: 'Hay ',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [
                {
                  type: 'bold',
                },
                {
                  type: 'code',
                },
              ],
              value: '{x}',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [
                {
                  type: 'bold',
                },
              ],
              value: ' planes',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [],
              value: ' para elegir.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
      ],
      nodeType: 'document',
    },
    learn_more_text: 'Obtenga más información sobre lo que ofrece este beneficio.',
    plan_card_text: {
      benefits: {
        title: 'Beneficios cubiertos',
        covered: 'Cubierto',
        payouts: 'Pagos de beneficios cubiertos',
        deductible: 'Deducible',
        maxBenefit: 'Máximo beneficio anual',
        notCovered: 'No cubierto',
        orthodontia: 'Ortodoncia',
        seeFullDetails: 'Ver detalles completos.',
      },
      exclusions: {
        title: 'Exclusiones y Limitaciones',
        instructions: 'Para exclusiones y limitaciones, consulte el desglose del plan.',
      },
      notEligible: 'Con su selección actual de miembro de la familia, no es elegible para este plan.',
      notEligibleWarning: {
        yourself: 'Solo el titular de la póliza es elegible',
        youAndSpouse: 'Solo el titular de la póliza y su cónyuge son elegibles',
        youAndDependents: 'Solo los titulares de pólizas y sus dependientes son elegibles',
      },
    },
    benefit_page_heading: 'Seguro de {x}',
    dental_benefit: {
      contentful_name: 'Dental Benefit',
      icon_link: 'https://picwellapppublicdata.s3.amazonaws.com/contentful/insuranceicons/dental.svg',
      card_title: 'Dental',
      card_description: 'Cubre chequeos dentales, cirugía dental imprevista, ortodoncia y más.',
      modal_description: {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  'La atención dental de rutina es una parte importante para mantener su salud general, y el seguro dental puede ayudarlo a pagar los gastos dentales y de ortodoncia esperados e inesperados. Cuando tiene seguro dental, paga los precios que su asegurador dental ha negociado con su dentista u ortodoncista, y su seguro lo ayudará a cubrir una parte de esos costos.',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
        ],
        nodeType: 'document',
      },
      insurance_info: {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  'Los planes de seguro dental ayudan a cubrir algunos de sus costos dentales y de ortodoncia. Los planes dentales generalmente tienen un monto máximo que pagarán cada año.',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
        ],
        nodeType: 'document',
      },
      video_link: 'https://vimeo.com/499305616',
      notes: {
        note: 'Incluso si excede el monto máximo del beneficio, se beneficia del precio que negocia su proveedor de seguros. Las aseguradoras dentales negocian los precios con los proveedores de su red, y usted obtiene acceso a este precio preferido cuando utiliza proveedores de la red.',
        title: 'Máximo beneficio dental',
      },
    },
    vision_benefit: {
      contentful_name: 'Vision Benefit',
      icon_link: 'https://picwellapppublicdata.s3.amazonaws.com/contentful/insuranceicons/vision.svg',
      card_title: 'Visión',
      card_description:
        'El seguro de la vista ayuda a cubrir exámenes de la vista, anteojos, lentes de contacto y problemas de visión imprevistos.',
      modal_description: {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  'Si usa anteojos o lentes de contacto o si planea hacerse exámenes de la vista de rutina, el seguro de visión puede ayudarlo a cubrir sus costos.',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
        ],
        nodeType: 'document',
      },
      insurance_info: {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  'Los planes de seguro de la vista ayudan a cubrir los costos de los exámenes de la vista de rutina, lentes de contacto y anteojos, y algunos planes de seguro de la vista pueden ayudar a cubrir algunos procedimientos correctivos.',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
        ],
        nodeType: 'document',
      },
      video_link: 'https://vimeo.com/499305687',
      notes: {
        note: 'Los planes de seguro de la vista generalmente tienen un beneficio máximo que pagarán cada año, pero aún puede tener acceso a precios con descuento que su proveedor de seguro negocia incluso si excede el monto máximo del beneficio.',
        title: 'Máximo beneficio de visión',
      },
    },
    accident_benefit: {
      contentful_name: 'Accident Benefit',
      icon_link: 'https://picwellapppublicdata.s3.amazonaws.com/contentful/insuranceicons/accident.svg',
      card_title: 'Accidente',
      card_description:
        'El seguro de accidentes proporciona un pago si sufre lesiones accidentales cubiertas.',
      modal_description: {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  'Los accidentes y las lesiones inesperadas son un hecho de la vida. Incluso las personas más sanas y más conscientes de la seguridad pueden encontrarse en el lado equivocado del accidente. El seguro de accidentes ayuda a cubrir estas facturas médicas imprevistas y gastos de bolsillo para usted o un familiar cubierto.',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
        ],
        nodeType: 'document',
      },
      insurance_info: {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  'Las pólizas de seguro de accidentes le pagan en efectivo para ayudarlo a administrar algunos de sus gastos si sufre una lesión accidental cubierta. La forma en que use su pago en efectivo depende completamente de usted.',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
        ],
        nodeType: 'document',
      },
      video_link: 'https://vimeo.com/499305974',
      notes: {
        note: 'La cantidad que recibe depende de su lesión y el tratamiento, que generalmente es una cantidad establecida para lesiones o cuidados cubiertos específicos.',
        title: 'Nota sobre pagos',
      },
    },
    illness_benefit: {
      contentful_name: 'Illness Benefit',
      icon_link:
        'https://picwellapppublicdata.s3.amazonaws.com/contentful/insuranceicons/critical-illness.svg',
      card_title: 'Enfermedad crítica',
      card_description:
        'El seguro de enfermedad crítica proporciona un pago si le diagnostican afecciones cubiertas.',
      modal_description: {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  'Además de su salud física, ser diagnosticado con una enfermedad puede tener un gran impacto en su salud financiera. Ya sea debido a facturas médicas más altas u otros costos en los que pueda incurrir para ayudarlo a lidiar con sus actividades diarias después de un diagnóstico, el seguro de Enfermedades Críticas puede ayudarlo a manejar mejor estos costos inesperados.',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
        ],
        nodeType: 'document',
      },
      insurance_info: {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  'El seguro de enfermedades críticas le paga en efectivo si le diagnostican ciertas enfermedades críticas. En la mayoría de las pólizas, selecciona un pago máximo de por vida y la póliza pagará un porcentaje de ese monto si se le diagnostica una afección cubierta.',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
        ],
        nodeType: 'document',
      },
      video_link: 'https://vimeo.com/499306025',
      notes: {
        note: 'Las exclusiones por condiciones preexistentes y los períodos de espera se aplican a la mayoría de los pagos, así que asegúrese de revisar estos detalles antes de comprar una póliza.',
        title: 'Nota sobre pagos',
      },
    },
    hospital_benefit: {
      contentful_name: 'Hospital Benefit',
      icon_link:
        'https://picwellapppublicdata.s3.amazonaws.com/contentful/insuranceicons/hospital-indemnity.svg',
      card_title: 'Indemnización hospitalaria',
      card_description:
        'El seguro de indemnización hospitalaria cubre lesiones imprevistas y ayuda a pagar los gastos hospitalarios.',
      modal_description: {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  'Casi la mitad de los estadounidenses dicen que no pueden pagar una factura médica sorpresa de $ 500 o más e, incluso si tiene seguro médico, una estadía en el hospital puede dejarlo con una factura médica alta. Los planes de indemnización hospitalaria le pagan dinero si está hospitalizado, pero la forma en que lo use depende de usted.',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
        ],
        nodeType: 'document',
      },
      insurance_info: {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  'Muchos planes de seguro médico requerirán que pague mucho si termina en el hospital, ya sea debido a deducibles, coseguros o copagos, o cargos fuera de la red. Estos planes generalmente le pagan un monto en función de la duración de su estadía, y puede usar estos fondos como lo desee, ya sea para cubrir facturas médicas o para pagar alimentos, alojamiento o transporte para los seres queridos que visitan mientras usted o un un familiar cubierto está en el hospital.',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
        ],
        nodeType: 'document',
      },
      video_link: 'https://vimeo.com/499306092',
      notes: {
        note: 'Se pueden aplicar exclusiones por condiciones preexistentes y períodos de espera a los pagos, así que asegúrese de revisar estos detalles antes de comprar una póliza.',
        title: 'Nota sobre pagos',
      },
    },
    life_benefit: {
      contentful_name: 'Life Benefit ',
      icon_link: 'https://picwellapppublicdata.s3.amazonaws.com/contentful/insuranceicons/life.svg',
      card_title: 'Vida',
      card_description:
        'El seguro de vida proporciona un pago al beneficiario al fallecer cualquier vida cubierta.',
      modal_description: {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  'Esperamos que siempre estaremos cerca para proveer a nuestros seres queridos, pero el seguro de vida le brinda una forma de planificar lo peor, dándole a sus dependientes fondos para cubrir los costos si fallece. Las pólizas de seguro de vida son una herramienta importante que puede utilizar para garantizar la seguridad financiera de su familia.',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
        ],
        nodeType: 'document',
      },
      insurance_info: {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  'Las pólizas de seguro de vida pagan un "beneficio por muerte" que viene en forma de un único pago de suma global si fallece. Puede elegir la cantidad de seguro de vida que desea comprar, por lo que puede planear tener suficiente para cubrir las obligaciones financieras de su familia si ya no está presente.',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
        ],
        nodeType: 'document',
      },
      video_link: 'https://vimeo.com/499305757',
      notes: {
        note: 'Los pagos del seguro de vida no tienen condiciones. Su familia puede usarlos para cubrir costos como vivienda, educación, deudas y costos funerarios.',
        title: 'Nota sobre pagos',
      },
    },
    death_dismemberment_benefit: {
      contentful_name: 'Death & Dismemberment Benefit',
      icon_link: 'https://picwellapppublicdata.s3.amazonaws.com/contentful/insuranceicons/add.svg',
      card_title: 'Muerte accidental y desmembramiento',
      card_description:
        'El seguro de muerte accidental y desmembramiento (AD&D) le pagará a usted o a su beneficiario una suma global si muere o es desmembrado en un accidente.',
      modal_description: {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  'El seguro de muerte accidental y desmembramiento (AD&D) le pagará a usted o a su beneficiario una suma global si muere o es desmembrado en un accidente. Cuando compra una póliza AD&D, puede seleccionar la cantidad de cobertura, que determina la cantidad que usted o su beneficiario recibirán en caso de muerte accidental o desmembramiento. Si tiene una póliza AD&D y una póliza de seguro de vida, sus beneficiarios recibirán pagos completos de ambas pólizas en caso de un accidente fatal. Si tiene un accidente que resulta en la pérdida de la vista, el habla, la audición o una extremidad, el monto del pago dependerá de la naturaleza del desmembramiento.',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
        ],
        nodeType: 'document',
      },
      insurance_info: {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  'El monto de la cobertura afectará su prima, y también puede pagar una prima más alta si trabaja en una ocupación de alto riesgo o realiza actividades rutinarias de alto riesgo. Asegúrese de revisar cuidadosamente los detalles de su plan para obtener más información.',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
        ],
        nodeType: 'document',
      },
      video_link: 'https://vimeo.com/499305907',
    },
    std_benefit: {
      contentful_name: 'Short Term Disability Benefit',
      icon_link: 'https://picwellapppublicdata.s3.amazonaws.com/contentful/insuranceicons/std.svg',
      card_title: 'Discapacidad a corto plazo',
      card_description:
        'El Seguro de Incapacidad a Corto Plazo paga una parte de sus ingresos si no puede trabajar debido a una enfermedad o lesión dentro de un plazo específico.',
      modal_description: {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  'El seguro de incapacidad a corto plazo puede ayudar a reemplazar parte de sus ingresos si no puede trabajar por un período corto, generalmente de 3 a 6 meses. Esta política reemplazará un porcentaje de sus ingresos si tiene que faltar al trabajo por un corto período de tiempo; sin embargo, deberá esperar una cierta cantidad de tiempo antes de que la política comience a realizar los pagos.',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
        ],
        nodeType: 'document',
      },
      insurance_info: {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  'La mayoría de las políticas de discapacidad a corto plazo pagarán si usted toma un tiempo libre no remunerado del trabajo después del parto. La cantidad de cobertura y el período de espera pueden afectar su prima. Asegúrese de revisar cuidadosamente los detalles de su plan para obtener más información.',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
        ],
        nodeType: 'document',
      },
      video_link: 'https://vimeo.com/499305815',
    },
    ltd_benefit: {
      contentful_name: 'Long Term Disability Benefit',
      icon_link: 'https://picwellapppublicdata.s3.amazonaws.com/contentful/insuranceicons/disability.svg',
      card_title: 'Discapacidad a largo plazo',
      card_description:
        'El seguro de incapacidad a largo plazo paga una parte de sus ingresos si no puede trabajar debido a una enfermedad o lesión después de estar incapacitado por un período de tiempo específico.',
      modal_description: {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  'El seguro de incapacidad a largo plazo puede ayudar a reemplazar parte de sus ingresos si no puede trabajar por un período más largo, generalmente un período de más de seis meses. Esta política reemplazará un porcentaje de sus ingresos si tiene que faltar al trabajo; sin embargo, deberá esperar una cierta cantidad de tiempo antes de que la política comience a realizar los pagos. Este período de espera, o "período de eliminación", generalmente varía de 30 días a 2 años, pero el más común es de 90 días.',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
        ],
        nodeType: 'document',
      },
      insurance_info: {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  'La cantidad de cobertura y el período de espera pueden afectar su prima. Asegúrese de revisar cuidadosamente los detalles de su plan para obtener más información.',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
        ],
        nodeType: 'document',
      },
      video_link: 'https://vimeo.com/499305869',
    },
    retirement_benefit: {
      contentful_name: 'Retirement Benefit',
      icon_link: 'https://picwellapppublicdata.s3.amazonaws.com/contentful/insuranceicons/retirement.svg',
      card_title: 'Jubilación',
      card_description:
        'Los planes de ahorro para la jubilación le permiten ahorrar e invertir una parte de su sueldo antes de deducir los impuestos.',
      modal_description: {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  'Los ahorros para la jubilación son fundamentales para su plan de bienestar financiero a largo plazo, y las cuentas de ahorro para la jubilación con impuestos preferidos pueden ayudarlo a aprovechar al máximo sus contribuciones para la jubilación.',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
        ],
        nodeType: 'document',
      },
      insurance_info: {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  'Las cuentas de ahorro para la jubilación con impuestos preferidos le permiten ahorrar en impuestos, en comparación con otras cuentas de inversión. Estos ahorros de impuestos pueden hacer grandes diferencias en su salud financiera a largo plazo.',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  'Las cuentas de jubilación con impuestos preferidos pueden permitirle ahorrar en impuestos cuando realiza su contribución, lo que le permite contribuir a su cuenta con dólares antes de impuestos, o cuando retira la contribución, lo que le permite evitar pagar impuestos cuando retira fondos en Jubilación.',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
        ],
        nodeType: 'document',
      },
      video_link: 'https://vimeo.com/605082604',
    },
    four_o_one_k_benefit: {
      contentful_name: '401k Benefit',
      icon_link: 'https://picwellapppublicdata.s3.amazonaws.com/contentful/insuranceicons/retirement.svg',
      card_title: '401(k)',
      card_description:
        'Los planes 401k le permiten ahorrar e invertir una parte de su cheque de pago antes de deducir los impuestos.',
      modal_description: {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  'Los ahorros para la jubilación son fundamentales para su plan de bienestar financiero a largo plazo, y las cuentas de ahorro para la jubilación con impuestos preferidos pueden ayudarlo a aprovechar al máximo sus contribuciones para la jubilación.',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
        ],
        nodeType: 'document',
      },
      insurance_info: {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  'Las cuentas de ahorro para la jubilación con impuestos preferidos le permiten ahorrar en impuestos, en comparación con otras cuentas de inversión. Estos ahorros impositivos pueden marcar grandes diferencias en su salud financiera a largo plazo. ',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  'Las cuentas de jubilación con impuestos preferidos pueden permitirle ahorrar en impuestos cuando realiza su contribución, lo que le permite contribuir a su cuenta con dólares antes de impuestos o cuando retira la contribución, lo que le permite evitar pagar impuestos cuando retira fondos en la jubilación.',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
        ],
        nodeType: 'document',
      },
      video_link: 'https://vimeo.com/605082604',
    },
    hsa_benefit: {
      contentful_name: 'HSA Benefit',
      icon_link: 'https://picwellapppublicdata.s3.amazonaws.com/contentful/insuranceicons/hsa.svg',
      card_title: 'Cuenta de ahorro de salud',
      card_description:
        'Las cuentas de ahorro de salud (HSA) le permiten ahorrar dinero al pagar ciertos costos de atención médica de su bolsillo.',
      modal_description: {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  'Si bien el seguro de salud lo ayuda a cubrir el costo de la atención médica, no cubre todo. Una cuenta de ahorro de salud, o HSA para abreviar, es una excelente manera de ahorrar para esos gastos.',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
        ],
        nodeType: 'document',
      },
      insurance_info: {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                marks: [
                  {
                    type: 'italic',
                  },
                ],
                value: 'Así es como funciona:',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
          {
            data: {},
            content: [
              {
                data: {},
                content: [
                  {
                    data: {},
                    content: [
                      {
                        data: {},
                        marks: [],
                        value:
                          'Primero, después de inscribirse en un plan de salud elegible para HSA, abra una cuenta de ahorros de salud.',
                        nodeType: 'text',
                      },
                    ],
                    nodeType: 'paragraph',
                  },
                ],
                nodeType: 'list-item',
              },
              {
                data: {},
                content: [
                  {
                    data: {},
                    content: [
                      {
                        data: {},
                        marks: [],
                        value:
                          'Luego, elija un monto de contribución que se deducirá de su cheque de pago. Algunos empleadores lo ayudarán contribuyendo también a su HSA. Todo el dinero que no use este año es suyo, incluso si cambia de trabajo.',
                        nodeType: 'text',
                      },
                    ],
                    nodeType: 'paragraph',
                  },
                ],
                nodeType: 'list-item',
              },
            ],
            nodeType: 'unordered-list',
          },
          {
            data: {},
            content: [
              {
                data: {},
                marks: [
                  {
                    type: 'italic',
                  },
                ],
                value: 'Aquí hay algunas otras cosas que debes saber',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
          {
            data: {},
            content: [
              {
                data: {},
                content: [
                  {
                    data: {},
                    content: [
                      {
                        data: {},
                        marks: [],
                        value:
                          'Puede usar su HSA para pagar gastos médicos calificados, incluidos copagos médicos, gastos de visión y dentales, medicamentos recetados y más.',
                        nodeType: 'text',
                      },
                    ],
                    nodeType: 'paragraph',
                  },
                ],
                nodeType: 'list-item',
              },
              {
                data: {},
                content: [
                  {
                    data: {},
                    content: [
                      {
                        data: {},
                        marks: [],
                        value: 'Cualquier dinero que contribuya a una HSA reduce sus impuestos.',
                        nodeType: 'text',
                      },
                    ],
                    nodeType: 'paragraph',
                  },
                ],
                nodeType: 'list-item',
              },
              {
                data: {},
                content: [
                  {
                    data: {},
                    content: [
                      {
                        data: {},
                        marks: [],
                        value: 'Las personas pueden contribuir hasta ',
                        nodeType: 'text',
                      },
                      {
                        data: {},
                        marks: [
                          {
                            type: 'code',
                          },
                        ],
                        value: '{x}',
                        nodeType: 'text',
                      },
                      {
                        data: {},
                        marks: [],
                        value: ' por año y las familias pueden contribuir hasta ',
                        nodeType: 'text',
                      },
                      {
                        data: {},
                        marks: [
                          {
                            type: 'code',
                          },
                        ],
                        value: '{x}',
                        nodeType: 'text',
                      },
                      {
                        data: {},
                        marks: [],
                        value: ' por año.',
                        nodeType: 'text',
                      },
                    ],
                    nodeType: 'paragraph',
                  },
                ],
                nodeType: 'list-item',
              },
              {
                data: {},
                content: [
                  {
                    data: {},
                    content: [
                      {
                        data: {},
                        marks: [],
                        value:
                          'A medida que crece su saldo, puede invertir ese dinero. La mejor parte es que las ganancias y los retiros de gastos calificados están libres de impuestos.',
                        nodeType: 'text',
                      },
                    ],
                    nodeType: 'paragraph',
                  },
                ],
                nodeType: 'list-item',
              },
            ],
            nodeType: 'unordered-list',
          },
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  'Asegúrese de revisar cuidadosamente los detalles de su plan para obtener más información.',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
          {
            data: {},
            content: [
              {
                data: {},
                marks: [
                  {
                    type: 'italic',
                  },
                ],
                value: '',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
        ],
        nodeType: 'document',
      },
      video_link: 'https://vimeo.com/499305539',
    },
    hra_benefit: {
      contentful_name: 'HRA Benefit',
      icon_link: 'https://picwellapppublicdata.s3.amazonaws.com/contentful/insuranceicons/hra.svg',
      card_title: 'Acuerdo de reembolso de salud',
      card_description:
        'Los acuerdos de reembolso de salud (HRA) le permiten ahorrar dinero al pagar ciertos costos de atención médica de su bolsillo.',
      modal_description: {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  'Sus planes de seguro de salud vienen con un Acuerdo de reembolso de salud o HRA. Una HRA es una cuenta que su empleador establece y con la que contribuye dinero para ayudarlo a pagar los gastos de atención médica de bolsillo que califiquen. Esto podría incluir copagos médicos, gastos dentales y de la vista, medicamentos recetados y más.',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
        ],
        nodeType: 'document',
      },
      insurance_info: {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value: 'Así es como funciona:',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
          {
            data: {},
            content: [
              {
                data: {},
                content: [
                  {
                    data: {},
                    content: [
                      {
                        data: {},
                        marks: [],
                        value:
                          'Una vez que se inscriba en un plan elegible para HRA, su empleador financiará una cuenta que puede usar para compensar sus gastos médicos.',
                        nodeType: 'text',
                      },
                    ],
                    nodeType: 'paragraph',
                  },
                ],
                nodeType: 'list-item',
              },
            ],
            nodeType: 'unordered-list',
          },
          {
            data: {},
            content: [
              {
                data: {},
                content: [
                  {
                    data: {},
                    content: [
                      {
                        data: {},
                        marks: [],
                        value:
                          'A medida que recibe atención médica, la compañía de seguros pagará sus facturas médicas que están cubiertas por su plan médico de su HRA hasta que haya agotado todos los fondos asignados. Dependiendo de cómo su empleador configure su HRA, es posible que deba presentar sus recibos para el reembolso. Pero tenga en cuenta que sus reembolsos se limitan a la cantidad que su empleador financia su HRA.',
                        nodeType: 'text',
                      },
                    ],
                    nodeType: 'paragraph',
                  },
                ],
                nodeType: 'list-item',
              },
            ],
            nodeType: 'unordered-list',
          },
          {
            data: {},
            content: [
              {
                data: {},
                marks: [
                  {
                    type: 'italic',
                  },
                ],
                value: 'Aquí hay algunas otras cosas que debes saber',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
          {
            data: {},
            content: [
              {
                data: {},
                content: [
                  {
                    data: {},
                    content: [
                      {
                        data: {},
                        marks: [],
                        value:
                          'Si no utiliza todos los dólares de su HRA para el reembolso, es posible que pueda usar esos fondos en años futuros, dependiendo de cuánto le permita a su empleador transferir.',
                        nodeType: 'text',
                      },
                    ],
                    nodeType: 'paragraph',
                  },
                ],
                nodeType: 'list-item',
              },
            ],
            nodeType: 'unordered-list',
          },
          {
            data: {},
            content: [
              {
                data: {},
                content: [
                  {
                    data: {},
                    content: [
                      {
                        data: {},
                        marks: [],
                        value:
                          'Si decide inscribirse en un plan que no califica para HRA o deja a su empleador actual, perderá todo el dinero de HRA que su empleador ha contribuido.',
                        nodeType: 'text',
                      },
                    ],
                    nodeType: 'paragraph',
                  },
                ],
                nodeType: 'list-item',
              },
            ],
            nodeType: 'unordered-list',
          },
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  'Asegúrese de revisar cuidadosamente los detalles de su plan para obtener más información.',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value: '',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
        ],
        nodeType: 'document',
      },
      video_link: 'https://vimeo.com/499306151',
    },
    fsa_benefit: {
      contentful_name: 'FSA Benefit',
      icon_link: 'https://picwellapppublicdata.s3.amazonaws.com/contentful/insuranceicons/fsa.svg',
      card_title: 'Cuenta de gastos flexible',
      alternate_title: 'FSA de propósito limitado',
      card_description:
        'Las cuentas de gastos flexibles (FSA) le permiten ahorrar dinero al pagar ciertos costos de atención médica de su bolsillo.',
      alternate_description:
        'La cuenta de gastos flexibles de propósito limitado (FSA) es una cuenta de beneficios antes de impuestos que se usa para pagar los gastos de atención dental y de la vista calificados que no están cubiertos por su plan de seguro de salud.',
      modal_description: {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  'Si su plan de salud es elegible para una Cuenta de Gastos Flexibles (FSA), puede aprovechar esta cuenta para ayudar a pagar los gastos médicos. Es importante contribuir solo lo que cree que necesitará usar, ya que los fondos de la FSA deben usarse dentro del año en que se realizan las contribuciones. Los fondos no utilizados regresan a su empleador al final del año.',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
        ],
        nodeType: 'document',
      },
      insurance_info: {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  'Las FSA le permiten reservar hasta $ 2,650 por año en contribuciones libres de impuestos para cubrir gastos médicos. Puede contribuir el doble si su cónyuge está cubierto por el mismo plan. Las contribuciones de la FSA deben usarse antes de fin de año Los saldos no utilizados se revierten a su empleador, por lo que es importante que solo contribuya lo que cree que necesitará usar. Los empleadores pueden ofrecer un período de gracia de hasta 2.5 meses adicionales para usar los fondos de la FSA y también pueden permitirle transferir hasta $ 500 para usar en el año siguiente.',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
        ],
        nodeType: 'document',
      },
      video_link: 'https://vimeo.com/499306201',
      alternate_video_link: 'https://vimeo.com/605082480',
    },
    medical_fsa_benefit: {
      contentful_name: 'Medical FSA Benefit',
      icon_link: 'https://picwellapppublicdata.s3.amazonaws.com/contentful/insuranceicons/fsa_m.svg',
      card_title: 'FSA médica',
      alternate_title: 'FSA de propósito limitado',
      card_description:
        'La Cuenta de gastos médicos flexibles (FSA) es una cuenta de beneficios antes de impuestos que se utiliza para pagar los gastos médicos, dentales y de la vista calificados que no están cubiertos por su plan de seguro de salud.',
      alternate_description:
        'La cuenta de gastos flexibles de propósito limitado (FSA) es una cuenta de beneficios antes de impuestos que se usa para pagar los gastos calificados de atención dental y de la vista que no están cubiertos por su plan de seguro de salud ".',
      modal_description: {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  'Una cuenta de gastos médicos flexibles, o Medical FSA, es una cuenta de beneficios antes de impuestos que puede usar para pagar los gastos médicos, dentales y de la vista calificados que no están cubiertos por su plan de seguro de salud.',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
        ],
        nodeType: 'document',
      },
      insurance_info: {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                marks: [
                  {
                    type: 'italic',
                  },
                ],
                value: 'Así es como funciona',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  'Abra una cuenta y decida cuánto contribuir. Luego, su empleador deduce el monto de su contribución (en partes iguales) de sus cheques de pago durante todo el año del plan. Y la buena noticia: ¡no tiene que esperar a que se acumulen fondos en su FSA médica! El monto total de su elección anual está disponible para usted el primer día del año de su plan. Una vez hecho esto, puede usar su FSA médica para ayudar a pagar cientos de gastos de atención médica elegibles, como copagos por visitas al médico, medicamentos recetados y anteojos o lentes de contacto nuevos.',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
          {
            data: {},
            content: [
              {
                data: {},
                marks: [
                  {
                    type: 'italic',
                  },
                ],
                value: 'Ahora, solo hay algunos otros puntos importantes sobre Medical FSA',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
          {
            data: {},
            content: [
              {
                data: {},
                content: [
                  {
                    data: {},
                    content: [
                      {
                        data: {},
                        marks: [],
                        value:
                          'Si no utiliza todos sus fondos de la FSA, es posible que pueda transferir más de $ 500 al próximo año del plan, pero perderá las contribuciones sobrantes por encima de ese límite de transferencia.',
                        nodeType: 'text',
                      },
                    ],
                    nodeType: 'paragraph',
                  },
                ],
                nodeType: 'list-item',
              },
              {
                data: {},
                content: [
                  {
                    data: {},
                    content: [
                      {
                        data: {},
                        marks: [],
                        value:
                          'Como puede perder los fondos de la FSA no utilizados, solo debe contribuir con cantidades que sepa que usará.',
                        nodeType: 'text',
                      },
                    ],
                    nodeType: 'paragraph',
                  },
                ],
                nodeType: 'list-item',
              },
              {
                data: {},
                content: [
                  {
                    data: {},
                    content: [
                      {
                        data: {},
                        marks: [],
                        value:
                          'Si está inscrito en un plan elegible para HSA, su FSA se convierte en FSA de propósito limitado. Los FSA de propósito limitado solo se pueden usar para pagar los gastos dentales, de la vista y de ortodoncia.',
                        nodeType: 'text',
                      },
                    ],
                    nodeType: 'paragraph',
                  },
                ],
                nodeType: 'list-item',
              },
            ],
            nodeType: 'unordered-list',
          },
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  'Asegúrese de revisar cuidadosamente los detalles de su plan para obtener más información.',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value: '',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
        ],
        nodeType: 'document',
      },
      video_link: 'https://vimeo.com/499306201',
      alternate_video_link: 'https://vimeo.com/605082480',
    },
    dependent_fsa_benefit: {
      contentful_name: 'Dependent FSA Benefit',
      icon_link: 'https://picwellapppublicdata.s3.amazonaws.com/contentful/insuranceicons/fsa_dc.svg',
      card_title: 'Cuidado de dependientes FSA',
      card_description:
        'Una FSA de cuidado de dependientes (DCFSA) es una cuenta de beneficios antes de impuestos que se utiliza para pagar los servicios de cuidado de dependientes, como preescolar, campamento de verano, programas después de la escuela y guardería infantil.',
      modal_description: {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  'Una FSA de cuidado de dependientes (DCFSA) es una cuenta antes de impuestos que puede usar para pagar los servicios de cuidado de dependientes mientras está en el trabajo. Como no tiene que pagar ningún impuesto sobre el dinero que contribuye a una FSA de atención a dependientes, esto le ayuda a ahorrar en costos de atención a dependientes. Según este tipo de cuenta, un "dependiente" incluye niños menores de 13 años y adultos dependientes que no pueden cuidarse solos. Tenga en cuenta que deben vivir con usted y ser reclamados como dependientes en su declaración de impuestos.',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
        ],
        nodeType: 'document',
      },
      insurance_info: {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  'Usted financia su FSA de Cuidado de Dependientes a través de su empleador. Durante el período de inscripción abierta de su empresa, usted le dice a su empleador cuánto le gustaría contribuir a su cuenta para el próximo año. La cantidad máxima que puede contribuir es de $ 5,000. Luego, su empleador deduce el monto de su contribución (en partes iguales) de sus cheques de pago durante todo el año del plan.',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
          {
            data: {},
            content: [
              {
                data: {},
                marks: [
                  {
                    type: 'italic',
                  },
                ],
                value: 'Puede usar los fondos en su FSA de Cuidado de Dependientes de dos maneras:',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
          {
            data: {},
            content: [
              {
                data: {},
                content: [
                  {
                    data: {},
                    content: [
                      {
                        data: {},
                        marks: [],
                        value:
                          'Pagar a mi proveedor: puede hacer los arreglos para que le paguen directamente a su proveedor de cuidado de dependientes.',
                        nodeType: 'text',
                      },
                    ],
                    nodeType: 'paragraph',
                  },
                ],
                nodeType: 'list-item',
              },
              {
                data: {},
                content: [
                  {
                    data: {},
                    content: [
                      {
                        data: {},
                        marks: [],
                        value:
                          'Devolverme el dinero: se le puede reembolsar los gastos calificados de cuidado de dependientes que paga de su bolsillo.',
                        nodeType: 'text',
                      },
                    ],
                    nodeType: 'paragraph',
                  },
                ],
                nodeType: 'list-item',
              },
            ],
            nodeType: 'ordered-list',
          },
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  '* Tenga en cuenta que si tiene un ingreso más bajo y gasta menos de $ 5,000 al año en cuidado de dependientes, es mejor que no use una FSA de cuidado de dependientes y reclame un crédito tributario por cuidado de dependientes.',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  'Asegúrese de revisar cuidadosamente los detalles de su plan para obtener más información.',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
        ],
        nodeType: 'document',
      },
      video_link: 'https://vimeo.com/499306284',
    },
    adoption_fsa_benefit: {
      contentful_name: 'Adoption FSA Benefit',
      icon_link: 'https://picwellapppublicdata.s3.amazonaws.com/contentful/insuranceicons/fsa_aa.svg',
      card_title: 'Asistencia de adopción FSA',
      card_description:
        'Asistencia de adopción La FSA le reembolsa los gastos razonables y necesarios en los que incurra en el proceso de adoptar legalmente a un niño elegible.',
      modal_description: {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  'Asistencia para la adopción La FSA le reembolsa los gastos razonables y necesarios en los que incurra en el proceso de adoptar legalmente a un niño elegible, incluidas las tarifas de adopción, los costos judiciales, los honorarios de abogados y los costos de viaje relacionados.',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
        ],
        nodeType: 'document',
      },
      insurance_info: {
        nodeType: 'document',
        data: {},
        content: [
          {
            nodeType: 'paragraph',
            content: [
              {
                nodeType: 'text',
                value:
                  'Asegúrese de revisar cuidadosamente los detalles de su plan para obtener más información.',
                marks: [],
                data: {},
              },
            ],
            data: {},
          },
        ],
      },
    },
    telemedicine_benefit: {
      contentful_name: 'Telemedicine Benefit',
      icon_link: 'https://picwellapppublicdata.s3.amazonaws.com/contentful/insuranceicons/telemed.svg',
      card_title: 'Telemedicina',
      card_description:
        'La telemedicina le brinda la posibilidad de visitar al médico por teléfono para diagnosticar enfermedades y dolencias comunes.',
      video_link: 'https://vimeo.com/605082676',
    },
    pet_benefit: {
      contentful_name: 'Pet Benefit',
      icon_link: 'https://picwellapppublicdata.s3.amazonaws.com/contentful/insuranceicons/pet.svg',
      card_title: 'Seguro para mascotas',
      benefit_page_title: 'Seguro para mascotas',
      card_description:
        'Las pólizas de seguro para mascotas pueden ayudarlo a cubrir el costo de las facturas veterinarias.',
      modal_description: {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  'El seguro para mascotas puede brindarle tranquilidad al hacer que los costos de salud de sus mascotas sean más asequibles, como exámenes de bienestar, vacunas, afecciones crónicas y enfermedades y lesiones agudas.',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
        ],
        nodeType: 'document',
      },
      insurance_info: {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  'Some plans start as low as $1 a day. However, policies are personalized specifically for your pet, which means costs for pet insurance can vary, depending on a few common factors:',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
          {
            data: {},
            content: [
              {
                data: {},
                content: [
                  {
                    data: {},
                    content: [
                      {
                        data: {},
                        marks: [],
                        value: 'Tipo de animal, incluidas las especies.',
                        nodeType: 'text',
                      },
                    ],
                    nodeType: 'paragraph',
                  },
                ],
                nodeType: 'list-item',
              },
              {
                data: {},
                content: [
                  {
                    data: {},
                    content: [
                      {
                        data: {},
                        marks: [],
                        value: 'Género de su mascota',
                        nodeType: 'text',
                      },
                    ],
                    nodeType: 'paragraph',
                  },
                ],
                nodeType: 'list-item',
              },
              {
                data: {},
                content: [
                  {
                    data: {},
                    content: [
                      {
                        data: {},
                        marks: [],
                        value: 'Años',
                        nodeType: 'text',
                      },
                    ],
                    nodeType: 'paragraph',
                  },
                ],
                nodeType: 'list-item',
              },
              {
                data: {},
                content: [
                  {
                    data: {},
                    content: [
                      {
                        data: {},
                        marks: [],
                        value: 'Raza',
                        nodeType: 'text',
                      },
                    ],
                    nodeType: 'paragraph',
                  },
                ],
                nodeType: 'list-item',
              },
              {
                data: {},
                content: [
                  {
                    data: {},
                    content: [
                      {
                        data: {},
                        marks: [],
                        value: 'Ubicación',
                        nodeType: 'text',
                      },
                    ],
                    nodeType: 'paragraph',
                  },
                ],
                nodeType: 'list-item',
              },
            ],
            nodeType: 'unordered-list',
          },
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  'Todos los planes de seguro para mascotas usan un porcentaje de la factura o un programa de beneficios para calcular los reembolsos.\nPorcentaje de la factura: el reembolso se basa en el costo del tratamiento. Obtiene un porcentaje de lo que pagó a su veterinario.\nPrograma de beneficios: el reembolso está limitado a un monto establecido para cada afección, independientemente del costo real del tratamiento.',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  'Asegúrese de revisar cuidadosamente los detalles de su plan para obtener más información.',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
        ],
        nodeType: 'document',
      },
    },
    cancer_benefit: {
      contentful_name: 'Cancer Benefit',
      icon_link:
        'https://picwellapppublicdata.s3.amazonaws.com/contentful/insuranceicons/critical-illness.svg',
      card_title: 'Seguro de cáncer',
      benefit_page_title: 'Seguro de cáncer',
      card_description:
        'El seguro contra el cáncer brinda protección financiera en caso de que te diagnostiquen cáncer.',
      modal_description: {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  'Se espera que más de uno de cada tres estadounidenses contraigan cáncer en sus vidas. Aunque sin duda no es una estadística agradable, cada vez más personas están logrando sobrevivir al cáncer gracias a los avances en la medicina moderna. ',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
        ],
        nodeType: 'document',
      },
      insurance_info: {
        nodeType: 'document',
        data: {},
        content: [
          {
            nodeType: 'paragraph',
            content: [
              {
                nodeType: 'text',
                value:
                  'Pero combatir el cáncer es costoso. Aunque el seguro médico cubre una gran parte de los costos del tratamiento, es casi seguro que llegarás al límite máximo de tu propio bolsillo para el año. Sin mencionar los costos de viajar a las citas, la pérdida de ingresos por faltar al trabajo y todos los gastos habituales de todos los días. Aquí es donde el seguro contra el cáncer puede ayudar.',
                marks: [],
                data: {},
              },
            ],
            data: {},
          },
          {
            nodeType: 'paragraph',
            content: [
              {
                nodeType: 'text',
                value:
                  'Si te diagnostican con un cáncer cubierto, este tipo de póliza te envía un cheque que puedes usar para ayudar a cubrir gastos médicos o gastar en lo que desees. También podrías recibir pagos adicionales basados en el tipo de tratamiento que necesitas o incluso por recibir ciertos cuidados preventivos sin un diagnóstico.',
                marks: [],
                data: {},
              },
            ],
            data: {},
          },
          {
            nodeType: 'paragraph',
            content: [
              {
                nodeType: 'text',
                value:
                  'Importante tener en cuenta: el seguro contra el cáncer no cubrirá ninguna enfermedad diagnosticada antes de que te inscribas en una póliza. Por lo tanto, si estás pensando en obtener esta cobertura, probablemente cuanto antes sea mejor.',
                marks: [],
                data: {},
              },
            ],
            data: {},
          },
          {
            nodeType: 'paragraph',
            content: [
              {
                nodeType: 'text',
                value:
                  'Estas pólizas tampoco pagarán beneficios por cualquier enfermedad relacionada con el cáncer que desarrolles durante el tratamiento y podrían incluir restricciones si tienes una recurrencia de un cáncer previo. Consulta los documentos de tu plan para obtener los detalles.',
                marks: [],
                data: {},
              },
            ],
            data: {},
          },
        ],
      },
    },
    commuter_benefit: {
      contentful_name: 'Commuter Benefit',
      icon_link: 'https://picwellapppublicdata.s3.amazonaws.com/contentful/insuranceicons/commuter.svg',
      card_title: 'Beneficios para viajeros',
      card_description:
        'Los beneficios de cercanías le brindan la oportunidad a través de su empleador de usar dólares libres de impuestos en los costos de traslado y mantener más de lo que ganan en cada cheque de pago.',
      modal_description: {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  'El programa de beneficios para viajeros le permite usar dólares libres de impuestos para pagar una variedad de gastos de viaje, como trenes, viajes compartidos, estacionamiento y más.',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
        ],
        nodeType: 'document',
      },
      insurance_info: {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  'Hay dos tipos de beneficios para el viajero: tránsito y estacionamiento. Usted decide cuánto dinero desea reservar cada mes, pero no puede aportar más de $ 270 por mes a cada cuenta. Como no paga impuestos sobre sus contribuciones, estos beneficios le permiten ahorrar en sus gastos de traslado.',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  'Asegúrese de revisar cuidadosamente los detalles de su plan para obtener más información.',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
        ],
        nodeType: 'document',
      },
      video_link: 'https://vimeo.com/605082259',
    },
    partner_benefit: {
      contentful_name: '[NCoA] Medicare',
      icon_link: 'https://picwellapppublicdata.s3.amazonaws.com/contentful/insuranceicons/medicare.svg',
      card_title: 'Medicare',
      card_description:
        'Thinking about retiring, turning 65 soon, or looking for information for a loved one? Start learning about Medicare enrollment now and get free, personalized Medicare advice. ',
      modal_description: {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  'Medicare can be complicated, but you don’t have to go it alone. Whether you are a caregiver, turning 65 soon, or planning for retirement the non-profit National Council on Aging is here to help. ',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
        ],
        nodeType: 'document',
      },
      insurance_info: {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                marks: [
                  {
                    type: 'italic',
                  },
                ],
                value: 'Here’s what you should know',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
          {
            data: {},
            content: [
              {
                data: {},
                content: [
                  {
                    data: {},
                    content: [
                      {
                        data: {},
                        marks: [],
                        value: 'There are options when it comes to Medicare coverage.',
                        nodeType: 'text',
                      },
                    ],
                    nodeType: 'paragraph',
                  },
                ],
                nodeType: 'list-item',
              },
              {
                data: {},
                content: [
                  {
                    data: {},
                    content: [
                      {
                        data: {},
                        marks: [],
                        value:
                          'Enrollment for Medicare is typically only available at certain times of the year.',
                        nodeType: 'text',
                      },
                    ],
                    nodeType: 'paragraph',
                  },
                ],
                nodeType: 'list-item',
              },
              {
                data: {},
                content: [
                  {
                    data: {},
                    content: [
                      {
                        data: {},
                        marks: [],
                        value:
                          'While some people get Medicare automatically, some may have to sign up if they are 65 (or almost 65) and not getting Social Security.',
                        nodeType: 'text',
                      },
                    ],
                    nodeType: 'paragraph',
                  },
                ],
                nodeType: 'list-item',
              },
              {
                data: {},
                content: [
                  {
                    data: {},
                    content: [
                      {
                        data: {},
                        marks: [],
                        value:
                          'Not signing up for Medicare Part B when first eligible can result in a penalty.',
                        nodeType: 'text',
                      },
                    ],
                    nodeType: 'paragraph',
                  },
                ],
                nodeType: 'list-item',
              },
              {
                data: {},
                content: [
                  {
                    data: {},
                    content: [
                      {
                        data: {},
                        marks: [],
                        value: 'Help with Medicare costs may be available.',
                        nodeType: 'text',
                      },
                    ],
                    nodeType: 'paragraph',
                  },
                  {
                    data: {},
                    content: [
                      {
                        data: {},
                        marks: [],
                        value: ' ',
                        nodeType: 'text',
                      },
                    ],
                    nodeType: 'paragraph',
                  },
                ],
                nodeType: 'list-item',
              },
            ],
            nodeType: 'ordered-list',
          },
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  'No matter where you are in your Medicare journey make sure you get the information you need to make the best choices for you and your loved ones. ',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
          {
            data: {},
            content: [
              {
                data: {},
                marks: [
                  {
                    type: 'bold',
                  },
                ],
                value: 'National Council on Aging’s Age Well Planner\nMedicare ',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value: 'Need help finding the right plan for you or a loved one? Get started now: ',
                nodeType: 'text',
              },
              {
                data: {
                  uri: 'https://www.ncoa.org/age-well-planner/landing/medicare-enrollment-help-awp',
                },
                content: [
                  {
                    data: {},
                    marks: [],
                    value: 'https://www.ncoa.org/age-well-planner/landing/medicare-enrollment-help-awp',
                    nodeType: 'text',
                  },
                ],
                nodeType: 'hyperlink',
              },
              {
                data: {},
                marks: [],
                value: '',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
        ],
        nodeType: 'document',
      },
    },
    legal_services_benefit: {
      contentful_name: 'Legal Services',
      icon_link: 'https://picwellapppublicdata.s3.amazonaws.com/legal_services.svg',
      card_title: 'Legal Services',
      card_description:
        'Legal plans are a supplemental benefit that employers offer to provide employees access to a network of experienced attorneys. ',
      insurance_info: {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  'A legal plan provides access to a network of attorneys. You can visit any of these Network Attorneys and the costs will be fully covered by your legal plan. Just like your other benefits, you pay for this service through easy payroll deductions on a monthly basis. ',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  'There are various instances in which you may need the advice or assistance of a qualified legal professional. For example, if you are buying or selling a home and are in need of contracts, deeds, or purchase agreements. Or, if you are starting a family and want to create a will or need help with estate planning documents. A legal plan can even provide you with access to an attorney to assist with traffic tickets or identity theft protection. Identity theft occurs when an individual uses someone else’s name or personally identifying information to commit fraud or other crimes. ',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  'Most frequent legal issues are typically covered in these plans but every plan can be different so it’s important to check the plan details to confirm if your matter will be covered. ',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  'A legal plan can be a great option as it provides affordable access to quality legal care for a fraction of the cost of retaining an attorney on your own.  ',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
        ],
        nodeType: 'document',
      },
      video_link: 'https://vimeo.com/605082378',
    },
    employee_assistant_program_benefit: {
      contentful_name: 'EAP',
      icon_link: 'https://picwellapppublicdata.s3.amazonaws.com/EAP.svg',
      card_title: 'Employee Assistance Program',
      card_description:
        'An Employee Assistance Program, or EAP, is benefit designed to help employees resolve various issues related to mental and emotional well-being that may be affecting job performance.',
      insurance_info: {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  'An EAP is typically provided free of charge by your employer. Your employer will provide you with a phone number you can call to discuss any issues you might be experiencing in your personal or professional life. You can use the EAP as much as you need without incurring costs for use.',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  'Depending on the type of EAP, you may receive counseling or advice directly over the phone or through another form of communication. Some EAPs provide referrals to professionals that can help you with whatever situation you are facing.',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  'It’s important to note that while accessing EAP services are free, you may incur some costs if you are provided a referral to a health care professional. EAPs are designed to be a support system for employees and can be very helpful for many employees throughout their tenure with an organization. ',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
        ],
        nodeType: 'document',
      },
      video_link: 'https://vimeo.com/605082281',
    },
    wellness_benefit: {
      contentful_name: 'Wellness Benefits',
      icon_link: 'https://picwellapppublicdata.s3.amazonaws.com/wellness.svg',
      card_title: 'Wellness Benefit',
      card_description:
        'Wellness benefits programs are designed to help improve the overall health and wellbeing of employees, and they focus on reducing illness and improving quality of life.',
      insurance_info: {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  'Wellness benefits can include a variety of services that promote improved physical, emotional and mental health of employees. These types of benefits can include health screenings, smoking cessation programs, fitness contests, or stress management programs. Some will even include reimbursements for gym memberships, diet plans, or fitness trackers and they may provide incentives for reaching fitness goals.',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  'Wellness programs can have several positive benefits. These programs, which can help you take proactive steps to improve your physical and mental health can lower stress, increase your day-to-day productivity and they can also help you save money in the long run, since better health can translate to lower health costs in the long run.',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  'The types of benefits included in a wellness plan will depend on the specific plan, so make sure to review your plan details. It’s a great idea to find out what might be available to you specifically so you can start improving your health and wellness today.',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
        ],
        nodeType: 'document',
      },
      video_link: 'https://vimeo.com/605082679',
    },
    financial_wellness_benefit: {
      contentful_name: 'Financial Wellness Benefits',
      icon_link: 'https://picwellapppublicdata.s3.amazonaws.com/financial_wellness.svg',
      card_title: 'Financial Wellness Benefits',
      card_description:
        'Financial wellness benefits include a range of services and are designed to help employees improve their financial standings.',
      insurance_info: {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  'Financial wellness is a huge issue that affects many people every day. If you’ve ever been in financial trouble you know how stressful it can be.',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  'The term Financial wellness refers to the total picture of your financial situation and how it affects not only your stress levels but also your physical and mental health. When you are stressed out about your finances, it’s hard to focus on anything else. When you have good financial wellness, you have full control over your day-to-day finances, have enough savings to handle unexpected expenses, and are on track with your retirement and savings goals.',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  'Financial wellness benefits include a range of services. Counseling and coaching services can provide you with 1-on-1 advice about ways to improve your financial wellness, and a financial specialist can help you take a broad look at your finances and work with you on a plan to improve them. These experts can help you create a budget, stick to it, and evaluate your success. ',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  'Since financial wellness benefits can cover a multitude of services, it’s best to check your plan details to see what is offered.',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
        ],
        nodeType: 'document',
      },
      video_link: 'https://vimeo.com/605082308',
    },
    combined_life_and_add_benefit: {
      contentful_name: 'Life/AD&D (combined)',
      icon_link: 'https://picwellapppublicdata.s3.amazonaws.com/contentful/insuranceicons/life.svg',
      card_title: 'Seguro de Vida/AD&D ',
      card_description:
        'Life and AD&D insurance are benefits that can help protect your loved ones from financial hardship due to death or serious injury.',
      insurance_info: {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  "​​We hope that we will always be around to provide for our loved ones, but life insurance and Accidental Death and Dismemberment (AD&D) insurance give you ways to plan for the worst, giving your dependents funds to cover costs if you pass away or are seriously injured. Both of these benefits are important tools that you can use to ensure your family's financial security.",
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  'Life insurance policies pay out a "death benefit" that comes in the form of a single, lump sum payment if you pass away. You can pick the amount of life insurance that you want to buy, so you can plan to have enough to cover your family\'s financial obligations if you are no longer around. Accidental Death & Dismemberment (AD&D) insurance will pay you or your beneficiary a lump sum if you die or are dismembered in an accident. When you purchase an AD&D policy, you may be able to select the amount of coverage, which determines the amount that you or your beneficiary will receive in the event of accidental death or dismemberment. If you have both a life insurance policy and an AD&D policy, your beneficiaries would receive full payouts from both policies in the event of a fatal accident. If you have an accident that results in loss of eyesight, speech, hearing, or a limb, that amount of the payout will depend on the nature of the dismemberment.',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  'While Life and AD&D insurance is often offered together, premiums may vary depending on the amount of coverage. Please make sure to carefully review your plan details for more information.',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
        ],
        nodeType: 'document',
      },
      video_link: 'https://vimeo.com/499286311',
    },
    four_o_three_benefit: {
      contentful_name: '403(b) Benefit',
      icon_link: 'https://picwellapppublicdata.s3.amazonaws.com/contentful/insuranceicons/retirement.svg',
      card_title: '403(b)',
      card_description:
        'Los planes 403b le permiten ahorrar e invertir una parte de su cheque de pago antes de deducir los impuestos.',
      modal_description: {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  'Los ahorros para la jubilación son fundamentales para su plan de bienestar financiero a largo plazo, y las cuentas de ahorro para la jubilación con impuestos preferidos pueden ayudarlo a aprovechar al máximo sus contribuciones para la jubilación.',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
        ],
        nodeType: 'document',
      },
      insurance_info: {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  'Las cuentas de ahorro para la jubilación con impuestos preferidos le permiten ahorrar en impuestos, en comparación con otras cuentas de inversión. Estos ahorros impositivos pueden marcar grandes diferencias en su salud financiera a largo plazo. ',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  'Las cuentas de jubilación con impuestos preferidos pueden permitirle ahorrar en impuestos cuando realiza su contribución, lo que le permite contribuir a su cuenta con dólares antes de impuestos o cuando retira la contribución, lo que le permite evitar pagar impuestos cuando retira fondos en la jubilación.',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
        ],
        nodeType: 'document',
      },
      video_link: 'https://vimeo.com/605082604',
    },
    educational_benefits: [],
  },
};

export default benefits;
