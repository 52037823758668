import { Drawer, DrawerContent, DrawerOverlay, Flex, Stack } from '@chakra-ui/react';
import React from 'react';

import { GlobalReducerState } from 'app/reducers';
import AlexLogo from 'Assets/alex-logo.svg';
import { Page } from 'Containers/App/Navigation/pages';
import { Button } from 'DesignLibrary/atoms/Button';
import { H5, Paragraph } from 'DesignLibrary/atoms/typography';
import Media from 'Shared/Media';
import Text from 'Shared/Text';
import { WELCOME_PATH, PROFILE_PATH } from 'Utils/urls';

import MobileLink from './MobileLink';
import { MobileNavFooter, MobileMenu } from './styled';
import AlexIDHeader from '../../AlexIDHeader';
import PayPeriodSelect from '../../PayPeriodSelect';

export interface MobileModalProps {
  path: string;
  pages: Page[];
  unlockedPage?: Page;
  isOpen: boolean;
  canChangePayPeriod: boolean;
  isAlexIdEnabled: boolean;
  hideNavItems: boolean;
  getStore: () => GlobalReducerState;
  handleClose: () => void;
}

export const MobileModal = ({
  getStore,
  path,
  pages,
  unlockedPage,
  isOpen,
  canChangePayPeriod,
  isAlexIdEnabled,
  hideNavItems,
  handleClose,
}: MobileModalProps) => (
  <Drawer isOpen={isOpen} placement="right" onClose={handleClose}>
    <DrawerOverlay />
    <DrawerContent overflow="auto">
      <Stack p={4} gap={2}>
        <Flex gap={2} justify="space-between" align="center">
          <div>
            {canChangePayPeriod && ![WELCOME_PATH, PROFILE_PATH].includes(path) ? (
              <PayPeriodSelect dir="left" />
            ) : (
              <H5>Navigation</H5>
            )}
          </div>
          <Button
            iconRight="X"
            size="small"
            onClick={handleClose}
            data-testid="menu-close-btn"
            className="menu-close-btn"
          />
        </Flex>
        {!hideNavItems && (
          <MobileMenu data-testid="mobile-nav-menu-items">
            <Stack gap={1} pt={2} pb={8}>
              {pages.map((page) => (
                <MobileLink
                  key={page.url}
                  path={path}
                  page={page}
                  handleClose={handleClose}
                  unlockedPage={unlockedPage}
                />
              ))}
            </Stack>
          </MobileMenu>
        )}
        <MobileNavFooter>
          <Stack gap={3}>
            {isAlexIdEnabled && (
              <div className="alex-id">
                <AlexIDHeader getStore={getStore} />
              </div>
            )}
            {!hideNavItems && (
              <Paragraph className="navNotice" size="mini" data-testid="mobile-nav-instructions">
                <Text field="top_level_navigation.mobile_nav_instructions" />
              </Paragraph>
            )}
            <Flex flexWrap="wrap" gap={2}>
              <img src={AlexLogo} alt="Alex" />
              <Media field="logo" form="image" />
            </Flex>
          </Stack>
        </MobileNavFooter>
      </Stack>
    </DrawerContent>
  </Drawer>
);

export default MobileModal;
