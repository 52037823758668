import { ContentfulHealthSection } from 'ContentfulDefaults/types/health';

export const health: {
  health_section: ContentfulHealthSection;
} = {
  health_section: {
    contentful_name: 'Health Section (DEFAULT DO NOT CHANGE)',
    page_header: {
      text: 'Elija un plan de salud',
      subtext:
        'Aquí están los {x} planes de los que puedes elegir, ordenados por cuán bien se adaptarán a tus necesidades y preferencias de atención médica ',
      text_welcome: '¡Bienvenido de nuevo!',
      subtext_alternate: 'Hay {x} planes de los que usted es elegible, entre los que puede elegir.',
      previous_selected_plan_text:
        'Hay {x} planes entre los que puedes elegir. El plan que elegiste la última vez está resaltado en la parte superior.',
      previous_selected_plan_text_alternate:
        'Hay {x} plan al que eres elegible para elegir. El plan que eligió la última vez está resaltado en la parte superior.',
      text_review_tool: 'Revisar los planes elegibles {x}',
      subtext_review_tool: 'Verifique estos planes y edite el perfil para explorar otros recursos posibles.',
      sortedtext_review_tool: 'Ordenado de la A a la Z',
    },
    loading_statuses: {
      '25%': 'Compilando sus respuestas...',
      '50%': 'Compilando sus respuestas...',
      '75%': 'Analizando sus planes elegibles...',
      '100%': 'Analizando sus planes elegibles...',
    },
    title_info_bar: {
      profileComplete: 'Impresionante, perfil completo!',
    },
    button_text: {
      hra: '¿Qué es una HRA?',
      hsa: '¿Qué es una HSA?',
      next: 'Seguir',
      sihra: "What's an SIHRA?",
      review: 'Ir a revisión',
      compare: 'Comparar',
      hsaPlus: 'Ir a HSA',
      noPlans: 'Volver a elegibilidad',
      allPlans: 'Volver a todos los planes',
      viewPlan: 'Ver detalles',
      choosePlan: 'Elige plan',
      noCoverage: 'Renunciar a la cobertura',
      selectedPlan: 'Plan seleccionado',
      showAllPlans: 'Mostrar todos los planes',
      supplemental: 'Ir a planes suplementarios',
      insuranceModal: 'Más sobre seguro de salud',
      providerSearch: 'Busque su proveedor',
      summaryOfBenefits: 'Detalles completos del plan',
      compareModalShowOut: 'Mostrar fuera de la red',
      deselectPlan: 'Deseleccionar',
      planPdf: 'Plan PDF',
      card: 'Tarjeta',
    },
    plan_tips: {
      ranking: {
        text: {
          p1: 'Según la información que proporcionó sobre sus necesidades y preferencias de atención médica, evaluamos los costos y la cobertura de cada plan e identificamos sus mejores opciones.',
          p2: 'Cuando evaluamos el valor, analizamos la cobertura que puede obtener y las primas que pagará por cada plan. Para estimar la cobertura que podría obtener, simulamos las experiencias de personas como usted en cada plan, utilizando reclamos médicos reales, y luego usamos sus respuestas a las preguntas de preferencia de riesgo para evaluar cuánto valor obtiene de la protección adicional contra riesgos.',
          p3: 'Es importante tener en cuenta que incluso si tiene una fuerte preferencia por la protección contra riesgos, su mejor valor aún puede ser un plan con una cobertura más baja. Esto puede deberse a que no enfrenta muchos riesgos o porque las opciones de mayor cobertura tienen un precio lo suficientemente alto como para que nunca tengan un buen valor.',
        },
        underline: '¿Cómo se clasifican estos planes?',
      },
      bestMatch: {
        text: 'Según sus preferencias, los detalles del plan y los costos de atención médica pronosticados, hemos identificado este plan como la mejor opción para usted {x}.',
        title: 'Tu mejor pareja',
        underline: '¡Tu mejor partido!',
      },
      colorTiers: {
        redText:
          'Este plan obtiene una calificación de Rojo. Esto significa que no es un muy buen valor, y hay muy pocas posibilidades de que termine ahorrando dinero con este plan.',
        redTitle: 'Plan Rojo',
        greenText:
          'Este plan obtiene una calificación Verde. Esto significa que es un buen valor para usted, en función de sus necesidades y preferencias de atención médica previstas.',
        greenTitle: 'Plan Verde',
        yellowText:
          'Este plan obtiene una calificación Amarilla. Esto significa que es un valor moderado para usted, pero obtendrá un mejor valor si elige un plan con una calificación Verde',
        yellowTitle: 'Plan amarillo',
      },
      hsaMatching: {
        text: 'Si elige este plan de salud elegible para HSA, su empleador igualará el {x}% de sus contribuciones {x} hechas a su HSA. Puede usar esto para ayudar a pagar sus facturas médicas y puede conservar lo que no usa.',
        title: 'Qué significa eso?',
        underline: 'coincidencia {x}% (hasta {x})',
      },
      hraContribution: {
        text: 'If you pick this HRA Eligible health plan, your employer will contribute up to {x} to the account. You can use this to help pay for your medical bills.',
        title: 'Whats does that mean?',
        underline: 'contribute up to {x} to your HRA',
      },
      hraIntroduction: {
        text: 'Un Acuerdo de reembolso de salud (HRA) es una cuenta de gastos que su empleador establece para ayudar a pagar los gastos médicos que su seguro no cubre. Si su empleador estableció una HRA, puede enviar recibos de facturas médicas que haya pagado y su empleador reembolsará esos cargos hasta que haya agotado todos los fondos asignados. Todos los fondos de la HRA que no use volverán a su empleador al final del año, pero su empleador puede permitirle transferir una parte de su HRA al año siguiente.',
        title: 'Cuenta de reembolso de salud (HRA)',
      },
      hsaContribution: {
        text: 'Si elige este plan de salud elegible para HSA, su empleador contribuirá {x} a su HSA. Puede usar esto para ayudar a pagar sus facturas médicas y puede conservar lo que no usa.',
        underline: 'contribuir {x} a su HSA',
      },
      hsaIntroduction: {
        text: 'Las HSA son cuentas especiales que le permiten ahorrar e invertir dinero para pagar los gastos de atención médica. Nunca paga impuestos sobre las contribuciones, ganancias o retiros de HSA, y esta ventaja de triple impuesto los convierte en la mejor manera de ahorrar para sus costos de atención médica tanto ahora como en el futuro. Las personas pueden contribuir hasta {x} por año (las personas de 55 años o más pueden ser elegibles para hacer una contribución de recuperación de $ 1,000), y las familias pueden contribuir hasta {x} por año. Una vez que ahorre lo suficiente para alcanzar el requisito de ahorro mínimo de su administrador de HSA, puede invertir cualquier exceso de fondos que acumule.',
        title: 'Cuenta de Ahorros de Salud (HSA)',
      },
      sihraContribution: {
        text: "If you pick this SIHRA Eligible health plan offered by your spouse's employer, your employer will contribute up to {x} to the account. You can use this to help pay for your medical bills.",
        title: 'What does that mean?',
        underline: 'contribute up to {x} to your SIHRA',
      },
      sihraIntroduction: {
        text: 'A Spousal Incentive Health Reimbursement Arrangement (SIHRA) is a spending account that your employer sets up to help pay for medical expenses that your insurance doesn’t cover. If your employer set up an SIHRA, you can submit receipts for medical bills that you have paid and your employer will reimburse those charges up until you have used up all of your allocated funds. Any SIHRA funds that you do not use go back to your employer at the end of the year, but your employer may allow you to carry over some portion of your SIHRA to the following year.',
        title: 'Spousal Incentive Health Reimbursement Account (SIHRA)',
      },
    },
    no_plans_modal: {
      title: 'No hay plan disponible',
      description:
        'Según sus respuestas a las preguntas de elegibilidad, no es elegible para ningún plan. Asegúrese de haber respondido estas preguntas de elegibilidad correctamente o comuníquese con su departamento de Recursos Humanos para obtener más detalles.',
    },
    plans_heading: 'Sus planes de salud',
    insurance_modal_text: {
      data: {},
      content: [
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'La idea básica del seguro de salud es que pague una tarifa por adelantado, denominada prima, y a cambio, su plan de seguro cubrirá parte del costo de sus facturas médicas. La cantidad que cubre su plan de seguro puede depender de muchos factores que pueden complicarse bastante. ¡Pero no te preocupes por eso! Picwell analizará todos esos detalles, para que no tenga que hacerlo. Aun así, hay algunos términos clave que debe conocer que lo ayudarán a comprender cómo funciona el seguro de salud.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: 'Tipos de plan',
              nodeType: 'text',
            },
          ],
          nodeType: 'heading-3',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'Es posible que haya notado que hay muchos acrónimos flotando en el mundo de los seguros de salud. ',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [
                {
                  type: 'bold',
                },
              ],
              value: 'HMO',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [],
              value: ', ',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [
                {
                  type: 'bold',
                },
              ],
              value: 'PPO',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [],
              value: ' y ',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [
                {
                  type: 'bold',
                },
              ],
              value: 'HDHP',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [],
              value: ' son acrónimos que describen algunas características importantes de su plan de salud.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: '\n',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [
                {
                  type: 'bold',
                },
              ],
              value: 'HMO',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [],
              value:
                ' significa organización de mantenimiento de la salud. Las HMO generalmente brindan más restricciones sobre la atención médica que recibe. Necesitará una referencia de su proveedor de atención primaria antes de recibir atención médica que no sea de emergencia, y es posible que los costos fuera de la red que no sean de emergencia no estén cubiertos. Las HMO que cubren una parte de los costos fuera de la red a menudo se refieren a los planes de ',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [
                {
                  type: 'bold',
                },
              ],
              value: 'Punto de Servicio (POS)',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [],
              value:
                '. La ventaja de una HMO es que estas restricciones tienen como objetivo reducir los costos, por lo que generalmente pagará primas más bajas por este tipo de planes.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: '\n',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [
                {
                  type: 'bold',
                },
              ],
              value: 'PPO',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [],
              value:
                ' significa organización de proveedores preferidos. Los PPO no requieren que obtenga una derivación para atención médica que no sea de emergencia, y proporcionarán una cobertura más integral para la atención fuera de la red que una HMO. Los PPO brindan una mayor flexibilidad en la forma en que obtiene su atención médica, pero generalmente pagará primas más altas por este tipo de planes.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: '\n',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [
                {
                  type: 'bold',
                },
              ],
              value: 'HDHP',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [],
              value:
                ' significa plan de salud con deducible alto. La característica definitoria de un HDHP es su deducible, los planes que son HMO o PPO pueden ser HDHP. El término HDHP generalmente se reserva para los planes que tienen un deducible que es lo suficientemente alto como para emparejarse con una HSA. En 2020, eso significa que el deducible debe ser de al menos $ 1,400 para cobertura individual y $ 2,800 para cobertura familiar.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [],
          nodeType: 'hr',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: 'Primas',
              nodeType: 'text',
            },
          ],
          nodeType: 'heading-3',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'Sus primas son el precio que paga por el seguro de salud. Como regla general, cuanto más pague en primas, más pagará su plan de seguro cuando reciba una factura médica. Las altas primas también se asocian frecuentemente con cosas como redes de proveedores más amplias y menos restricciones para cosas como ver a un especialista. (Los cubriremos más tarde).',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                '\nLas primas están exentas de impuestos. Esto significa que si obtiene su seguro de salud a través de su empleador, sus primas se deducen de su cheque de pago antes de pagar cualquier impuesto.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [],
          nodeType: 'hr',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: 'Deducibles',
              nodeType: 'text',
            },
          ],
          nodeType: 'heading-3',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: 'El ',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [
                {
                  type: 'bold',
                },
              ],
              value: 'deducible',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [],
              value:
                ' define el monto que debe pagar antes de que su seguro comience a cubrir los costos. Si su plan tiene un deducible de $ 1,000, su seguro no comenzará a cubrir ninguna de sus facturas médicas hasta que haya incurrido tanto en facturas médicas. Incluso si su plan tiene un deducible, la atención preventiva siempre estará cubierta desde el principio. Esto significa que incluso si tiene un plan con un deducible alto, no tendrá que pagar nada por cosas como vacunas, un examen físico anual o una visita de bienestar del bebé.\n',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'Algunos planes de salud tendrán diferentes deducibles para medicamentos recetados y otra atención médica. Por ejemplo, si un plan tiene un deducible de $ 0 para medicamentos con receta y un deducible de $ 1,000 para otra atención médica, eso significa que su seguro comienza a cubrir el costo de las recetas de inmediato.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [],
          nodeType: 'hr',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: 'Copagos y coseguros',
              nodeType: 'text',
            },
          ],
          nodeType: 'heading-3',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'Los copagos y el coseguro definen la cantidad de una factura médica que paga. Si su plan tiene un deducible, se aplican copagos y coseguros después de que haya alcanzado su deducible. Un copago se refiere a un monto fijo en dólares que paga por un servicio médico, mientras que el coseguro se refiere a un porcentaje de la factura que paga.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                '\nAqui hay un par de ejemplos. Supongamos que su plan de salud cobra un copago de $ 20 por una visita al consultorio de atención primaria y tiene una tasa de coseguro del 10% para una visita al consultorio especializado. Si ya ha alcanzado su deducible, en este escenario, pagará $ 20 por la cita de atención primaria, sin importar cuánto resulte ser la factura final, pero el monto que pague por la cita con el especialista dependerá de la factura . Si la factura del especialista es de $ 400, usted paga $ 40, pero si la factura es de $ 500, paga $ 50.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [],
          nodeType: 'hr',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: 'Máximos de gastos de bolsillo (OOP)',
              nodeType: 'text',
            },
          ],
          nodeType: 'heading-3',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'Sus costos de bolsillo (OOP) se refieren al monto total que paga por sus facturas médicas después de sumar su deducible, coseguro y copagos. El máximo de OOP de su plan de salud pone un límite a la cantidad que debe pagar cada año. Una vez que la suma de su deducible, coseguro y copagos alcance el máximo de OOP, su plan de salud cubrirá todas las facturas médicas restantes.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: '\n',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [
                {
                  type: 'italic',
                },
              ],
              value: 'Aquí hay un ejemplo.',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [],
              value:
                ' Supongamos que se enferma mucho y termina ingresado en el hospital durante una semana, y acumula $ 21,000 en facturas médicas. Si tiene un plan de salud con un deducible de $ 1,000, cobra una tasa de coseguro del 20% para ingresos hospitalarios y tiene un máximo de OOP de $ 3,000, así es como el plan de salud calcularía sus pagos. Inmediatamente, pagará $ 1,000, dejando $ 20,000 que estarían cubiertos por el coseguro. Su tasa de coseguro del 20% le obligaría a pagar $ 4,000 adicionales en pagos de coseguro, pero dado que el máximo de OOP limita su costo a $ 3,000, solo paga $ 2,000 adicionales en pagos de coseguro.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [],
          nodeType: 'hr',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: 'Formulario',
              nodeType: 'text',
            },
          ],
          nodeType: 'heading-3',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'Un formulario es una lista de medicamentos recetados que define qué cubre su plan de salud y cómo está cubierto. Si una receta no está incluida en el formulario, es posible que su seguro de salud no la cubra, lo que significa que usted será responsable de pagar el costo total de esa receta. Si hay una receta en el formulario, la cantidad que pague dependerá del nivel en el que se encuentre el medicamento. Los formularios generalmente tienen diferentes niveles para medicamentos genéricos, medicamentos de marca preferidos, medicamentos de marca no preferidos. Por lo general, paga menos por las recetas que se encuentran en el medicamento genérico o en los niveles de medicamentos de marca preferidos.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [
                {
                  type: 'italic',
                },
              ],
              value:
                'Si toma recetas de rutina, siempre es una buena idea asegurarse de que su receta esté en el formulario y averiguar en qué nivel se encuentra.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [],
          nodeType: 'hr',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: 'Redes de proveedores',
              nodeType: 'text',
            },
          ],
          nodeType: 'heading-3',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'Las redes de proveedores son listas de proveedores y centros de atención médica que se incluyen en la red de un plan de salud. Su plan de salud ayudará a cubrir los costos en los que incurra por parte de proveedores "dentro de la red", pero si recibe atención médica de proveedores fuera de la red, su plan de salud puede cubrir menos costos o no cubrir esos costos en absoluto.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                '\nSi tiene proveedores de atención médica o ubicaciones a las que va habitualmente, asegúrese de verificar que estén dentro de la red antes de inscribirse en un plan de salud. En muchos casos, los planes con menos proveedores en la red cobran primas más bajas. Esto puede brindarle la oportunidad de ahorrar dinero, pero puede significar que deberá cambiar de médico para asegurarse de que su plan lo ayudará a cubrir sus facturas médicas.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: '',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
      ],
      nodeType: 'document',
    },
    eligibility_text: {
      hra: {
        tag: 'HRA elegible',
        standardPlan: 'Este es un plan elegible para HRA.',
      },
      hsa: {
        tag: 'HSA elegible',
        standardPlan: 'Este es un plan elegible para HSA.',
        contributionPlan:
          'Este plan le permite contribuir a una cuenta HSA (Health Savings Account). Puede usar esto para ayudar a pagar sus facturas médicas y puede quedarse con lo que no usa',
      },
      sihra: {
        tag: 'SIHRA Eligible',
        standardPlan: 'This is an SIHRA eligible plan.',
      },
    },
    client_disclaimers: {
      resultPageSihra: {
        text: "Enroll in your spouse's insurance plan and your employer will contribute up to {x} to a Spousal Incentive HRA account.",
        title: 'Spousal Incentive HRA Eligible',
      },
      resultPageSpousal: {
        text: 'This is a result page disclaimer',
        title: "Head's up",
      },
      viewPlanPageSpousal: {
        text: 'This is a plan details disclaimer',
        title: "Head's up",
      },
      buildAPlanModalSihra: {
        note: 'Please note:  The SIHRA benefit cannot be combined with any spousal health plan that includes the employer or employee contributing to an HSA .  Having an existing balance in an HSA is allowed for SIHRA participation.',
        text: "Enroll in your spouse's insurance plan and your employer will contribute up to {x} to a Spousal Incentive HRA account.",
        title: 'Spousal Incentive HRA Eligible',
      },
    },
    employer_involvement_options: {
      hsaMatch: 'HSA Match',
      hraContribution: 'Contribución efectiva del empleador a la HRA.',
      hsaContribution: 'Contribución de HSA',
    },
    hsa_hra_employer_contribution: {
      data: {},
      content: [
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: 'y su empleador ',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [
                {
                  type: 'bold',
                },
                {
                  type: 'underline',
                },
              ],
              value: 'contribuirá/igualará',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [],
              value: ' el próximo año.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
      ],
      nodeType: 'document',
    },
    no_employer_contribution_text: {
      data: {},
      content: [
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'Si elige un plan de salud elegible para HSA, puede hacer contribuciones antes de impuestos para ayudar a pagar sus facturas médicas, y puede quedarse con lo que no usa para ayudar a pagar los gastos de atención médica futuros. Haga clic en el plan para ver cuánto puede ahorrar en impuestos al pagar sus costos de bolsillo a través de su HSA.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
      ],
      nodeType: 'document',
    },
    hsa_video: 'https://vimeo.com/499305539',
    hra_video: 'https://vimeo.com/499306151',
    hsa_modal_text: {
      data: {},
      content: [
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'Si bien el seguro de salud lo ayuda a cubrir el costo de la atención médica, no cubre todo. Una cuenta de ahorro de salud, o HSA para abreviar, es una excelente manera de ahorrar para esos gastos.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [
                {
                  type: 'italic',
                },
              ],
              value: 'Así es como funciona:',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                '- Primero, después de inscribirse en un plan de salud elegible para HSA, abra una cuenta de ahorros de salud.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                '- Luego, elija un monto de contribución que se deducirá de su cheque de pago. Algunos empleadores lo ayudarán contribuyendo también a su HSA. Todo el dinero que no use este año es suyo, incluso si cambia de trabajo.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [
                {
                  type: 'italic',
                },
              ],
              value: 'Aquí hay algunas otras cosas que debes saber:',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                '- Puede usar su HSA para pagar gastos médicos calificados, incluidos copagos médicos, gastos de visión y dentales, medicamentos recetados y más.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: '- Cualquier dinero que contribuya a una HSA reduce sus impuestos.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: '- Las personas pueden contribuir hasta ',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [
                {
                  type: 'code',
                },
              ],
              value: '{x}',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [],
              value: ' por año y las familias pueden contribuir hasta ',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [
                {
                  type: 'code',
                },
              ],
              value: '{x}',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [],
              value: ' por año.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                '- A medida que crece su saldo, puede invertir ese dinero. La mejor parte es que las ganancias y los retiros de gastos calificados están libres de impuestos.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'Asegúrese de revisar cuidadosamente los detalles de su plan para obtener más información.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
      ],
      nodeType: 'document',
    },
    hra_modal_text: {
      data: {},
      content: [
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'Sus planes de seguro de salud vienen con un Acuerdo de reembolso de salud o HRA. Una HRA es una cuenta que su empleador establece y con la que contribuye dinero para ayudarlo a pagar los gastos de atención médica de bolsillo que califiquen. Esto podría incluir copagos médicos, gastos dentales y de la vista, medicamentos recetados y más.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [
                {
                  type: 'italic',
                },
              ],
              value: 'Así es como funciona:',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                '- Una vez que se inscriba en un plan elegible para HRA, su empleador financiará una cuenta que puede usar para compensar sus gastos médicos.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                '- A medida que recibe atención médica, la compañía de seguros pagará sus facturas médicas que están cubiertas por su plan médico de su HRA hasta que haya agotado todos los fondos asignados. Dependiendo de cómo su empleador configure su HRA, es posible que deba presentar sus recibos para el reembolso. Pero tenga en cuenta que sus reembolsos se limitan a la cantidad que su empleador financia su HRA.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [
                {
                  type: 'italic',
                },
              ],
              value: 'Aquí hay algunas otras cosas que debes saber:',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                '- Si no utiliza todos los dólares de su HRA para el reembolso, es posible que pueda usar esos fondos en los próximos años, dependiendo de cuánto le permita a su empleador transferir.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                '- Si decide inscribirse en un plan que no califica para HRA o deja a su empleador actual, perderá todo el dinero de HRA que su empleador ha contribuido.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'Asegúrese de revisar cuidadosamente los detalles de su plan para obtener más información.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
      ],
      nodeType: 'document',
    },
    waive_coverage_text: '¿No quieres un seguro de salud?',
    waive_coverage_modal: {
      title: 'Plan de salud exento',
      disclaimer: 'Regrese en cualquier momento para actualizar su plan.',
      description:
        'Decidió renunciar a su cobertura de salud. Siempre puede cambiar esta decisión más tarde volviendo a esta página y seleccionando otro plan de salud.',
      loadingText: 'Renunciar a su plan de salud',
    },
    saved_plan_modal: {
      title: 'Plan de salud guardado',
      disclaimer: 'Vuelva en cualquier momento para actualizar su plan.',
      loadingText: 'Guardar su plan de salud',
    },
    hsa_reminder: {
      data: {},
      content: [
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: '¡Importante recordatorio de HSA!',
              nodeType: 'text',
            },
          ],
          nodeType: 'heading-3',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'Ha seleccionado un plan de atención médica elegible para HSA. Asegúrese de abrir correctamente una cuenta HSA para aprovechar lo que una HSA tiene para ofrecer.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
      ],
      nodeType: 'document',
    },
    hra_reminder: {
      data: {},
      content: [
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: '¡Recordatorio importante de HRA!',
              nodeType: 'text',
            },
          ],
          nodeType: 'heading-3',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'You’ve selected an HRA eligible health care plan. An account will automatically be set up for you. There are no additional tasks needed to access this fantastic benefit!',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: '',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
      ],
      nodeType: 'document',
    },
    hsahra_plan_details: {
      data: {},
      content: [
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: 'Si elige este ',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [
                {
                  type: 'code',
                },
              ],
              value: '{x}',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [],
              value: ' plan de salud elegible, su empleador lo ',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [
                {
                  type: 'code',
                },
              ],
              value: '{x}',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [],
              value:
                '. Puede usar esto para ayudar a pagar sus facturas médicas y puede conservar lo que no usa. Vea los detalles del plan a continuación para ver cómo la ',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [
                {
                  type: 'code',
                },
              ],
              value: '{x}',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [],
              value: ' de su empleador lo ayuda a ahorrar.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
      ],
      nodeType: 'document',
    },
    hsa_plan_details: {
      data: {},
      content: [
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: 'If you pick this HSA Eligible health plan, your employer will ',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [
                {
                  type: 'code',
                },
              ],
              value: '{x}',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [],
              value:
                ". You can use this to help pay your medical bills, and you get to keep what you don't use. View the plan details below to see how your employer's ",
              nodeType: 'text',
            },
            {
              data: {},
              marks: [
                {
                  type: 'code',
                },
              ],
              value: '{x}',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [],
              value: ' helps you save.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
      ],
      nodeType: 'document',
    },
    hra_plan_details: {
      data: {},
      content: [
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: 'If you pick this HRA Eligible health plan, your employer will ',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [
                {
                  type: 'code',
                },
              ],
              value: '{x}',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [],
              value:
                ". You can use this to help pay your medical bills. View the plan details below to see how your employer's ",
              nodeType: 'text',
            },
            {
              data: {},
              marks: [
                {
                  type: 'code',
                },
              ],
              value: '{x}',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [],
              value: ' helps you save.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
      ],
      nodeType: 'document',
    },
    plan_details_heading: 'Detalles del plan',
    comprehensive_plan_terms: {
      drug: 'fármaco',
      medical: 'Médico',
    },
    breakdown_heading: 'Desglose del costo total de atención médica anual',
    plan_cost_contributions: {
      oop: {
        no: 'Las contribuciones del empleador {x} no cubren el costo de sus costos de bolsillo.',
        yes: 'Las contribuciones del empleador {x} lo ayudarán a pagar sus costos de bolsillo.',
      },
      premium: {
        no: 'Las contribuciones del empleador {x} no cubren el costo de su prima.',
        yes: 'Las contribuciones del empleador {x} lo ayudarán a pagar los costos de sus primas.',
      },
    },
    premium_cost_header: {
      data: {},
      content: [
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: 'Costos premium',
              nodeType: 'text',
            },
          ],
          nodeType: 'heading-4',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: 'Siempre pagará primas por este plan. ',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [
                {
                  type: 'code',
                },
              ],
              value: '{x}',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
      ],
      nodeType: 'document',
    },
    oop_cost_header: {
      data: {},
      content: [
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: 'Costos estimados de su bolsillo',
              nodeType: 'text',
            },
          ],
          nodeType: 'heading-4',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'Calculamos los costos de bolsillo estimados comparando sus respuestas con millones de puntos de datos de personas como usted. ',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [
                {
                  type: 'code',
                },
              ],
              value: '{x}',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
      ],
      nodeType: 'document',
    },
    premium_cost_receipt: {
      yearlyCost: 'Costo anual',
      premiumCosts: 'Costos premium',
      yearlyPremiums: 'Primas anuales',
      yearlyMultiplier: 'Multiplicador anual',
    },
    oop_cost_receipt: {
      drugs: 'Medicamentos con receta',
      hsaMatch: 'Empleador HSA Match',
      medicalCare: 'Atención médica',
      oopEstimate: 'Presupuesto de bolsillo',
      remainingOop: 'Su bolsillo restante',
      hraContribution: 'Contribución de la HRA del empleador',
      hsaContribution: 'Contribución de la HSA del empleador',
      recommendedHsaContribution: 'Recommended HSA Contribution',
      hsaTaxSavings: 'HSA Tax Savings',
      noReimburseCopay: 'La HRA no cubre el reembolso de copagos',
    },
    other_plan_notes: {
      title: 'Otras notas del plan',
      hsaEligible: {
        employerContribution:
          'Asegúrese de verificar cómo su empleador distribuye las contribuciones de HSA al finalizar su plan. A veces, los empleadores distribuyen fondos durante todo el año, lo que significa que los fondos podrían no estar totalmente disponibles para pagar sus gastos médicos cuando comience su plan.',
        'oopEstimate_<_hsaLimit':
          'Es posible que desee considerar contribuir más que la contribución recomendada de la HSA, ya que ayudará con los costos de atención médica futuros.',
        'employerContribution_>_oopEstimate': {
          employer:
            'La contribución de la HSA de su empleador cubrirá sus gastos de bolsillo estimados. Esto le deja con {x} ({x} menos {x}) en su HSA. Este valor adicional permanecerá en su HSA hasta que necesite usarlo.',
          personal:
            'Su recomendación personal de contribución a la HSA es de $ 0 porque la contribución a la HSA de su empleador cubre todos sus costos de bolsillo estimados. Siempre puede agregar a su HSA para cubrir costos imprevistos.',
        },
      },
      comprehensiveInNetworkOopLimit:
        'Su desembolso máximo es {x}. En el peor de los casos, no pagará más de {x} por los servicios cubiertos dentro de la red en 2020.',
    },
    next_steps: {
      fbs: 'A continuación, elija su cobertura suplementaria',
      noFbs: 'Repasemos sus selecciones',
      hsaPlus: 'A continuación, veamos su Cuenta de Ahorros de Salud.',
    },
    contribution_types: {
      hra: 'HRA',
      hsa: 'HSA',
      both: 'HSA & HRA',
      sihra: 'SIHRA',
      hra_sihra: 'HRA & SIHRA',
    },
    provider_search_text: {
      heading: 'Buscar proveedores',
      subheading: 'Buscar proveedores dentro de la red de {x}.',
    },
    summary_of_benefits: {
      heading: '¿Necesitas más detalles?',
      subheading: 'Ver más detalles aquí',
    },
    null_plan_detail_text: {
      deductibleAndOopMax: 'Unlimited',
    },
    compare_plan_text: {
      compareBar: {
        thirdPlan: 'Compara hasta 3 planes',
        secondPlan: 'Seleccione un segundo plan',
      },
      compareModal: {
        copay: 'Copago',
        inNetwork: 'Dentro de la Red',
        outOfNetwork: 'Fuera de la Red',
        deductible: 'Deducible',
        oopLimit: 'Límite de gastos de bolsillo',
        terms: {
          premium: {
            term: 'USE UNIVERSAL TOOLTIP DEFINITION',
            title: 'Prima',
          },
          planType: {
            term: 'NO TOOLTIP',
            title: 'Tipo de plan',
          },
          brandDrug: {
            term: 'USE UNIVERSAL TOOLTIP DEFINITION',
            title: 'Copago o coseguro de medicamentos de marca',
          },
          genericDrug: {
            term: 'USE UNIVERSAL TOOLTIP DEFINITION',
            title: 'Copago o coseguro de medicamentos genéricos',
          },
          oopEstimate: {
            term: 'USE UNIVERSAL TOOLTIP DEFINITION',
            title: 'Estimación de gastos de bolsillo',
          },
          primaryCare: {
            term: 'USE UNIVERSAL TOOLTIP DEFINITION',
            title: 'Copago o coseguro de atención primaria',
          },
          specialtyDrug: {
            term: 'USE UNIVERSAL TOOLTIP DEFINITION',
            title: 'Medicamentos especiales',
          },
          estYearlyTotal: {
            term: 'USE UNIVERSAL TOOLTIP DEFINITION',
            title: 'Total anual estimado',
          },
          specialistCare: {
            term: 'USE UNIVERSAL TOOLTIP DEFINITION',
            title: 'Copago o coseguro de atención especializada',
          },
          nonFormularyDrug: {
            term: 'USE UNIVERSAL TOOLTIP DEFINITION',
            title: 'Copago o coseguro de medicamentos no incluidos en el formulario',
          },
          score: {
            term: 'La puntuación indica qué tan bueno es cada plan para usted, personalmente, en función de cuáles son sus necesidades de cuidados médicos junto con sus preferencias de protección contra riesgos. El mejor valor disponible para usted tendrá el puntaje más alto, pero debe pensar en cualquier plan con una puntuación superior a 90 como una excelente opción para usted.',
            title: 'Puntuación',
          },
        },
        headers: {
          copays: 'Copagos y Coaseguros',
          mainDetails: 'Los detalles principales',
          deductiblesLimits: 'Deducibles y límites',
        },
        perVisit: 'por visita',
        modalTitle: 'Compare sus planes',
        notCovered: 'No cubierto',
        coinsurance: 'Coaseguro',
        selectThirdPlan: 'Psst, puede comparar hasta 3 planes a la vez.',
        afterDeductibleAsterisk: 'Después de que se alcanza el deducible',
      },
    },
    min_max_cost_sharing: {
      maxCostSharing: ' up to {x}',
    },
    secondary_content: {
      explore_our_predictions_cta: 'We rank your plans from best (99) to worst. Curious how we do it?',
      explore_our_predictions_button_text: 'Explore Our Predictions',
      build_a_plan_cta: 'Use our Build-A-Plan tool to consider any external health plans.',
      build_a_plan_button_text: 'Build-A-Plan',
      build_a_plan_instructions_button_text: "Here's What You Need",
      build_a_plan_instructions_button_link:
        'https://picwellapppublicdata.s3.amazonaws.com/Build-a-plan_instructions-English.pdf',
      waive_coverage_cta: "Don't need an employer health plan?",
      waive_coverage_button_text: 'Waive Coverage',
      hsa_hra_fsa_cta: 'Learn more about these savings and spending accounts.',
      hsa_button_text: 'HSA',
      hra_button_text: 'HRA',
      fsa_button_text: 'FSA',
      hsa_modal: {
        contentful_name: '[DEFAULT] HSA Educational Modal',
        title: 'Health Savings Account',
        header: {
          text: 'What is an HSA?',
          subtext:
            'While health insurance helps you cover the cost of medical care, it does not cover everything. A Health Savings Account, or HSA for short, is a great way to save for those expenses.',
        },
        eligible_plan_message: {
          contentful_name: '[DEFAULT] HSA Educational Modal - Eligible Plan',
          header: "You've chosen an HSA eligible plan!",
          text: {
            data: {},
            content: [
              {
                data: {},
                content: [
                  {
                    data: {},
                    marks: [],
                    value: "We'll help you choose a contribution amount on the HSA page.",
                    nodeType: 'text',
                  },
                ],
                nodeType: 'paragraph',
              },
            ],
            nodeType: 'document',
          },
        },
        non_eligible_plan_message: {
          contentful_name: '[DEFAULT] HSA Educational Modal - Non-Eligible Plan',
          header: "You haven't chosen an HSA eligible plan yet.",
          text: {
            data: {},
            content: [
              {
                data: {},
                content: [
                  {
                    data: {},
                    marks: [],
                    value: "If you do, we'll help you figure out how much to contribute.",
                    nodeType: 'text',
                  },
                ],
                nodeType: 'paragraph',
              },
            ],
            nodeType: 'document',
          },
        },
        employer_contribution_selected_plan_message: {
          contentful_name: '[DEFAULT] HSA Educational Modal - Employer Contribution Plan selected',
          header: 'Your employer will contribute {x} to your HSA',
          text: {
            data: {},
            content: [
              {
                data: {},
                content: [
                  {
                    data: {},
                    marks: [],
                    value: 'You can use these funds to pay for eligible expenses listed below.',
                    nodeType: 'text',
                  },
                ],
                nodeType: 'paragraph',
              },
            ],
            nodeType: 'document',
          },
        },
        employer_contribution_no_selected_plan_message: {
          contentful_name: '[DEFAULT] HSA Educational Modal - Employer Contribution No Plan selected',
          header: 'Your employer will contribute up to {x} to your HSA',
          text: {
            data: {},
            content: [
              {
                data: {},
                content: [
                  {
                    data: {},
                    marks: [],
                    value: 'You can use these funds to pay for eligible expenses listed below.',
                    nodeType: 'text',
                  },
                ],
                nodeType: 'paragraph',
              },
            ],
            nodeType: 'document',
          },
        },
        video_url: 'https://vimeo.com/499286061',
        how_does_it_work_header_text: 'How does it work?',
        how_does_it_work_content: {
          data: {},
          content: [
            {
              data: {},
              content: [
                {
                  data: {},
                  content: [
                    {
                      data: {},
                      content: [
                        {
                          data: {},
                          marks: [],
                          value: 'Select an HSA-eligible health plan.',
                          nodeType: 'text',
                        },
                      ],
                      nodeType: 'paragraph',
                    },
                  ],
                  nodeType: 'list-item',
                },
                {
                  data: {},
                  content: [
                    {
                      data: {},
                      content: [
                        {
                          data: {},
                          marks: [],
                          value: 'Open up a HSA (more about this on the review page).',
                          nodeType: 'text',
                        },
                      ],
                      nodeType: 'paragraph',
                    },
                  ],
                  nodeType: 'list-item',
                },
                {
                  data: {},
                  content: [
                    {
                      data: {},
                      content: [
                        {
                          data: {},
                          marks: [],
                          value: 'Choose how much to save in your HSA (we’ll help you with that).',
                          nodeType: 'text',
                        },
                      ],
                      nodeType: 'paragraph',
                    },
                  ],
                  nodeType: 'list-item',
                },
                {
                  data: {},
                  content: [
                    {
                      data: {},
                      content: [
                        {
                          data: {},
                          marks: [],
                          value:
                            'You and your employer combined can contribute up to the federal limit each year.',
                          nodeType: 'text',
                        },
                      ],
                      nodeType: 'paragraph',
                    },
                    {
                      data: {},
                      content: [
                        {
                          data: {},
                          content: [
                            {
                              data: {},
                              content: [
                                {
                                  data: {},
                                  marks: [
                                    {
                                      type: 'code',
                                    },
                                    {
                                      type: 'bold',
                                    },
                                  ],
                                  value: '{x}',
                                  nodeType: 'text',
                                },
                                {
                                  data: {},
                                  marks: [],
                                  value: ' for individuals',
                                  nodeType: 'text',
                                },
                              ],
                              nodeType: 'paragraph',
                            },
                          ],
                          nodeType: 'list-item',
                        },
                        {
                          data: {},
                          content: [
                            {
                              data: {},
                              content: [
                                {
                                  data: {},
                                  marks: [
                                    {
                                      type: 'code',
                                    },
                                    {
                                      type: 'bold',
                                    },
                                  ],
                                  value: '{x}',
                                  nodeType: 'text',
                                },
                                {
                                  data: {},
                                  marks: [],
                                  value: ' for families',
                                  nodeType: 'text',
                                },
                              ],
                              nodeType: 'paragraph',
                            },
                          ],
                          nodeType: 'list-item',
                        },
                      ],
                      nodeType: 'unordered-list',
                    },
                  ],
                  nodeType: 'list-item',
                },
              ],
              nodeType: 'unordered-list',
            },
            {
              data: {},
              content: [
                {
                  data: {},
                  marks: [],
                  value: '',
                  nodeType: 'text',
                },
              ],
              nodeType: 'paragraph',
            },
          ],
          nodeType: 'document',
        },
        what_can_it_do_header_text: 'What can it do?',
        what_can_it_do_content: [
          {
            contentful_name: '[DEFAULT] HSA Educational Modal Content Block - Lower Your Taxes',
            title: 'Lower your taxes',
            content: {
              nodeType: 'document',
              data: {},
              content: [
                {
                  nodeType: 'paragraph',
                  data: {},
                  content: [
                    {
                      nodeType: 'text',
                      value:
                        'Contributions that you make to an HSA are tax free. This means that you can lower your tax bill by putting money in an HSA.',
                      marks: [],
                      data: {},
                    },
                  ],
                },
              ],
            },
          },
          {
            contentful_name:
              '[DEFAULT] HSA Educational Modal Content Block - Pay for qualified medical expenses',
            title: 'Pay for qualified medical expenses',
            content: {
              data: {},
              content: [
                {
                  data: {},
                  content: [
                    {
                      data: {},
                      marks: [],
                      value:
                        'Use your tax-free HSA contributions to cover qualified medical expenses. View the full list of eligible expenses.',
                      nodeType: 'text',
                    },
                  ],
                  nodeType: 'paragraph',
                },
              ],
              nodeType: 'document',
            },
            button_text: 'Eligible Expenses',
            button_link: 'https://picwellapppublicdata.s3.amazonaws.com/hsa-eligible-expenses.pdf',
          },
          {
            contentful_name: '[DEFAULT] HSA Educational Modal Custom Block - Invest For Retirement',
            title: 'Invest for retirement',
            content: {
              nodeType: 'document',
              data: {},
              content: [
                {
                  nodeType: 'paragraph',
                  data: {},
                  content: [
                    {
                      nodeType: 'text',
                      value:
                        'Invest your HSA dollars to get ready for health care costs in retirement. \n\nInvestments earnings and withdrawals to cover qualified medical expenses are tax free.',
                      marks: [],
                      data: {},
                    },
                  ],
                },
              ],
            },
          },
          {
            contentful_name: '[DEFAULT] HSA Educational Modal Custom Block - Contributions',
            title: 'Contributions are yours to keep',
            content: {
              nodeType: 'document',
              data: {},
              content: [
                {
                  nodeType: 'paragraph',
                  data: {},
                  content: [
                    {
                      nodeType: 'text',
                      value:
                        'Any contributions to your HSA are yours to keep. You never have to worry about losing your HSA money at the end of the year.',
                      marks: [],
                      data: {},
                    },
                  ],
                },
              ],
            },
          },
        ],
        faq_header_text: 'Frequently Asked Questions',
        faq_content: [
          {
            contentful_name: '[DEFAULT] HSA Educational Modal Custom Block - FAQ - Open HSA',
            title: 'How do I open an HSA?',
            content: {
              nodeType: 'document',
              data: {},
              content: [
                {
                  nodeType: 'paragraph',
                  data: {},
                  content: [
                    {
                      nodeType: 'text',
                      value:
                        'You can open an HSA through your benefit administration platform. You can find a link to your HSA admin platform on the review page.',
                      marks: [],
                      data: {},
                    },
                  ],
                },
              ],
            },
          },
          {
            contentful_name: '[DEFAULT] HSA Educational Modal Custom Block - FAQ - Contribute to HSA',
            title: 'How do I contribute to my HSA?',
            content: {
              data: {},
              content: [
                {
                  data: {},
                  content: [
                    {
                      data: {},
                      marks: [],
                      value:
                        '(1) Payroll deduction - your annual contribution will be divided into equal amounts and deducted from your payroll before taxes (2) Electronic transfer from your checking or savings account using your HSA admin platform (link on review page).',
                      nodeType: 'text',
                    },
                  ],
                  nodeType: 'paragraph',
                },
              ],
              nodeType: 'document',
            },
          },
          {
            contentful_name: '[DEFAULT] HSA Educational Modal Custom Block - FAQ - Use HSA',
            title: 'How do I use my HSA funds?',
            content: {
              nodeType: 'document',
              data: {},
              content: [
                {
                  nodeType: 'paragraph',
                  data: {},
                  content: [
                    {
                      nodeType: 'text',
                      value:
                        'When you open up an HSA, you will receive a debit card that you can use to pay for eligible medical expenses. You can use this debit card to pay health care providers directly, and you may also be able to submit these payments through your insurance company’s website.',
                      marks: [],
                      data: {},
                    },
                  ],
                },
              ],
            },
          },
          {
            contentful_name: '[DEFAULT] HSA Educational Modal Custom Block - FAQ - HSA, FSA, HRA Difference',
            title: "HSA, FSA, HRA: What's the difference?",
            image_url:
              'https://picwellapppublicdata.s3.amazonaws.com/secondary_educational_content/hsa_fsa_hra_difference_en.png',
            cells: [
              ['', 'HSA', 'FSA', 'HRA'],
              ['The money in the account belongs to you', 'X', '', ''],
              ['The money in the account belongs to your employer', '', 'X', 'X'],
              ['You can contribute', 'X', 'X', ''],
              ['Your employer can contribute', 'X', 'X', 'X'],
              ['Must had high deductible plan to contribute', 'X', '', ''],
              ['You can invest funds', 'X', '', ''],
            ],
          },
          {
            contentful_name:
              '[DEFAULT] HSA Educational Modal Custom Block - FAQ - HSA, 401(k), IRA Difference',
            title: "HSA, 401(k), IRA: What's the difference?",
            image_url:
              'https://picwellapppublicdata.s3.amazonaws.com/secondary_educational_content/hsa_401k_hra_difference_en.png',
            cells: [
              ['', 'HSA', '401(k)', 'HRA'],
              [
                'Contributions are tax free',
                '',
                '401(k) contributions are not excluded from Social Security and Medicare taxes.',
                '',
              ],
              ['Capitial gains are tax free', 'X', 'X', 'X'],
              ['Withdrawals are tax free', 'If used for qualified medical expenses.', 'X', 'Roth IRA only.'],
              ['Minimum distribution requirements', 'X', 'X', 'Roth IRAs excluded.'],
            ],
          },
          {
            contentful_name: '[DEFAULT] HSA Educational Modal Custom Block - FAQ - No Future Enrollment',
            title: "What if I don't enroll in an HSA eligible plan in the future?",
            content: {
              data: {},
              content: [
                {
                  data: {},
                  content: [
                    {
                      data: {},
                      marks: [],
                      value:
                        'Once you deposit the funds into an HSA, the account can be used to pay for qualified medical expenses tax-free, even if you no longer have HDHP coverage. However, you won’t be able to make new contributions.',
                      nodeType: 'text',
                    },
                  ],
                  nodeType: 'paragraph',
                },
              ],
              nodeType: 'document',
            },
          },
          {
            contentful_name: '[DEFAULT] HSA Educational Modal Custom Block - FAQ - Laid Off',
            title: 'What happens to my HSA/funds if I switch jobs or get laid off?',
            content: {
              data: {},
              content: [
                {
                  data: {},
                  content: [
                    {
                      data: {},
                      marks: [],
                      value:
                        "The money in your HSA remains yours even after you lose or leave your job. You can usually keep that money in your former employer's HSA, or you can roll it over to another HSA administrator.",
                      nodeType: 'text',
                    },
                  ],
                  nodeType: 'paragraph',
                },
                {
                  data: {},
                  content: [
                    {
                      data: {},
                      marks: [
                        {
                          type: 'bold',
                        },
                      ],
                      value: '\n',
                      nodeType: 'text',
                    },
                  ],
                  nodeType: 'paragraph',
                },
              ],
              nodeType: 'document',
            },
          },
          {
            contentful_name: '[DEFAULT] HSA Educational Modal Custom Block - FAQ - Retired',
            title: 'What happens to my HSA after I retire?',
            content: {
              nodeType: 'document',
              data: {},
              content: [
                {
                  nodeType: 'paragraph',
                  data: {},
                  content: [
                    {
                      nodeType: 'text',
                      value:
                        'Once you turn 65, regardless of whether you are enrolled in Medicare, there is no penalty to withdraw funds from the HSA. If you withdraw funds for qualified medical expenses, you won’t pay any taxes. HSA funds used for other purposes are taxed as normal income, just like a 401(k) withdrawal.',
                      marks: [],
                      data: {},
                    },
                  ],
                },
              ],
            },
          },
        ],
      },
      hra_modal: {
        contentful_name: '[DEFAULT] HRA Educational Modal',
        title: 'Health Reimbursement Account',
        header: {
          text: 'What is an HRA?',
          subtext:
            'A Health Reimbursement Arrangement, or HRA, is an account that your employer sets up to help you cover your medical costs. Your employer contributes money to this fund, and you can use these funds to cover qualified medical expenses.',
        },
        eligible_plan_message: {
          contentful_name: '[DEFAULT] HRA Educational Modal - Eligible Plan',
          header: "You've chosen an HSA eligible plan!",
          text: {
            data: {},
            content: [
              {
                data: {},
                content: [
                  {
                    data: {},
                    marks: [],
                    value: "We'll help you choose a contribution amount on the HSA page.",
                    nodeType: 'text',
                  },
                ],
                nodeType: 'paragraph',
              },
            ],
            nodeType: 'document',
          },
          color: 'blue',
          icon: 'CheckCircle',
        },
        non_eligible_plan_message: {
          contentful_name: '[DEFAULT] HRA Educational Modal - Non-Eligible Plan',
          header: "You haven't chosen an HRA eligible plan yet.",
          text: {
            data: {},
            content: [
              {
                data: {},
                content: [
                  {
                    data: {},
                    marks: [],
                    value: "If you do, we'll help you figure out how much to contribute.",
                    nodeType: 'text',
                  },
                ],
                nodeType: 'paragraph',
              },
            ],
            nodeType: 'document',
          },
          icon: 'Circle',
        },
        employer_contribution_selected_plan_message: {
          contentful_name: '[DEFAULT] HRA Educational Modal - Employer Contribution Plan selected',
          header: 'Your employer will contribute {x} to your HRA',
          text: {
            data: {},
            content: [
              {
                data: {},
                content: [
                  {
                    data: {},
                    marks: [],
                    value: 'You can use these funds to pay for eligible expenses listed below.',
                    nodeType: 'text',
                  },
                ],
                nodeType: 'paragraph',
              },
            ],
            nodeType: 'document',
          },
          color: 'blue',
          icon: 'CheckCircle',
        },
        employer_contribution_no_selected_plan_message: {
          contentful_name: '[DEFAULT] HRA Educational Modal - Employer Contribution No Plan selected',
          header: 'Your employer will contribute up to {x} to your HRA',
          text: {
            data: {},
            content: [
              {
                data: {},
                content: [
                  {
                    data: {},
                    marks: [],
                    value: 'You can use these funds to pay for eligible expenses listed below.',
                    nodeType: 'text',
                  },
                ],
                nodeType: 'paragraph',
              },
            ],
            nodeType: 'document',
          },
          color: 'blue',
          icon: 'CheckCircle',
        },
        video_url: 'https://vimeo.com/499286770',
        how_does_it_work_header_text: 'How does it work?',
        how_does_it_work_content: {
          data: {},
          content: [
            {
              data: {},
              content: [
                {
                  data: {},
                  content: [
                    {
                      data: {},
                      content: [
                        {
                          data: {},
                          marks: [],
                          value:
                            'Once you select an HRA-eligible health plan, your employer will have access to up to ',
                          nodeType: 'text',
                        },
                        {
                          data: {},
                          marks: [
                            {
                              type: 'code',
                            },
                            {
                              type: 'bold',
                            },
                          ],
                          value: '{x}',
                          nodeType: 'text',
                        },
                        {
                          data: {},
                          marks: [],
                          value: ' that you can use to offset your medical expenses.',
                          nodeType: 'text',
                        },
                      ],
                      nodeType: 'paragraph',
                    },
                  ],
                  nodeType: 'list-item',
                },
                {
                  data: {},
                  content: [
                    {
                      data: {},
                      content: [
                        {
                          data: {},
                          marks: [],
                          value:
                            'When you receive medical care or purchase an item that is eligible for HRA reimbursement, submit a receipt to your employer. If your employer provides you with an HRA debit card, you can also use this to pay for eligible expenses.',
                          nodeType: 'text',
                        },
                      ],
                      nodeType: 'paragraph',
                    },
                  ],
                  nodeType: 'list-item',
                },
                {
                  data: {},
                  content: [
                    {
                      data: {},
                      content: [
                        {
                          data: {},
                          marks: [],
                          value:
                            'Your employer will reimburse you from the HRA funds up until you reach the HRA funding limit. Your employer keeps any funds that you do not use by the end of the health insurance plan year.',
                          nodeType: 'text',
                        },
                      ],
                      nodeType: 'paragraph',
                    },
                  ],
                  nodeType: 'list-item',
                },
              ],
              nodeType: 'unordered-list',
            },
            {
              data: {},
              content: [
                {
                  data: {},
                  marks: [],
                  value: '',
                  nodeType: 'text',
                },
              ],
              nodeType: 'paragraph',
            },
          ],
          nodeType: 'document',
        },
        what_can_it_do_header_text: 'What can it do?',
        what_can_it_do_content: [
          {
            contentful_name: '[DEFAULT] HRA Educational Modal Content Block - Qualified Medical Expenses',
            title: 'Pay for qualified medical expenses',
            content: {
              nodeType: 'document',
              data: {},
              content: [
                {
                  nodeType: 'paragraph',
                  data: {},
                  content: [
                    {
                      nodeType: 'text',
                      value:
                        'Use your employer-provided HRA funds to cover eligible medical expenses. Your employer determines the specific list of eligible expenses, but they typically include costs that your health insurance plan does not cover, such as copayments and deductible payments, as well as vision and dental care, over the counter prescriptions, and many common home health items.',
                      marks: [],
                      data: {},
                    },
                  ],
                },
              ],
            },
          },
        ],
        faq_header_text: 'Frequently Asked Questions',
        faq_content: [
          {
            contentful_name: '[DEFAULT] HRA Educational Modal Custom Block - FAQ - Can I Contribute',
            title: 'Can I contribute to an HRA?',
            content: {
              nodeType: 'document',
              data: {},
              content: [
                {
                  nodeType: 'paragraph',
                  data: {},
                  content: [
                    {
                      nodeType: 'text',
                      value: 'No.  Only your employer can contribute funds to an HRA.',
                      marks: [],
                      data: {},
                    },
                  ],
                },
              ],
            },
          },
          {
            contentful_name: "[DEFAULT] HRA Educational Modal Custom Block - FAQ - I Didn't Use My Funds",
            title: 'What happens if I don’t use all of my HRA funds?',
            content: {
              nodeType: 'document',
              data: {},
              content: [
                {
                  nodeType: 'paragraph',
                  data: {},
                  content: [
                    {
                      nodeType: 'text',
                      value:
                        'Unused funds usually go back to your employer, but check with your employer for details. In some cases, your employer may allow you to roll over some unused funds for use next year.',
                      marks: [],
                      data: {},
                    },
                  ],
                },
              ],
            },
          },
          {
            contentful_name: '[DEFAULT] HRA Educational Modal Custom Block - FAQ - Access Funds',
            title: 'How do I access my HRA funds?',
            content: {
              nodeType: 'document',
              data: {},
              content: [
                {
                  nodeType: 'paragraph',
                  data: {},
                  content: [
                    {
                      nodeType: 'text',
                      value:
                        'If your employer provides you with an HRA debit card, you can use that to pay for HRA eligible expenses. If you do not have an HRA debit card, you can submit receipts to your employer for reimbursement.',
                      marks: [],
                      data: {},
                    },
                  ],
                },
              ],
            },
          },
          {
            contentful_name: '[DEFAULT] HRA Educational Modal Custom Block - FAQ - Left Company',
            title: 'What happens if I leave my company part way through the year?',
            content: {
              nodeType: 'document',
              data: {},
              content: [
                {
                  nodeType: 'paragraph',
                  data: {},
                  content: [
                    {
                      nodeType: 'text',
                      value:
                        'If you leave your company part way through the year, you will no longer have access to unused funds.',
                      marks: [],
                      data: {},
                    },
                  ],
                },
              ],
            },
          },
          {
            contentful_name: '[DEFAULT] HRA Educational Modal Custom Block - FAQ - Expense Types',
            title: 'What type of expenses can be reimbursed from an HRA?',
            content: {
              nodeType: 'document',
              data: {},
              content: [
                {
                  nodeType: 'paragraph',
                  data: {},
                  content: [
                    {
                      nodeType: 'text',
                      value:
                        'You can use HRA funds to pay for qualified medical expenses, including copayments and deductible payments for your health insurance, dental and vision care, and many other health care goods and services. ',
                      marks: [],
                      data: {},
                    },
                    {
                      nodeType: 'hyperlink',
                      data: {
                        uri: 'https://picwellapppublicdata.s3.amazonaws.com/hsa-eligible-expenses.pdf',
                      },
                      content: [
                        {
                          nodeType: 'text',
                          value: 'HSA Expense Sheet',
                          marks: [],
                          data: {},
                        },
                      ],
                    },
                    {
                      nodeType: 'text',
                      value: '',
                      marks: [],
                      data: {},
                    },
                  ],
                },
                {
                  nodeType: 'paragraph',
                  data: {},
                  content: [
                    {
                      nodeType: 'text',
                      value: '\n',
                      marks: [],
                      data: {},
                    },
                  ],
                },
              ],
            },
          },
          {
            contentful_name: '[DEFAULT] HRA Educational Modal Custom Block - FAQ - Combine HRA and HSA',
            title: 'Can I combine an HRA and an HSA?',
            content: {
              nodeType: 'document',
              data: {},
              content: [
                {
                  nodeType: 'paragraph',
                  data: {},
                  content: [
                    {
                      nodeType: 'text',
                      value: 'No. You cannot have both an HRA and an HSA.',
                      marks: [],
                      data: {},
                    },
                  ],
                },
              ],
            },
          },
          {
            contentful_name: '[DEFAULT] HRA Educational Modal Custom Block - FAQ - Combine HRA and FSA',
            title: 'Can I combine an HRA and an FSA?',
            content: {
              nodeType: 'document',
              data: {},
              content: [
                {
                  nodeType: 'paragraph',
                  data: {},
                  content: [
                    {
                      nodeType: 'text',
                      value:
                        'Yes. You can have both an FSA (Flexible Spending Account) and an HRA.  If you have both types of accounts, you cannot reimburse the same expense from both accounts. Typically, you cannot use FSA funds until you have used up your full HRA amount. However, this varies by employer, so check with your employer’s plan documents for more information.',
                      marks: [],
                      data: {},
                    },
                  ],
                },
                {
                  nodeType: 'paragraph',
                  data: {},
                  content: [
                    {
                      nodeType: 'text',
                      value: '\n',
                      marks: [],
                      data: {},
                    },
                  ],
                },
              ],
            },
          },
        ],
      },
      fsa_modal: {
        contentful_name: '[DEFAULT] FSA Educational Modal',
        title: 'Flexible Spending Account',
        header: {
          text: 'What is an FSA?',
          subtext:
            'A Medical Flexible Spending Account, or Medical FSA, is a pre-tax benefit account that you can use to pay for qualified medical, dental, and vision care expenses that aren’t covered by your health insurance plan.',
        },
        eligible_plan_message: {
          contentful_name: '[DEFAULT] FSA Educational Modal - Eligible Plan',
          header: 'You’re eligible to use a Medical FSA.',
          color: 'green',
          icon: 'CheckCircle',
        },
        non_eligible_plan_message: {
          contentful_name: '[DEFAULT] FSA Educational Modal - Non-Eligible Plan',
          header: 'Because you chose an HSA eligible plan, you’re eligible to use a LPFSA.',
          color: 'green',
          icon: 'CheckCircle',
        },
        employer_contribution_no_selected_plan_message: {
          contentful_name: '[DEFAULT] FSA Educational Modal - Employer Contribution No Plan selected',
          header: 'Your employer will contribute {x} to your FSA',
          text: {
            data: {},
            content: [
              {
                data: {},
                content: [
                  {
                    data: {},
                    marks: [],
                    value: 'You can use these funds to pay for eligible expenses listed below.',
                    nodeType: 'text',
                  },
                ],
                nodeType: 'paragraph',
              },
            ],
            nodeType: 'document',
          },
        },
        video_url: 'https://vimeo.com/418250339',
        how_does_it_work_header_text: 'How does it work?',
        how_does_it_work_content: {
          data: {},
          content: [
            {
              data: {},
              content: [
                {
                  data: {},
                  content: [
                    {
                      data: {},
                      content: [
                        {
                          data: {},
                          marks: [],
                          value:
                            'Open up an account and decide how much to contribute each year. Your employer will calculate how much to withhold from each paycheck to get you to your total annual contribution.',
                          nodeType: 'text',
                        },
                      ],
                      nodeType: 'paragraph',
                    },
                  ],
                  nodeType: 'list-item',
                },
                {
                  data: {},
                  content: [
                    {
                      data: {},
                      content: [
                        {
                          data: {},
                          marks: [],
                          value:
                            'You must decide how much to contribute when you open your account. You will have access to the entire annual amount immediately, even though you will be making those contributions gradually with each paycheck.',
                          nodeType: 'text',
                        },
                      ],
                      nodeType: 'paragraph',
                    },
                  ],
                  nodeType: 'list-item',
                },
                {
                  data: {},
                  content: [
                    {
                      data: {},
                      content: [
                        {
                          data: {},
                          marks: [],
                          value:
                            "Once that's done, you can use your account to help pay for hundreds of eligible healthcare expenses, like co-payments for doctor visits, prescription drugs, and new eyeglasses or contact lenses.",
                          nodeType: 'text',
                        },
                      ],
                      nodeType: 'paragraph',
                    },
                  ],
                  nodeType: 'list-item',
                },
                {
                  data: {},
                  content: [
                    {
                      data: {},
                      content: [
                        {
                          data: {},
                          marks: [],
                          value:
                            "If you don't use all of your FSA funds, you might be able to roll over some unused funds to the next plan year, but you will lose any left-over contributions above that roll-over limit. ",
                          nodeType: 'text',
                        },
                      ],
                      nodeType: 'paragraph',
                    },
                  ],
                  nodeType: 'list-item',
                },
                {
                  data: {},
                  content: [
                    {
                      data: {},
                      content: [
                        {
                          data: {},
                          marks: [],
                          value:
                            'Since you can lose unused FSA funds, you should only contribute amounts that you know you will use.',
                          nodeType: 'text',
                        },
                      ],
                      nodeType: 'paragraph',
                    },
                  ],
                  nodeType: 'list-item',
                },
                {
                  data: {},
                  content: [
                    {
                      data: {},
                      content: [
                        {
                          data: {},
                          marks: [],
                          value:
                            'If you are enrolled in an HSA eligible plan, your Medical FSA turns into Limited Purpose FSA. Limited Purpose FSAs can only be used to pay for Dental, Vision, and Orthodontia expenses.',
                          nodeType: 'text',
                        },
                      ],
                      nodeType: 'paragraph',
                    },
                  ],
                  nodeType: 'list-item',
                },
              ],
              nodeType: 'unordered-list',
            },
            {
              data: {},
              content: [
                {
                  data: {},
                  marks: [],
                  value: '',
                  nodeType: 'text',
                },
              ],
              nodeType: 'paragraph',
            },
          ],
          nodeType: 'document',
        },
        what_can_it_do_header_text: 'What can it do?',
        what_can_it_do_content: [
          {
            contentful_name: '[DEFAULT] FSA Educational Modal Content Block - Lower Your Taxes',
            title: 'Lower your taxes',
            content: {
              nodeType: 'document',
              data: {},
              content: [
                {
                  nodeType: 'paragraph',
                  data: {},
                  content: [
                    {
                      nodeType: 'text',
                      value:
                        'Contributions that you make to an FSA are tax free. This means that you can lower your tax bill by putting money in an FSA.',
                      marks: [],
                      data: {},
                    },
                  ],
                },
              ],
            },
          },
          {
            contentful_name: '[DEFAULT] FSA Educational Modal Content Block - Qualified Medical Expenses',
            title: 'Pay for qualified medical expenses',
            content: {
              nodeType: 'document',
              data: {},
              content: [
                {
                  nodeType: 'paragraph',
                  data: {},
                  content: [
                    {
                      nodeType: 'text',
                      value: 'Use your FSA funds to cover eligible medical expenses. ',
                      marks: [],
                      data: {},
                    },
                    {
                      nodeType: 'hyperlink',
                      data: {
                        uri: 'https://picwellapppublicdata.s3.amazonaws.com/hsa-eligible-expenses.pdf',
                      },
                      content: [
                        {
                          nodeType: 'text',
                          value: 'Eligible Expenses',
                          marks: [],
                          data: {},
                        },
                      ],
                    },
                    {
                      nodeType: 'text',
                      value: '',
                      marks: [],
                      data: {},
                    },
                  ],
                },
              ],
            },
          },
        ],
        faq_header_text: 'Frequently Asked Questions',
        faq_content: [
          {
            contentful_name: '[DEFAULT] FSA Educational Modal Custom Block - FAQ - Can I Contribute',
            title: 'Can I contribute to an FSA?',
            content: {
              data: {},
              content: [
                {
                  data: {},
                  content: [
                    {
                      data: {},
                      marks: [],
                      value:
                        'Yes. You can contribute money to an FSA. Because FSA contributions are tax free, this is a good way to get a tax break on qualifying expenses that you know that you will incur.',
                      nodeType: 'text',
                    },
                  ],
                  nodeType: 'paragraph',
                },
              ],
              nodeType: 'document',
            },
          },
          {
            contentful_name: '[DEFAULT] FSA Educational Modal Custom Block - FAQ - How Much Can I Contribute',
            title: 'How much can I contribute to an FSA?',
            content: {
              nodeType: 'document',
              data: {},
              content: [
                {
                  nodeType: 'paragraph',
                  data: {},
                  content: [
                    {
                      nodeType: 'text',
                      value:
                        'In 2022, you can contribute up to $2,850 to an FSA. Just be sure to only contribute funds that you know that you will use, since you can lose unused FSA funds at the end of the year.',
                      marks: [],
                      data: {},
                    },
                  ],
                },
              ],
            },
          },
          {
            contentful_name: '[DEFAULT] FSA Educational Modal Custom Block - FAQ - Can I Change My Mind',
            title: 'Can I change my mind about how much I contribute to an FSA?',
            content: {
              data: {},
              content: [
                {
                  data: {},
                  content: [
                    {
                      data: {},
                      marks: [
                        {
                          type: 'italic',
                        },
                      ],
                      value:
                        'No. Once you set your contribution amount at the beginning of your benefit plan year, you cannot change the contribution amount unless you have a specific qualifying event.  Qualifying events include things like changes to marital status, changes to the number of dependents, or changes to your employment status.',
                      nodeType: 'text',
                    },
                  ],
                  nodeType: 'paragraph',
                },
                {
                  data: {},
                  content: [
                    {
                      data: {},
                      marks: [],
                      value: '\n',
                      nodeType: 'text',
                    },
                  ],
                  nodeType: 'paragraph',
                },
              ],
              nodeType: 'document',
            },
          },
          {
            contentful_name: "[DEFAULT] FSA Educational Modal Custom Block - FAQ - Didn't Use Funds",
            title: 'What happens if I don’t use all of my FSA funds?',
            content: {
              nodeType: 'document',
              data: {},
              content: [
                {
                  nodeType: 'paragraph',
                  data: {},
                  content: [
                    {
                      nodeType: 'text',
                      value:
                        'Unused funds usually go back to your employer, but check with your employer for details. The IRS permits employers to allow up to $570 in unused funds to roll over to the next year.',
                      marks: [],
                      data: {},
                    },
                  ],
                },
              ],
            },
          },
          {
            contentful_name: '[DEFAULT] FSA Educational Modal Custom Block - FAQ - Access Funds',
            title: 'How do I access my FSA funds?',
            content: {
              nodeType: 'document',
              data: {},
              content: [
                {
                  nodeType: 'paragraph',
                  data: {},
                  content: [
                    {
                      nodeType: 'text',
                      value:
                        'Your employer may provide you with an FSA debit card that you can use to pay for eligible expenses. You can also request to be reimbursed for eligible expenses from your account.',
                      marks: [],
                      data: {},
                    },
                  ],
                },
              ],
            },
          },
          {
            contentful_name: '[DEFAULT] FSA Educational Modal Custom Block - FAQ - Leave Job',
            title: 'What happens if I leave my company part way through the year?',
            content: {
              nodeType: 'document',
              data: {},
              content: [
                {
                  nodeType: 'paragraph',
                  data: {},
                  content: [
                    {
                      nodeType: 'text',
                      value:
                        'If you leave your company part way through the year, you may have a “run out period” during which you can still use FSA funds after you leave your employer.  You lose any funds that you do not use during that run out period.  Check with you employer for more details about run our periods.',
                      marks: [],
                      data: {},
                    },
                  ],
                },
              ],
            },
          },
          {
            contentful_name: '[DEFAULT] FSA Educational Modal Custom Block - FAQ - Expense Types',
            title: 'What type of expenses can I use my FSA for?',
            content: {
              nodeType: 'document',
              data: {},
              content: [
                {
                  nodeType: 'paragraph',
                  data: {},
                  content: [
                    {
                      nodeType: 'text',
                      value:
                        'You can use FSA funds to pay for qualified medical expenses, including copayments and deductible payments for your health insurance, dental and vision care, and many other health care goods and services. ',
                      marks: [],
                      data: {},
                    },
                    {
                      nodeType: 'hyperlink',
                      data: {
                        uri: 'https://picwellapppublicdata.s3.amazonaws.com/hsa-eligible-expenses.pdf',
                      },
                      content: [
                        {
                          nodeType: 'text',
                          value: 'HSA Expense List',
                          marks: [],
                          data: {},
                        },
                      ],
                    },
                    {
                      nodeType: 'text',
                      value: '',
                      marks: [],
                      data: {},
                    },
                  ],
                },
              ],
            },
          },
          {
            contentful_name: '[DEFAULT] FSA Educational Modal Custom Block - FAQ - FSA and HRA',
            title: 'Can I combine an FSA and an HRA?',
            content: {
              nodeType: 'document',
              data: {},
              content: [
                {
                  nodeType: 'paragraph',
                  data: {},
                  content: [
                    {
                      nodeType: 'text',
                      value:
                        'Yes.  You can have both an FSA (Flexible Spending Account) and an HRA.  If you have both types of accounts, you cannot reimburse the same expense from both accounts. Typically, you cannot use FSA funds until you have used up your full HRA amount. However, this varies by employer, so check with your employer’s plan documents for more information.',
                      marks: [],
                      data: {},
                    },
                  ],
                },
              ],
            },
          },
          {
            contentful_name: '[DEFAULT] FSA Educational Modal Custom Block - FAQ - FSA and HSA',
            title: 'Can I combine an FSA and an HSA?',
            content: {
              nodeType: 'document',
              data: {},
              content: [
                {
                  nodeType: 'paragraph',
                  data: {},
                  content: [
                    {
                      nodeType: 'text',
                      value:
                        'Sort of.  If you have an HSA, you can combine that with a limited purpose FSA or a post-deductible FSA. Limited purpose FSA can be used to pay for vision and dental care, while a post-deductible FSA can be used to pay for medical cost, like coinsurance, after the HSA deductible has been met.',
                      marks: [],
                      data: {},
                    },
                  ],
                },
              ],
            },
          },
        ],
      },
      no_deductible_modal: {
        contentful_name: '[DEFAULT] NoDeductible Educational Modal',
        title: 'Headline',
        header: {
          text: '¿Cómo funciona el plan {x}?',
          subtext:
            'Con la mayoría de los planes de salud, te enfermas, recibes atención, y luego descubres cuánto te costó todo. Un plan {x} funciona un poco diferente. Puedes usar un sitio web o una aplicación especial para ver los precios por adelantado, lo que te permite comparar en médicos u hospitales de tu red y encontrar la mejor oferta en la atención que necesitas. A menos que sea una emergencia, porque esas cuestan prácticamente lo mismo en todas partes. En general, estos planes hacen que administrar y entender tus gastos sea un poco más fácil: el precio que ves es el precio que pagas, sin deducibles que cumplir o porcentajes que calcular. Además, recibes un solo estado de cuenta al final del mes con todos tus costos de atención médica en un solo lugar, lo que facilita mucho pagarlos de una vez.',
        },
        video_url: 'https://vimeo.com/813320445',
      },
      member_selection_mobile_header: {
        everyone: 'Covering all members',
        yourself: 'Covering yourself',
        familyMembers: 'Covering {x} members',
      },
      member_selection_header: 'Who are you covering?',
      member_overage_dependent_disclaimer: {
        contentful_name: '[DEFAULT] ',
        header: '¡Atención!',
        text: {
          data: {},
          content: [
            {
              data: {},
              content: [
                {
                  data: {},
                  marks: [],
                  value: 'Su cobertura médica tiene un límite de edad para dependientes.',
                  nodeType: 'text',
                },
              ],
              nodeType: 'paragraph',
            },
          ],
          nodeType: 'document',
        },
        color: 'blue',
        icon: 'CheckCircle',
      },
      tooltips: {
        memberSelectionHeader: {
          text: 'I am in [Health] Secondary Content > Tooltips',
          title: 'JAKE BLANK!',
        },
        providerSelectionHeader: {
          text: 'Cree una lista de sus médicos y otros proveedores de atención médica para saber qué planes los incluyen dentro de la red.',
        },
        continue_button_tooltip: {
          text: 'Nota: El estado de la red de cada proveedor puede cambiar en cualquier momento, por lo que es una buena idea comunicarse con ellos antes de su próxima cita para ver si todavía están dentro de la red.',
        },
      },
      member_selection_info_bar: {
        contentful_name: '[Health] Member Selection Info Bar',
        text: {
          nodeType: 'document',
          data: {},
          content: [
            {
              nodeType: 'paragraph',
              data: {},
              content: [
                {
                  nodeType: 'text',
                  value: 'Build-a-plan details will have to be re-added if you update household members.',
                  marks: [],
                  data: {},
                },
              ],
            },
          ],
        },
      },
      member_selection_update_button: 'Update Members',
      member_selection_pregnancy_save: 'Guardar respuesta',
      member_selection_pregnancy_question:
        'Ha actualizado a quién cubre. ¿Su familia que ya tiene cobertura está planeando tener un embarazo para el año que viene?',
      member_selection_modal_header: 'Actualizar preguntas de perfil',
      member_selection_modal_incentives_prompt:
        'Asegurémonos de que estas respuestas sigan siendo precisas para quién está cubriendo.',
      accessibility: {
        loading_bar_alert: 'Analizar sus planes elegibles',
        plan_selected_alert: 'Plan seleccionado',
        waived_coverage_alert: 'Se renunció a la cobertura',
        loading_bar_complete_alert: 'Sus planes están listos',
        compare_modal_add_plan_alert: 'Seleccionó {x}',
        compare_bar_remove_plan_button: 'Eliminar plan',
        compare_modal_remove_plan_alert: 'Eliminado {x]',
      },
      provider_selection_header: 'Médicos y proveedores',
      provider_selection_add_button: 'Agregar proveedores',
      provider_selection_update_button: 'Actualizar proveedores',
      provider_selection_cta_no_providers: 'No doctors or providers added yet.',
      none_added: 'Ninguno agregado',
    },
    recommendation: {
      contentful_name: '[DEFAULT] Health Recommendation',
      yearly_expense_total: 'YEARLY EXPENSE TOTAL',
      yearly_premium: 'YEARLY PREMIUM',
      premium: 'PREMIUM',
      deductible: 'DEDUCTIBLE',
      out_of_pocket_estimate: 'OUT-OF-POCKET EST.',
      out_of_pocket_max: 'OUT-OF-POCKET MAX.',
      view_details: 'View Details',
      choose_plan_button: 'Choose Plan',
      compare: 'Compare',
      unlimited: 'Unlimited',
      unselect: 'Unselect',
      hsa_info_with_employer_match: {
        contentful_name: '[DEFAULT] HSA Info With Employer Match',
        header: 'HSA Eligible',
        text: {
          nodeType: 'document',
          data: {},
          content: [
            {
              nodeType: 'paragraph',
              data: {},
              content: [
                {
                  nodeType: 'text',
                  value: 'and your employer will match ',
                  marks: [],
                  data: {},
                },
                {
                  nodeType: 'text',
                  value: '{x}',
                  marks: [
                    {
                      type: 'code',
                    },
                    {
                      type: 'bold',
                    },
                    {
                      type: 'underline',
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: 'text',
                  value: ' of your contributions up to ',
                  marks: [],
                  data: {},
                },
                {
                  nodeType: 'text',
                  value: '{x}',
                  marks: [
                    {
                      type: 'code',
                    },
                    {
                      type: 'bold',
                    },
                    {
                      type: 'underline',
                    },
                  ],
                  data: {},
                },
              ],
            },
          ],
        },
        color: 'blue',
        icon: 'CurrencyDollar',
      },
      hsa_info_with_contribution: {
        contentful_name: '[DEFAULT] HSA Info With Contribution',
        header: 'HSA Eligible',
        text: {
          nodeType: 'document',
          data: {},
          content: [
            {
              nodeType: 'paragraph',
              data: {},
              content: [
                {
                  nodeType: 'text',
                  value: 'and your employer will contribute up to ',
                  marks: [],
                  data: {},
                },
                {
                  nodeType: 'text',
                  value: '{x}',
                  marks: [
                    {
                      type: 'code',
                    },
                    {
                      type: 'bold',
                    },
                    {
                      type: 'underline',
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: 'text',
                  value: ' annually.',
                  marks: [],
                  data: {},
                },
              ],
            },
          ],
        },
        color: 'blue',
        icon: 'CurrencyDollar',
      },
      hsa_info: {
        contentful_name: '[DEFAULT] HSA Info',
        header: 'HSA Eligible',
        text: {
          nodeType: 'document',
          data: {},
          content: [
            {
              nodeType: 'paragraph',
              data: {},
              content: [
                {
                  nodeType: 'text',
                  value: 'Pay for medical bills with tax-free HSA contributions',
                  marks: [],
                  data: {},
                },
              ],
            },
          ],
        },
        color: 'blue',
        icon: 'CurrencyDollar',
      },
      hra_info_with_contribution: {
        contentful_name: '[DEFAULT] HRA Info With Contribution',
        header: 'HRA Eligible',
        text: {
          nodeType: 'document',
          data: {},
          content: [
            {
              nodeType: 'paragraph',
              data: {},
              content: [
                {
                  nodeType: 'text',
                  value: 'and your employer will contribute up to ',
                  marks: [],
                  data: {},
                },
                {
                  nodeType: 'text',
                  value: '{x}',
                  marks: [
                    {
                      type: 'code',
                    },
                    {
                      type: 'bold',
                    },
                    {
                      type: 'underline',
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: 'text',
                  value: ' annually.',
                  marks: [],
                  data: {},
                },
              ],
            },
          ],
        },
        color: 'blue',
        icon: 'CurrencyDollar',
      },
      hra_info_no_contribution: 'You’ve chosen an HRA eligible plan',
      sihra_info_with_contribution: {
        contentful_name: '[DEFAULT] SIHRA Info With Contribution',
        header: 'SIHRA Eligible',
        text: {
          nodeType: 'document',
          data: {},
          content: [
            {
              nodeType: 'paragraph',
              data: {},
              content: [
                {
                  nodeType: 'text',
                  value: 'and your employer will contribute up to ',
                  marks: [],
                  data: {},
                },
                {
                  nodeType: 'text',
                  value: '{x}',
                  marks: [
                    {
                      type: 'code',
                    },
                    {
                      type: 'bold',
                    },
                    {
                      type: 'underline',
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: 'text',
                  value: ' annually.',
                  marks: [],
                  data: {},
                },
              ],
            },
          ],
        },
        color: 'blue',
        icon: 'CurrencyDollar',
      },
      fsa_info_with_contribution: {
        contentful_name: '[DEFAULT] FSA Info With Contribution',
        header: 'FSA Eligible',
        text: {
          data: {},
          content: [
            {
              data: {},
              content: [
                {
                  data: {},
                  marks: [],
                  value: 'and your employer will contribute ',
                  nodeType: 'text',
                },
                {
                  data: {},
                  marks: [{ type: 'code' }, { type: 'bold' }],
                  value: '{x}',
                  nodeType: 'text',
                },
              ],
              nodeType: 'paragraph',
            },
          ],
          nodeType: 'document',
        },
        color: 'green',
        icon: 'CurrencyDollar',
      },
      fsa_info: {
        contentful_name: '[DEFAULT] FSA Info',
        header: 'FSA Eligible',
        text: {
          data: {},
          content: [
            {
              data: {},
              content: [
                {
                  data: {},
                  marks: [],
                  value: 'Pay for medical bills with tax-free FSA contributions',
                  nodeType: 'text',
                },
              ],
              nodeType: 'paragraph',
            },
          ],
          nodeType: 'document',
        },
        color: 'green',
        icon: 'CurrencyDollar',
      },
      plan_fit_labels: {
        contentful_name: '[default] Plan Fit Tiers',
        best_fit_label: 'tu mejor partido!',
        great_fit_label: 'Buen partido',
        fair_fit_label: 'partido justo',
        poor_fit_label: 'mal partido',
        best_fit_tooltip: {
          data: {},
          content: [
            {
              data: {},
              content: [
                {
                  data: {},
                  marks: [],
                  value:
                    'Based on your predicted healthcare needs and preferences, this plan is likely the best value for you.',
                  nodeType: 'text',
                },
              ],
              nodeType: 'paragraph',
            },
            {
              data: {},
              content: [
                {
                  data: {},
                  marks: [],
                  value: '\nCheck out ',
                  nodeType: 'text',
                },
                {
                  data: {},
                  marks: [
                    {
                      type: 'code',
                    },
                  ],
                  value: '{plan fit link}',
                  nodeType: 'text',
                },
                {
                  data: {},
                  marks: [],
                  value: ' to learn more about how ALEX ranks plans.',
                  nodeType: 'text',
                },
              ],
              nodeType: 'paragraph',
            },
          ],
          nodeType: 'document',
        },
        great_fit_tooltip: {
          data: {},
          content: [
            {
              data: {},
              content: [
                {
                  data: {},
                  marks: [],
                  value:
                    'Based on your predicted healthcare needs and preferences, this plan looks like a good value for you.',
                  nodeType: 'text',
                },
              ],
              nodeType: 'paragraph',
            },
            {
              data: {},
              content: [
                {
                  data: {},
                  marks: [],
                  value: '\nCheck out ',
                  nodeType: 'text',
                },
                {
                  data: {},
                  marks: [
                    {
                      type: 'code',
                    },
                  ],
                  value: '{plan fit link}',
                  nodeType: 'text',
                },
                {
                  data: {},
                  marks: [],
                  value: ' to learn more about how ALEX ranks plans.',
                  nodeType: 'text',
                },
              ],
              nodeType: 'paragraph',
            },
          ],
          nodeType: 'document',
        },
        fair_fit_tooltip: {
          data: {},
          content: [
            {
              data: {},
              content: [
                {
                  data: {},
                  marks: [],
                  value:
                    'Based on your predicted healthcare needs and preferences, this plan looks like a decent fit for you. You may get a better value if you pick a plan with a “good fit” rating or higher.',
                  nodeType: 'text',
                },
              ],
              nodeType: 'paragraph',
            },
            {
              data: {},
              content: [
                {
                  data: {},
                  marks: [],
                  value: '\nCheck out ',
                  nodeType: 'text',
                },
                {
                  data: {},
                  marks: [
                    {
                      type: 'code',
                    },
                  ],
                  value: '{plan fit link}',
                  nodeType: 'text',
                },
                {
                  data: {},
                  marks: [],
                  value: ' to learn more about how ALEX ranks plans.',
                  nodeType: 'text',
                },
              ],
              nodeType: 'paragraph',
            },
          ],
          nodeType: 'document',
        },
        poor_fit_tooltip: {
          data: {},
          content: [
            {
              data: {},
              content: [
                {
                  data: {},
                  marks: [],
                  value:
                    'Based on your predicted healthcare needs and preferences, there’s a low chance you’ll end up saving money with this plan. You’ll likely get a better value if you pick a plan with a “good fit” rating or higher.',
                  nodeType: 'text',
                },
              ],
              nodeType: 'paragraph',
            },
            {
              data: {},
              content: [
                {
                  data: {},
                  marks: [],
                  value: '\nCheck out ',
                  nodeType: 'text',
                },
                {
                  data: {},
                  marks: [
                    {
                      type: 'code',
                    },
                  ],
                  value: '{plan fit link}',
                  nodeType: 'text',
                },
                {
                  data: {},
                  marks: [],
                  value: ' to learn more about how ALEX ranks plans.',
                  nodeType: 'text',
                },
              ],
              nodeType: 'paragraph',
            },
          ],
          nodeType: 'document',
        },
        plan_fit_link_text: 'your plan fit breakdown',
        why_button_text: 'Why?',
      },
      in_network_providers_heading: 'Dentro de la red',
      in_network_providers_add_button: 'Agregar proveedores',
      in_network_providers_none: 'Ninguno',
      in_network_providers_add_provider_text:
        'Agregue sus proveedores para ver si están cubiertos por este plan.',
      invalidate_plan_message: {
        noLongerEligible: 'TEl plan que seleccionaste la última vez ya no está disponible para ti.',
        everyoneNotCovered:
          'Ha actualizado a quién cubre, por lo que es posible que algunos detalles del plan hayan cambiado. Asegúrese de que todo se vea bien antes de continuar',
      },
      no_deductible_plan_callout: {
        header: 'Recuerda que puedes comparar precios con este plan, por lo que los costos son estimados.',
        text: 'Específicamente, tu estimado de gastos de tu bolsillo se basa en los copagos comunes para la atención que dijiste que podrías necesitar. Los costos reales dependerán de qué proveedores elijas.',
        buttonText: 'Más información',
      },
    },
    text_note: {
      short_text: {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value: 'text note short text',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
        ],
        nodeType: 'document',
      },
      long_text: {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value: 'long text text note',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
        ],
        nodeType: 'document',
      },
      button_text: 'Leer más',
    },
    text_notes: [
      {
        plan_year: 2024,
        short_text: {
          data: {},
          content: [
            {
              data: {},
              content: [
                {
                  data: {},
                  marks: [],
                  value: 'text note short text',
                  nodeType: 'text',
                },
              ],
              nodeType: 'paragraph',
            },
          ],
          nodeType: 'document',
        },
        long_text: {
          data: {},
          content: [
            {
              data: {},
              content: [
                {
                  data: {},
                  marks: [],
                  value: 'long text text note',
                  nodeType: 'text',
                },
              ],
              nodeType: 'paragraph',
            },
          ],
          nodeType: 'document',
        },
        button_text: 'Leer más',
      },
    ],
    provider_selection_modal: {
      title: 'Médicos y proveedores',
      zipcode_label: 'CÓDIGO POSTAL',
      zipcode_validation_error: 'Ingrese un código postal valido',
      save_button: 'Continuar',
      search_char_limit_cta: 'Ingrese al menos 3 caracteres',
      search_no_results_cta: 'Sin resultados',
      distance_label: 'DISTANCIA EN MILLAS',
      select_location_label: 'SELECCIONE UNA UBICACIÓN',
      add_button_cta: 'Agregar',
      cancel_button_cta: 'Cancelar',
      create_list_description:
        'Cree una lista de sus médicos y otros proveedores de atención médica para saber qué planes los incluyen dentro de la red.',
      locations: 'Locations',
      location: 'Location',
      footer_text: 'Verificar el estado del proveedor',
      update_button_cta: 'Actualizar',
      loading: 'Cargando...',
      search_placeholder: 'Buscar médicos o proveedores',
    },
  },
};

export default health;
