import React from 'react';

import { TextField } from 'Containers/App/types';
import RichText from 'Shared/RichText';
import Text from 'Shared/Text';

import { InsuranceType } from './types';

export interface ContentMapElement {
  field:
    | 'benefits_section.dental_benefit'
    | 'benefits_section.vision_benefit'
    | 'benefits_section.accident_benefit'
    | 'benefits_section.illness_benefit'
    | 'benefits_section.hospital_benefit'
    | 'benefits_section.life_benefit'
    | 'benefits_section.combined_life_and_add_benefit'
    | 'benefits_section.std_benefit'
    | 'benefits_section.ltd_benefit'
    | 'benefits_section.partner_benefit'
    | 'benefits_section.retirement_benefit'
    | 'benefits_section.four_o_one_k_benefit'
    | 'benefits_section.fsa_benefit'
    | 'benefits_section.medical_fsa_benefit'
    | 'benefits_section.dependent_fsa_benefit'
    | 'benefits_section.adoption_fsa_benefit'
    | 'benefits_section.death_dismemberment_benefit'
    | 'benefits_section.commuter_benefit'
    | 'benefits_section.telemedicine_benefit'
    | 'benefits_section.pet_benefit'
    | 'benefits_section_additional.cancer_benefit'
    | 'benefits_section.legal_services_benefit'
    | 'benefits_section_additional.id_theft_benefit'
    | 'benefits_section.employee_assistant_program_benefit'
    | 'benefits_section.wellness_benefit'
    | 'benefits_section.financial_wellness_benefit'
    | 'benefits_section.four_o_three_benefit'
    | 'benefits_section.hsa_benefit'
    | 'benefits_section.hra_benefit'
    | 'benefits_section_additional.other_benefit';
  description: JSX.Element;
  info: JSX.Element;
  alternate_description?: JSX.Element;
  alternate_title?: JSX.Element;
  video_link?: string;
  alternate_video_link?: string;
  notes: {
    title: JSX.Element;
    note: JSX.Element;
  };
}

export type ContentMapProps = Record<InsuranceType, ContentMapElement>;

export const CONTENT_MAP: ContentMapProps = {
  dental: {
    field: 'benefits_section.dental_benefit',
    description: <RichText noWrapper={false} field="benefits_section.dental_benefit.modal_description" />,
    info: <RichText noWrapper={false} field="benefits_section.dental_benefit.insurance_info" />,
    notes: {
      title: <Text field={'benefits_section.dental_benefit.notes.title' as TextField} />,
      note: <Text field={'benefits_section.dental_benefit.notes.note' as TextField} />,
    },
  },
  vision: {
    field: 'benefits_section.vision_benefit',
    description: <RichText noWrapper={false} field="benefits_section.vision_benefit.modal_description" />,
    info: <RichText noWrapper={false} field="benefits_section.vision_benefit.insurance_info" />,
    notes: {
      title: <Text field={'benefits_section.vision_benefit.notes.title' as TextField} />,
      note: <Text field={'benefits_section.vision_benefit.notes.note' as TextField} />,
    },
  },
  accident: {
    field: 'benefits_section.accident_benefit',
    description: <RichText noWrapper={false} field="benefits_section.accident_benefit.modal_description" />,
    info: <RichText noWrapper={false} field="benefits_section.accident_benefit.insurance_info" />,
    notes: {
      title: <Text field={'benefits_section.accident_benefit.notes.title' as TextField} />,
      note: <Text field={'benefits_section.accident_benefit.notes.note' as TextField} />,
    },
  },
  critical: {
    field: 'benefits_section.illness_benefit',
    description: <RichText noWrapper={false} field="benefits_section.illness_benefit.modal_description" />,
    info: <RichText noWrapper={false} field="benefits_section.illness_benefit.insurance_info" />,
    notes: {
      title: <Text field={'benefits_section.illness_benefit.notes.title' as TextField} />,
      note: <Text field={'benefits_section.illness_benefit.notes.note' as TextField} />,
    },
  },
  hospital: {
    field: 'benefits_section.hospital_benefit',
    description: <RichText noWrapper={false} field="benefits_section.hospital_benefit.modal_description" />,
    info: <RichText noWrapper={false} field="benefits_section.hospital_benefit.insurance_info" />,
    notes: {
      title: <Text field={'benefits_section.hospital_benefit.notes.title' as TextField} />,
      note: <Text field={'benefits_section.hospital_benefit.notes.note' as TextField} />,
    },
  },
  life: {
    field: 'benefits_section.life_benefit',
    description: <RichText noWrapper={false} field="benefits_section.life_benefit.modal_description" />,
    info: <RichText noWrapper={false} field="benefits_section.life_benefit.insurance_info" />,
    notes: {
      title: <Text field={'benefits_section.life_benefit.notes.title' as TextField} />,
      note: <Text field={'benefits_section.life_benefit.notes.note' as TextField} />,
    },
  },
  life_add: {
    field: 'benefits_section.combined_life_and_add_benefit',
    description: (
      <RichText noWrapper={false} field="benefits_section.combined_life_and_add_benefit.modal_description" />
    ),
    info: (
      <RichText noWrapper={false} field="benefits_section.combined_life_and_add_benefit.insurance_info" />
    ),
    notes: {
      title: <Text field={'benefits_section.combined_life_and_add_benefit.notes.title' as TextField} />,
      note: <Text field={'benefits_section.combined_life_and_add_benefit.notes.note' as TextField} />,
    },
  },
  std: {
    field: 'benefits_section.std_benefit',
    description: <RichText noWrapper={false} field="benefits_section.std_benefit.modal_description" />,
    info: <RichText noWrapper={false} field="benefits_section.std_benefit.insurance_info" />,
    notes: {
      title: <Text field={'benefits_section.std_benefit.notes.title' as TextField} />,
      note: <Text field={'benefits_section.std_benefit.notes.note' as TextField} />,
    },
  },
  ltd: {
    field: 'benefits_section.ltd_benefit',
    description: <RichText noWrapper={false} field="benefits_section.ltd_benefit.modal_description" />,
    info: <RichText noWrapper={false} field="benefits_section.ltd_benefit.insurance_info" />,
    notes: {
      title: <Text field={'benefits_section.ltd_benefit.notes.title' as TextField} />,
      note: <Text field={'benefits_section.ltd_benefit.notes.note' as TextField} />,
    },
  },
  medicare: {
    field: 'benefits_section.partner_benefit',
    description: <RichText noWrapper={false} field="benefits_section.partner_benefit.modal_description" />,
    info: <RichText noWrapper={false} field="benefits_section.partner_benefit.insurance_info" />,
    notes: {
      title: <Text field={'benefits_section.partner_benefit.notes.title' as TextField} />,
      note: <Text field={'benefits_section.partner_benefit.notes.note' as TextField} />,
    },
  },
  retirement: {
    field: 'benefits_section.retirement_benefit',
    description: <RichText noWrapper={false} field="benefits_section.retirement_benefit.modal_description" />,
    info: <RichText noWrapper={false} field="benefits_section.retirement_benefit.insurance_info" />,
    notes: {
      title: <Text field={'benefits_section.retirement_benefit.notes.title' as TextField} />,
      note: <Text field={'benefits_section.retirement_benefit.notes.note' as TextField} />,
    },
  },
  '401k': {
    field: 'benefits_section.four_o_one_k_benefit',
    description: (
      <RichText noWrapper={false} field="benefits_section.four_o_one_k_benefit.modal_description" />
    ),
    info: <RichText noWrapper={false} field="benefits_section.four_o_one_k_benefit.insurance_info" />,
    notes: {
      title: <Text field={'benefits_section.four_o_one_k_benefit.notes.title' as TextField} />,
      note: <Text field={'benefits_section.four_o_one_k_benefit.notes.note' as TextField} />,
    },
  },
  fsa: {
    field: 'benefits_section.fsa_benefit',
    description: <RichText noWrapper={false} field="benefits_section.fsa_benefit.modal_description" />,
    info: <RichText noWrapper={false} field="benefits_section.fsa_benefit.insurance_info" />,
    notes: {
      title: <Text field={'benefits_section.fsa_benefit.notes.title' as TextField} />,
      note: <Text field={'benefits_section.fsa_benefit.notes.note' as TextField} />,
    },
  },
  fsa_medical: {
    field: 'benefits_section.medical_fsa_benefit',
    description: (
      <RichText noWrapper={false} field="benefits_section.medical_fsa_benefit.modal_description" />
    ),
    alternate_description: (
      <Text field={'benefits_section.medical_fsa_benefit.alternate_description' as TextField} />
    ),
    info: <RichText noWrapper={false} field="benefits_section.medical_fsa_benefit.insurance_info" />,
    alternate_title: <Text field={'benefits_section.medical_fsa_benefit.alternate_title' as TextField} />,
    notes: {
      title: <Text field={'benefits_section.medical_fsa_benefit.notes.title' as TextField} />,
      note: <Text field={'benefits_section.medical_fsa_benefit.notes.note' as TextField} />,
    },
  },
  fsa_dependent: {
    field: 'benefits_section.dependent_fsa_benefit',
    description: (
      <RichText noWrapper={false} field="benefits_section.dependent_fsa_benefit.modal_description" />
    ),
    info: <RichText noWrapper={false} field="benefits_section.dependent_fsa_benefit.insurance_info" />,
    notes: {
      title: <Text field={'benefits_section.dependent_fsa_benefit.notes.title' as TextField} />,
      note: <Text field={'benefits_section.dependent_fsa_benefit.notes.note' as TextField} />,
    },
  },
  fsa_adoption: {
    field: 'benefits_section.adoption_fsa_benefit',
    description: (
      <RichText noWrapper={false} field="benefits_section.adoption_fsa_benefit.modal_description" />
    ),
    info: <RichText noWrapper={false} field="benefits_section.adoption_fsa_benefit.insurance_info" />,
    notes: {
      title: <Text field={'benefits_section.adoption_fsa_benefit.notes.title' as TextField} />,
      note: <Text field={'benefits_section.adoption_fsa_benefit.notes.note' as TextField} />,
    },
  },
  add: {
    field: 'benefits_section.death_dismemberment_benefit',
    description: (
      <RichText noWrapper={false} field="benefits_section.death_dismemberment_benefit.modal_description" />
    ),
    info: <RichText noWrapper={false} field="benefits_section.death_dismemberment_benefit.insurance_info" />,
    notes: {
      title: <Text field={'benefits_section.death_dismemberment_benefit.notes.title' as TextField} />,
      note: <Text field={'benefits_section.death_dismemberment_benefit.notes.note' as TextField} />,
    },
  },
  commuter: {
    field: 'benefits_section.commuter_benefit',
    description: <RichText noWrapper={false} field="benefits_section.commuter_benefit.modal_description" />,
    info: <RichText noWrapper={false} field="benefits_section.commuter_benefit.insurance_info" />,
    notes: {
      title: <Text field={'benefits_section.commuter_benefit.notes.title' as TextField} />,
      note: <Text field={'benefits_section.commuter_benefit.notes.note' as TextField} />,
    },
  },
  teledoc: {
    field: 'benefits_section.telemedicine_benefit',
    description: (
      <RichText noWrapper={false} field="benefits_section.telemedicine_benefit.modal_description" />
    ),
    info: <RichText noWrapper={false} field="benefits_section.telemedicine_benefit.insurance_info" />,
    notes: {
      title: <Text field={'benefits_section.telemedicine_benefit.notes.title' as TextField} />,
      note: <Text field={'benefits_section.telemedicine_benefit.notes.note' as TextField} />,
    },
  },
  pet: {
    field: 'benefits_section.pet_benefit',
    description: <RichText noWrapper={false} field="benefits_section.pet_benefit.modal_description" />,
    info: <RichText noWrapper={false} field="benefits_section.pet_benefit.insurance_info" />,
    notes: {
      title: <Text field={'benefits_section.pet_benefit.notes.title' as TextField} />,
      note: <Text field={'benefits_section.pet_benefit.notes.note' as TextField} />,
    },
  },

  cancer: {
    field: 'benefits_section_additional.cancer_benefit',
    description: (
      <RichText noWrapper={false} field="benefits_section_additional.cancer_benefit.modal_description" />
    ),
    info: <RichText noWrapper={false} field="benefits_section_additional.cancer_benefit.insurance_info" />,
    notes: {
      title: <Text field={'benefits_section_additional.cancer_benefit.notes.title' as TextField} />,
      note: <Text field={'benefits_section_additional.cancer_benefit.notes.note' as TextField} />,
    },
  },
  legal: {
    field: 'benefits_section.legal_services_benefit',
    description: (
      <RichText noWrapper={false} field="benefits_section.legal_services_benefit.modal_description" />
    ),
    info: <RichText noWrapper={false} field="benefits_section.legal_services_benefit.insurance_info" />,
    notes: {
      title: <Text field={'benefits_section.legal_services_benefit.notes.title' as TextField} />,
      note: <Text field={'benefits_section.legal_services_benefit.notes.note' as TextField} />,
    },
  },
  id_theft: {
    field: 'benefits_section_additional.id_theft_benefit',
    description: (
      <RichText noWrapper={false} field="benefits_section_additional.id_theft_benefit.modal_description" />
    ),
    info: <RichText noWrapper={false} field="benefits_section_additional.id_theft_benefit.insurance_info" />,
    notes: {
      title: <Text field={'benefits_section_additional.id_theft_benefit.notes.title' as TextField} />,
      note: <Text field={'benefits_section_additional.id_theft_benefit.notes.note' as TextField} />,
    },
  },
  employee_assistance_program: {
    field: 'benefits_section.employee_assistant_program_benefit',
    description: (
      <RichText
        noWrapper={false}
        field="benefits_section.employee_assistant_program_benefit.modal_description"
      />
    ),
    info: (
      <RichText
        noWrapper={false}
        field="benefits_section.employee_assistant_program_benefit.insurance_info"
      />
    ),
    notes: {
      title: <Text field={'benefits_section.employee_assistant_program_benefit.notes.title' as TextField} />,
      note: <Text field={'benefits_section.employee_assistant_program_benefit.notes.note' as TextField} />,
    },
  },
  wellness: {
    field: 'benefits_section.wellness_benefit',
    description: <RichText noWrapper={false} field="benefits_section.wellness_benefit.modal_description" />,
    info: <RichText noWrapper={false} field="benefits_section.wellness_benefit.insurance_info" />,
    notes: {
      title: <Text field={'benefits_section.wellness_benefit.notes.title' as TextField} />,
      note: <Text field={'benefits_section.wellness_benefit.notes.note' as TextField} />,
    },
  },
  financial_wellness: {
    field: 'benefits_section.financial_wellness_benefit',
    description: (
      <RichText noWrapper={false} field="benefits_section.financial_wellness_benefit.modal_description" />
    ),
    info: <RichText noWrapper={false} field="benefits_section.financial_wellness_benefit.insurance_info" />,
    notes: {
      title: <Text field={'benefits_section.financial_wellness_benefit.notes.title' as TextField} />,
      note: <Text field={'benefits_section.financial_wellness_benefit.notes.note' as TextField} />,
    },
  },
  '403b': {
    field: 'benefits_section.four_o_three_benefit',
    description: (
      <RichText noWrapper={false} field="benefits_section.four_o_three_benefit.modal_description" />
    ),
    info: <RichText noWrapper={false} field="benefits_section.four_o_three_benefit.insurance_info" />,
    notes: {
      title: <Text field={'benefits_section.four_o_three_benefit.notes.title' as TextField} />,
      note: <Text field={'benefits_section.four_o_three_benefit.notes.note' as TextField} />,
    },
  },
  hsa: {
    field: 'benefits_section.hsa_benefit',
    description: <RichText noWrapper={false} field="benefits_section.hsa_benefit.modal_description" />,
    info: <RichText noWrapper={false} field="benefits_section.hsa_benefit.insurance_info" />,
    notes: {
      title: <Text field={'benefits_section.hsa_benefit.notes.title' as TextField} />,
      note: <Text field={'benefits_section.hsa_benefit.notes.note' as TextField} />,
    },
  },
  hra: {
    field: 'benefits_section.hra_benefit',
    description: <RichText noWrapper={false} field="benefits_section.hra_benefit.modal_description" />,
    info: <RichText noWrapper={false} field="benefits_section.hra_benefit.insurance_info" />,
    notes: {
      title: <Text field={'benefits_section.hra_benefit.notes.title' as TextField} />,
      note: <Text field={'benefits_section.hra_benefit.notes.note' as TextField} />,
    },
  },
  Other: {
    field: 'benefits_section_additional.other_benefit',
    description: (
      <RichText noWrapper={false} field="benefits_section_additional.other_benefit.modal_description" />
    ),
    info: <RichText noWrapper={false} field="benefits_section_additional.other_benefit.insurance_info" />,
    notes: {
      title: <Text field={'benefits_section_additional.other_benefit.notes.title' as TextField} />,
      note: <Text field={'benefits_section_additional.other_benefit.notes.note' as TextField} />,
    },
  },
};
