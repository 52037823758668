import React from 'react';

import { Button } from 'DesignLibrary/atoms';
import Text from 'Shared/Text';

import { PageHeaderContainer } from '../styled';

type Props = {
  hasHealthPlanSection: boolean;
  animate?: boolean;
  numTokens: number;
};

/**
 *
 * @param props: Props
 * @returns JSX element to show cards and other information
 */
const ResultsReviewHeader = ({ hasHealthPlanSection, numTokens, animate }: Props) => {
  return hasHealthPlanSection ? (
    <PageHeaderContainer numTokens={numTokens || 0} animate={animate || false}>
      <Button
        className=""
        size="small"
        onClick={() => null}
        data-testid="results-card-btn"
        buttonType="primary"
        color="blue"
        iconProps={{ color: '--colors-gray-0' }}
        iconLeft="CardsThree"
      >
        <Text field="health_section.button_text.card" />
      </Button>
    </PageHeaderContainer>
  ) : null;
};

export default ResultsReviewHeader;
