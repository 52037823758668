import { KenticoConfig, StorybookRuntime, StorybookRuntimeState } from '@jellyvision/storybook-runtime';
import React from 'react';
import { useSearchParams } from 'react-router-dom';

import { StorybookRuntimeContainer } from 'Containers/MomentPage/styled';
import { PageLayout } from 'DesignLibrary/atoms/PageLayout';
import Logger from 'Utils/logger';

export interface MomentProps {
  topic: string;
  codename: string | null;
  client: string;
  // updateJVPUserUiSettings: (uiSettings: Partial<DirectlyUpdateableJVPUserAttributes>) => void;
}

export const Moment = ({ codename, topic, client }: MomentProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  // const { audioState } = useAudioContext();

  // TODO: Handle moment enabled/disabled
  //   New selector to determine publication based on plan year
  //   and return the associated go_settings toggle
  // const planYear = searchParams.get('planYear');
  // const enabled = makeGetMomentEnabled(planYear, topic);

  const momentTopic = searchParams.get('topic') || topic;
  const momentCodename = searchParams.get('moment') || codename;
  const runtimeContent = {
    codename: momentCodename || '',
    module: momentTopic,
    customerKey: client,
  };

  const kenticoEnvironmentId = localStorage.getItem('kenticoEnvironmentId');
  const usePreviewMode = localStorage.getItem('usePreviewMode') === 'true';

  const runtimeState: StorybookRuntimeState = {
    cc: true,
    muted: false,
  };

  const kenticoConfig: KenticoConfig = {
    usePreviewMode,
    environment: {
      environmentId: kenticoEnvironmentId || '',
    },
  };

  // const handleToggleCC = () => {
  //   updateJVPUserUiSettings({
  //     closed_captioned: !audioState.areClosedCaptionsEnabled,
  //   });
  // };

  // const handleToggleMute = () => {
  //   const newMuteState = !audioState.isMuted;
  //   const newClosedCaptionState = newMuteState ? true : audioState.areClosedCaptionsEnabled;
  //   updateJVPUserUiSettings({
  //     audio_muted: newMuteState,
  //     closed_captioned: newClosedCaptionState,
  //   });
  // };

  const goToNextMoment = (codename: string) => {
    searchParams.set('moment', codename);
    setSearchParams(searchParams);
  };

  return (
    <PageLayout>
      <StorybookRuntimeContainer>
        <StorybookRuntime
          // changing the key between moments forces the runtime component to hard reload rather than
          // minimally re-render with new state. without this, when navigating back and forth with
          // browser nav, the display will work but the audio won't start over.
          key={codename || 'default'}
          content={runtimeContent}
          kenticoConfig={kenticoConfig}
          state={runtimeState}
          externalAdapters={{
            onCodenameChange: (nextCodename: string) => goToNextMoment(nextCodename),
            onActionTrigger: () => {},
            onUserStateChange: () => {},
            onUiStateChange: () => ({
              // TODO: Get these working
              // toggleCC: handleToggleCC,
              // toggleMute: handleToggleMute,
            }),
            onErrorNotify: (err) => {
              Logger.error(err);
            },
          }}
        />
      </StorybookRuntimeContainer>
    </PageLayout>
  );
};
