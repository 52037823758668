import React from 'react';
import { useNavigate } from 'react-router-dom';

import { useTextContext } from 'Contexts/textContext';
import { Button, H3, Paragraph } from 'DesignLibrary/atoms';
import { PageLayout } from 'DesignLibrary/atoms/PageLayout';
import Text from 'Shared/Text';
import { OVERVIEW_PATH, PROFILE_PATH } from 'Utils/urls';

import { EligNone } from '../styled';

const NoEligiblePlans = () => {
  const { retrieveContentfulData } = useTextContext();
  const navigate = useNavigate();

  // client has external enrollment option
  const externalPlanLink = retrieveContentfulData<string>('health_section.no_plans_modal.externalPlanLink');

  return (
    <PageLayout>
      <EligNone>
        <H3>
          <Text field="health_section.no_plans_modal.title" />
        </H3>
        <Paragraph>
          <Text field="health_section.no_plans_modal.description" />
        </Paragraph>
        {externalPlanLink ? (
          <>
            <Button iconRight="ArrowUpRight" url={externalPlanLink}>
              <Text field="health_section.no_plans_modal.externalButtonText" />
            </Button>
            <Button buttonType="primary" onClick={() => navigate(OVERVIEW_PATH)}>
              <Text field="health_section.button_text.noPlans" />
            </Button>
          </>
        ) : (
          <Button id="modify-employee-status-btn" buttonType="primary" onClick={() => navigate(PROFILE_PATH)}>
            <Text field="health_section.button_text.noPlans" />
          </Button>
        )}
      </EligNone>
    </PageLayout>
  );
};

export default NoEligiblePlans;
