import React, { ReactNode } from 'react';

import { H5 } from 'DesignLibrary/atoms';
import { IconBlock, IconBlockProps } from 'DesignLibrary/molecules/IconBlock';

import { PlanCardWrapper, PlanCardHeader } from '../_shared/styled';

interface ViewPlanPageCard {
  title: string;
  icon: IconBlockProps;
  border?: boolean;
  children?: ReactNode;
}

export const ViewPlanPageCard: React.FC<ViewPlanPageCard> = ({ title, icon, border = true, children }) => (
  <PlanCardWrapper>
    <PlanCardHeader border={border}>
      <IconBlock {...icon} />
      <H5 as="h2">{title}</H5>
    </PlanCardHeader>
    <div>{children}</div>
  </PlanCardWrapper>
);
