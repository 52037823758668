import { TextField } from 'Containers/App/types';
import { InsuranceType } from 'Containers/ViewBenefitPage/types';

export const SELECT_PLAN = 'app/CommercialV2/OverviewPage/SELECT_PLAN';
export const REMOVE_PLAN = 'app/CommercialV2/OverviewPage/REMOVE_PLAN';
export const SET_DEFAULT_COVERED_MEMBERS = 'app/CommercialV2/OverviewPage/SET_DEFAULT_COVERED_MEMBERS';
export const SET_DEFAULT_SELECTED_PLANS = 'app/CommercialV2/OverviewPage/SET_DEFAULT_SELECTED_PLANS';
export const CLEAR_SUPPLEMENTAL_PLANS_CACHE = 'app/CommercialV2/OverviewPage/CLEAR_SUPPLEMENTAL_PLANS_CACHE';

export const GET_SUPPLEMENTAL_PLANS_REQUEST = 'app/CommercialV2/OverviewPage/GET_SUPPLEMENTAL_PLANS_REQUEST';
export const GET_SUPPLEMENTAL_PLANS_FOR_VIEW_BENEFIT_REQUEST =
  'app/CommercialV2/OverviewPage/GET_SUPPLEMENTAL_PLANS_FOR_VIEW_BENEFIT_REQUEST';
export const GET_SUPPLEMENTAL_PLANS_SUCCESS = 'app/CommercialV2/OverviewPage/GET_SUPPLEMENTAL_PLANS_SUCCESS';
export const GET_SUPPLEMENTAL_PLANS_FAILURE = 'app/CommercialV2/OverviewPage/GET_SUPPLEMENTAL_PLANS_FAILURE';
export const GET_SUPPLEMENTAL_PLANS_SUCCESS_SET_CACHE =
  'app/CommercialV2/OverviewPage/GET_SUPPLEMENTAL_PLANS_SUCCESS_SET_CACHE';

export const RESET_AUTO_ENROLLED = 'app/CommercialV2/OverviewPage/RESET_AUTO_ENROLLED';

export const TRACK_SUPP_ENROLLMENT_SUCCESS = 'app/CommercialV2/OverviewPage/TRACK_SUPP_ENROLLMENT_SUCCESS';
export const TRACK_SUPP_ENROLLMENT_FAILURE = 'app/CommercialV2/OverviewPage/TRACK_SUPP_ENROLLMENT_FAILURE';

/* FBS Plan Types */
export const SET_PREMIUM_PLAN_TYPES: InsuranceType[] = [
  'dental',
  'vision',
  'accident',
  'critical',
  'legal',
  'id_theft',
];

export const NO_SET_PREMIUMS_PLAN_TYPES: InsuranceType[] = [
  'life',
  'life_add',
  'add',
  'std',
  'ltd',
  '401k',
  '403b',
  'retirement',
  'cancer',
  'hospital',
  'pet',
];

export const ALL_INSURANCE_PLAN_TYPES: InsuranceType[] = [
  'dental',
  'vision',
  'accident',
  'critical',
  'hospital',
  'pet',
  'cancer',
  'legal',
  'id_theft',
  'life',
  'life_add',
  'add',
  'std',
  'ltd',
];

export const ALL_SELECTABLE_PLAN_TYPES: InsuranceType[] = [
  ...SET_PREMIUM_PLAN_TYPES,
  ...NO_SET_PREMIUMS_PLAN_TYPES,
];

export const ALL_NONSELECTABLE_PLAN_TYPES: InsuranceType[] = [
  'medicare',
  // 'hsa',
  // 'hra',
  'fsa',
  'fsa_medical',
  'fsa_dependent',
  'fsa_adoption',
  'employee_assistance_program',
  'wellness',
  'financial_wellness',
  'commuter',
  'teledoc',
  'Other',
];

export const UVA_SELECTABLE_PLAN_TYPES: InsuranceType[] = [
  'dental',
  'vision',
  'accident',
  'critical',
  'hospital',
  'life',
  'add',
  'std',
  'pet',
  'cancer',
];

export const UVA_NONSELECTABLE_PLAN_TYPES: InsuranceType[] = [
  'ltd',
  'retirement',
  '401k',
  // 'hsa',
  // 'hra',
  'fsa',
  'fsa_medical',
  'fsa_dependent',
  'fsa_adoption',
  'commuter',
  'teledoc',
];

// CUSTOM_NAME_PLAN_TYPES show names from builder, such as on SelectedPlanCard.
// All others get generic names (e.g. "Life" instead of "Platinum Lifelock Plan")
export const CUSTOM_NAME_PLAN_TYPES: InsuranceType[] = ['dental', 'vision'];

// TODO: Move this hard-coded configuration to Contentful?
export function getSelectableBenefits(client?: string): InsuranceType[] {
  if (client === 'uva') {
    return UVA_SELECTABLE_PLAN_TYPES;
  }

  return ALL_SELECTABLE_PLAN_TYPES;
}

export function getNonSelectableBenefits(client?: string): InsuranceType[] {
  if (client === 'uva') {
    return UVA_NONSELECTABLE_PLAN_TYPES;
  }

  return ALL_NONSELECTABLE_PLAN_TYPES;
}

type ContentMap = Record<
  InsuranceType,
  {
    title: TextField;
    titleAlt?: TextField;
    description: TextField;
    descriptionAlt?: TextField;
  }
>;

/* Educational Content */
export const CONTENT_MAP: ContentMap = {
  dental: {
    title: 'benefits_section.dental_benefit.card_title',
    description: 'benefits_section.dental_benefit.card_description',
  },
  vision: {
    title: 'benefits_section.vision_benefit.card_title',
    description: 'benefits_section.vision_benefit.card_description',
  },
  accident: {
    title: 'benefits_section.accident_benefit.card_title',
    description: 'benefits_section.accident_benefit.card_description',
  },
  hospital: {
    title: 'benefits_section.hospital_benefit.card_title',
    description: 'benefits_section.hospital_benefit.card_description',
  },
  critical: {
    title: 'benefits_section.illness_benefit.card_title',
    description: 'benefits_section.illness_benefit.card_description',
  },
  life: {
    title: 'benefits_section.life_benefit.card_title',
    description: 'benefits_section.life_benefit.card_description',
  },
  life_add: {
    title: 'benefits_section.combined_life_and_add_benefit.card_title',
    description: 'benefits_section.combined_life_and_add_benefit.card_description',
  },
  std: {
    title: 'benefits_section.std_benefit.card_title',
    description: 'benefits_section.std_benefit.card_description',
  },
  ltd: {
    title: 'benefits_section.ltd_benefit.card_title',
    description: 'benefits_section.ltd_benefit.card_description',
  },
  medicare: {
    title: 'benefits_section.partner_benefit.card_title',
    description: 'benefits_section.partner_benefit.card_description',
  },
  retirement: {
    title: 'benefits_section.retirement_benefit.card_title',
    description: 'benefits_section.retirement_benefit.card_description',
  },
  '401k': {
    title: 'benefits_section.four_o_one_k_benefit.card_title',
    description: 'benefits_section.four_o_one_k_benefit.card_description',
  },
  hsa: {
    title: 'benefits_section.hsa_benefit.card_title',
    description: 'benefits_section.hsa_benefit.card_description',
  },
  hra: {
    title: 'benefits_section.hra_benefit.card_title',
    description: 'benefits_section.hra_benefit.card_description',
  },
  fsa: {
    title: 'benefits_section.fsa_benefit.card_title',
    titleAlt: 'benefits_section.fsa_benefit.alternate_title',
    description: 'benefits_section.fsa_benefit.card_description',
    descriptionAlt: 'benefits_section.fsa_benefit.alternate_description',
  },
  fsa_medical: {
    title: 'benefits_section.medical_fsa_benefit.card_title',
    titleAlt: 'benefits_section.medical_fsa_benefit.alternate_title',
    description: 'benefits_section.medical_fsa_benefit.card_description',
    descriptionAlt: 'benefits_section.medical_fsa_benefit.alternate_description',
  },
  fsa_dependent: {
    title: 'benefits_section.dependent_fsa_benefit.card_title',
    description: 'benefits_section.dependent_fsa_benefit.card_description',
  },
  fsa_adoption: {
    title: 'benefits_section.adoption_fsa_benefit.card_title',
    description: 'benefits_section.adoption_fsa_benefit.card_description',
  },
  legal: {
    title: 'benefits_section.legal_services_benefit.card_title',
    description: 'benefits_section.legal_services_benefit.card_description',
  },
  id_theft: {
    title: 'benefits_section_additional.id_theft_benefit.card_title',
    description: 'benefits_section_additional.id_theft_benefit.card_description',
  },
  employee_assistance_program: {
    title: 'benefits_section.employee_assistant_program_benefit.card_title',
    description: 'benefits_section.employee_assistant_program_benefit.card_description',
  },
  wellness: {
    title: 'benefits_section.wellness_benefit.card_title',
    description: 'benefits_section.wellness_benefit.card_description',
  },
  financial_wellness: {
    title: 'benefits_section.financial_wellness_benefit.card_title',
    description: 'benefits_section.financial_wellness_benefit.card_description',
  },
  '403b': {
    title: 'benefits_section.four_o_three_benefit.card_title',
    description: 'benefits_section.four_o_three_benefit.card_description',
  },
  add: {
    title: 'benefits_section.death_dismemberment_benefit.card_title',
    description: 'benefits_section.death_dismemberment_benefit.card_description',
  },
  commuter: {
    title: 'benefits_section.commuter_benefit.card_title',
    description: 'benefits_section.commuter_benefit.card_description',
  },
  teledoc: {
    title: 'benefits_section.telemedicine_benefit.card_title',
    description: 'benefits_section.telemedicine_benefit.card_description',
  },
  pet: {
    title: 'benefits_section.pet_benefit.card_title',
    description: 'benefits_section.pet_benefit.card_description',
  },
  cancer: {
    title: 'benefits_section_additional.cancer_benefit.card_title',
    description: 'benefits_section_additional.cancer_benefit.card_description',
  },
  Other: {
    title: 'benefits_section_additional.other_benefit.card_title',
    description: 'benefits_section_additional.other_benefit.card_description',
  },
};

export enum SUPPLEMENTAL_ELECTIONS {
  dental = 'DentalPlan',
  vision = 'VisionPlan',
}
