import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Page } from 'Containers/App/Navigation/pages';
import { Button, IconTypes } from 'DesignLibrary/atoms';

export interface MobileLinkProps {
  path: string;
  page: Page;
  unlockedPage?: Page;
  handleClose: () => void;
}

const MobileLink = ({ path, page, unlockedPage, handleClose }: MobileLinkProps) => {
  const isActive = page?.paths.includes(path); // current page
  const isLocked = unlockedPage && page?.idx > unlockedPage?.idx;
  const navigate = useNavigate();

  const handleClick = (url) => {
    navigate(url);
    handleClose();
  };

  let icon: IconTypes | null = null;
  if (!isActive && !isLocked) {
    icon = 'ArrowRight';
  }
  if (isLocked) {
    icon = 'LockSimple';
  }

  return (
    <Button
      buttonType={isActive ? 'primary' : 'transparent'}
      key={page?.idx}
      onClick={isActive ? () => null : () => handleClick(page.url)}
      isDisabled={isLocked}
      iconRight={icon}
    >
      {(page?.step as string) || undefined}
    </Button>
  );
};

export default MobileLink;
