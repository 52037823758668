import styled, { css } from 'styled-components';

import { breakpoint } from 'DesignLibrary/global';
import { cardStyling, selectedBlueStyling } from 'DesignLibrary/utils';
import { SAVINGS_ACCOUNT_TYPES } from 'Types/entities';

export const EligibleCardsContainer = styled.section`
  display: grid;
  gap: var(--sizing-md);
  padding: var(--sizing-lg) 0;

  ${breakpoint.TABLET} {
    grid-template-columns: 1fr 1fr;
    padding: var(--sizing-xl) 0;
  }
`;

interface CardContainerProps {
  account: SAVINGS_ACCOUNT_TYPES | 'lpfsa';
}

export const CardContainer = styled.div<CardContainerProps>`
  ${cardStyling}
  position: relative;
  padding: var(--sizing-sm);
  display: flex;
  justify-content: space-between;
  gap: var(--sizing-md);

  ${({ account }) =>
    account &&
    css`
      ${account === SAVINGS_ACCOUNT_TYPES.HSA && selectedBlueStyling}
      ${(account === SAVINGS_ACCOUNT_TYPES.FSA || account === 'lpfsa') && selectedBlueStyling}
      ${account === SAVINGS_ACCOUNT_TYPES.HRA && selectedBlueStyling}
    `}

  ${breakpoint.TABLET} {
    align-items: center;
  }

  h5 {
    font-weight: 800 !important;
    margin-bottom: var(--sizing-2xs);
  }

  .icon {
    position: absolute;
    top: -24px;
    left: -24px;
  }
`;
