import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { connect, Selector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import { GlobalReducerState } from 'app/reducers';
import {
  makeSelectCommercialField,
  makeSelectConfigField,
  makeSelectGlobalField,
  selectIsFirstPageView,
} from 'Containers/App/selectors';
import {
  makeGetCatchupEligible,
  makeGetRecommendedHsaContribution,
  makeSelectUserContributionLimit,
} from 'Containers/CommercialRoutes/selectors';
import { HsaBullet } from 'Containers/HsaPage/HsaBullet';
import { Interactive } from 'Containers/HsaPage/Interactive';
import { NoPlanSelected } from 'Containers/HsaPage/NoPlanSelected';
import { NotEligible } from 'Containers/HsaPage/NotEligible';
import { makeGetBuilderGoSettings } from 'Containers/ProfilePage/selectors';
import { useFeatureFlagContext } from 'Contexts/featureFlagContext';
import { Spinner } from 'DesignLibrary/atoms';
import { PageLayout } from 'DesignLibrary/atoms/PageLayout';
import Plan from 'Models/plan';
import { Recommendation } from 'Types/entities';
import { BuilderGoSettings, GetHsaRecommendationResponse } from 'Utils/apiTypes';
import injectSaga from 'Utils/injectSaga';
import { RESULT_PATH, HSA_PLUS_PATH, OVERVIEW_PATH, REVIEW_PATH } from 'Utils/urls';

import {
  forecastRetirement,
  getHsaRecommendation,
  setHsaBalanceAndPersona,
  setLockedHsaContribution,
} from './actions';
import { Education } from './Education';
import saga from './saga';
import {
  makeGetAvgForecastedHsaBalance,
  makeGetAvgRetirementCost,
  makeGetIsPolicyholderOverRetirementAge,
  makeGetPolicyholderLifeExpectancy,
  makeSelectHsaData,
  makeSelectHsaField,
} from './selectors';
import { HsaContainer, InteractiveLoading } from './styled';
import { Persona } from './types';
import { YourContributions } from './YourContributions';
import { ContentfulInfoBars } from '../../contentfulWrappers/ContentfulInfoBars';
import { ContentfulHeaderWrapper } from '../../contentfulWrappers/header';

interface HsaPageStateProps {
  hsaData: GetHsaRecommendationResponse | Record<string, never>;
  recommendedHsaContribution: number;
  isHsaRecommendationLoading: boolean;
  isForecastLoading: boolean;
  isRetirementForecastLoading: boolean;
  selectedPlan: Recommendation;
  isFullBenefitEnabled: boolean;
  isHsaForecastEnabled: boolean;
  lockedHsaContribution: number | null;
  persona: Persona;
  retirementCost: number;
  forecastedHsaBalance: number;
  currentHsaBalance: number | null;
  userContributionLimit: number;
  individualHsaContributionLimit: number;
  familyHsaContributionLimit: number;
  isFirstPageView: boolean;
  catchupEligible: boolean;
  isPolicyholderOverRetirementAge: boolean;
  policyholderLifeExpectancy: number;
  builderGoSettings: BuilderGoSettings | null;
}

interface HsaPageDispatchProps {
  fetchHsaRecommendation: () => void;
  fetchRetirementForecasts: () => void;
  handleLockedHsaContribution: (contribution: number) => void;
  handleHsaBalanceAndPersona: (balance: number, persona: Persona) => void;
}

export type HsaPageProps = HsaPageStateProps & HsaPageDispatchProps;

export const HsaPage = ({
  hsaData,
  recommendedHsaContribution,
  isHsaRecommendationLoading,
  isForecastLoading,
  isRetirementForecastLoading,
  selectedPlan,
  isFullBenefitEnabled,
  isHsaForecastEnabled,
  lockedHsaContribution,
  persona,
  retirementCost,
  forecastedHsaBalance,
  currentHsaBalance,
  userContributionLimit,
  individualHsaContributionLimit,
  familyHsaContributionLimit,
  isFirstPageView,
  catchupEligible,
  isPolicyholderOverRetirementAge,
  policyholderLifeExpectancy,
  fetchHsaRecommendation,
  fetchRetirementForecasts,
  handleLockedHsaContribution,
  handleHsaBalanceAndPersona,
  builderGoSettings,
}: HsaPageProps) => {
  const [contribution, setContribution] = useState(0);
  const isPlanSelected = !isEmpty(selectedPlan);
  const { is_educational_videos_enabled } = useFeatureFlagContext();

  useEffect(() => {
    if (isPlanSelected) {
      fetchHsaRecommendation();

      // We can get this ahead of time as we have all the information we need
      if (isHsaForecastEnabled) {
        fetchRetirementForecasts();
      }
    }
  }, []);

  useEffect(() => {
    if (lockedHsaContribution === null) {
      handleLockedHsaContribution(recommendedHsaContribution);
    }
  }, []);

  useEffect(() => {
    // The initial value of HSA data is set to an empty object
    // We don't want to set contribution until we have an actual recommended_contribution value from hsaData
    if (!isEmpty(hsaData)) {
      const newContribution =
        lockedHsaContribution !== null ? lockedHsaContribution : recommendedHsaContribution;
      setContribution(newContribution as number);
    }
  }, [hsaData]);

  const navigate = useNavigate();

  const goToHealth = () => navigate(RESULT_PATH);
  const goToSupplemental = () => navigate(OVERVIEW_PATH);
  const goToReview = () => navigate(REVIEW_PATH);

  const next = isFullBenefitEnabled ? goToSupplemental : goToReview;

  if (!isPlanSelected) {
    return <NoPlanSelected handleGoToHealth={goToHealth} handleNext={next} />;
  }

  // Determine if plan has employer contribution/match
  const plan = new Plan(selectedPlan);
  const { isEmployerMatching, hsaEligible, employerHsaPercentageMatch } = plan;
  const employerHsaContribution = isEmployerMatching
    ? (plan.employerHsaContributionLimit as number)
    : (plan.employerHsaFixedContribution as number);

  if (!hsaEligible) {
    return <NotEligible handleGoToHealth={goToHealth} handleNext={next} />;
  }

  return (
    <>
      <ContentfulHeaderWrapper sectionKey="hsa" animate={isFirstPageView} />
      <ContentfulInfoBars sectionKey="hsa" />

      <PageLayout>
        <HsaContainer>
          <section className="grid">
            <Education
              selectedPlan={selectedPlan}
              employerContribution={employerHsaContribution}
              // userContributionLimit={userContributionLimit}
              individualHsaContributionLimit={individualHsaContributionLimit}
              familyHsaContributionLimit={familyHsaContributionLimit}
              shouldShowEducationalVideo={
                !is_educational_videos_enabled || !!builderGoSettings?.show_hsa_video
              }
            />
            <YourContributions
              employerHsaContribution={employerHsaContribution}
              recommendedHsaContribution={recommendedHsaContribution}
              isEmployerMatching={isEmployerMatching}
              employerHsaPercentageMatch={employerHsaPercentageMatch}
              catchupEligible={catchupEligible}
              deductionsPerYear={selectedPlan.costs.annual_premium_deductions}
              vendorName={selectedPlan.plan_details?.health_savings_account?.vendor_name}
              vendorUrl={selectedPlan.plan_details?.health_savings_account?.vendor_url}
              userContributionLimit={userContributionLimit}
              selectedPlan={selectedPlan}
            />
          </section>
          {isHsaRecommendationLoading ? (
            <InteractiveLoading>
              <Spinner color="--border-gray" />
            </InteractiveLoading>
          ) : (
            <Interactive
              userContributionLimit={userContributionLimit}
              hsaData={hsaData}
              selectedPlan={selectedPlan}
              employerHsaContribution={employerHsaContribution}
              isForecastLoading={isForecastLoading}
              isRetirementForecastLoading={isRetirementForecastLoading}
              retirementCost={retirementCost}
              forecastedHsaBalance={forecastedHsaBalance}
              contribution={contribution}
              currentHsaBalance={currentHsaBalance}
              persona={persona}
              isPolicyholderOverRetirementAge={isPolicyholderOverRetirementAge}
              policyholderLifeExpectancy={policyholderLifeExpectancy}
              handleLockedHsaContribution={handleLockedHsaContribution}
              setContribution={setContribution}
              handleHsaBalanceAndPersona={handleHsaBalanceAndPersona}
            />
          )}

          <section>
            <HsaBullet icon="ArrowRight" field="hsa.contribution_explainer.continue" />
          </section>
        </HsaContainer>
      </PageLayout>
    </>
  );
};

const mapStateToProps = createStructuredSelector<GlobalReducerState, HsaPageStateProps>({
  userContributionLimit: makeSelectUserContributionLimit(),
  individualHsaContributionLimit: makeSelectGlobalField('hsaContributionLimitIndividual'),
  familyHsaContributionLimit: makeSelectGlobalField('hsaContributionLimitFamily'),
  currentHsaBalance: makeSelectHsaField('currentHsaBalance'),
  forecastedHsaBalance: makeGetAvgForecastedHsaBalance(),
  hsaData: makeSelectHsaData(),
  recommendedHsaContribution: makeGetRecommendedHsaContribution(),
  selectedPlan: makeSelectCommercialField('selectedHealthPlan') as Selector<
    GlobalReducerState,
    Recommendation
  >,
  isHsaRecommendationLoading: makeSelectHsaField('isHsaRecommendationLoading'),
  isForecastLoading: makeSelectHsaField('isForecastLoading'),
  isRetirementForecastLoading: makeSelectHsaField('isRetirementForecastLoading'),
  isFullBenefitEnabled: makeSelectConfigField('is_fbs_enabled'),
  isHsaForecastEnabled: makeSelectConfigField('hsa_forecast_enabled'),
  lockedHsaContribution: makeSelectHsaField('lockedHsaContribution'),
  persona: makeSelectHsaField('persona'),
  retirementCost: makeGetAvgRetirementCost(),
  isFirstPageView: selectIsFirstPageView(HSA_PLUS_PATH),
  catchupEligible: makeGetCatchupEligible(),
  isPolicyholderOverRetirementAge: makeGetIsPolicyholderOverRetirementAge(),
  policyholderLifeExpectancy: makeGetPolicyholderLifeExpectancy(),
  builderGoSettings: makeGetBuilderGoSettings(),
});

export function mapDispatchToProps(dispatch): HsaPageDispatchProps {
  return {
    fetchHsaRecommendation: () => dispatch(getHsaRecommendation()),
    fetchRetirementForecasts: () => dispatch(forecastRetirement()),
    handleHsaBalanceAndPersona: (balance, persona) => dispatch(setHsaBalanceAndPersona(balance, persona)),
    handleLockedHsaContribution: (contribution) => dispatch(setLockedHsaContribution(contribution)),
  };
}

const withSaga = injectSaga({
  key: 'hsaPage',
  saga,
});
const withConnect = connect(mapStateToProps, mapDispatchToProps);

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default compose(withSaga, withConnect)(HsaPage);
