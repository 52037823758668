import React, { FC, ReactElement } from 'react';

import { PageLayout } from 'DesignLibrary/atoms/PageLayout';
import { H1 } from 'DesignLibrary/atoms/typography';

import ResultsReviewHeader from './ResultsReviewHeader';
import { PageHeaderContainer, Subtext } from './styled';

export interface PageHeaderProps extends React.HTMLAttributes<HTMLUListElement> {
  header: string;
  subtext: ReactElement<Text> | string;
  hideSubtext?: boolean;
  animate?: boolean;
  welcomeBackText?: ReactElement<Text> | string;
  hasHealthPlanSection?: boolean;
}

export const PageHeader: FC<PageHeaderProps> = ({
  header,
  subtext,
  hideSubtext = false,
  animate = true,
  welcomeBackText = '', // only used by ContentfulHeaderWrapperForHealthSection,
  hasHealthPlanSection = false, // only used by ContentfulHeaderWrapperForHealthSection,
}): JSX.Element => {
  const headerTokens = header?.split(' ');
  const isDevMode = localStorage.getItem('dev');
  const shouldAnimate = !isDevMode && animate;

  return (
    <PageLayout>
      <PageHeaderContainer numTokens={headerTokens?.length} animate={shouldAnimate}>
        <H1 color="--text-black">
          {headerTokens?.map((token, index) => (
            <span id={`headerToken-${index}`} key={token}>
              {token}
              &nbsp;
            </span>
          ))}
        </H1>
        {/* todo we should refactor this so we can have a "single" subtext to render. */}
        {welcomeBackText && (
          <Subtext hideSubtext={!hideSubtext} animate={shouldAnimate}>
            {welcomeBackText}
          </Subtext>
        )}
        <Subtext hideSubtext={hideSubtext} animate={shouldAnimate}>
          {subtext}
        </Subtext>

        <ResultsReviewHeader
          numTokens={headerTokens?.length}
          animate={shouldAnimate}
          hasHealthPlanSection={hasHealthPlanSection}
        />
      </PageHeaderContainer>
    </PageLayout>
  );
};
