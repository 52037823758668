import styled, { keyframes } from 'styled-components';

import { breakpoint, media } from 'DesignLibrary/global';

export const CtaPosition = styled.div`
  position: fixed;
  bottom: 0;
  z-index: 1299;
  width: 100%;
  height: 0;

  @media print {
    display: none;
  }
`;

interface CtaContainerProps {
  profile: 1 | 0;
}

export const CtaContainer = styled.div<CtaContainerProps>`
  position: relative;
  left: -16px;
  z-index: 0;
  width: calc(100% + 32px);
  height: var(--sizing-2xl);
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: ${({ profile }) => (profile ? '0' : 'var(--sizing-xs)')};
  padding: 0 var(--sizing-xs);
  background-color: var(--primary-white);
  border-top: 2px solid var(--colors-gray-300);
  box-shadow:
    0px -6px 24px 0px rgba(0, 0, 0, 0.08),
    0px 0px 4px 0px rgba(0, 0, 0, 0.05);

  ${media.TABLET`
    column-gap: var(--sizing-md);
    border-radius: var(--border-radius-xs) var(--border-radius-xs) 0 0;
    border-left: 2px solid var(--colors-gray-300);
    border-right: 2px solid var(--colors-gray-300);
  `}

  ${media.DESKTOP`
    column-gap: var(--sizing-lg);
  `}
`;

const HaloGlow = keyframes`
  0% {
    transform: scale(1)
    opacity: .5;
  }

  50% {
    transform: scaleY(3.5) scaleX(2);
    opacity: .85;
  }

  100% {
    transform: scale(1);
    opacity: .5;
  }
`;

export const CtaHalo = styled.div`
  position: absolute;
  z-index: -1;
  pointer-events: none;
  right: var(--sizing-xs);
  bottom: 0;
  height: var(--sizing-xl);
  width: var(--sizing-4xl);
  border-radius: 100rem 100rem 0 0;
  background: radial-gradient(50% 50% at 50% 50%, #fed837 0%, rgba(254, 217, 58, 0) 100%);
  animation: ${HaloGlow} 4s 1.5s 4;
  display: none;

  ${breakpoint.TABLET} {
    display: block;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 10px;
  justify-content: end; /* Adjust alignment as needed */
  align-items: center;
  width: 100%; /* Ensures proper spacing */
`;
