import { Stack } from '@chakra-ui/react';
import React from 'react';

import { TextField } from 'Containers/App/types';
import { InitialDiagnosisContainer } from 'Containers/OverviewPage/styled';
import {
  DetailsContainer,
  DetailsWrapper,
  ListItem,
  ConditionsContainer,
} from 'Containers/ViewBenefitPage/ExpandablePlanCard/PlanContent/styled';
import { CancerBenefits } from 'Containers/ViewBenefitPage/types';
import { transformBenefitItems } from 'Containers/ViewBenefitPage/utils';
import { RichTextContent } from 'ContentfulDefaults/types/_shared';
import { ContentfulSupplementalPlan } from 'ContentfulDefaults/types/benefitsAdditional';
import { useTextContext } from 'Contexts/textContext';
import { H5, Numbers, Paragraph } from 'DesignLibrary/atoms';
import { TooltipPlanDetails } from 'DesignLibrary/molecules';
import RichText from 'Shared/RichText';
import Text from 'Shared/Text';
import { SupplementalPlan } from 'Types/entities';
import { toDollars } from 'Utils/helpers';

export interface CancerPlanContentProps {
  plan: SupplementalPlan;
  contentfulSupplementalPlan: ContentfulSupplementalPlan | undefined;
}

export interface TextProps {
  headerField: TextField;
  textField: TextField;
  richText?: RichTextContent;
  dataTestId?: string;
}

export const CancerPlanContent = (props: CancerPlanContentProps) => {
  const { plan, contentfulSupplementalPlan } = props;
  const benefits = transformBenefitItems(plan) as CancerBenefits;
  const { retrieveContentfulData } = useTextContext();

  const containerStack = ({ headerField, textField, richText, dataTestId }: TextProps) => (
    <Stack gap={2} data-testid={dataTestId}>
      <Stack>
        <H5>
          <Text field={headerField} />
        </H5>
        <Paragraph
          as="div"
          lineHeight={1.75}
          size="small"
          className="plan-description"
          data-testid={`para_${dataTestId}`}
        >
          <Text field={textField} />
        </Paragraph>
      </Stack>
      {richText && (
        <Stack>
          <ConditionsContainer>
            <Paragraph as="div" lineHeight={1.75} size="small" className="plan-description">
              <RichText field={undefined} fieldContent={richText} />
            </Paragraph>
          </ConditionsContainer>
        </Stack>
      )}
    </Stack>
  );

  const commonStack = (
    <Stack data-testid="common-stack">
      <H5>
        <Text field="benefits_section_cancer.header_limitations" />
      </H5>
      <Paragraph
        as="div"
        lineHeight={1.75}
        size="small"
        className="plan-description"
        data-testid="limitations-text"
      >
        <Text field="benefits_section_cancer.limitations_description" />
      </Paragraph>
    </Stack>
  );

  const recurrenceStack = (
    <Stack data-testid="recurrence-stack">
      <H5 data-testid="header-recurrence-coverage-text">
        <Text field="benefits_section_cancer.header_recurrence_coverage" />
      </H5>
      <Paragraph
        as="div"
        lineHeight={1.75}
        size="small"
        className="plan-description"
        data-testid="recurrence-coverage-text"
      >
        <Text field="benefits_section_cancer.recurrence_coverage_text" />
        <Paragraph as="div" lineHeight={1.75} size="small" className="plan-description">
          <RichText field={undefined} fieldContent={contentfulSupplementalPlan?.recurrence_coverage} />
        </Paragraph>
        <Text field="benefits_section_cancer.recurrence_coverage_text2" />
      </Paragraph>
    </Stack>
  );

  const waitingStack = (
    <Stack data-testid="waiting-period-stack">
      <H5 data-testid="header-waiting-period-details-text">
        <Text field="benefits_section_cancer.header_waiting_period" />
      </H5>
      <Paragraph
        as="div"
        lineHeight={1.75}
        size="small"
        className="plan-description"
        data-testid="waiting-period-details-text"
      >
        <Text field="benefits_section_cancer.waiting_period" />
        <Paragraph as="div" lineHeight={1.75} size="small" className="plan-description">
          <RichText field={undefined} fieldContent={contentfulSupplementalPlan?.waiting_period_details} />
        </Paragraph>
      </Paragraph>
    </Stack>
  );

  const outroTextNoteShort: RichTextContent | undefined = contentfulSupplementalPlan?.outro_text_note_short;
  const outroTextNoteLong: RichTextContent | undefined = contentfulSupplementalPlan?.outro_text_note_long;

  const textNoteStack = (
    <Stack>
      <H5>
        <Text field="benefits_section_cancer.header_additional_information" />
      </H5>
      <Paragraph size="small">
        <RichText field={undefined} fieldContent={outroTextNoteShort} />
      </Paragraph>
      {outroTextNoteLong && (
        <Paragraph size="small">
          <RichText field={undefined} fieldContent={outroTextNoteLong} />
        </Paragraph>
      )}
    </Stack>
  );

  const getFormattedBenefitAmount = (key: string): number | undefined => {
    return toDollars(benefits[key]);
  };

  const renderList = (numCoverageLevels: number) => {
    return Array.from({ length: numCoverageLevels }).map((_, index) => (
      <li key={index}>{getFormattedBenefitAmount(`level_${index + 1}_coverage`)}</li>
    ));
  };

  const getInitialDiagnosisLabel = () => {
    if (benefits.initial_diagnosis_payout) {
      return (
        <TooltipPlanDetails
          tooltip={{
            text: retrieveContentfulData<string>(`benefits_section_cancer.initial_diagnosis_benefits`) || '',
          }}
          keyHint="initial_diagnosis_benefits"
        >
          <Text field="benefits_section_cancer.header_initial_diagnosis_benefits" />
        </TooltipPlanDetails>
      );
    }
    return undefined;
  };

  const getInitialPayment = () => (
    <Numbers data-testid="number-initial-diagnosis" size="small" weight="normal">
      {toDollars(benefits.initial_diagnosis_payout)}
    </Numbers>
  );

  const getCoverageLevels = (
    <div>
      <Stack data-testid="waiting-period-stack">
        <H5>{retrieveContentfulData<string>('benefits_section_cancer.header_coverage_levels')}</H5>
        <Paragraph data-testid="para_coverage-levels">
          <Text field="benefits_section_cancer.coverage_level_text" />
        </Paragraph>
      </Stack>
      <Stack>
        <DetailsContainer data-testid="coverage-levels">
          <ListItem className="covered-benefits">
            <div className="item">{renderList(parseInt(benefits.num_coverage_levels || '0', 10) || 0)}</div>
          </ListItem>
        </DetailsContainer>
      </Stack>
    </div>
  );

  const getInitialDiagnosisComponent = (
    <InitialDiagnosisContainer data-testid="diagnosis-section">
      <div className="left-text">{getInitialDiagnosisLabel()}</div>
      <div className="right-text">{getInitialPayment()}</div>
    </InitialDiagnosisContainer>
  );

  const getCoverageType = () => benefits.coverage_type === 'per_procedure';

  return (
    <DetailsWrapper>
      {getCoverageType() && getInitialDiagnosisComponent}
      {!getCoverageType() && getCoverageLevels}
      <Stack gap={6}>
        {getCoverageType() &&
          containerStack({
            headerField: 'benefits_section_cancer.header_covered_services',
            textField: 'benefits_section_cancer.covered_services_text',
            richText: contentfulSupplementalPlan?.per_procedure_coverage,
            dataTestId: 'covered_services',
          })}
        {getCoverageType() &&
          benefits.cancer_preventive &&
          containerStack({
            headerField: 'benefits_section_cancer.header_preventive_services',
            textField: 'benefits_section_cancer.preventive_services',
            richText: contentfulSupplementalPlan?.provided_benefits_preventive,
            dataTestId: 'preventive_services',
          })}

        {!getCoverageType() &&
          benefits?.has_services_rider &&
          containerStack({
            headerField: 'benefits_section_cancer.header_additional_text',
            textField: 'benefits_section_cancer.additional_text',
            richText: contentfulSupplementalPlan?.services_rider_coverage,
            dataTestId: 'additional_text_benefits',
          })}
        {commonStack}
        {benefits?.waiting_period && waitingStack}
        {benefits?.has_recurrence_coverage && recurrenceStack}

        {outroTextNoteShort && textNoteStack}
      </Stack>
    </DetailsWrapper>
  );
};
