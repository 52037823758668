import React from 'react';

import { TextField } from 'Containers/App/types';
import { CUSTOM_NAME_PLAN_TYPES } from 'Containers/OverviewPage/constants';
import { CONTENT_MAP } from 'Containers/ViewBenefitPage/constants';
import { InsuranceType } from 'Containers/ViewBenefitPage/types';
import { ContentfulSupplementalPlan } from 'ContentfulDefaults/types/benefitsAdditional';
import { useTextContext } from 'Contexts/textContext';
import { H5, Icon, Paragraph } from 'DesignLibrary/atoms';
import { PremiumPerDeduction } from 'DesignLibrary/atoms/PremiumPerDeduction';
import { SelectedBadge } from 'DesignLibrary/atoms/SelectedBadge';
import Text from 'Shared/Text';
import { CoveredMember, CoveredMembers, SupplementalPlan } from 'Types/entities';

import { BottomBar, MainContent, SelectedPlan } from './styled';

export interface SelectedPlanCardProps {
  insuranceType: InsuranceType;
  selectedPlan: SupplementalPlan | Record<string, never>;
  contentfulSupplementalPlan: ContentfulSupplementalPlan | null | undefined;
  coveredMembers: CoveredMembers;
  planCount: number;
  isAutoEnrolled: boolean;
  isEmployerPaid?: boolean;
  handleOpenBenefit: (insuranceType: InsuranceType) => void;
}

const SelectedPlanCard = ({
  insuranceType,
  selectedPlan,
  contentfulSupplementalPlan,
  coveredMembers,
  planCount,
  isAutoEnrolled,
  isEmployerPaid,
  handleOpenBenefit,
}: SelectedPlanCardProps) => {
  const { retrieveContentfulData } = useTextContext();

  let benefitTitle = insuranceType.toString();
  const insuranceContent = CONTENT_MAP[insuranceType];

  if (CUSTOM_NAME_PLAN_TYPES.includes(insuranceType)) {
    benefitTitle = selectedPlan.name;
  } else if (insuranceContent) {
    benefitTitle = retrieveContentfulData<string>(`${insuranceContent.field}.card_title`, '');
  }

  let buttonLabel = '';

  if (isAutoEnrolled && planCount >= 2) {
    buttonLabel = `Auto-enrolled ${retrieveContentfulData<string>(
      `benefits_section.button_text.addCoverage`,
    )} ${selectedPlan.carrier_name} ${selectedPlan.name}`;
  } else if (isAutoEnrolled && planCount <= 1) {
    buttonLabel = `Auto-enrolled  ${retrieveContentfulData<string>(
      `benefits_section.button_text.learnMore`,
    )} ${selectedPlan.carrier_name} ${selectedPlan.name}`;
  } else if (!isAutoEnrolled) {
    buttonLabel = `Selected ${retrieveContentfulData<string>(
      `benefits_section.button_text.updatePlan`,
    )} ${selectedPlan.carrier_name} ${selectedPlan.name}`;
  }

  const carrierName = contentfulSupplementalPlan?.carrier || selectedPlan.carrier_name;

  const handleOnKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleOpenBenefit(insuranceType);
    }
  };

  const renderMonthlyPremiumOrTag = () => {
    if (isEmployerPaid && planCount === 1) {
      return (
        <span className="auto-enrolled">
          <Text field="benefits_section.auto_enrolled_info.tagText" />
        </span>
      );
    }
    if (isAutoEnrolled) {
      return null;
    }

    return (
      selectedPlan.monthly_premium && (
        <PremiumPerDeduction
          annualPremium={(selectedPlan.monthly_premium?.data as number) * 12}
          deductions={selectedPlan.deductions_per_year}
          currency
          precision={2}
        />
      )
    );
  };

  const coveredMembersText = (members: CoveredMember[]) => {
    const covered = members.filter((m) => m.covered === true);
    const coveredCount = covered.length;
    let textKey = 'familyMembersV3';

    if (coveredCount === 1) {
      textKey = 'yourselfV3';
    } else if (members.length === coveredCount) {
      textKey = 'allV3';
    } else if (coveredCount === 2 && !covered[1].policyholder && !covered[1].dependant) {
      textKey = 'youAndSpouseV3';
    }

    return (
      <Text field={`benefits_section.coverage_options.${textKey}` as TextField} vars={{ x: coveredCount }} />
    );
  };

  return (
    <SelectedPlan
      id={`selected-${insuranceType}`}
      aria-label={buttonLabel}
      role="button"
      className="selected-plan"
      interactive
      onClick={() => handleOpenBenefit(insuranceType)}
      onKeyDown={(e) => handleOnKeyDown(e)}
      tabIndex={0}
    >
      <div className="selected-badge">
        <SelectedBadge sizeOverride="small" type={isAutoEnrolled ? 'auto_enrolled' : 'selected'} />
      </div>
      <MainContent>
        <section className="plan-head">
          <Paragraph className="details" size="small" data-testid="covered-member-text">
            {!isAutoEnrolled && insuranceType !== 'pet' && (
              <>
                <Icon type="Users" color="--text-gray" />
                {coveredMembersText(Object.values(coveredMembers))}
                {carrierName && <span className="bullet">&#8226;</span>}
              </>
            )}
            {carrierName}
          </Paragraph>
          <H5 as="h4" className="title">
            {benefitTitle}
          </H5>
        </section>
        <section className="price">{renderMonthlyPremiumOrTag()}</section>
      </MainContent>

      <BottomBar data-testid="add-coverage-cta" color={isAutoEnrolled ? 'blue' : 'green'}>
        <Paragraph
          size="mini"
          weight="bold"
          color={isAutoEnrolled ? '--colors-blue-600' : '--colors-green-600'}
        >
          {isAutoEnrolled && planCount >= 2 && <Text field="benefits_section.button_text.addCoverage" />}

          {isAutoEnrolled && planCount <= 1 && <Text field="benefits_section.button_text.learnMore" />}

          {!isAutoEnrolled && <Text field="benefits_section.button_text.updatePlan" />}
        </Paragraph>
        <Icon
          type="CaretRight"
          size="small"
          color={isAutoEnrolled ? '--colors-blue-600' : '--colors-green-600'}
        />
      </BottomBar>
    </SelectedPlan>
  );
};

export default SelectedPlanCard;
