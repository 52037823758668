import { isEmpty } from 'lodash';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';

import { getOopChance } from 'Containers/HsaPage/helpers';
import { sanitizeProfileInputs, resetFormDirtyState } from 'Containers/ProfilePage/actions';
import { useProfileContext } from 'Contexts/profileContext';
import { useTextContext } from 'Contexts/textContext';
import { Button, ButtonProps } from 'DesignLibrary/atoms/Button';
import Text from 'Shared/Text';
import { Recommendation, SelectedSupplementalPlans } from 'Types/entities';
import { GetHsaRecommendationResponse } from 'Utils/apiTypes';
import { sendInsightsEvent } from 'Utils/insights';
import {
  PROFILE_PATH,
  HSA_PLUS_PATH,
  OVERVIEW_PATH,
  RESULT_PATH,
  REVIEW_PATH,
  VIEW_BENEFIT_PATH,
  VIEW_PLAN_PATH,
  TAX_SAVINGS_PATH,
} from 'Utils/urls';

import { Wrapper } from './styled';

export interface ButtonsProps {
  selectedPlan: Recommendation | Record<string, never>;
  isCoverageWaived: boolean;
  selectedSupplementalPlans: SelectedSupplementalPlans;
  hsaData: GetHsaRecommendationResponse | Record<string, never>;
  fbsEnabled: boolean;
  hsaEnabled: boolean;
  taxSavingsEnabled: boolean;
  lockedHsaContribution: number | null;
  isLoading?: boolean;
  buttonText?: ChildrenText;
  dataTestId?: string;
}

type ChildrenText =
  | ''
  | 'profile'
  | 'profileReview'
  | 'results'
  | 'hsa'
  | 'review'
  | 'overview'
  | 'viewBenefit';

export const Buttons = ({
  selectedPlan,
  isCoverageWaived,
  selectedSupplementalPlans,
  fbsEnabled,
  hsaEnabled,
  taxSavingsEnabled,
  hsaData,
  lockedHsaContribution,
  isLoading = false,
  buttonText,
  dataTestId = 'cta-continue-button',
}: ButtonsProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { getIsFormValid, getIsFormCompleted, setReviewMode } = useProfileContext();
  const { locale, retrieveContentfulData } = useTextContext();
  const { pathname } = useLocation();

  // Button Properties Init
  let childrenText: ChildrenText = '';
  let buttonProps: ButtonProps = {
    buttonType: 'primary',
    iconRight: 'ArrowRight',
    onClick: () => null,
  };

  // Disabled Button Props
  const disabledButtonProps: ButtonProps = {
    isDisabled: true,
    disabled: true,
    iconRight: 'LockSimple',
    onClick: () => null,
  };

  // ----------------------
  // PROFILE PATH
  // ----------------------
  if (pathname === PROFILE_PATH) {
    const isFormValid = getIsFormValid();
    const isFormUnlocked = getIsFormCompleted();

    const click = (e) => {
      sendInsightsEvent(e, 'language_preference', { locale });
      dispatch(resetFormDirtyState());
      dispatch(sanitizeProfileInputs());
      setReviewMode(false);
      if (buttonText === 'profileReview') {
        setReviewMode(true);
        sendInsightsEvent(e, 'user_entered_review_mode');
      }
      navigate(RESULT_PATH);
    };

    // Update Button Props
    buttonProps.className = 'pingdom-profile-continue';

    if (isFormValid && isFormUnlocked) {
      childrenText = 'profile';
      buttonProps.onClick = click;
      buttonProps.iconRight = 'ArrowRight';
      if (buttonText === 'profileReview') {
        childrenText = buttonText;
        buttonProps.color = 'blue';
        buttonProps.iconProps = {
          color: '--primary-white',
        };
      }
    } else {
      buttonProps = disabledButtonProps;
    }
  }

  // ----------------------
  // RESULT/VIEW PLAN PATH
  // ----------------------
  else if (pathname === RESULT_PATH || pathname.includes(VIEW_PLAN_PATH)) {
    if (!isEmpty(selectedPlan) || isCoverageWaived) {
      const insightsEnrollEventData = isCoverageWaived
        ? 'coverage_waived'
        : selectedPlan.plan.external_plan_id;

      let path;
      // if hsa enabled + hsa eligible , set path to go to hsa
      if (hsaEnabled && !isCoverageWaived && selectedPlan?.plan?.hsa_eligible && !taxSavingsEnabled) {
        path = HSA_PLUS_PATH;
      } else if (taxSavingsEnabled) {
        path = TAX_SAVINGS_PATH;
      }
      // else (non hsa eligible plan or waived coverage) + fbs IS enabled, go to supplemental
      else if (fbsEnabled) {
        path = OVERVIEW_PATH;
        // else (non hsa eligible plan or waived coverage) + fbs NOT enabled, go to review
      } else {
        path = REVIEW_PATH;
      }

      const click = () => {
        sendInsightsEvent(null, 'choose_health_plan', {
          selected_plan_id: insightsEnrollEventData,
        });
        navigate(path);
      };

      // Update Button Props
      childrenText = 'results';
      buttonProps.onClick = click;
    } else {
      buttonProps = { ...disabledButtonProps };
    }
  }

  // ----------------------
  // Tax Savings PATH
  // ----------------------
  else if (pathname === TAX_SAVINGS_PATH) {
    const next = fbsEnabled ? OVERVIEW_PATH : REVIEW_PATH;
    const nextField = fbsEnabled ? 'hsa' : 'review';
    childrenText = nextField;
    buttonProps.onClick = () => {
      navigate(next);
    };
  }

  // ----------------------
  // HSA+ PATH
  // ----------------------
  else if (pathname === HSA_PLUS_PATH) {
    const next = fbsEnabled ? OVERVIEW_PATH : REVIEW_PATH;
    const nextField = fbsEnabled ? 'hsa' : 'review';
    // Update Button Props
    childrenText = nextField;
    buttonProps.onClick = (e) => {
      // both here and the review page event are using this `if null`
      // logic, we should refactor this to use a selector for both,
      // and eventually the ben admin enrollment logic as well
      const hsaContribution =
        lockedHsaContribution !== null ? lockedHsaContribution : hsaData.recommended_contribution;
      const oopChance = getOopChance(hsaData.recommended_contribution_deciles, hsaContribution);
      const taxBenefit = hsaData.tax_rate * hsaContribution;
      const insightsEvent = {
        hsaContribution,
        taxBenefit,
        oopChance,
      };
      sendInsightsEvent(e, 'save_hsa_contribution', insightsEvent);
      navigate(next);
    };
    buttonProps.color = 'yellow';
  }

  // ----------------------
  // OVERVIEW PATH
  // ----------------------
  else if (pathname === OVERVIEW_PATH) {
    // Update Button Props
    childrenText = 'overview';
    buttonProps.onClick = () => navigate(REVIEW_PATH);
  }

  // ----------------------
  // VIEW BENEFIT PATH
  // ----------------------
  else if (pathname.includes(VIEW_BENEFIT_PATH)) {
    // Button type should be primary if there's a plan selected for this benefit
    const benefit = window.location.pathname.slice(9);
    const buttonType = selectedSupplementalPlans[benefit] ? 'primary' : 'secondary';

    childrenText = 'viewBenefit';
    buttonProps.onClick = () => navigate(OVERVIEW_PATH);
    buttonProps.buttonType = buttonType;
    buttonProps.iconLeft = 'ArrowLeft';
    buttonProps.iconRight = null;
  }

  // ----------------------
  // REVIEW PATH
  // ----------------------
  else if (pathname === REVIEW_PATH) {
    // Display no button if we're at the end
    return null;
  }

  // ----------------------
  // DISABLED BUTTON
  // ----------------------
  else {
    buttonProps = { ...disabledButtonProps };
  }
  if (isLoading) {
    return (
      <Wrapper>
        <Button stretch {...disabledButtonProps} isLoading={isLoading} />
      </Wrapper>
    );
  }
  const buttonLabel = retrieveContentfulData<string>('call_to_action.accessibility.continue_button');

  return (
    <Wrapper>
      <Button aria-label={buttonLabel} stretch data-testid={dataTestId} {...buttonProps}>
        {childrenText && <Text field={`call_to_action.continue_button.${childrenText}`} />}
      </Button>
    </Wrapper>
  );
};
