import { ContentfulBenefitsSection } from 'ContentfulDefaults/types/benefits';

export const benefits: {
  benefits_section: ContentfulBenefitsSection;
} = {
  benefits_section: {
    contentful_name: 'Benefits Section (DEFAULT DO NOT CHANGE)',
    title_info_bar: {
      healthPlan: "Great, you've selected a healthcare plan!",
      noHealthPlan: 'You have opted to not enroll in a health plan.',
      hsaContribution: 'Your HSA Contribution has been locked in.',
    },
    button_text: {
      allDone: "Yes, Let's Review",
      undoPlan: 'Changed My Mind',
      breakdown: 'PLAN BREAKDOWN',
      learnMore: 'Learn More',
      noDetails: 'PDF Unavailable',
      plansFrom: 'Plans from',
      viewPlans: 'Choose',
      goToReview: 'Continue to Review',
      selectPlan: 'Select This Plan',
      choosePlan: 'Choose Plan',
      updatePlan: 'Update Selection',
      addCoverage: 'Add More Coverage',
      notEligible: 'Not Eligible',
      planDetails: 'Full Plan Details',
      learnMorePdf: 'Learn More (PDF)',
      selectedPlan: 'Plan Selected',
      unselectPlan: 'Unselect',
      hideBreakdown: 'HIDE BREAKDOWN',
      notInterested: 'Not Interested',
      backToBenefits: 'Back to all Benefits',
    },
    benefits_heading: 'Choose Your Benefits',
    benefits_description: {
      data: {},
      content: [
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: 'Your employer offers these voluntary supplemental benefits.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
      ],
      nodeType: 'document',
    },
    no_benefits_available:
      'Based on your employee group, you are not eligible for any additional benefits. Please continue to the review page.',
    covered_benefits_title: 'Covered Benefits',
    card_additions: {
      onePlan: '1 plan from',
      manyPlans: '{x} plans from',
      fullDetails: '... (see full details)',
      hraIncluded: "You are seeing this because you've enrolled in a HRA-eligible health plan.",
      hsaIncluded: "You are seeing this because you've enrolled in a HSA-eligible health plan.",
    },
    footer_text: {
      data: {},
      content: [
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: 'You’ve picked',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [
                {
                  type: 'bold',
                },
              ],
              value: ' ',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [
                {
                  type: 'bold',
                },
                {
                  type: 'code',
                },
              ],
              value: '{x}',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [
                {
                  type: 'bold',
                },
              ],
              value: ' supplemental plans ',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [],
              value: '(including auto-enrolled). All done?',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
      ],
      nodeType: 'document',
    },
    no_plans_footer_text:
      'Based on your employee group, you are not eligible for any additional benefits. Please continue to the review page.',
    auto_enrolled_info: {
      tipText: 'Your employer offers this benefit at no additional cost.',
      tipTitle: 'Auto Enrolled',
      modalTitle: 'Your employer offers an auto-enrolled plan',
      moreCoverage:
        'You may also elect to purchase the following coverage which provides benefit beyond what your auto-enrolled plan covers.',
      tagText: 'Employer Paid',
    },
    reasons_why_heading: {
      title: 'Why Consider {x}?',
    },
    reasons_why: {
      sports: 'If your children play any sports.',
      tobacco: 'Tobacco use increases the risk of being diagnosed with a covered condition.',
      eyeExams: "Even if you don’t wear glasses, it's a good idea to get routine eye exams.",
      age40to50:
        'You said that you or your partner are over 40 years old. If you have a family history of any covered conditions, you may be at greater risk.',
      ageOver50:
        'You said that you or your partner are over 55 years old. You may benefit from this type of insurance because of higher age-related risk of being diagnosed with a covered condition.',
      childcare:
        'If you are planning on paying for qualifying childcare, this account can help you save on taxes.',
      pregnancy: 'You may receive benefits if you get bills for inpatient labor and delivery.',
      eyeGlasses: 'This coverage helps pay for glasses or contacts, if you need them.',
      childDental: 'If your child has or needs braces, this coverage may help reduce the costs.',
      moderateRisk:
        'People with {x} risk aversion often value having benefits like this that provide extra risk protection.',
      childActivity:
        'You have a kid, and we all know kids can be accident-prone, especially if they’re physically active or play sports.',
      lifeDependent:
        'Because you have dependents, you may want to consider adding life insurance to make sure your family’s financial needs are covered, even if you’re not around.',
      parentalLeave: 'This coverage can help cover lost wages for any time off work following childbirth.',
      prescriptions: 'People who take a similar amount of prescriptions tend to select these policies.',
      childrenDental: 'If your kids have or need braces, this coverage may help reduce those costs.',
      youngDependent: 'Since you have children under the age of 6.',
      dentalCleanings: 'This coverage will help you cover the cost of routine dental care.',
      extraProtection:
        'You said that you could afford to pay {x} for an unexpected medical bill, which means you could especially benefit from extra protection against lost wages from disability.',
      childrenActivity:
        'You have {x} kids, and we all know kids can be accident-prone, especially if they’re physically active or play sports.',
      hospitalAdmission:
        'You said that you or someone in your family was admitted to a hospital in the last 12 months. People with prior hospital admissions are more likely than average to experience a hospital admission in the future.',
      longTermDisability:
        'You may want to consider adding Long-Term Disability coverage, which is an affordable way to reduce your financial risk if you become disabled. That’s worth a look.',
      highDeductLowCapacity:
        'Your medical plan’s {healthDeductible} deductible exceeds what you said you can afford to pay ({capacityToPay}). This extra benefit can help you cover that gap.',
      plannedProcedureInpatient:
        "You've told us that you or a family member is planning to have an inpatient procedure in the next 12 months.",
    },
    your_plans_heading: 'Your {x} Plans',
    member_coverage_text: {
      data: {},
      content: [
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: "You're covering ",
              nodeType: 'text',
            },
            {
              data: {},
              marks: [
                {
                  type: 'code',
                },
                {
                  type: 'bold',
                },
              ],
              value: '{x}',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
      ],
      nodeType: 'document',
    },
    coverage_options: {
      all: 'everyone',
      yourself: 'yourself',
      isCovered: 'Yes, covered',
      notCovered: 'Not covered',
      youAndSpouse: 'you and your spouse/partner',
      familyMembers: '{x} family members',
      yourselfV3: 'Yourself',
      allV3: 'Entire Family',
      youAndSpouseV3: 'You & Spouse/Partner',
      familyMembersV3: '{x} Family Members',
    },
    single_plan: {
      data: {},
      content: [
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: 'There is ',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [
                {
                  type: 'bold',
                },
              ],
              value: '1 plan ',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [],
              value: 'to choose from. ',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
      ],
      nodeType: 'document',
    },
    multiple_plans: {
      data: {},
      content: [
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: 'There are',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [
                {
                  type: 'bold',
                },
              ],
              value: ' ',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [
                {
                  type: 'bold',
                },
                {
                  type: 'code',
                },
              ],
              value: '{x}',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [
                {
                  type: 'bold',
                },
              ],
              value: ' plans',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [],
              value: ' to choose from.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
      ],
      nodeType: 'document',
    },
    learn_more_text: 'Learn more about what this benefit offers.',
    plan_card_text: {
      benefits: {
        title: 'Covered Benefits',
        covered: 'Covered',
        payouts: 'Covered Benefit Payouts',
        deductible: 'Deductible',
        maxBenefit: 'Maximum Annual Benefit',
        notCovered: 'Not Covered',
        orthodontia: 'Orthodontia',
        seeFullDetails: 'See full details.',
      },
      exclusions: {
        title: 'Exclusions and Limitations',
        instructions: 'For exclusions and limitations, see plan breakdown.',
      },
      notEligible: 'With your current family member selection, you are not eligible for this plan.',
      notEligibleWarning: {
        yourself: 'Only policyholder is eligible',
        youAndSpouse: 'Only policyholder and spouse are eligible',
        youAndDependents: 'Only policyholder and dependents are eligible',
      },
    },
    benefit_page_heading: '{x} Insurance',
    dental_benefit: {
      contentful_name: 'Dental Benefit',
      icon_link: 'https://picwellapppublicdata.s3.amazonaws.com/contentful/insuranceicons/dental.svg',
      card_title: 'Dental',
      card_description: 'Covers dental checkups, unforeseen dental surgery, orthodontics, and more.',
      modal_description: {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  'Routine dental care is an important part of maintaining your overall health, and dental insurance can help you pay for both expected and unexpected dental and orthodontia expenses. When you have dental insurance, you pay the prices that your dental insurer has negotiated with your dentist or orthodontist, and your insurance will help you cover a portion of those costs.',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
        ],
        nodeType: 'document',
      },
      insurance_info: {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  'Dental insurance plans help cover some of your dental and orthodontia costs. Dental plans typically have a maximum amount that they will pay out each year.',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
        ],
        nodeType: 'document',
      },
      video_link: 'https://vimeo.com/499286140',
      notes: {
        note: 'Even if you exceed the maximum benefit amount, you benefit from pricing that your insurance provider negotiates. Dental insurers negotiate prices with providers in their network, and you get access to this preferred pricing when you use in-network providers.',
        title: 'Maximum Dental Benefit',
      },
    },
    vision_benefit: {
      contentful_name: 'Vision Benefit',
      icon_link: 'https://picwellapppublicdata.s3.amazonaws.com/contentful/insuranceicons/vision.svg',
      card_title: 'Vision',
      card_description:
        'Vision insurance helps cover eye exams, glasses, contacts, and unforeseen vision problems.',
      modal_description: {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  'If you wear glasses or contacts or if you plan on getting routine eye exams, vision insurance can help you cover your costs.',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
        ],
        nodeType: 'document',
      },
      insurance_info: {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  'Vision insurance plans help cover the costs of routine eye exams, contacts and glasses, and some vision insurance plans may help cover some corrective procedures.',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
        ],
        nodeType: 'document',
      },
      video_link: 'https://vimeo.com/499286228',
      notes: {
        note: 'Vision insurance plans typically have a maximum benefit that they will pay out each year, but you may still have access to discounted prices that your insurance provider negotiates even if you exceed the maximum benefit amount.',
        title: 'Maximum Vision Benefit',
      },
    },
    accident_benefit: {
      contentful_name: 'Accident Benefit',
      icon_link: 'https://picwellapppublicdata.s3.amazonaws.com/contentful/insuranceicons/accident.svg',
      card_title: 'Accident',
      card_description: 'Accident insurance provides a payout if you suffer covered accidental injuries.',
      modal_description: {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  'Accidents and unexpected injuries are a fact of life. Even the healthiest and most safety-conscious individuals can find themselves on the wrong side of mishap. Accident insurance helps cover these unforeseen medical bills and out-of-pocket expenses for you or a covered family member.',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
        ],
        nodeType: 'document',
      },
      insurance_info: {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  'Accident Insurance policies pay you cash to help you manage some of your expenses if you suffer a covered accidental injury. How you use your cash payment is completely up to you.',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
        ],
        nodeType: 'document',
      },
      video_link: 'https://vimeo.com/499286587',
      notes: {
        note: 'The amount you receive depends on your injury and the treatment, which is usually a set amount for specific covered injuries or care.',
        title: 'Note on Payouts',
      },
    },
    illness_benefit: {
      contentful_name: 'Illness Benefit',
      icon_link:
        'https://picwellapppublicdata.s3.amazonaws.com/contentful/insuranceicons/critical-illness.svg',
      card_title: 'Critical Illness',
      card_description:
        'Critical Illness insurance provides a payout if you are diagnosed with covered conditions.',
      modal_description: {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  'In addition to your physical health, being diagnosed with an illness can have a big impact on your financial health. Whether it is due to higher medical bills or other costs that you might incur to help you deal with your day-to-day activities after a diagnosis, Critical Illness insurance can help you better manage these unexpected costs.',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
        ],
        nodeType: 'document',
      },
      insurance_info: {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  'Critical Illness Insurance pays you cash if you are diagnosed with certain critical illnesses. In most policies, you select a lifetime maximum payout and the policy will pay out some percentage of that amount if you are diagnosed with a covered condition.',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
        ],
        nodeType: 'document',
      },
      video_link: 'https://vimeo.com/499286641',
      notes: {
        note: 'Pre-existing condition exclusions and waiting periods apply to most payouts, so be sure to review these details before purchasing a policy.',
        title: 'Note on Payouts',
      },
    },
    hospital_benefit: {
      contentful_name: 'Hospital Benefit',
      icon_link:
        'https://picwellapppublicdata.s3.amazonaws.com/contentful/insuranceicons/hospital-indemnity.svg',
      card_title: 'Hospital Indemnity',
      card_description:
        'Hospital Indemnity insurance covers unforeseen injuries and helps pay for hospital charges.',
      modal_description: {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value: `Nearly half Americans say that they can't afford a surprise medical bill of $500 or more, and, even if you have health insurance, a hospital stay can leave you with a high medical bill. Hospital indemnity plans pay you money if you are hospitalized, but how you use it is up to you.`,
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
        ],
        nodeType: 'document',
      },
      insurance_info: {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  'Many health insurance plans will require you to pay a lot if you end up in the hospital, whether it be due to deductibles, coinsurance or copayments, or out-of-network charges. These plans typically pay you an amount based on the length of your stay, and you can use these funds however you choose, whether it be to cover medical bills or to pay for food, lodging or transportation for loved ones who visit while you or a covered family member are in the hospital.',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
        ],
        nodeType: 'document',
      },
      video_link: 'https://vimeo.com/499286711',
      notes: {
        note: 'Pre-existing condition exclusions and waiting periods may apply to payouts, so be sure to review these details before purchasing a policy.',
        title: 'Note on Payouts',
      },
    },
    life_benefit: {
      contentful_name: 'Life Benefit ',
      icon_link: 'https://picwellapppublicdata.s3.amazonaws.com/contentful/insuranceicons/life.svg',
      card_title: 'Life',
      card_description:
        'Life Insurance provides a payout to the beneficiary upon the death of any covered lives.',
      modal_description: {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  "We hope that we will always be around to provide for our loved ones, but life insurance gives you a way to plan for the worst, giving your dependents funds to cover costs if you pass away. Life insurance policies are an important tool that you can use to ensure your family's financial security.",
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
        ],
        nodeType: 'document',
      },
      insurance_info: {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  'Life insurance policies pay out a "death benefit" that comes in the form of a single, lump sum payment if you pass away. You can pick the amount of life insurance that you want to buy, so you can plan to have enough to cover your family\'s financial obligations if you are no longer around.',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
        ],
        nodeType: 'document',
      },
      video_link: 'https://vimeo.com/499286311',
      notes: {
        note: 'Life insurance payments have no strings attached. Your family can use these to cover costs like housing, education, debts, and funeral costs.',
        title: 'Note on Payouts',
      },
    },
    death_dismemberment_benefit: {
      contentful_name: 'Death & Dismemberment Benefit',
      icon_link: 'https://picwellapppublicdata.s3.amazonaws.com/contentful/insuranceicons/add.svg',
      card_title: 'Accidental Death & Dismemberment',
      card_description:
        'Accidental Death & Dismemberment (AD&D) insurance will pay you or your beneficiary a lump sum if you die or are dismembered in an accident.',
      modal_description: {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  'Accidental Death & Dismemberment (AD&D) insurance will pay you or your beneficiary a lump sum if you die or are dismembered in an accident. When you purchase an AD&D policy, you may be able to select the amount of coverage, which determines the amount that you or your beneficiary will receive in the event of accidental death or dismemberment. If you have both an AD&D policy and a life insurance policy, your beneficiaries would receive full payouts from both policies in the event of a fatal accident. If you have an accident that results in loss of eyesight, speech, hearing, or a limb, that amount of the payout will depend on the nature of the dismemberment.',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
        ],
        nodeType: 'document',
      },
      insurance_info: {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  'The amount of coverage will affect your premium, and you may also pay a higher premium if you work in a high risk occupation or routinely engage in high risk activities. Please make sure to carefully review your plan details for more information.',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
        ],
        nodeType: 'document',
      },
      video_link: 'https://vimeo.com/499286532',
    },
    std_benefit: {
      contentful_name: 'Short Term Disability Benefit',
      icon_link: 'https://picwellapppublicdata.s3.amazonaws.com/contentful/insuranceicons/std.svg',
      card_title: 'Short-Term Disability',
      card_description:
        'Short-Term Disability Insurance pays a portion of your income if you cannot work because of an illness or injury within a specified timeframe.',
      modal_description: {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  'Short-term disability insurance can help replace some of your income if you are unable to work for a short period -- typically 3 to 6 months. This policy will replace a percentage of your income if you have to miss work for a short period of time; however, you will need to wait a certain amount of time before the policy will start making payments.',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
        ],
        nodeType: 'document',
      },
      insurance_info: {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  'Most short-term disability policies will pay out if you take unpaid time off of work following childbirth. The amount of coverage and the waiting period can both affect your premium. Please make sure to carefully review your plan details for more information.',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
        ],
        nodeType: 'document',
      },
      video_link: 'https://vimeo.com/499286387',
    },
    ltd_benefit: {
      contentful_name: 'Long Term Disability Benefit',
      icon_link: 'https://picwellapppublicdata.s3.amazonaws.com/contentful/insuranceicons/disability.svg',
      card_title: 'Long-Term Disability',
      card_description:
        'Long-Term Disability Insurance pays a portion of your income if you cannot work because of an illness or injury after being disabled for a specified amount of time.',
      modal_description: {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  'Long-term disability insurance can help replace some of your income if you are unable to work for a longer period -- typically a period of over six months. This policy will replace a percentage of your income if you have to miss work; however, you will need to wait a certain amount of time before the policy will start making payments. This waiting period, or “elimination period”, typically ranges from 30 days to 2 years, but the most common is 90 days.',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
        ],
        nodeType: 'document',
      },
      insurance_info: {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  'The amount of coverage and the waiting period can both affect your premium. Please make sure to carefully review your plan details for more information.',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
        ],
        nodeType: 'document',
      },
      video_link: 'https://vimeo.com/499286453',
    },
    retirement_benefit: {
      contentful_name: 'Retirement Benefit',
      icon_link: 'https://picwellapppublicdata.s3.amazonaws.com/contentful/insuranceicons/retirement.svg',
      card_title: 'Retirement ',
      card_description:
        'Retirement savings plans let you save and invest a portion of your paycheck before taxes are taken out.',
      modal_description: {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  'Retirement savings are critical to your long term financial wellness plan, and tax preferred retirement savings accounts can help you get the most out of your retirement contributions.',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
        ],
        nodeType: 'document',
      },
      insurance_info: {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  'Tax-preferred retirement savings accounts allow you to save on taxes, compared to other investment accounts. These tax savings can make big differences in your long-term financial health.',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  'Tax-preferred retirement accounts can allow you to save on taxes either when you make your contribution, allowing you to contribute to your account with pre-tax dollars, or when you withdraw the contribution, allowing you to avoid paying taxes when you withdraw funds in retirement.',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
        ],
        nodeType: 'document',
      },
      video_link: 'https://flimp.me/ex6wir9d?em=Y',
    },
    four_o_one_k_benefit: {
      contentful_name: '401k Benefit',
      icon_link: 'https://picwellapppublicdata.s3.amazonaws.com/contentful/insuranceicons/retirement.svg',
      card_title: '401(k)',
      card_description:
        '401k plans let you save and invest a portion of your paycheck before taxes are taken out.',
      modal_description: {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  'Retirement savings are critical to your long term financial wellness plan, and tax-preferred retirement savings accounts can help you get the most out of your retirement contributions.',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
        ],
        nodeType: 'document',
      },
      insurance_info: {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  'Tax-preferred retirement savings accounts allow you to save on taxes, compared to other investment accounts. These tax savings can make big differences in your long-term financial health.',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  'Tax-preferred retirement accounts can allow you to save on taxes either when you make your contribution, allowing you to contribute to your account with pre-tax dollars, or when you withdraw the contribution, allowing you to avoid paying taxes when you withdraw funds in retirement.',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
        ],
        nodeType: 'document',
      },
      video_link: 'https://vimeo.com/605082604',
    },
    hsa_benefit: {
      contentful_name: 'HSA Benefit',
      icon_link: 'https://picwellapppublicdata.s3.amazonaws.com/contentful/insuranceicons/hsa.svg',
      card_title: 'Health Savings Account',
      card_description:
        'Health Savings Accounts (HSA) let you save money when paying for certain out-of-pocket healthcare costs.',
      modal_description: {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  'While health insurance helps you cover the cost of medical care, it does not cover everything. A Health Savings Account, or HSA for short, is a great way to save for those expenses.',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
        ],
        nodeType: 'document',
      },
      insurance_info: {
        nodeType: 'document',
        data: {},
        content: [
          {
            nodeType: 'paragraph',
            content: [
              {
                nodeType: 'text',
                value: "Here's how it works:",
                marks: [
                  {
                    type: 'italic',
                  },
                ],
                data: {},
              },
            ],
            data: {},
          },
          {
            nodeType: 'unordered-list',
            content: [
              {
                nodeType: 'list-item',
                content: [
                  {
                    nodeType: 'paragraph',
                    content: [
                      {
                        nodeType: 'text',
                        value:
                          'First, after enrolling into HSA eligible health plan, open up a health savings account.',
                        marks: [],
                        data: {},
                      },
                    ],
                    data: {},
                  },
                ],
                data: {},
              },
            ],
            data: {},
          },
          {
            nodeType: 'unordered-list',
            content: [
              {
                nodeType: 'list-item',
                content: [
                  {
                    nodeType: 'paragraph',
                    content: [
                      {
                        nodeType: 'text',
                        value:
                          'Next, choose a contribution amount which will get deducted from your paycheck. Some employers will help you by also contributing to your HSA. Any money you don’t use this year is yours to keep, even if you switch jobs.',
                        marks: [],
                        data: {},
                      },
                    ],
                    data: {},
                  },
                ],
                data: {},
              },
            ],
            data: {},
          },
          {
            nodeType: 'paragraph',
            content: [
              {
                nodeType: 'text',
                value: 'Here are some other things you should know',
                marks: [
                  {
                    type: 'italic',
                  },
                ],
                data: {},
              },
            ],
            data: {},
          },
          {
            nodeType: 'unordered-list',
            content: [
              {
                nodeType: 'list-item',
                content: [
                  {
                    nodeType: 'paragraph',
                    content: [
                      {
                        nodeType: 'text',
                        value:
                          'You can use your HSA to pay for qualified medical expenses including medical copays, vision and dental expenses, prescription drugs, and more.',
                        marks: [],
                        data: {},
                      },
                    ],
                    data: {},
                  },
                ],
                data: {},
              },
              {
                nodeType: 'list-item',
                content: [
                  {
                    nodeType: 'paragraph',
                    content: [
                      {
                        nodeType: 'text',
                        value: 'Any money you contribute to an HSA lowers your taxes.',
                        marks: [],
                        data: {},
                      },
                    ],
                    data: {},
                  },
                ],
                data: {},
              },
              {
                nodeType: 'list-item',
                content: [
                  {
                    nodeType: 'paragraph',
                    content: [
                      {
                        nodeType: 'text',
                        value: 'Individuals can contribute up to ',
                        marks: [],
                        data: {},
                      },
                      {
                        nodeType: 'text',
                        value: '{x}',
                        marks: [
                          {
                            type: 'code',
                          },
                        ],
                        data: {},
                      },
                      {
                        nodeType: 'text',
                        value: ' per year and families can contribute up to ',
                        marks: [],
                        data: {},
                      },
                      {
                        nodeType: 'text',
                        value: '{x}',
                        marks: [
                          {
                            type: 'code',
                          },
                        ],
                        data: {},
                      },
                      {
                        nodeType: 'text',
                        value: ' per year in 2022.',
                        marks: [],
                        data: {},
                      },
                    ],
                    data: {},
                  },
                ],
                data: {},
              },
              {
                nodeType: 'list-item',
                content: [
                  {
                    nodeType: 'paragraph',
                    content: [
                      {
                        nodeType: 'text',
                        value:
                          'As your balance grows, you can invest that money. The best part is that any earnings and any withdrawals for qualified expenses are tax-free.',
                        marks: [],
                        data: {},
                      },
                    ],
                    data: {},
                  },
                ],
                data: {},
              },
            ],
            data: {},
          },
          {
            nodeType: 'paragraph',
            content: [
              {
                nodeType: 'text',
                value: '\n\nPlease make sure to carefully review your plan details for more information.',
                marks: [],
                data: {},
              },
            ],
            data: {},
          },
          {
            nodeType: 'paragraph',
            content: [
              {
                nodeType: 'text',
                value: '',
                marks: [],
                data: {},
              },
            ],
            data: {},
          },
        ],
      },
      video_link: 'https://vimeo.com/408084854',
    },
    hra_benefit: {
      contentful_name: 'HRA Benefit',
      icon_link: 'https://picwellapppublicdata.s3.amazonaws.com/contentful/insuranceicons/hra.svg',
      card_title: 'Health Reimbursement Arrangement',
      card_description:
        'Health Reimbursement Arrangements (HRA) let you save money when paying for certain out-of-pocket healthcare costs.',
      modal_description: {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  'Your health insurance plans come with a Health Reimbursement Arrangement, or HRA. An HRA is an account that your employer sets up and contributes money to in order to help you pay for qualified out-of-pocket health care expenses. This could include medical copays, vision and dental expenses, prescription drugs, and more.',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
        ],
        nodeType: 'document',
      },
      insurance_info: {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                marks: [
                  {
                    type: 'italic',
                  },
                ],
                value: "Here's how it works:",
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
          {
            data: {},
            content: [
              {
                data: {},
                content: [
                  {
                    data: {},
                    content: [
                      {
                        data: {},
                        marks: [],
                        value:
                          'Once you enroll into an HRA eligible plan, your employer will fund an account that you can use to offset your medical expenses.',
                        nodeType: 'text',
                      },
                    ],
                    nodeType: 'paragraph',
                  },
                ],
                nodeType: 'list-item',
              },
            ],
            nodeType: 'unordered-list',
          },
          {
            data: {},
            content: [
              {
                data: {},
                content: [
                  {
                    data: {},
                    content: [
                      {
                        data: {},
                        marks: [],
                        value:
                          'As you get medical care, the insurance company will pay your medical bills that are covered under your medical plan from your HRA until you have used up all of your allocated funds. Depending how your employer setup your HRA, you might have to submit your receipts for reimbursement. But keep in mind, your reimbursements are limited to the amount your employer funds your HRA.',
                        nodeType: 'text',
                      },
                    ],
                    nodeType: 'paragraph',
                  },
                ],
                nodeType: 'list-item',
              },
            ],
            nodeType: 'unordered-list',
          },
          {
            data: {},
            content: [
              {
                data: {},
                marks: [
                  {
                    type: 'italic',
                  },
                ],
                value: 'Here are some other things you should know',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
          {
            data: {},
            content: [
              {
                data: {},
                content: [
                  {
                    data: {},
                    content: [
                      {
                        data: {},
                        marks: [],
                        value:
                          'If you don’t use all of your HRA dollars for reimbursement, you may be able to use those funds in future years, depending on how much your employer allows you to roll-over.',
                        nodeType: 'text',
                      },
                    ],
                    nodeType: 'paragraph',
                  },
                ],
                nodeType: 'list-item',
              },
              {
                data: {},
                content: [
                  {
                    data: {},
                    content: [
                      {
                        data: {},
                        marks: [],
                        value:
                          'If you decide to enroll in a plan that is not HRA qualified or leave your current employer, you will lose all of the HRA money your employer has contributed.',
                        nodeType: 'text',
                      },
                    ],
                    nodeType: 'paragraph',
                  },
                ],
                nodeType: 'list-item',
              },
            ],
            nodeType: 'unordered-list',
          },
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value: '\nPlease make sure to carefully review your plan details for more information.',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value: '',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
        ],
        nodeType: 'document',
      },
      video_link: 'https://vimeo.com/418250602',
    },
    fsa_benefit: {
      contentful_name: 'FSA Benefit',
      icon_link: 'https://picwellapppublicdata.s3.amazonaws.com/contentful/insuranceicons/fsa.svg',
      card_title: 'Flexible Spending Account',
      alternate_title: 'Health Care Limited Purpose FSA',
      card_description:
        'Flexible Spending Accounts (FSA) let you save money when paying for certain out-of-pocket healthcare costs.',
      alternate_description:
        'Limited Purpose Flexible Spending Account (FSA) is a pre-tax benefit account used to pay for qualified dental and vision care expenses that aren’t covered by your health insurance plan.',
      modal_description: {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  'If your health plan is eligible for a Flexible Spending Account (FSA), you can take advantage of this account to help pay for medical expenses. It’s important to only contribute as much as you think you will need to use since FSA funds must be used within the year that the contributions are made. Unused funds revert back to your employer at the end of the year.',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
        ],
        nodeType: 'document',
      },
      insurance_info: {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  "FSA's allow you to set aside up to $2,650 per year in tax free contributions to cover medical expenses. You can contribute twice that if your spouse is covered by the same plan. FSA contributions must be used by the end of the year Unused balances revert to your employer, so it is important to only contribute what you think you will need to use. Employers may offer a grace period of up to 2.5 extra months to use FSA funds and they can also allow you to roll over up to $500 to use in the following year.",
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
        ],
        nodeType: 'document',
      },
      video_link: 'https://vimeo.com/499286816',
      alternate_video_link: 'https://vimeo.com/581347728',
    },
    medical_fsa_benefit: {
      contentful_name: 'Medical FSA Benefit',
      icon_link: 'https://picwellapppublicdata.s3.amazonaws.com/contentful/insuranceicons/fsa_m.svg',
      card_title: 'Health Care FSA',
      alternate_title: 'Health Care Limited Purpose FSA',
      card_description:
        'A Medical Flexible Spending Account, or Medical FSA, is a pre-tax benefit account that you can use to pay for qualified medical, dental, and vision care expenses that aren’t covered by your health insurance plan.',
      alternate_description:
        'Limited Purpose Flexible Spending Account (FSA) is a pre-tax benefit account used to pay for qualified dental and vision care expenses that aren’t covered by your health insurance plan.',
      modal_description: {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  'A Medical Flexible Spending Account, or Medical FSA, is a pre-tax benefit account that you can use to pay for qualified medical, dental, and vision care expenses that aren’t covered by your health insurance plan.',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
        ],
        nodeType: 'document',
      },
      insurance_info: {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                marks: [
                  {
                    type: 'italic',
                  },
                ],
                value: 'Here’s how it works ',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  "Open up an account and decide how much to contribute. Your employer then deducts your contribution amount (in equal portions) from your paychecks throughout the plan year. And the good news - you don't have to wait for funds to build up in your medical FSA! Your entire annual election amount is available to you on the first day of your plan year. Once that’s done, you can use your Medical FSA to help pay for hundreds of eligible healthcare expenses, like co-payments for doctor visits, prescription drugs, and new eyeglasses or contact lenses.",
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
          {
            data: {},
            content: [
              {
                data: {},
                marks: [
                  {
                    type: 'italic',
                  },
                ],
                value: 'Now, there are just a few other important points about Medical FSA',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
          {
            data: {},
            content: [
              {
                data: {},
                content: [
                  {
                    data: {},
                    content: [
                      {
                        data: {},
                        marks: [],
                        value:
                          'If you don’t use all of your FSA funds, you might be able to roll over $500 to the next plan year, but you will lose any left-over contributions above that roll-over limit.',
                        nodeType: 'text',
                      },
                    ],
                    nodeType: 'paragraph',
                  },
                ],
                nodeType: 'list-item',
              },
              {
                data: {},
                content: [
                  {
                    data: {},
                    content: [
                      {
                        data: {},
                        marks: [],
                        value:
                          'Since you can lose unused FSA funds, you should only contribute amounts that you know you will use.',
                        nodeType: 'text',
                      },
                    ],
                    nodeType: 'paragraph',
                  },
                ],
                nodeType: 'list-item',
              },
              {
                data: {},
                content: [
                  {
                    data: {},
                    content: [
                      {
                        data: {},
                        marks: [],
                        value:
                          'If you are enrolled in an HSA eligible plan, your FSA turns into Limited Purpose FSA. Limited Purpose FSAs can only be used to pay for Dental, Vision, and Orthodontia expenses.',
                        nodeType: 'text',
                      },
                    ],
                    nodeType: 'paragraph',
                  },
                ],
                nodeType: 'list-item',
              },
            ],
            nodeType: 'unordered-list',
          },
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value: 'Please make sure to carefully review your plan details for more information.',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value: '',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
        ],
        nodeType: 'document',
      },
      video_link: 'https://vimeo.com/418250339',
      alternate_video_link: 'https://vimeo.com/581347728',
    },
    dependent_fsa_benefit: {
      contentful_name: 'Dependent FSA Benefit',
      icon_link: 'https://picwellapppublicdata.s3.amazonaws.com/contentful/insuranceicons/fsa_dc.svg',
      card_title: 'Dependent Care FSA',
      card_description:
        'A Dependent Care FSA (DCFSA) is a pre-tax benefit account used to pay for qualified dependent care services, such as preschool, summer day camp, before or after school programs, and child or adult daycare.',
      modal_description: {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  "A Dependent Care FSA (DCFSA) is a pre-tax account that you can use to pay for dependent care services while you are at work. Since you don’t have to pay any taxes on money that you contribute to a Dependent Care FSA, this helps you save on dependent care costs.  Under this type of account, a “dependent “ includes children under 13 years of age and adult dependents who can't take care of themselves. Please keep in mind that they must live with you and be claimed as dependents on your tax return.",
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
        ],
        nodeType: 'document',
      },
      insurance_info: {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  "You fund your Dependent Care FSA through your employer. During your company's Open Enrollment period, you tell your employer how much you would like to contribute to your account for the coming year. The maximum amount you can contribute is $5,000. Your employer then deducts your contribution amount (in equal portions) from your paychecks throughout the plan year.",
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
          {
            data: {},
            content: [
              {
                data: {},
                marks: [
                  {
                    type: 'italic',
                  },
                ],
                value: 'You can use the funds in your Dependent Care FSA in two ways:',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
          {
            data: {},
            content: [
              {
                data: {},
                content: [
                  {
                    data: {},
                    content: [
                      {
                        data: {},
                        marks: [],
                        value:
                          'Pay My Provider - You can arrange to have your dependent care provider paid directly.',
                        nodeType: 'text',
                      },
                    ],
                    nodeType: 'paragraph',
                  },
                ],
                nodeType: 'list-item',
              },
              {
                data: {},
                content: [
                  {
                    data: {},
                    content: [
                      {
                        data: {},
                        marks: [],
                        value:
                          'Pay Me Back - You can be reimbursed for qualified dependent care expenses you pay out of pocket.',
                        nodeType: 'text',
                      },
                    ],
                    nodeType: 'paragraph',
                  },
                ],
                nodeType: 'list-item',
              },
            ],
            nodeType: 'ordered-list',
          },
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  '*Note that if you have a lower income and spend less than $5,000 a year on dependent care, you may be better off not using a Dependent Care FSA and claiming a Dependent Care Tax Credit instead.',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value: 'Please make sure to carefully review your plan details for more information.',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
        ],
        nodeType: 'document',
      },
      video_link: 'https://vimeo.com/499286890',
    },
    adoption_fsa_benefit: {
      contentful_name: 'Adoption FSA Benefit',
      icon_link: 'https://picwellapppublicdata.s3.amazonaws.com/contentful/insuranceicons/fsa_aa.svg',
      card_title: 'Adoption Assistance FSA',
      card_description:
        'Adoption Assistance FSA provides reimbursement to you for the reasonable and necessary expense that you incur in the process of legally adopting an eligible child.',
      modal_description: {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  'Adoption Assistance FSA provides reimbursement to you for the reasonable and necessary expense that you incur in the process of legally adopting an eligible child, including adoption fees, court costs, attorney fees and related travel costs.',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
        ],
        nodeType: 'document',
      },
      insurance_info: {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value: 'Please make sure to carefully review your plan details for more information.',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
        ],
        nodeType: 'document',
      },
    },
    telemedicine_benefit: {
      contentful_name: 'Telemedicine Benefit',
      icon_link: 'https://picwellapppublicdata.s3.amazonaws.com/contentful/insuranceicons/telemed.svg',
      card_title: 'Telemedicine',
      card_description:
        'Telemedicine provides you with the ability to have a doctor’s visit over the phone to diagnose common illnesses and ailments.',
      video_link: 'https://vimeo.com/581347759',
    },
    pet_benefit: {
      contentful_name: 'Pet Benefit',
      icon_link: 'https://picwellapppublicdata.s3.amazonaws.com/contentful/insuranceicons/pet.svg',
      card_title: 'Pet Insurance',
      benefit_page_title: 'Pet Insurance',
      card_description: 'Pet insurance policies can help you cover the cost of veterinary bills.',
      modal_description: {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  'Pet insurance can give you peace of mind by making health costs for your pets more affordable - things like wellness exams, shots, chronic conditions and acute illnesses and injuries.',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
        ],
        nodeType: 'document',
      },
      insurance_info: {
        nodeType: 'document',
        data: {},
        content: [
          {
            nodeType: 'paragraph',
            content: [
              {
                nodeType: 'text',
                value:
                  'Some plans start as low as $1 a day. However, policies are personalized specifically for your pet, which means costs for pet insurance can vary, depending on a few common factors:',
                marks: [],
                data: {},
              },
            ],
            data: {},
          },
          {
            nodeType: 'unordered-list',
            content: [
              {
                nodeType: 'list-item',
                content: [
                  {
                    nodeType: 'paragraph',
                    content: [
                      {
                        nodeType: 'text',
                        value: 'Type of animal, including species',
                        marks: [],
                        data: {},
                      },
                    ],
                    data: {},
                  },
                ],
                data: {},
              },
              {
                nodeType: 'list-item',
                content: [
                  {
                    nodeType: 'paragraph',
                    content: [
                      {
                        nodeType: 'text',
                        value: 'Gender of your pet',
                        marks: [],
                        data: {},
                      },
                    ],
                    data: {},
                  },
                ],
                data: {},
              },
              {
                nodeType: 'list-item',
                content: [
                  {
                    nodeType: 'paragraph',
                    content: [
                      {
                        nodeType: 'text',
                        value: 'Age',
                        marks: [],
                        data: {},
                      },
                    ],
                    data: {},
                  },
                ],
                data: {},
              },
              {
                nodeType: 'list-item',
                content: [
                  {
                    nodeType: 'paragraph',
                    content: [
                      {
                        nodeType: 'text',
                        value: 'Breed',
                        marks: [],
                        data: {},
                      },
                    ],
                    data: {},
                  },
                ],
                data: {},
              },
              {
                nodeType: 'list-item',
                content: [
                  {
                    nodeType: 'paragraph',
                    content: [
                      {
                        nodeType: 'text',
                        value: 'Location',
                        marks: [],
                        data: {},
                      },
                    ],
                    data: {},
                  },
                ],
                data: {},
              },
            ],
            data: {},
          },
          {
            nodeType: 'paragraph',
            content: [
              {
                nodeType: 'text',
                value:
                  'All pet insurance plans use either a percentage of invoice or a benefit schedule to calculate reimbursements.\nPercentage of invoice - reimbursement is based on the treatment cost. You get back a percentage of what you paid your vet.\nBenefit schedule - reimbursement is capped at a set amount for each condition regardless of the actual treatment cost.',
                marks: [],
                data: {},
              },
            ],
            data: {},
          },
          {
            nodeType: 'paragraph',
            content: [
              {
                nodeType: 'text',
                value: 'Please make sure to carefully review your plan details for more information.',
                marks: [],
                data: {},
              },
            ],
            data: {},
          },
        ],
      },
    },
    cancer_benefit: {
      contentful_name: 'Cancer Benefit',
      icon_link:
        'https://picwellapppublicdata.s3.amazonaws.com/contentful/insuranceicons/critical-illness.svg',
      card_title: 'Cancer Insurance',
      benefit_page_title: 'Cancer Insurance',
      card_description:
        'Cancer insurance provides financial protection in case you’re diagnosed with cancer.',
      modal_description: {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  'More than one in three Americans are expected to get cancer in their lifetimes. While that’s certainly not a fun statistic, more and more people are also surviving cancer thanks to advances in modern medicine.',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
        ],
        nodeType: 'document',
      },
      insurance_info: {
        nodeType: 'document',
        data: {},
        content: [
          {
            nodeType: 'paragraph',
            content: [
              {
                nodeType: 'text',
                value:
                  "But fighting cancer is expensive. Medical insurance covers a lot of treatment costs, but you'd almost certainly hit your out-of-pocket maximum for the year. Not to mention the costs of traveling to appointments, lost income from time off work, and all of your usual everyday expenses. That’s where cancer insurance can help.",
                marks: [],
                data: {},
              },
            ],
            data: {},
          },
          {
            nodeType: 'paragraph',
            content: [
              {
                nodeType: 'text',
                value:
                  'If you’re diagnosed with a covered cancer, this type of policy sends you a check that you can use to help cover medical expenses or spend on anything you like. You may also receive additional payments based on the type of treatment you need or even for getting certain preventive care without a diagnosis.',
                marks: [],
                data: {},
              },
            ],
            data: {},
          },
          {
            nodeType: 'paragraph',
            content: [
              {
                nodeType: 'text',
                value:
                  'Important to note: Cancer insurance won’t cover any illness diagnosed before you enroll in a policy. So if you’re thinking of getting this coverage, sooner is probably better.',
                marks: [],
                data: {},
              },
            ],
            data: {},
          },
          {
            nodeType: 'paragraph',
            content: [
              {
                nodeType: 'text',
                value:
                  'These policies also won’t pay benefits for any cancer-related illness you develop over the course of treatment and may include restrictions if you have a recurrence of a previous cancer. Check your plan docs for the details.',
                marks: [],
                data: {},
              },
            ],
            data: {},
          },
        ],
      },
    },
    commuter_benefit: {
      contentful_name: 'Commuter Benefit',
      icon_link: 'https://picwellapppublicdata.s3.amazonaws.com/contentful/insuranceicons/commuter.svg',
      card_title: 'Commuter Benefits',
      card_description:
        'Commuter benefits gives you the opportunity through their employer to use tax-free dollars on commuting costs and keep more of what they earn in every paycheck.',
      modal_description: {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  'Commuter benefit program enables you to use tax-free dollars to pay for a variety of commuting expenses, such as train, ride share, parking, and more.',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
        ],
        nodeType: 'document',
      },
      insurance_info: {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  'There are two types of commuter benefits: transit and parking. You decide how much money you want to set aside each month, but you cannot contribute more than $270 per month to each account.  Since you don’t pay taxes on your contributions, these benefits allow you to save on your commuting expenses.',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value: 'Please make sure to carefully review your plan details for more information.',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
        ],
        nodeType: 'document',
      },
      video_link: 'https://vimeo.com/581347736',
    },
    partner_benefit: {
      contentful_name: '[NCoA] Medicare',
      icon_link: 'https://picwellapppublicdata.s3.amazonaws.com/contentful/insuranceicons/medicare.svg',
      card_title: 'Medicare',
      card_description:
        'Thinking about retiring, turning 65 soon, or looking for information for a loved one? Start learning about Medicare enrollment now and get free, personalized Medicare advice. ',
      modal_description: {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value: `Medicare can be complicated, but you dont have to go it alone. Whether you are a caregiver, turning 65 soon, or planning for retirement the non-profit National Council on Aging is here to help.`,
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
        ],
        nodeType: 'document',
      },
      insurance_info: {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                marks: [
                  {
                    type: 'italic',
                  },
                ],
                value: 'Here’s what you should know',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
          {
            data: {},
            content: [
              {
                data: {},
                content: [
                  {
                    data: {},
                    content: [
                      {
                        data: {},
                        marks: [],
                        value: 'There are options when it comes to Medicare coverage.',
                        nodeType: 'text',
                      },
                    ],
                    nodeType: 'paragraph',
                  },
                ],
                nodeType: 'list-item',
              },
              {
                data: {},
                content: [
                  {
                    data: {},
                    content: [
                      {
                        data: {},
                        marks: [],
                        value:
                          'Enrollment for Medicare is typically only available at certain times of the year.',
                        nodeType: 'text',
                      },
                    ],
                    nodeType: 'paragraph',
                  },
                ],
                nodeType: 'list-item',
              },
              {
                data: {},
                content: [
                  {
                    data: {},
                    content: [
                      {
                        data: {},
                        marks: [],
                        value:
                          'While some people get Medicare automatically, some may have to sign up if they are 65 (or almost 65) and not getting Social Security.',
                        nodeType: 'text',
                      },
                    ],
                    nodeType: 'paragraph',
                  },
                ],
                nodeType: 'list-item',
              },
              {
                data: {},
                content: [
                  {
                    data: {},
                    content: [
                      {
                        data: {},
                        marks: [],
                        value:
                          'Not signing up for Medicare Part B when first eligible can result in a penalty.',
                        nodeType: 'text',
                      },
                    ],
                    nodeType: 'paragraph',
                  },
                ],
                nodeType: 'list-item',
              },
              {
                data: {},
                content: [
                  {
                    data: {},
                    content: [
                      {
                        data: {},
                        marks: [],
                        value: 'Help with Medicare costs may be available.',
                        nodeType: 'text',
                      },
                    ],
                    nodeType: 'paragraph',
                  },
                  {
                    data: {},
                    content: [
                      {
                        data: {},
                        marks: [],
                        value: ' ',
                        nodeType: 'text',
                      },
                    ],
                    nodeType: 'paragraph',
                  },
                ],
                nodeType: 'list-item',
              },
            ],
            nodeType: 'ordered-list',
          },
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  'No matter where you are in your Medicare journey make sure you get the information you need to make the best choices for you and your loved ones. ',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
          {
            data: {},
            content: [
              {
                data: {},
                marks: [
                  {
                    type: 'bold',
                  },
                ],
                value: 'National Council on Aging’s Age Well Planner\nMedicare ',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value: 'Need help finding the right plan for you or a loved one? Get started now: ',
                nodeType: 'text',
              },
              {
                data: {
                  uri: 'https://www.ncoa.org/age-well-planner/landing/medicare-enrollment-help-awp',
                },
                content: [
                  {
                    data: {},
                    marks: [],
                    value: 'https://www.ncoa.org/age-well-planner/landing/medicare-enrollment-help-awp',
                    nodeType: 'text',
                  },
                ],
                nodeType: 'hyperlink',
              },
              {
                data: {},
                marks: [],
                value: '',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
        ],
        nodeType: 'document',
      },
    },
    legal_services_benefit: {
      contentful_name: 'Legal Services',
      icon_link: 'https://picwellapppublicdata.s3.amazonaws.com/legal_services.svg',
      card_title: 'Legal Services',
      card_description:
        'Legal plans are a supplemental benefit that employers offer to provide employees access to a network of experienced attorneys. ',
      insurance_info: {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  'A legal plan provides access to a network of attorneys. You can visit any of these Network Attorneys and the costs will be fully covered by your legal plan. Just like your other benefits, you pay for this service through easy payroll deductions on a monthly basis. ',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  'There are various instances in which you may need the advice or assistance of a qualified legal professional. For example, if you are buying or selling a home and are in need of contracts, deeds, or purchase agreements. Or, if you are starting a family and want to create a will or need help with estate planning documents. A legal plan can even provide you with access to an attorney to assist with traffic tickets or identity theft protection. Identity theft occurs when an individual uses someone else’s name or personally identifying information to commit fraud or other crimes. ',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  'Most frequent legal issues are typically covered in these plans but every plan can be different so it’s important to check the plan details to confirm if your matter will be covered. ',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  'A legal plan can be a great option as it provides affordable access to quality legal care for a fraction of the cost of retaining an attorney on your own.  ',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
        ],
        nodeType: 'document',
      },
      video_link: 'https://vimeo.com/585477415 ',
    },
    employee_assistant_program_benefit: {
      contentful_name: 'EAP',
      icon_link: 'https://picwellapppublicdata.s3.amazonaws.com/EAP.svg',
      card_title: 'Employee Assistance Program',
      card_description:
        'An Employee Assistance Program, or EAP, is benefit designed to help employees resolve various issues related to mental and emotional well-being that may be affecting job performance.',
      insurance_info: {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  'An EAP is typically provided free of charge by your employer. Your employer will provide you with a phone number you can call to discuss any issues you might be experiencing in your personal or professional life. You can use the EAP as much as you need without incurring costs for use.',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  'Depending on the type of EAP, you may receive counseling or advice directly over the phone or through another form of communication. Some EAPs provide referrals to professionals that can help you with whatever situation you are facing.',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  'It’s important to note that while accessing EAP services are free, you may incur some costs if you are provided a referral to a health care professional. EAPs are designed to be a support system for employees and can be very helpful for many employees throughout their tenure with an organization. ',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
        ],
        nodeType: 'document',
      },
      video_link: 'https://vimeo.com/585477420',
    },
    wellness_benefit: {
      contentful_name: 'Wellness Benefits',
      icon_link: 'https://picwellapppublicdata.s3.amazonaws.com/wellness.svg',
      card_title: 'Wellness Benefit',
      card_description:
        'Wellness benefits programs are designed to help improve the overall health and wellbeing of employees, and they focus on reducing illness and improving quality of life.',
      insurance_info: {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  'Wellness benefits can include a variety of services that promote improved physical, emotional and mental health of employees. These types of benefits can include health screenings, smoking cessation programs, fitness contests, or stress management programs. Some will even include reimbursements for gym memberships, diet plans, or fitness trackers and they may provide incentives for reaching fitness goals.',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  'Wellness programs can have several positive benefits. These programs, which can help you take proactive steps to improve your physical and mental health can lower stress, increase your day-to-day productivity and they can also help you save money in the long run, since better health can translate to lower health costs in the long run.',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  'The types of benefits included in a wellness plan will depend on the specific plan, so make sure to review your plan details. It’s a great idea to find out what might be available to you specifically so you can start improving your health and wellness today.',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
        ],
        nodeType: 'document',
      },
      video_link: 'https://vimeo.com/585477443',
    },
    financial_wellness_benefit: {
      contentful_name: 'Financial Wellness Benefits',
      icon_link: 'https://picwellapppublicdata.s3.amazonaws.com/financial_wellness.svg',
      card_title: 'Financial Wellness Benefits',
      card_description:
        'Financial wellness benefits include a range of services and are designed to help employees improve their financial standings.',
      insurance_info: {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  'Financial wellness is a huge issue that affects many people every day. If you’ve ever been in financial trouble you know how stressful it can be.',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  'The term Financial wellness refers to the total picture of your financial situation and how it affects not only your stress levels but also your physical and mental health. When you are stressed out about your finances, it’s hard to focus on anything else. When you have good financial wellness, you have full control over your day-to-day finances, have enough savings to handle unexpected expenses, and are on track with your retirement and savings goals.',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  'Financial wellness benefits include a range of services. Counseling and coaching services can provide you with 1-on-1 advice about ways to improve your financial wellness, and a financial specialist can help you take a broad look at your finances and work with you on a plan to improve them. These experts can help you create a budget, stick to it, and evaluate your success. ',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  'Since financial wellness benefits can cover a multitude of services, it’s best to check your plan details to see what is offered.',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
        ],
        nodeType: 'document',
      },
      video_link: 'https://vimeo.com/585477476',
    },
    combined_life_and_add_benefit: {
      contentful_name: 'Life/AD&D (combined)',
      icon_link: 'https://picwellapppublicdata.s3.amazonaws.com/contentful/insuranceicons/life.svg',
      card_title: 'Life / AD&D',
      card_description:
        'Life and AD&D insurance are benefits that can help protect your loved ones from financial hardship due to death or serious injury.',
      insurance_info: {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  "​​We hope that we will always be around to provide for our loved ones, but life insurance and Accidental Death and Dismemberment (AD&D) insurance give you ways to plan for the worst, giving your dependents funds to cover costs if you pass away or are seriously injured. Both of these benefits are important tools that you can use to ensure your family's financial security.",
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  'Life insurance policies pay out a "death benefit" that comes in the form of a single, lump sum payment if you pass away. You can pick the amount of life insurance that you want to buy, so you can plan to have enough to cover your family\'s financial obligations if you are no longer around. Accidental Death & Dismemberment (AD&D) insurance will pay you or your beneficiary a lump sum if you die or are dismembered in an accident. When you purchase an AD&D policy, you may be able to select the amount of coverage, which determines the amount that you or your beneficiary will receive in the event of accidental death or dismemberment. If you have both a life insurance policy and an AD&D policy, your beneficiaries would receive full payouts from both policies in the event of a fatal accident. If you have an accident that results in loss of eyesight, speech, hearing, or a limb, that amount of the payout will depend on the nature of the dismemberment.',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  'While Life and AD&D insurance is often offered together, premiums may vary depending on the amount of coverage. Please make sure to carefully review your plan details for more information.',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
        ],
        nodeType: 'document',
      },
      video_link: 'https://vimeo.com/499286311',
    },
    four_o_three_benefit: {
      contentful_name: '403(b) Benefit',
      icon_link: 'https://picwellapppublicdata.s3.amazonaws.com/contentful/insuranceicons/retirement.svg',
      card_title: '403(b)',
      card_description:
        '403(b) plans let you save and invest a portion of your paycheck before taxes are taken out.',
      modal_description: {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  'Retirement savings are critical to your long term financial wellness plan, and tax-preferred retirement savings accounts can help you get the most out of your retirement contributions.',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
        ],
        nodeType: 'document',
      },
      insurance_info: {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  'Tax-preferred retirement savings accounts allow you to save on taxes, compared to other investment accounts. These tax savings can make big differences in your long-term financial health.',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  'Tax-preferred retirement accounts can allow you to save on taxes either when you make your contribution, allowing you to contribute to your account with pre-tax dollars, or when you withdraw the contribution, allowing you to avoid paying taxes when you withdraw funds in retirement.',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
        ],
        nodeType: 'document',
      },
      video_link: 'https://vimeo.com/572706162',
    },
    educational_benefits: [],
  },
};
