import { all, call, ForkEffect, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import { GO_GUEST_ID } from 'Containers/App/constants';
import { makeSelectConfigField } from 'Containers/App/selectors';
import { getCookie } from 'Containers/App/utils';
import { Election, EnteredPlan } from 'Containers/CommercialRoutes/types';
import { GET_SUPPLEMENTAL_PLANS_REQUEST } from 'Containers/OverviewPage/constants';
import { getXhrSupplementalPlans } from 'Containers/OverviewPage/saga';
import { makeGetSupplementalPlanElections } from 'Containers/OverviewPage/selectors';
import { getIncentiveSurveyFailure, setResultPageIncentiveSurvey } from 'Containers/ProfilePage/actions';
import {
  CREATE_OR_UPDATE_HOUSEHOLD_SUCCESS,
  UPDATE_HOUSEHOLD_FROM_RESULT_PAGE,
} from 'Containers/ProfilePage/constants';
import { getXhrIncentiveSurveyRequest } from 'Containers/ProfilePage/saga';
import {
  makeGetActiveHousholdMembers,
  makeGetHealthPlanMemberIds,
  makeGetHousehold,
  makeGetHouseholdId,
  makeGetSelectedEnrollmentEventId,
  makeGetSelectedPublicationInfo,
  makeSelectProfileField,
} from 'Containers/ProfilePage/selectors';
import { REVIEW_PAGE_LOADED } from 'Containers/ReviewPage/constants';
import { postXhrContributions } from 'Containers/TaxSavingsPage/saga';
import { Household, Recommendation } from 'Types/entities';
import {
  apiCreateEphemeralPlan,
  apiJVPPutElections,
  enroll,
  getHouseholdIdByExternalId,
  getIncentiveSurveyQuestion,
  getMarketplacePeopleLikeYou,
  getMarketplaceRecommendations,
  getPeopleLikeYou,
  getRecommendations,
  postIncentiveSurvey,
} from 'Utils/api';
import * as API_TYPES from 'Utils/apiTypes';
import { JVPBootstrapPublicationInfo } from 'Utils/apiTypes';
import Logger from 'Utils/logger';

import {
  getHouseholdIdByExternalIdError,
  getHouseholdIdByExternalIdSuccess,
  getRecommendationsFailure,
  getRecommendationsSuccess,
  getResultsPageData,
  peopleLikeYouFailure,
  peopleLikeYouSuccess,
  postIncentiveSurveyFailure,
  postIncentiveSurveySuccess,
  selectHealthPlanFailure,
  selectHealthPlanSuccess,
} from './actions';
import {
  GET_RECOMMENDATIONS_EPHEMERAL_REQUEST,
  GET_RESULTS_PAGE_DATA,
  SELECT_HEALTH_PLAN,
  SELECT_HEALTH_PLAN_SUCCESS,
  SET_SELECTED_MEMBER_IDS,
} from './constants';
import { makeGetHealthPlanElection, makeSelectPlanSelectionId } from './selectors';

export function* postXhrIncentiveSurvey() {
  const householdId = yield select(makeGetHouseholdId());
  const enrollmentEventId = yield select(makeGetSelectedEnrollmentEventId());
  const profileIncentiveSurveyAnswers = yield select(makeSelectProfileField('incentiveSurveyAnswers'));
  const { incentiveSurveyAnswers } = yield select(makeSelectProfileField('resultPageIncentiveSurvey'));

  const answers =
    Object.keys(incentiveSurveyAnswers).length > 0 ? incentiveSurveyAnswers : profileIncentiveSurveyAnswers;

  try {
    const resp: API_TYPES.PostIncentiveSurveyResponse = yield call(
      postIncentiveSurvey,
      householdId,
      enrollmentEventId,
      answers,
    );
    // Firing this action doesn't actually do anything; response just contains the payload we sent
    yield put(postIncentiveSurveySuccess(resp));
  } catch (err: unknown) {
    yield put(postIncentiveSurveyFailure(err as Error));
  }
}

export function* getXhrRecommendations(ephemeralPlanIdAndPlan?: {
  ephemeralPlanId: string;
  ephemeralPlan: EnteredPlan;
}) {
  const householdId = yield select(makeGetHouseholdId());

  const coveredMemberIds = yield select(makeGetHealthPlanMemberIds());

  const planSelectionId = yield makeSelectPlanSelectionId(); // TODO: wrap in select() once we store plan_selection_id in redux and update selector
  const enrollmentEventId = yield select(makeGetSelectedEnrollmentEventId());

  const publicationInfo: JVPBootstrapPublicationInfo = yield select(makeGetSelectedPublicationInfo());
  const publicationId = publicationInfo?.publication?.jv_publication_uuid;

  try {
    const resp: API_TYPES.GetRecommendationsResponse = yield call(
      getRecommendations,
      householdId,
      coveredMemberIds,
      ephemeralPlanIdAndPlan,
      planSelectionId,
      enrollmentEventId,
      publicationId,
    );
    yield put(getRecommendationsSuccess(resp, true));
  } catch (err: unknown) {
    yield put(getRecommendationsFailure(err as Error));
  }
}

export function* getXhrMarketplaceRecommendations(household: Household) {
  try {
    const resp: API_TYPES.GetRecommendationsResponse = yield call(getMarketplaceRecommendations, household);
    yield put(getRecommendationsSuccess(resp));
  } catch (err: unknown) {
    yield put(getRecommendationsFailure(err as Error));
  }
}

export function* getXhrPeopleLikeYou() {
  const enrollmentEventId = yield select(makeGetSelectedEnrollmentEventId());
  const householdId = yield select(makeGetHouseholdId());

  try {
    const resp: API_TYPES.PeopleLikeYou = yield call(getPeopleLikeYou, householdId, enrollmentEventId);
    yield put(peopleLikeYouSuccess(resp));
  } catch (err: unknown) {
    yield put(peopleLikeYouFailure(err as Error));
  }
}

export function* getXhrMarketplacePeopleLikeYou(household: Household) {
  try {
    const resp: API_TYPES.PeopleLikeYou = yield call(getMarketplacePeopleLikeYou, household);
    yield put(peopleLikeYouSuccess(resp));
  } catch (err: unknown) {
    yield put(peopleLikeYouFailure(err as Error));
  }
}

export function* getXhrHouseholdIdByExternalId() {
  try {
    const externalId = getCookie(GO_GUEST_ID);
    const enrollmentEventId = yield select(makeGetSelectedEnrollmentEventId());

    const resp: { household_id: string } = yield call(
      getHouseholdIdByExternalId,
      externalId,
      enrollmentEventId,
    );
    const householdId = resp?.household_id;
    yield put(getHouseholdIdByExternalIdSuccess(householdId));
    return householdId;
  } catch (err) {
    yield put(getHouseholdIdByExternalIdError(err as Error));
    return err;
  }
}

export function* createEphemeralPlan(plan: EnteredPlan) {
  try {
    const resp: API_TYPES.EphemeralPlanResponse = yield call(apiCreateEphemeralPlan, plan);
    return resp;
  } catch (err) {
    return err;
  }
}

export function* getXhrEphemeralResultPageData(action: { type: string; plan: EnteredPlan }) {
  const planResp = yield call(createEphemeralPlan, action.plan);
  const ephemeralPlanId = planResp.planId;

  yield call(getXhrRecommendations, {
    ephemeralPlanId,
    ephemeralPlan: action.plan,
  });
}

export function* getXhrSelectedMembersResultPageData() {
  yield put(getResultsPageData());
}

export function* getXhrResultPageData() {
  const isMarketplace = yield select(makeSelectConfigField('is_marketplace'));
  const isPlyEnabled = yield select(makeSelectConfigField('is_ply_enabled'));

  if (isMarketplace) {
    const household = yield select(makeGetHousehold());

    if (isPlyEnabled) {
      yield all([
        call(getXhrMarketplaceRecommendations, household),
        call(getXhrMarketplacePeopleLikeYou, household),
      ]);
    } else {
      yield call(getXhrMarketplaceRecommendations, household);
    }
  } else {
    yield call(getRecommendationsAndOrPeopleLikeYou);
  }
}

export function* getRecommendationsAndOrPeopleLikeYou() {
  const isPlyEnabled = yield select(makeSelectConfigField('is_ply_enabled'));
  const householdId = yield select(makeGetHouseholdId());
  const builderCustomerKey: string = yield select(makeSelectConfigField('builder_customer_key'));

  if (householdId) {
    const calls = builderCustomerKey
      ? [call(postXhrIncentiveSurvey), call(getXhrRecommendations)]
      : [call(getXhrRecommendations)];

    if (isPlyEnabled) {
      calls.push(call(getXhrPeopleLikeYou));
    }
    yield all(calls);
  } else {
    yield put(
      getRecommendationsFailure(
        new Error('getRecommendationsAndOrPeopleLikeYou failure: missing household id'),
      ),
    );
  }
}

export function* postXhrEnrollment(action: { plan: Recommendation }) {
  try {
    // Get previously stored household id and send it with request if it exists
    const enrollmentEventId = yield select(makeGetSelectedEnrollmentEventId());
    const householdId = yield select(makeGetHouseholdId());

    const resp: API_TYPES.UpdateHouseholdResponse = yield call(
      enroll,
      action?.plan?.plan?.external_id || action?.plan?.plan?.external_plan_id || '',
      householdId,
      enrollmentEventId,
    );
    yield put(selectHealthPlanSuccess(resp));
  } catch (err: unknown) {
    yield put(selectHealthPlanFailure(err as Error));
  }
}

export function* handleSelectHealthPlan(payload) {
  yield all([call(postXhrEnrollment, payload)]);
}

export function* getIncentiveQuestionResultPage() {
  try {
    const householdId = yield select(makeGetHouseholdId());
    const enrollmentEventId = yield select(makeGetSelectedEnrollmentEventId());
    const activeHouseholdMembers = yield select(makeGetActiveHousholdMembers());
    const resp: API_TYPES.IncentiveSurveyResponse = yield call(
      getIncentiveSurveyQuestion,
      householdId,
      enrollmentEventId,
      activeHouseholdMembers,
    );
    yield put(setResultPageIncentiveSurvey(resp));
  } catch (err) {
    yield put(getIncentiveSurveyFailure(err as Error));
  }
}

export function* putXhrElections(payload) {
  const healthPlanElection = yield select(makeGetHealthPlanElection());
  const isReviewPageLoaded = payload.type === REVIEW_PAGE_LOADED;
  const supplementalPlanElections = yield select(makeGetSupplementalPlanElections(isReviewPageLoaded));
  const planElections: Election[] = [...healthPlanElection, ...supplementalPlanElections];
  const enrollmentId = yield select(makeGetSelectedEnrollmentEventId());

  try {
    yield call(apiJVPPutElections, enrollmentId, planElections);
  } catch (err: unknown) {
    Logger.log(`Failed to send plan elections to JVP API: `, err);
  }
}

// Individual exports for testing
export default function* getCommercialV2Saga() {
  const builderCustomerKey = yield select(makeSelectConfigField('builder_customer_key'));

  const sagas: ForkEffect[] = [];
  sagas.push(takeEvery(GET_RESULTS_PAGE_DATA, getXhrResultPageData));
  sagas.push(takeEvery(GET_RECOMMENDATIONS_EPHEMERAL_REQUEST, getXhrEphemeralResultPageData));
  sagas.push(takeEvery(SELECT_HEALTH_PLAN, handleSelectHealthPlan));
  sagas.push(takeEvery(SET_SELECTED_MEMBER_IDS, getXhrSelectedMembersResultPageData));
  sagas.push(takeLatest(GET_SUPPLEMENTAL_PLANS_REQUEST, getXhrSupplementalPlans));
  sagas.push(takeEvery(SET_SELECTED_MEMBER_IDS, getXhrSelectedMembersResultPageData));
  sagas.push(takeEvery(UPDATE_HOUSEHOLD_FROM_RESULT_PAGE, getIncentiveQuestionResultPage));

  // JVP EXCLUSIVE CALLS -- NO LEGACY PICWELL CLIENTS
  if (builderCustomerKey) {
    sagas.push(takeEvery(CREATE_OR_UPDATE_HOUSEHOLD_SUCCESS, getXhrIncentiveSurveyRequest));
    sagas.push(takeEvery(SELECT_HEALTH_PLAN, postXhrContributions));
    sagas.push(takeEvery(SELECT_HEALTH_PLAN_SUCCESS, putXhrElections));
    sagas.push(takeEvery(REVIEW_PAGE_LOADED, putXhrElections));
  }

  yield all(sagas);
}
