/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from 'redux';

import globalReducer, { GlobalAppReducerState } from 'Containers/App/reducer';
import commercialAppReducer, { CommercialRoutesReducerState } from 'Containers/CommercialRoutes/reducer';
import hsaPageReducer from 'Containers/HsaPage/reducer';
import { HsaPageReducerState } from 'Containers/HsaPage/types';
import overviewPageReducer, { OverviewPageReducerState } from 'Containers/OverviewPage/reducer';
import profilePageReducer, { IProfilePageReducerState } from 'Containers/ProfilePage/reducer';
import reviewPageReducer from 'Containers/ReviewPage/reducer';
import taxSavingsPageReducer from 'Containers/TaxSavingsPage/reducer';
import { TaxSavingsPageReducerState } from 'Containers/TaxSavingsPage/types';

export type GlobalReducerState = {
  globalApp: GlobalAppReducerState;
  profilePage: IProfilePageReducerState;
  commercialApp: CommercialRoutesReducerState;
  hsaPage: HsaPageReducerState;
  taxSavingsPage: TaxSavingsPageReducerState;
  overviewPage: OverviewPageReducerState;
  reviewPage: {
    feedbackSubmitted: boolean;
  };
};

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export default function createReducer(injectedReducers = {}) {
  const rootReducer = combineReducers({
    globalApp: globalReducer,
    // load these reducers optimistically because data in these reducers are used in selectors that spans multiple reducers
    profilePage: profilePageReducer,
    overviewPage: overviewPageReducer,
    hsaPage: hsaPageReducer,
    taxSavingsPage: taxSavingsPageReducer,
    reviewPage: reviewPageReducer,
    commercialApp: commercialAppReducer,
    ...injectedReducers,
  });

  return rootReducer;
}
