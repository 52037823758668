import { ContentfulToolTipsSection } from 'ContentfulDefaults/types/toolTips';

export const toolTips: {
  tool_tips: ContentfulToolTipsSection;
} = {
  tool_tips: {
    contentful_title: 'Tool Tips (DEFAULT DO NOT CHANGE)',
    picwell_score: {
      text: 'Your Picwell Score rates the value that each plan provides after accounting for premiums, plan details, your preferences, and the predicted health care cost and risk of people like you',
      title: 'Your Picwell Score',
      underline: 'Your Picwell Score',
    },
    pay_period: {
      title: 'Pay Period',
      weekly: {
        text: 'Weekly: Every week on a specific day of the week (52 payrolls a year). Example: every Friday.',
        underline: '/wk',
      },
      monthly: {
        text: 'Monthly: Every month on a specific date of the month ({x} payrolls per year). Example: on the 26th.',
        underline: '/mo',
      },
      biWeekly: {
        text: 'Bi-Weekly: Every two weeks on a specific day of the week (26 payrolls per year). Example: every other Friday.',
        underline: '/bwk',
      },
      semiMonthly: {
        text: 'Semi-Monthly: Twice per month on two specific dates of the month (24 payrolls per year). Example: the 15th and the last day of the month.',
        underline: '/sm',
      },
      yearly: {
        text: 'Yearly: Total amount in a year.',
        underline: '/yr',
      },
      perPaycheck: {
        text: 'Per paycheck: every time you are paid. {x} payrolls per year.',
        underline: '/paycheck',
      },
    },
    number_with_pay_period: {
      yearly: {
        text: 'Total amount in a year.',
        title: 'Yearly',
        underline: '/yr',
      },
      pay_period: {
        title: 'Pay Period',
        weekly: {
          text: 'Weekly: Every week on a specific day of the week (52 payrolls a year). Example: every Friday.',
          underline: '/wk',
        },
        yearly: {
          text: 'Yearly: Total amount in a year.',
          underline: '/yr',
        },
        monthly: {
          text: 'Monthly: Every month on a specific date of the month ({x} payrolls per year). Example: on the 26th.',
          underline: '/mo',
        },
        biWeekly: {
          text: 'Bi-Weekly: Every two weeks on a specific day of the week (26 payrolls per year). Example: every other Friday.',
          underline: '/bwk',
        },
        semiMonthly: {
          text: 'Semi-Monthly: Twice per month on two specific dates of the month (24 payrolls per year). Example: the 15th and the last day of the month.',
          underline: '/sm',
        },
      },
    },
    hsa: {
      text: "HSAs are special accounts that allow you to save and invest money to pay for health care expenses. You never pay taxes on HSA contributions, earnings or withdrawals, and this 'triple-tax advantage' makes them the single best way to save for your health care costs both now and in the future. Individuals can contribute up to {x} per year (individuals age 55 or older may be eligible to make a catch-up contribution of $1,000), and families can contribute up to {x} per year. Once you save up enough to reach your HSA administrator's minimum savings requirement, you can invest any excess funds that you accumulate.",
      title: 'Health Savings Account',
      underline: 'Health Savings Account (HSA)',
    },
    recommended_hsa_contribution: {
      title: 'Recommended HSA Contribution',
      text: 'This is how much we recommend you contribute to your HSA this year.',
      underline: 'Recommended HSA Contribution',
    },
    estimated_yearly_total: {
      text: {
        p1: 'Yearly Premiums (Premiums x {x})',
        p2: ' + Out-of-Pocket Estimate',
      },
      title: 'Estimated Yearly Total =',
      underline: 'Est. Yearly Total',
    },
    premium: {
      text: 'The amount that you pay {x} for your health insurance plan.',
      title: 'Premium',
      underline: 'Premium',
    },
    annual_premium: {
      text: 'The amount that you pay annually for your health insurance plan.',
      title: 'Yearly Premium',
      underline: 'Yearly Premium',
    },
    out_of_pocket_estimate: {
      text: 'The health care costs that the insurance company does not pay for. In other words, when you get a medical bill, this is the amount that you are responsible for.',
      title: 'Out-of-Pocket Estimate',
      underline: 'Out-of-Pocket',
    },
    yearly_estimate_greater_than_out_of_pocket_limit: {
      text: "Your estimated Out-of-Pocket (OOP) costs are higher than this plan's OOP Maximum. Cost estimates can exceed the OOP Maximum when people like you are likely to use health care services or prescriptions that this plan does not cover.  Your costs will not exceed the OOP Maximum as long as you only get medical care or prescriptions that are in-network and covered by this plan.",
      title: 'Note:',
    },
    striked_oop_estimate: {
      text: "This crossed out number is the price before taking into account your employer's {x} contribution",
      underline: '(WHY?)',
    },
    deductible: {
      note: 'Note: This deductible value has the following breakdown - Medical Deductible: {medicalDeductible} and Drug Deductible: {drugDeductible}',
      text: 'The amount that you are responsible for paying before your insurance plan will start covering some of your health care costs. After you have met the deductible, you will pay a copayment or coinsurance for subsequent health care that you receive.',
      title: 'Deductible',
      underline: 'Deductible',
    },
    out_of_pocket_max: {
      note: 'Note: This OOP limit value has the following breakdown - Medical OOP Limit: {medicalMax} and Drug OOP Limit: {drugMax}',
      text: 'The most that you will pay for covered health care in a year. Once you have paid up to the OOP limit, your insurance pays for 100% of any additional covered health care costs that you incur.',
      title: 'Out-of-Pocket Limit (Max)',
      underline: 'OOP Max',
    },
    primary_care_copay: {
      text: 'The dollar amount that you pay when you go to your primary care provider to treat an illness or injury. Note that primary care visits for routine preventive care may have a different copayment.',
      title: 'Primary Care Copay',
      underline: 'Primary Care Copay',
    },
    primary_care_coinsurance: {
      text: 'The percentage of the total cost that you pay when you go to your primary care provider to treat an illness or injury. Your insurance company pays the remaining amount. Note that primary care visits for routine preventive care may have a different copayment.',
      title: 'Primary Care Coinsurance',
      underline: 'Primary Care Coinsurance',
    },
    specialist_care_copay: {
      text: 'The dollar amount that your pay when you go to a specialist.',
      title: 'Specialist Care Copay',
      underline: 'Specialist Care Copay',
    },
    specialist_care_coinsurance: {
      text: 'The percentage of the total cost that you pay when you go to a specialist. Your insurance company pays the remaining amount.',
      title: 'Specialist Care Coinsurance',
      underline: 'Specialist Care Coinsurance',
    },
    generic_drug_copay: {
      text: 'The dollar amount that you pay for a generic or Tier 1 prescription. Copayments typically are associated with a one month prescription.',
      title: 'Generic Drug Copay',
      underline: 'Generic Drug Copay',
    },
    generic_drug_coinsurance: {
      text: 'The percentage of the total cost of a generic or Tier 1 prescription that you pay for. Your insurance company pays the remaining amount.',
      title: 'Generic Drug Coinsurance',
      underline: 'Generic Drug Coinsurance',
    },
    brand_drug_copay: {
      text: 'The dollar amount that you pay for a brand name or Tier 2 prescription. Copayments typically are associated with a one month prescription.',
      title: 'Brand Drug Copay',
      underline: 'Brand Drug Copay',
    },
    brand_drug_coinsurance: {
      text: 'The percentage of the total cost of a brand name or Tier 2 prescription that you pay for. Your insurance company pays the remaining amount.',
      title: 'Brand Drug Coinsurance',
      underline: 'Brand Drug Coinsurance',
    },
    non_formulary_drug_copay: {
      text: 'The dollar amount that you pay for a prescription that is not included on your health plan’s formulary. Copayments typically are associated with a one month prescription.',
      title: 'Non-formulary Drug Copay',
      underline: 'Non-formulary Drug Copay',
    },
    non_formulary_drug_coinsurance: {
      text: 'The percentage of the total cost that you pay for a prescription that is not included on your health plan’s formulary. Your insurance company pays the remaining amount.',
      title: 'Non-formulary Drug Coinsurance',
      underline: 'Non-formulary Drug Coinsurance',
    },
    specialty_drug_copay: {
      text: 'The dollar amount that you pay for a specialty or Tier 3 prescription. Copayments typically are associated with a one month prescription.',
      title: 'Specialty Drug Copay',
      underline: 'Specialty Drug Copay',
    },
    specialty_drug_coinsurance: {
      text: 'The percentage of the total cost of a specialty or Tier 3 prescription that you pay for. Your insurance company pays the remaining amount.',
      title: 'Specialty Drug Coinsurance',
      underline: 'Specialty Drug Coinsurance',
    },
    tier1_deductible: {
      text: 'The deductible that applies to health care that you receive from Tier 1 providers.',
      title: 'Tier 1 Deductible',
      underline: 'Tier 1 Deductible',
    },
    tier2_deductible: {
      text: 'The deductible that applies to health care that you receive from Tier 2 providers.',
      title: 'Tier 2 Deductible',
      underline: 'Tier 2 Deductible',
    },
    in_network_deductible: {
      text: 'The deductible that applies to health care that you receive from in-network providers.',
      title: 'In-Network Deductible',
      underline: 'In-Network Deductible',
    },
    out_of_network_deductible: {
      text: 'The deductible that applies to health care that you receive from out-of-network providers.',
      title: 'Out-of-Network Deductible',
      underline: 'Out-of-Network Deductible',
    },
    combined_network_deductible: {
      text: 'The deductible that applies to health care that you receive from providers.',
      title: 'Deductible',
      underline: 'Deductible',
    },
    in_network_out_of_pocket_limit: {
      text: 'The most that you will pay in a year for covered health care that you receive from in-network providers. Once you have paid up to this limit, your insurance pays for 100% of any additional covered, in-network health care costs that you incur.',
      title: 'In-Network Out-of-Pocket Limit',
      underline: 'In-Network Out-of-Pocket Limit',
    },
    out_of_network_out_of_pocket_limit: {
      text: 'The most that you will pay in a year for covered health care that you receive from out-of-network providers. Once you have paid up to this limit, your insurance pays for 100% of any additional covered, out-of-network health care costs that you incur.',
      title: 'Out-of-Network Out-of-Pocket Limit',
      underline: 'Out-of-Network Out-of-Pocket Limit',
    },
    combined_network_out_of_pocket_limit: {
      text: 'The most that you will pay in a year for covered health care that you receive from providers. Once you reach the limit, your insurance pays for 100% of any additional covered costs.',
      title: 'Out-of-Pocket Limit',
      underline: 'Out-of-Pocket Limit',
    },
    family_threshold: {
      text: 'Your summary of benefits may contain values for both "individual" and "family" thresholds. Enter the values for "family" threshold below.',
      title: 'Family threshold',
    },
    hra: {
      text: 'A Health Reimbursement Arrangement (HRA) is a spending account that your employer sets up to help pay for medical expenses that your insurance doesn’t cover. If your employer set up an HRA, you can submit receipts for medical bills that you have paid and your employer will reimburse those charges up until you have used up all of your allocated funds. Any HRA funds that you do not use go back to your employer at the end of the year, but your employer may allow you to carry over some portion of your HRA to the following year.',
      title: 'Health Reimbursement Account',
      underline: 'Health Reimbursement Account (HRA)',
    },
    remaining_oop: {
      text: 'This is the amount Picwell predicts you will have to spend out of  pocket (less premiums) on healthcare expenses.',
      title: 'Remaining Out-of-Pocket',
    },
    account_eligibility: {
      text: 'Some health plans may be eligible for special accounts that can help you pay for your medical care.',
      title: 'Account Eligibility',
    },
  },
};
