import React, { useEffect, useRef, useState } from 'react';

import { getAddTagList } from 'Containers/ViewBenefitPage/ExpandablePlanCard/addContent';
import { getDentalTagList } from 'Containers/ViewBenefitPage/ExpandablePlanCard/dentalContent';
import FbsDetail from 'Containers/ViewBenefitPage/ExpandablePlanCard/FbsDetail';
import HiddenContent from 'Containers/ViewBenefitPage/ExpandablePlanCard/HiddenContent/HiddenContent';
import { getHospitalIndemnityTagList } from 'Containers/ViewBenefitPage/ExpandablePlanCard/hospitalIndemnityContent';
import { getLifeTagList } from 'Containers/ViewBenefitPage/ExpandablePlanCard/lifeContent';
import { BenefitItem, ListItem } from 'Containers/ViewBenefitPage/ExpandablePlanCard/PlanContent/styled';
import { getRetirementTagList } from 'Containers/ViewBenefitPage/ExpandablePlanCard/retirementContent';
import { getVisionTagList } from 'Containers/ViewBenefitPage/ExpandablePlanCard/visionContent';
import {
  AddBenefits,
  DentalBenefits,
  HospitalIndemnityBenefits,
  LifeBenefits,
  RetirementBenefits,
  VisionBenefits,
} from 'Containers/ViewBenefitPage/types';
import { transformBenefitItems } from 'Containers/ViewBenefitPage/utils';
import { ContentfulSupplementalPlan } from 'ContentfulDefaults/types/benefitsAdditional';
import { useTextContext } from 'Contexts/textContext';
import { Button, Paragraph } from 'DesignLibrary/atoms';
import Text from 'Shared/Text';
import { Benefits, SupplementalPlan } from 'Types/entities';
import { sendInsightsEvent } from 'Utils/insights';

interface SupplementalPlanCoveredBenefitsProps {
  plan: SupplementalPlan;
  contentfulSupplementalPlan?: ContentfulSupplementalPlan;
  normalizedBenefitData: Benefits;
  showFbsCardDetails: boolean;
  builderCustomerKey: string | null;
}

export const SupplementalPlanCoveredBenefits = ({
  plan,
  contentfulSupplementalPlan,
  normalizedBenefitData,
  showFbsCardDetails,
  builderCustomerKey,
}: SupplementalPlanCoveredBenefitsProps) => {
  const [isHiddenOpen, setHiddenOpen] = useState(false);

  const coveredBenefitsRef = useRef<HTMLDivElement>(null);
  const { retrieveContentfulData } = useTextContext();

  useEffect(() => {
    if (isHiddenOpen && coveredBenefitsRef.current) {
      coveredBenefitsRef.current.focus();
    }
  }, [isHiddenOpen]);

  const toggleHiddenOpen = (e) => {
    // send insights event if opening plan details
    if (!isHiddenOpen) {
      sendInsightsEvent(e, 'show_supplemental_plan_details', {
        plan_type: plan.plan_type,
        plan,
      });
    }

    setHiddenOpen((prevState) => !prevState);
  };

  const getHideButtonLabel = () => {
    const pre = 'benefits_section_additional.accessibility';
    const labelKey = isHiddenOpen ? 'collapse' : 'expand';

    return retrieveContentfulData<string>(`${pre}.${labelKey}_benefit_details`).replace(/{x}/, plan.name);
  };

  const getBenefitsList = () => {
    if (contentfulSupplementalPlan?.benefit_items && contentfulSupplementalPlan?.benefit_items.length > 0) {
      return contentfulSupplementalPlan?.benefit_items.map((bi, idx) => (
        <BenefitItem key={idx}>{bi.split(':')[0]}</BenefitItem>
      ));
    }

    let tags: JSX.Element[] = normalizedBenefitData.items.map((i, idx) => (
      <FbsDetail key={idx} type={i.item.type} data={i.item.data} />
    ));
    const benefits = transformBenefitItems(plan);

    switch (plan.plan_type) {
      case 'add':
        tags = getAddTagList(benefits as AddBenefits);
        break;
      case 'dental':
        tags = getDentalTagList(benefits as DentalBenefits);
        break;
      case 'vision':
        tags = getVisionTagList(benefits as VisionBenefits);
        break;
      case 'hospital':
        tags = getHospitalIndemnityTagList(benefits as HospitalIndemnityBenefits);
        break;
      case 'life':
        tags = getLifeTagList(benefits as LifeBenefits);
        break;
      case '401k':
      case '403b':
      case 'retirement':
        tags = getRetirementTagList(benefits as RetirementBenefits);
        break;
      default:
        break;
    }

    return (
      <>
        {tags.slice(0, 7).map((tag, idx) => (
          <BenefitItem key={idx}>{tag}</BenefitItem>
        ))}
        {tags.length >= 7 && <BenefitItem>...</BenefitItem>}
      </>
    );
  };

  const renderCoverageHeader = () => {
    if (plan.plan_type === 'dental' || plan.plan_type === 'vision') {
      return <Text field="benefits_section_additional.coverage_details_header" />;
    }
    // Only render for real HI modules, not text-only modules
    if (plan.plan_type === 'hospital' && plan.benefits.items.length > 0) {
      return <Text field="benefits_section_hospital_indemnity.covered_services" />;
    }
    return <Text field="benefits_section.plan_card_text.benefits.title" />;
  };

  return (
    <ListItem className="covered-benefits" ref={coveredBenefitsRef}>
      <div className="item">
        <Paragraph>{renderCoverageHeader()}</Paragraph>
        <ul>{getBenefitsList()}</ul>
      </div>
      {isHiddenOpen && (
        <HiddenContent
          plan={plan}
          contentfulSupplementalPlan={contentfulSupplementalPlan}
          builderCustomerKey={builderCustomerKey}
        />
      )}
      {showFbsCardDetails && (
        <Button
          data-testid={`${plan.external_id}-expand-toggle`}
          aria-label={getHideButtonLabel()}
          size="small"
          className="toggle-details-btn"
          onClick={toggleHiddenOpen}
          stretch
          buttonType="transparent"
          iconLeft={isHiddenOpen ? 'CaretUp' : 'CaretDown'}
        />
      )}
    </ListItem>
  );
};
