import { Stack } from '@chakra-ui/react';
import React from 'react';

import { TextField } from 'Containers/App/types';
import {
  CoveredConditionsContainer,
  PetEnrollmentContainer,
  PetEnrollmentItem,
} from 'Containers/OverviewPage/styled';
import {
  BenefitItem,
  DetailsContainer,
  DetailsWrapper,
  ListItem,
} from 'Containers/ViewBenefitPage/ExpandablePlanCard/PlanContent/styled';
import SupplementalPlanDeductible from 'Containers/ViewBenefitPage/ExpandablePlanCard/PlanContent/SupplementalPlanDeductible';
import { SupplementalPlanMaximumPayout } from 'Containers/ViewBenefitPage/ExpandablePlanCard/PlanContent/SupplementalPlanMaximumPayout';
import { PetBenefits } from 'Containers/ViewBenefitPage/types';
import { transformBenefitItems } from 'Containers/ViewBenefitPage/utils';
import { RichTextContent } from 'ContentfulDefaults/types/_shared';
import { ContentfulSupplementalPlan } from 'ContentfulDefaults/types/benefitsAdditional';
import { useTextContext } from 'Contexts/textContext';
import { H5, Paragraph } from 'DesignLibrary/atoms';
import RichText from 'Shared/RichText';
import Text from 'Shared/Text';
import { SupplementalPlan } from 'Types/entities';

export interface PetPlanContentProps {
  plan: SupplementalPlan;
  contentfulSupplementalPlan: ContentfulSupplementalPlan | undefined;
}

export const PetPlanContent = (props: PetPlanContentProps) => {
  const { plan, contentfulSupplementalPlan } = props;
  const benefits = transformBenefitItems(plan) as PetBenefits;

  const { retrieveContentfulData } = useTextContext();

  const enrollmentField: TextField =
    benefits.enrollment === 'oe'
      ? 'benefits_section_pet.enrollment_period_only_oe'
      : 'benefits_section_pet.enrollment_period_anytime';

  const accidentStack = (
    <Stack gap={2} data-testid="accident-stack">
      <Stack>
        <H5>
          <Text field="benefits_section_pet.header_accident_coverage" />
        </H5>
        <Paragraph as="div" lineHeight={1.75} size="small" className="plan-description">
          <Text field="benefits_section_pet.info_accident_coverage" />
        </Paragraph>
      </Stack>
      {contentfulSupplementalPlan?.accident_coverage && (
        <Stack>
          <CoveredConditionsContainer>
            <Text field="benefits_section_pet.covered_conditions" />
            <Paragraph as="div" lineHeight={1.75} size="small" className="plan-description">
              <RichText field={undefined} fieldContent={contentfulSupplementalPlan?.accident_coverage} />
            </Paragraph>
          </CoveredConditionsContainer>
        </Stack>
      )}
    </Stack>
  );

  const accidentIllnessStack = (
    <Stack gap={2} data-testid="accident-illness-stack">
      <Stack>
        <H5>
          <Text field="benefits_section_pet.header_accident_illness_coverage" />
        </H5>
        <Paragraph as="div" lineHeight={1.75} size="small" className="plan-description">
          <Text field="benefits_section_pet.info_accident_illness_coverage" />
        </Paragraph>
      </Stack>
      {contentfulSupplementalPlan?.illness_coverage && (
        <Stack>
          <CoveredConditionsContainer>
            <Text field="benefits_section_pet.covered_conditions" />
            <Paragraph as="div" lineHeight={1.75} size="small" className="plan-description">
              <RichText field={undefined} fieldContent={contentfulSupplementalPlan?.illness_coverage} />
            </Paragraph>
          </CoveredConditionsContainer>
        </Stack>
      )}
    </Stack>
  );

  const wellnessStack = (
    <Stack gap={2} data-testid="wellness-stack">
      <H5>
        <Text field="benefits_section_pet.header_wellness_coverage" />
      </H5>
      <Paragraph
        as="div"
        lineHeight={1.75}
        size="small"
        className="plan-description"
        data-testid="info-wellness"
      >
        {benefits.wellness_buyup ? (
          <Text field="benefits_section_pet.info_wellness_buyup" />
        ) : (
          <Text field="benefits_section_pet.info_wellness_coverage" />
        )}
      </Paragraph>
      {contentfulSupplementalPlan?.wellness_coverage && (
        <CoveredConditionsContainer>
          <RichText field={undefined} fieldContent={contentfulSupplementalPlan.wellness_coverage} />
        </CoveredConditionsContainer>
      )}
    </Stack>
  );

  const enrollmentAnchor = benefits.enrollment_url ? (
    <a href={benefits.enrollment_url} target="_blank" rel="noreferrer">
      <Text field="benefits_section_pet.enrollment_website_text" />
    </a>
  ) : null;

  const enrollmentStack = (
    <Stack>
      <H5>
        <Text field="benefits_section_pet.header_enrollment_info" />
      </H5>
      <PetEnrollmentContainer>
        <PetEnrollmentItem>
          <Paragraph as="span" size="mini">
            <Text field="benefits_section_pet.enrollment_period_label" />
          </Paragraph>
          <Paragraph as="span" size="mini" data-testid="enrollment-period">
            <Text field={enrollmentField} />
          </Paragraph>
        </PetEnrollmentItem>
        {benefits.show_enrollment_url && benefits.enrollment_url && (
          <PetEnrollmentItem>
            <Paragraph as="span" size="mini">
              <Text field="benefits_section_pet.enrollment_website_label" />
            </Paragraph>
            <Paragraph as="span" size="mini" data-testid="enrollment-url">
              {enrollmentAnchor}
            </Paragraph>
          </PetEnrollmentItem>
        )}
      </PetEnrollmentContainer>
    </Stack>
  );

  const outroTextNoteShort: RichTextContent | undefined = contentfulSupplementalPlan?.outro_text_note_short;
  const outroTextNoteLong: RichTextContent | undefined = contentfulSupplementalPlan?.outro_text_note_long;

  const textNoteStack = (
    <Stack>
      <H5>
        <Text field="benefits_section_pet.header_additional_info" />
      </H5>
      <Paragraph size="small">
        <RichText field={undefined} fieldContent={outroTextNoteShort} />
      </Paragraph>
      {outroTextNoteLong && (
        <Paragraph size="small">
          <RichText field={undefined} fieldContent={outroTextNoteLong} />
        </Paragraph>
      )}
    </Stack>
  );
  const deductibleTooltip = retrieveContentfulData<string>('benefits_section_pet.deductible_tooltip');
  const annualCoverageLimitTooltip = retrieveContentfulData<string>(
    'benefits_section_pet.annual_coverage_limit_tooltip',
  );

  return (
    <DetailsWrapper>
      <Stack gap={6}>
        <DetailsContainer>
          <SupplementalPlanDeductible plan={plan} customTooltipText={deductibleTooltip} hideTooltipTitle />
          {plan.maximum_payout && (
            <SupplementalPlanMaximumPayout
              plan={plan}
              customLabelField="benefits_section_pet.annual_coverage_limit"
              customTooltipText={annualCoverageLimitTooltip}
              hideTooltipTitle
            />
          )}
          <ListItem className="covered-benefits">
            <div className="item">
              <Paragraph>
                <Text field="benefits_section_additional.coverage_details_header" />
              </Paragraph>
              <ul>
                <BenefitItem key="pet-benefit-item-accident">
                  <Text field="benefits_section_pet.accident" />
                </BenefitItem>
                {benefits.coverage_type === 'accident_illness' && (
                  <BenefitItem key="pet-benefit-item-illness">
                    <Text field="benefits_section_pet.illness" />
                  </BenefitItem>
                )}
                {benefits.has_wellness_coverage && (
                  <BenefitItem key="pet-benefit-item-wellness">
                    <Text field="benefits_section_pet.wellness" />
                  </BenefitItem>
                )}
              </ul>
            </div>
          </ListItem>
        </DetailsContainer>
        <Stack gap={6}>
          {accidentStack}
          {benefits.coverage_type === 'accident_illness' && accidentIllnessStack}
          {benefits.has_wellness_coverage && wellnessStack}
        </Stack>
        {enrollmentStack}
        {outroTextNoteShort && textNoteStack}
      </Stack>
    </DetailsWrapper>
  );
};
