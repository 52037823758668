import styled from 'styled-components';

import { media } from 'DesignLibrary/global';
import { cardStyling } from 'DesignLibrary/utils';

export const CallOutCardWrapper = styled.div`
  ${cardStyling}
  display: flex;
  flex-direction: column;
  padding: var(--sizing-sm);
  gap: var(--sizing-sm);

  ${media.TABLET`
    flex-direction: row;
    padding: var(--sizing-md);
  `}
`;

export const Wrapper = styled.div`
  height: 100% !important;
  border-radius: var(--border-radius-xs);
  background: var(--colors-blue-50);
  display: inline-block;
  gap: 12px;
  padding: var(--sizing-sm) !important;
  width: 100%;

  span[role='img'] {
    margin-right: var(--sizing-sm);
    display: inline-block !important;
    height: 16px;
    width: 16px;
  }

  .left-side-callout {
    display: flex;
    align-items: flex-start;
    gap: 8px;
  }

  .callout-header-style {
    font-weight: bold;
    font-family: 'Museo Sans', sans-serif;
  }

  .right-side-callout {
    min-width: 125px;
  }

  .paragraph,
  p {
    display: inline;
    b {
      color: inherit;
      font-weight: 900;
    }

    .tooltip-icon {
      color: inherit !important;
    }

    span > * {
      color: inherit;
    }
  }

  ${media.TABLET`
    display: flex;
    align-items: center;
    padding: 0 var(--sizing-sm);
    height: 40px;

    span[role='img'] {
      display: flex;
      margin: 0;
      height: 24px;
      width: 24px;
    }

    .paragraph,
    p {
      display: block;
    }
  `}
`;
