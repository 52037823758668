import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import { GlobalReducerState } from 'app/reducers';
import ReviewIllustration from 'Assets/banner/review-illustration.png';
import {
  makeSelectCommercialField,
  makeSelectConfigField,
  selectIsFirstPageView,
} from 'Containers/App/selectors';
import { makeGetRecommendedHsaContribution } from 'Containers/CommercialRoutes/selectors';
import { makeSelectHsaField } from 'Containers/HsaPage/selectors';
import {
  makeGetSupplementalPremiums,
  makeGetHsaPlan,
  makeGetHraPlan,
  makeSelectOverviewField,
} from 'Containers/OverviewPage/selectors';
import { ContentfulInfoBars } from 'ContentfulWrappers/ContentfulInfoBars';
import { ContentfulHeaderWrapper } from 'ContentfulWrappers/header';
import { Button, Paragraph } from 'DesignLibrary/atoms';
import { PageLayout } from 'DesignLibrary/atoms/PageLayout';
import { BannerCard } from 'DesignLibrary/molecules/BannerCard';
import Text from 'Shared/Text';
import {
  Recommendation,
  SelectedSupplementalPlans,
  SupplementalPlan,
  SupplementalPlansFullHousehold,
  USDRangeData,
} from 'Types/entities';
import { sendInsightsEvent } from 'Utils/insights';
import { RESULT_PATH, HSA_PLUS_PATH, OVERVIEW_PATH, REVIEW_PATH } from 'Utils/urls';

import { reviewPageLoaded, submitFeedback } from './actions';
import Feedback from './Feedback';
import NextSteps from './NextSteps';
import ReviewSection from './ReviewSection';
import { makeSelectReviewPageField } from './selectors';
import { GridContainer } from './styled';

interface ReviewPageStateProps {
  healthPlan: Recommendation | Record<string, never>;
  supplementalPlans: SupplementalPlansFullHousehold;
  selectedSupplementalPlans: SelectedSupplementalPlans;
  supplementalPremiums: USDRangeData;
  enrollmentUrl: string;
  recommendedHsaContribution: number;
  lockedHsaContribution: number | null;
  hsaPlan: SupplementalPlan | null;
  hraPlan: SupplementalPlan | null;
  sihraContribution: number;
  feedbackSubmitted: boolean;
  isFbsEnabled: boolean;
  isFirstPageView: boolean;
}

interface ReviewPageDispatchProps {
  setReviewPageLoaded: () => void;
  handleFeedbackSubmit: () => void;
}

export type ReviewPageProps = ReviewPageStateProps & ReviewPageDispatchProps;

export const ReviewPage = ({
  healthPlan,
  hsaPlan,
  hraPlan,
  sihraContribution,
  supplementalPremiums,
  supplementalPlans,
  selectedSupplementalPlans,
  enrollmentUrl,
  recommendedHsaContribution,
  lockedHsaContribution,
  // employerHraContribution,
  feedbackSubmitted,
  isFbsEnabled,
  isFirstPageView,
  setReviewPageLoaded,
  handleFeedbackSubmit,
}: ReviewPageProps) => {
  const navigate = useNavigate();

  useEffect(() => {
    setReviewPageLoaded();
  }, []);

  const trackPrint = (e: React.MouseEvent<HTMLButtonElement>) => {
    sendInsightsEvent(e, 'print_results');

    window.print();
  };

  const trackEnroll = () => {
    sendInsightsEvent(null, 'enroll_health');
  };

  const handleSupplementalChange = (e: React.MouseEvent<HTMLButtonElement>) => {
    sendInsightsEvent(e, 'return_to_fbs');

    navigate(OVERVIEW_PATH);
  };

  const handleHealthChange = (e: React.MouseEvent<HTMLButtonElement>) => {
    sendInsightsEvent(e, 'return_to_health');

    navigate(RESULT_PATH);
  };

  const handleHsaChange = (e: React.MouseEvent<HTMLButtonElement>) => {
    sendInsightsEvent(e, 'return_to_hsa');

    navigate(HSA_PLUS_PATH);
  };

  return (
    <div id="review-container">
      <ContentfulHeaderWrapper sectionKey="review_section" animate={isFirstPageView} />
      <ContentfulInfoBars sectionKey="review_section" />
      <PageLayout page="review">
        <GridContainer>
          <section className="main-content">
            <ReviewSection
              healthPlan={healthPlan}
              hsaPlan={hsaPlan}
              hraPlan={hraPlan}
              sihraContribution={sihraContribution}
              supplementalPlans={supplementalPlans}
              selectedSupplementalPlans={selectedSupplementalPlans}
              supplementalPremiums={supplementalPremiums}
              // both here and the HSA insights event are using this `if null`
              // logic, we should refactor this to use a selector for both,
              // and eventually the ben admin enrollment logic as well
              recommendedHsaContribution={
                lockedHsaContribution !== null ? lockedHsaContribution : recommendedHsaContribution
              }
              isFbsEnabled={isFbsEnabled}
              handleSupplementalChange={handleSupplementalChange}
              handleHealthChange={handleHealthChange}
              handleHsaChange={handleHsaChange}
            />
            <NextSteps enrollmentUrl={enrollmentUrl} trackEnroll={trackEnroll} />
          </section>
          <aside className="review-sidebar">
            <BannerCard imgUrl={ReviewIllustration}>
              <Paragraph size="small">
                <Text field="review_section.lets_enroll_card_text" />
              </Paragraph>
              {enrollmentUrl && (
                <Button
                  className="enroll-btn"
                  url={enrollmentUrl}
                  onClick={trackEnroll}
                  buttonType="primary"
                  iconRight="ArrowUpRight"
                  stretch
                >
                  <Text field="review_section.button_text.enroll" />
                </Button>
              )}
              <Button
                onClick={(e) => trackPrint(e)}
                buttonType="transparent"
                size="small"
                iconRight="Printer"
                stretch
              >
                <Text field="review_section.button_text.PDF" />
              </Button>
            </BannerCard>
            <Feedback feedbackSubmitted={feedbackSubmitted} handleFeedbackSubmit={handleFeedbackSubmit} />
          </aside>
        </GridContainer>
      </PageLayout>
    </div>
  );
};

const mapStateToProps = () =>
  createStructuredSelector<GlobalReducerState, ReviewPageStateProps>({
    healthPlan: makeSelectCommercialField('selectedHealthPlan'),
    supplementalPlans: makeSelectOverviewField('supplemental_plans_full_household'),
    selectedSupplementalPlans: makeSelectOverviewField('selected_plans'),
    supplementalPremiums: makeGetSupplementalPremiums(),
    enrollmentUrl: makeSelectConfigField('enrollment_url'),
    recommendedHsaContribution: makeGetRecommendedHsaContribution(),
    lockedHsaContribution: makeSelectHsaField('lockedHsaContribution'),
    hsaPlan: makeGetHsaPlan(),
    hraPlan: makeGetHraPlan(),
    sihraContribution: makeSelectCommercialField('annual_sihra_contribution'),
    feedbackSubmitted: makeSelectReviewPageField('feedbackSubmitted'),
    isFbsEnabled: makeSelectConfigField('is_fbs_enabled'),
    isFirstPageView: selectIsFirstPageView(REVIEW_PATH),
  });

export function mapDispatchToProps(dispatch): ReviewPageDispatchProps {
  return {
    setReviewPageLoaded: () => dispatch(reviewPageLoaded()),
    handleFeedbackSubmit: () => dispatch(submitFeedback()),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(ReviewPage);
