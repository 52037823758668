import { useDisclosure } from '@chakra-ui/react';
import React from 'react';

import EducationalDrawer from 'Containers/_drawers/EducationalDrawer';
import { TextField } from 'Containers/App/types';
import { Button, Icon, Paragraph } from 'DesignLibrary/atoms';
import Text from 'Shared/Text';

import { CallOutCardWrapper, Wrapper } from './styled';

export const CallOutCard = ({ recommendation, type }) => {
  const {
    isOpen: noDeductibleIsOpen,
    onOpen: noDeductibleOnOpen,
    onClose: noDeductibleOnClose,
  } = useDisclosure();

  const isOpen = noDeductibleIsOpen;
  const handleOpenInfoModal = noDeductibleOnOpen;
  const handleCloseInfoModal = noDeductibleOnClose;

  return (
    <CallOutCardWrapper data-testid="noDedeuctible-callout-card">
      <Wrapper>
        <div className="left-side-callout">
          <div className="left-side-callout-icon">
            <Icon type="BellRinging" weight="duotone" color="--primary-blue" />
          </div>
          <Paragraph size="small" color="--text-black">
            <strong className="callout-header-style">
              <Text
                field={`${'health_section.recommendation.no_deductible_plan_callout'}.header` as TextField}
              />
              &nbsp;
            </strong>
            <br />
            <Text field="health_section.recommendation.no_deductible_plan_callout.text" />
          </Paragraph>
        </div>
        <div className="right-side-callout">
          <Button
            size="small"
            onClick={handleOpenInfoModal}
            data-testid="learn-more-plan-eligibility"
            buttonType="secondary"
            stretch
            justify="space-between"
          >
            <Text field="health_section.recommendation.no_deductible_plan_callout.buttonText" />
          </Button>
        </div>
        <EducationalDrawer
          handleClose={handleCloseInfoModal}
          isOpen={isOpen}
          infoType={type}
          employerContribution={0}
          selectedPlan={recommendation}
          shouldShowEducationalVideo
        />
      </Wrapper>
    </CallOutCardWrapper>
  );
};
