import React from 'react';

import { formatDeductibleOrOop } from 'Containers/ResultPage/CompareModal/DeductiblesLimits/_shared';
import { useFeatureFlagContext } from 'Contexts/featureFlagContext';
import { usePremiumContext } from 'Contexts/premiumContext';
import { useTextContext } from 'Contexts/textContext';
import { FormattedTooltipProps, Icon, Numbers, NumberWithPayPeriod, Paragraph } from 'DesignLibrary/atoms';
import { PremiumPerDeduction } from 'DesignLibrary/atoms/PremiumPerDeduction';
import { useViewport } from 'DesignLibrary/context';
import { TooltipCaps } from 'DesignLibrary/molecules';
import Plan from 'Models/plan';
import Text from 'Shared/Text';
import {
  OopEstimateTooltipWithTooltip,
  OopMaxTooltipWithText,
  PremiumTooltipWithText,
  YearlyTotalTooltipWithText,
} from 'Utils/createTooltips';
import { toDollars } from 'Utils/helpers';

import { BreakdownTag, SplitThreshold, Value, ValuesContainer } from './styled';

interface BreakdownProps {
  med: JSX.Element | number;
  drug: JSX.Element | number;
}

const Breakdown = ({ med, drug }: BreakdownProps) => (
  <SplitThreshold>
    <div>
      {/* TODO: CONTENTFUL */}
      <BreakdownTag>Med</BreakdownTag>
      {med}
    </div>
    <div>
      {/* TODO: CONTENTFUL */}
      <BreakdownTag>Drug</BreakdownTag>
      {drug}
    </div>
  </SplitThreshold>
);

export interface ValuesProps {
  plan: Plan;
  hsaContributionLimit: number;
  isProviderSearchEnabled?: boolean;
}

export const Values = ({ plan, hsaContributionLimit, isProviderSearchEnabled = false }: ValuesProps) => {
  const { retrieveContentfulData } = useTextContext();
  const { payPeriod } = usePremiumContext();
  const { device } = useViewport();

  // ------------------------
  // VALUES
  // ------------------------

  const {
    // Remove when retiring feature flag APP-1028-oop-estimate-uses-comm-api
    is_real_cost_before_tax_benefit_enabled,
  } = useFeatureFlagContext();

  const estimatedYearlyTotal = is_real_cost_before_tax_benefit_enabled
    ? plan.realCostBeforeTaxBenefit
    : plan.calcEstimatedYearlyTotal(hsaContributionLimit);

  const outOfPocketEstimate = plan.calcOutOfPocket(hsaContributionLimit);

  const hasComprehensiveDeductible = plan.getIsInNetworkDeductibleComprehensive();
  const hasComprehensiveOopMax = plan.getIsInNetworkOopLimitComprehensive();
  const {
    inNetworkDeductible,
    inNetworkMedDeductible,
    inNetworkDrugDeductible,
    inNetworkOopMax,
    inNetworkMedOopMax,
    inNetworkDrugOopMax,
    combinedNetworkMedDeductible, // Builder Indemnity network deductible
    combinedNetworkOopMax, // Builder Indemnity network oop max
  } = plan;

  const hasNoInNetworkDeductible = plan.getIsNoInNetworkDeductible();

  const isOopGreaterThanOopMax =
    outOfPocketEstimate >
    (inNetworkOopMax || (inNetworkMedOopMax || 0) + (inNetworkDrugOopMax || 0) || Infinity);

  // ------------------------
  // TOOLTIPS
  // ------------------------

  const deductibleTooltip = {
    ...retrieveContentfulData<FormattedTooltipProps>('tool_tips.deductible'),
    note: hasComprehensiveDeductible ? null : (
      <Text
        vars={{
          medicalDeductible: toDollars(inNetworkMedDeductible),
          drugDeductible: toDollars(inNetworkDrugDeductible),
        }}
        field="tool_tips.deductible.note"
      />
    ),
  };

  // ------------------------
  // RENDERING VALUES
  // ------------------------

  // YEARLY
  const yearlyExpenseTotalContainer = (
    <Value data-testid="yearly-expense-total">
      <YearlyTotalTooltipWithText payPeriod={payPeriod} />
      <NumberWithPayPeriod
        value={estimatedYearlyTotal}
        payPeriodType="yearly"
        fontWeight="bold"
        size={device === 'mobile' ? 'medium' : 'large'}
        keyHint={`plan-${plan.planId}-yearly-expense-total`}
      />
    </Value>
  );

  // PREMIUM
  const premiumPerDeductionContainer = (
    <Value data-testid="premium">
      <PremiumTooltipWithText payPeriod={payPeriod} keyHint={`plan-${plan.planId}-premium`} />
      <PremiumPerDeduction
        annualPremium={plan.annualPremium}
        deductions={plan.annualPremiumDeductions}
        currency
        precision={2}
        keyHint={`plan-${plan.planId}-premium-per-deduction`}
      />
    </Value>
  );

  // DEDUCTIBLE
  const renderDeductible = () => {
    if (hasNoInNetworkDeductible && plan.combinedNetworkMedDeductible === null) {
      return (
        <Numbers size="medium">
          <Text field="health_section.null_plan_detail_text.deductibleAndOopMax" />
        </Numbers>
      );
    }

    if (hasNoInNetworkDeductible && plan.combinedNetworkMedDeductible !== null) {
      return (
        <Numbers size="medium" data-testid="combined-network-deductible">
          {toDollars(combinedNetworkMedDeductible)}
        </Numbers>
      );
    }

    if (hasComprehensiveDeductible) {
      return <Numbers size="medium">{toDollars(inNetworkDeductible)}</Numbers>;
    }

    const medDedForBreakdown = formatDeductibleOrOop(inNetworkMedDeductible);
    const drugDedForBreakdown = formatDeductibleOrOop(inNetworkDrugDeductible);

    return <Breakdown med={medDedForBreakdown} drug={drugDedForBreakdown} />;
  };

  const deductibleContainer = (
    <Value data-testid="deductible">
      <TooltipCaps tooltip={deductibleTooltip} keyHint={`plan-${plan.planId}-deductible`}>
        <Text field="health_section.recommendation.deductible" />
      </TooltipCaps>
      {renderDeductible()}
    </Value>
  );

  // OOP ESTIMATE
  const oopGreaterThanOopMaxTooltip = isOopGreaterThanOopMax && (
    <div data-testid="oop-greater-than">
      <TooltipCaps
        tooltip={retrieveContentfulData<FormattedTooltipProps>(
          'tool_tips.yearly_estimate_greater_than_out_of_pocket_limit',
        )}
        keyHint={`plan-${plan.planId}-yearly-estimate-greater-than-oop`}
      >
        <Icon type="WarningCircle" />
      </TooltipCaps>
    </div>
  );

  const outOfPocketEstimateContainer = (
    <Value data-testid="out-of-pocket-estimate">
      <OopEstimateTooltipWithTooltip keyHint={`plan-${plan.planId}-oop-estimate`} />
      <NumberWithPayPeriod
        value={outOfPocketEstimate}
        payPeriodType="yearly"
        keyHint={`plan-${plan.planId}-oop-estimate-value`}
      />
      {oopGreaterThanOopMaxTooltip}
    </Value>
  );

  // OOP MAX
  const renderOutOfPocketMaximum = () => {
    const hasNoInNetworkOopMax =
      (inNetworkOopMax === 0 && (inNetworkMedOopMax || 0) + (inNetworkDrugOopMax || 0) === 0) ||
      inNetworkOopMax === 999999;
    if (hasNoInNetworkOopMax && !plan.hasCombinedOopMax()) {
      return (
        <Paragraph color="--text-color">
          <Text field="health_section.recommendation.unlimited" />;
        </Paragraph>
      );
    }

    if (plan.hasCombinedOopMax()) {
      return (
        <Numbers size="medium" data-testid="combined-network-oop-max">
          {toDollars(combinedNetworkOopMax)}
        </Numbers>
      );
    }

    if (hasComprehensiveOopMax) {
      return <Numbers size="medium">{toDollars(inNetworkOopMax)}</Numbers>;
    }

    return (
      <Breakdown
        med={formatDeductibleOrOop(inNetworkMedOopMax)}
        drug={formatDeductibleOrOop(inNetworkDrugOopMax)}
      />
    );
  };

  const outOfPocketMaximumContainer = (
    <Value>
      <OopMaxTooltipWithText
        hasComprehensiveOopMax={hasComprehensiveOopMax}
        medicalOopMax={inNetworkMedOopMax || 0}
        drugOopMax={inNetworkDrugOopMax || 0}
        keyHint={`plan-${plan.planId}-oop-max`}
      />
      {renderOutOfPocketMaximum()}
    </Value>
  );

  return (
    <ValuesContainer isProviderSearchEnabled={isProviderSearchEnabled}>
      {yearlyExpenseTotalContainer}
      {premiumPerDeductionContainer}
      {deductibleContainer}
      {outOfPocketEstimateContainer}
      {outOfPocketMaximumContainer}
    </ValuesContainer>
  );
};
