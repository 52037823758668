import {
  ClientSurveyResponses,
  IncentiveSurveyAnswer,
  IncentiveSurveyQuestion,
  PlannedProcedures,
} from 'Containers/ProfilePage/types';
import { InsuranceType } from 'Containers/ViewBenefitPage/types';
import { ContentfulCustomPlanMessage } from 'ContentfulDefaults/types/health';

export interface Utilization {
  pcp_visits: number;
  specialist_visits: number;
  inpatient_days: number;
  // Optional for now until comm api is updated
  mental_health_visits?: number;
}

export interface Prescription {
  ndc: string;
  frequency: number;
  quantity: number;
}

export interface HouseholdMember {
  age: number;
  gender: string | null;
  prescriptions: Prescription[];
  uses_tobacco: boolean | null;
  member_type: string;
  dependant: boolean;
  policyholder: boolean;
  external_id: string;
  utilization: Utilization;
  planned_procedures: PlannedProcedures;
}

export interface CoveredMember {
  memberId: string;
  covered: boolean;
  gender: string;
  age: number;
  policyholder: boolean;
  dependant: boolean;
}

export type CoveredMembers =
  | {
      [memberId: string]: CoveredMember;
    }
  | Record<string, never>;

export type DefaultCoveredMembers = Record<InsuranceType, CoveredMembers>;

export type Relationship = 'policyholder' | 'spouse' | 'dependent';

export interface SpendingAccount {
  employee_contribution?: number;
  employer_contribution: number;
  employer_percentage_contribution?: number;
  type: string;
  employer_contribution_limit?: number;
}

export interface HouseholdSurveyQuestion {
  question_id: string;
  answer: string;
}

export enum PAY_PERIOD_KEYS {
  MONTHLY = 'monthly',
  SEMI_MONTHLY = 'semi_monthly',
  BI_WEEKLY = 'bi_weekly',
  WEEKLY = 'weekly',
}

export enum PAY_PERIOD_VALUES {
  monthly = 12,
  semi_monthly = 24,
  bi_weekly = 26,
  weekly = 52,
}

export enum PAY_PERIOD_TEXT {
  MONTHLY = 'every month',
  SEMI_MONTHLY = 'twice per month',
  BI_WEEKLY = 'every 2 weeks',
  WEEKLY = 'every week',
}

export enum SAVINGS_ACCOUNT_TYPES {
  HSA = 'hsa',
  FSA = 'fsa',
  HRA = 'hra',
}

export type EducationalDrawerInfoTypes = SAVINGS_ACCOUNT_TYPES | 'no_deductible';

export interface Household {
  spending_accounts: SpendingAccount[];
  enrollment?: {
    plan_id: string;
    network_id: string;
  };
  zip_code: string;
  version: number;
  survey: HouseholdSurveyQuestion[];
  members: HouseholdMember[];
  income: number | null;
  external_id?: string;
  group?: string;
  pay_periods_per_year?: PAY_PERIOD_VALUES;
  // only if feature flag show_client_survey=true
  client_survey: ClientSurveyResponses | null;
  // only if feature flag collect_tax_inputs=true
  state?: string;
  filing_status?: string;
}

export interface Location {
  external_id: number;
  type: 'specialist' | 'pcp' | 'family';
  specialty: string;
  name: string;
  location: string;
  state?: string;
}

export interface Drug {
  key: string;
  text: string;
  value: string;
}

export interface DrugDescription {
  description: string;
  key: string;
  title: string;
}

export interface Thresholds {
  in_network: {
    coverage_tier: string;
    medical_deductible: number | null;
    medical_oop_max: number | null;
    drug_deductible: number | null;
    drug_oop_max: number | null;
    medical_and_drug_deductible: number | null;
    medical_and_drug_oop_max: number | null;
    network_type: 'in_network';
    embedded_deductible: boolean;
    embedded_oop_max: boolean;
  };
  out_of_network: {
    coverage_tier: string;
    medical_deductible: number | null;
    medical_oop_max: number | null;
    drug_deductible: number | null;
    drug_oop_max: number | null;
    medical_and_drug_deductible: number | null;
    medical_and_drug_oop_max: number | null;
    network_type: 'out_of_network';
    embedded_deductible: boolean;
    embedded_oop_max: boolean;
  };
}

export interface DrugCoverage {
  imputed: boolean;
  coinsurance: number | null;
  copay: number | null;
  covered: boolean;
  days_supply: number | null;
  deductible_applies: boolean;
  max_cost_sharing: number | null;
  max_oop_applies: boolean;
  min_cost_sharing: number | null;
}

export interface HealthCoverage {
  coinsurance: number | null;
  copay: number | null;
  copay_frequency: unknown;
  covered: boolean;
  excluded_from_moop: boolean;
  deductible_applies: boolean;
  max_cost_sharing?: number | null;
  max_oop_applies?: boolean;
  min_cost_sharing?: number | null;
  limit_quantity?: number | null;
  limit_unit?: string | null;
  minimum_stay?: number | null;
  copay_prior_to_deductible?: boolean | null;
}

export interface Recommendation {
  costs: {
    annual_fsa_dollars_used?: number;
    annual_hra_dollars_used?: number;
    annual_hra_employer_dollars_used?: number;
    annual_hsa_dollars_used?: number;
    annual_hsa_employer_dollars_used?: number;
    annual_hsa_tax_savings?: number;
    annual_premium: number;
    annual_premium_deductions: number;
    annual_tax_benefit?: number;
    annual_sihra_dollars_used?: number;
    drugs: number;
    effective_premium: number;
    real_cost: number;
    real_cost_before_tax_benefit: number;
    services: {
      in_network: number;
    };
    spending_account_balance_increase: number;
    spending_account_benefit: number;
  };
  health_provider_locations: unknown[];
  plan: {
    benefits_summary_url: string | null;
    brand_drug: DrugCoverage;
    carrier_name: string;
    docfinder_network_uuids: string[] | null;
    external_id: string;
    external_plan_id: string;
    family_thresholds: Thresholds;
    generic_drug: DrugCoverage;
    hra_eligible: boolean;
    hsa_eligible: boolean;
    id: string;
    individual_child_thresholds: Thresholds;
    individual_children_thresholds: Thresholds;
    individual_spouse_thresholds: Thresholds;
    individual_thresholds: Thresholds;
    non_formulary_drug: DrugCoverage;
    plan_marketing_name: string;
    plan_type: string;
    primary_care: HealthCoverage;
    specialist_care: HealthCoverage;
    specialty_drug: DrugCoverage;
    spending_account_contributions: {
      external_id: string;
      hra_annual: {
        employer_contribution_amount: number | null;
      };
      hsa_annual: {
        employer_contribution_amount: number | null;
        employer_contribution_limit: number | null;
        employer_contribution_percent: number | null;
        employer_contribution_type: string;
      };
    };
  };
  plan_details: PlanDetails;
  score: number;
  tier: Tier;
  publication: string;
}

export interface AnnualIncentive {
  amount: number;
  id: string;
  is_surcharge: boolean;
}

export interface PlanDetails {
  deductible: {
    comprehensive: EffectiveSpendingLimitByNetwork | null;
    health_and_drug: {
      drug: EffectiveSpendingLimitByNetwork | null;
      health: EffectiveSpendingLimitByNetwork | null;
    } | null;
  };
  max_oop: {
    comprehensive: EffectiveSpendingLimitByNetwork | null;
    health_and_drug: {
      drug: EffectiveSpendingLimitByNetwork | null;
      health: EffectiveSpendingLimitByNetwork | null;
    } | null;
  };
  health_savings_account?: {
    id: string;
    annual_incentives: AnnualIncentive[];
    employer_contribution_annual_amount: number;
    employer_contribution_limit: number | null;
    employer_contribution_percent: number | null;
    employer_contribution_type: string;
    minimum_employee_contribution_amount: number | null;
    vendor_name: string | null;
    vendor_url: string | null;
  } | null;
  health_reimbursement_arrangement?: {
    id: string;
    annual_incentives: AnnualIncentive[];
    employer_contribution_annual_amount: number | null;
    kick_in_amount: number | null;
    can_reimburse_copay: boolean;
  } | null;
}

interface EffectiveSpendingLimitByNetwork {
  combined: EffectiveSpendingLimit | null;
  in_network: EffectiveSpendingLimit | null;
  secondary_in_network: EffectiveSpendingLimit | null;
  out_of_network: EffectiveSpendingLimit | null;
}

interface EffectiveSpendingLimit {
  embedded_per_person: number | null;
  total: number;
}

export type Tier = 'green' | 'yellow' | 'red';

interface ForcastValues {
  mean: number;
  median: number;
  pct_20: number;
  pct_80: number;
  pct_90: number;
}

export interface Forecast {
  commercial_premiums: {
    total_premium: number;
  };
  drug: ForcastValues;
  hsa: {
    balance: ForcastValues;
    cost_not_paid_by_hsa: ForcastValues;
    cost_paid_by_hsa: ForcastValues;
    hsa_balance_likelihood: number;
    oop_coverage_likelihood: number;
    tax_savings: number;
  };
  medical: ForcastValues;
  medical_and_drug: ForcastValues;
  medicare_premiums: {
    medigap_premium: number;
    part_b_premium: number;
    part_b_premium_reduction: number;
    part_c_premium: number;
    part_d_premium: number;
    total_premium: number;
  };
  total_cost: ForcastValues;
  total_premium: number;
  year: number;
}

export interface SupplementalRecommendationReason {
  code: string;
  data: Record<string, unknown>;
  description: string;
}

export interface SupplementalPlan {
  max_is_per_person?: boolean;
  plan_type: string;
  external_id: string;
  name: string;
  pdf_url: string | null;
  pdf_url_spanish?: string | null;
  carrier_name: string;
  monthly_premium: DollarRangeField | DollarField | null;
  deductions_per_year: number;
  benefits: Benefits;
  auto_enrolled: boolean;
  maximum_payout: StringField | ReferenceField | DollarField | null;
  deductible: StringField | ReferenceField | DollarField | null;
  eligible_members: string[];
  ineligible_members: string[];
  metadata: { sort_order: number } | null;
  fsa_specific?: {
    grace_period?: {
      data: string;
      rule: string;
      type: string;
    };
    rollover_end_date?: {
      data: string;
      rule: string;
      type: string;
    };
    rollover_amount_limit?: {
      data: number;
      rule: string;
      type: string;
    };
  };
  reasons?: SupplementalRecommendationReason[];
  score?: number;
  score_type?: string;
}

export type SupplementalRecommendations = Partial<
  Record<
    InsuranceType,
    {
      score: number;
      score_type: 'qualitative' | 'always_recommend';
      label: 'not_recommended' | 'recommended' | 'best';
    }
  >
>;

export type SupplementalPlansFullHousehold =
  | Record<
      InsuranceType | string,
      {
        plans: SupplementalPlan[];
      }
    >
  | Record<string, never>;

export type SupplementalPlansCache = Record<string, SupplementalPlansFullHousehold>;

export type SelectedSupplementalPlans = Partial<Record<InsuranceType | string, SupplementalPlan | null>>;

export type SelectedPlansCoveredMembers = Record<
  InsuranceType | string,
  {
    [memberId: string]: CoveredMember;
  }
>;

export interface SupplementalBenefitGroup {
  title: string;
  position: number;
  plan_types: Array<{
    position: number;
    plan_type: InsuranceType | string;
  }>;
  description: string;
}

export enum FieldDataTypes {
  'STRING' = 'string',
  'RICHTEXT' = 'richtext',
  'TEXTFIELD' = 'textfield',
  'REFERENCE' = 'reference',
  'USD' = 'USD',
  'RANGE' = 'range',
}

export interface USDRangeData {
  type: FieldDataTypes.USD;
  lower: number;
  upper: number;
}

export interface DollarRangeField {
  type: FieldDataTypes.RANGE;
  data: USDRangeData;
}

export interface DollarField {
  type: FieldDataTypes.USD;
  data: number;
}

export interface StringField {
  type: FieldDataTypes.STRING;
  rule?: Rule;
  data: string;
}

export interface RichTextField {
  type: FieldDataTypes.RICHTEXT;
  data: string;
}

export interface TextField {
  type: FieldDataTypes.TEXTFIELD;
  data: string;
}

export interface ReferenceField {
  type?: FieldDataTypes.REFERENCE;
  rule?: Rule;
  data: string;
}

export type Rule = 'as_is' | 'reference';

export interface Benefits {
  description: StringField | RichTextField | TextField | ReferenceField | DollarField | null;
  items: Items[];
  exclusions?: (StringField | ReferenceField)[];
}

export interface Items {
  description: StringField | ReferenceField | DollarField;
  item: StringField | ReferenceField;
}

export type invalidPlanMessageType = keyof ContentfulCustomPlanMessage | null;

export type GraphMode = 'short' | 'long';

// These keys come from Comm API supplemental recommendation `reason.code`
export enum REASONS_WHY {
  CHILDCARE = 'fsa_dependent_pregnancy',
  EXTRA_PROTECTION = 'low_capacity_to_pay',
  HAS_DENTAL_CLEANINGS = 'dental_routine_care',
  HAS_EYE_EXAMS = 'eye_exam',
  HAS_EYE_GLASSES = 'eye_glasses',
  HAS_HIGH_DEDUCTIBLE_LOW_CAPACITY = 'high_ded_low_cap',
  HAS_HIGH_RISK_AVERSION = 'high_risk_aversion',
  HAS_HOSPITAL_ADMISSION = 'hospitalization',
  HAS_KIDS_DENTAL = 'dental_orthodontia',
  HAS_MODERATE_HIGH_RISK_AVERSION = 'moderate_high_risk_aversion',
  HAS_PHYSICAL_ACTIVITIES = 'physical_activity',
  HAS_PLANNED_PREGNANCY = 'hospital_pregnancy',
  IS_OLDEST_AGE_40_TO_55 = 'middle_aged_illnesses',
  IS_OLDEST_AGE_OVER_55 = 'senior_aged_illnesses',
  LIFE_DEPENDENT = 'life_dependent',
  LONG_TERM_DISABILITY = 'long_term_disability',
  PARENTAL_LEAVE = 'std_pregnancy',
  PLANNED_PROCEDURE_INPATIENT = 'planned_procedure_inpatient',
  PRESCRIPTIONS = 'risk_averse_with_high_rx_count',
  TOBACCO = 'tobacco_usage',
}

export type ReasonsWhy = Partial<Record<InsuranceType, REASONS_WHY[]>>;

/* Below types are used for doctor search provider feature
/*
 * Interface defining a doctor's specialty
 */
interface Specialty {
  boardSpecialty: string | null;
  colloquial: string | null;
  display: string;
  nonMdSpecialty: string | null;
  nonMdSubSpecialty: string | null;
  providerName: string;
  taxonomy1: string | null;
  taxonomy2: string | null;
  taxonomy3: string | null;
  taxonomyCode: string;
  uuid: string;
}

/*
 * Interface defining an insurance object
 */
export interface Insurance {
  carrier_association: string;
  carrier_brand: string;
  carrier_name: string;
  category: string;
  codes: string[];
  confidence: number;
  display_name: string;
  metal_level: string;
  network: string;
  plan_name: string;
  plan_type: string;
  state: string;
  uuid: string;
}

/*
 * Interface defining an education object
 */
interface Education {
  education: {
    name: string;
    uuid: string;
  };
  type: string | null;
  year: number;
}

/*
 * Interface defining a phone number
 */
export interface PhoneNumber {
  details: string;
  phone: string;
}

/*
 * Interface defining the properties of a doctor's address
 */
export interface DoctorLocation {
  address: string;
  address_details?: {
    street: string | null;
    city: string;
    state: string;
    zip: string;
    address_line_1: string;
    address_line_2: string;
  };
  confidence?: number;
  distance: number;
  googleMapsLink?: string;
  latitude?: number;
  longitude?: number;
  name: string | null;
  phone_numbers: PhoneNumber[];
  uuid: string;
}

/**
 * Interface defining the shape of a doctor object as returned by RibbonHealth
 */
export interface DoctorType {
  age: number | null;
  degrees: string[];
  distance: number;
  educations: Education[];
  first_name: string;
  gender: string;
  insurances?: Insurance[];
  languages: string[];
  last_name: string;
  locations: DoctorLocation[];
  middle_name: string;
  npi: string;
  online_profiles: { url: string }[];
  provider_types: string[];
  ratings_avg: number | null;
  ratings_count: number;
  specialties: Specialty[];
  selected_location?: DoctorLocation;
}

export interface DoctorSummary {
  npi: string;
  first_name: string;
  middle_name: string | '';
  last_name: string;
  age: number | null;
}

export interface DoctorApi {
  data: DoctorType[];
  parameters: SearchApiParameters;
}

export interface LocationProps {
  uuid: string;
  name: string | null;
  address: string;
  distance: number;
  phoneNumbers?: PhoneNumber[];
  value: string;
}

export interface ProviderLocationProps {
  label: string;
  value: string;
  locations: LocationProps[];
  providerTypes: string[];
}

export interface SearchApiParameters {
  total_count: number;
}

export interface ProviderSectionProps {
  type?: 'in-network' | 'out-of-network';
  providers?: DoctorType[];
}
export interface DoctorProvidersProps {
  planId: string;
}

export type ProfileIncentiveQuestionAnswers = {
  incentiveSurvey: IncentiveSurveyQuestion[];
  incentiveSurveyAnswers: Record<string, IncentiveSurveyAnswer | null>;
};
