import { Box } from '@chakra-ui/react';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { useTextContext } from 'Contexts/textContext';
import { Button } from 'DesignLibrary/atoms';
import { useViewport } from 'DesignLibrary/context';
import Plan from 'Models/plan';
import Text from 'Shared/Text';
import { Recommendation as RecommendationEntity } from 'Types/entities';
import { VIEW_PLAN_PATH } from 'Utils/urls';

import { ButtonContainer } from './styled';

export interface ButtonsProps {
  plan: Plan;
  isSelected: boolean;
  isEntered: boolean;
  choosePlan: (recommendation: RecommendationEntity) => void;
  deselectPlan: () => void;
  handleOpenPlanEntry: () => void;
}

export const Buttons = ({
  plan,
  isSelected,
  isEntered,
  choosePlan,
  deselectPlan,
  handleOpenPlanEntry,
}: ButtonsProps) => {
  const { device } = useViewport();
  const { retrieveContentfulData } = useTextContext();
  const navigate = useNavigate();

  const viewPlanButtonLabel = `${retrieveContentfulData<string>(
    'health_section.button_text.viewPlan',
  )} ${plan.carrierName} ${plan.planMarketingName}`;

  const choosePlanButtonLabel = `${retrieveContentfulData<string>(
    'health_section.button_text.choosePlan',
  )} ${plan.carrierName} ${plan.planMarketingName}`;

  const selectedPlanButtonLabel = `${retrieveContentfulData<string>(
    'health_section.button_text.selectedPlan',
  )} ${plan.carrierName} ${plan.planMarketingName}`;

  return (
    <ButtonContainer>
      <>
        {isEntered && (
          <div className="edit-btn">
            <Button
              size={device !== 'mobile' ? 'normal' : 'small'}
              iconLeft="Pencil"
              stretch={device === 'mobile'}
              onClick={handleOpenPlanEntry}
            >
              <Text field="spousal_plan_comparison.button_text.editModal" />
            </Button>
          </div>
        )}
        <Button
          aria-label={viewPlanButtonLabel}
          size={device !== 'mobile' ? 'normal' : 'small'}
          buttonType="secondary"
          stretch
          onClick={() => navigate(`${VIEW_PLAN_PATH}/${plan?.planId}`)}
          className="view-plan-btn"
        >
          <Text field="health_section.button_text.viewPlan" />
        </Button>
        <Box maxW={['initial', null, '280']} w="100%">
          <Button
            aria-label={isSelected ? selectedPlanButtonLabel : choosePlanButtonLabel}
            className="choose-plan-btn"
            size={device !== 'mobile' ? 'normal' : 'small'}
            buttonType="primary"
            onClick={isSelected ? deselectPlan : () => choosePlan(plan.recommendation)}
            color={!isSelected ? 'yellow' : 'red'}
            iconRight={isSelected ? 'X' : null}
            iconLeft={!isSelected ? 'PlusCircle' : null}
            stretch
          >
            {isSelected ? (
              <Text field="health_section.button_text.deselectPlan" />
            ) : (
              <Text field="health_section.button_text.choosePlan" />
            )}
          </Button>
        </Box>
      </>
    </ButtonContainer>
  );
};
