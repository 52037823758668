import React from 'react';

import { TextField } from 'Containers/App/types';
import { ContentfulCustomContentBlock } from 'ContentfulDefaults/types/_shared';
import { useTextContext } from 'Contexts/textContext';
import { Button, H5, Paragraph } from 'DesignLibrary/atoms';
import RichText from 'Shared/RichText';
import Text from 'Shared/Text';
import { EducationalDrawerInfoTypes } from 'Types/entities';

import { Bullet, BulletIcon, Item } from './styled';

interface WhatCanItDoProps {
  infoType: EducationalDrawerInfoTypes;
}

export const WhatCanItDo = ({ infoType }: WhatCanItDoProps) => {
  const { retrieveContentfulData } = useTextContext();

  const color = 'blue';
  const content = `health_section.secondary_content.${infoType}_modal.what_can_it_do_content`;

  const whatCanItDoContent = retrieveContentfulData<ContentfulCustomContentBlock[]>(content);
  const whatCanItDoItems = whatCanItDoContent.map((item, idx) => ({
    title: item.title,
    content: <RichText field={`${content}[${idx}].content` as TextField} />,
    button_text: item.button_text,
    button_link: item.button_link,
  }));

  return (
    whatCanItDoContent && (
      <>
        <H5 as="h2">
          <Text field={`health_section.secondary_content.${infoType}_modal.what_can_it_do_header_text`} />
        </H5>
        {whatCanItDoItems.map((content) => (
          <Item key={content.title}>
            <Bullet color={color}>
              <BulletIcon aria-hidden color={color}>
                {'>'}
              </BulletIcon>
              <Paragraph color="--text-black" weight="bold" as="h3">
                {content.title}
              </Paragraph>
            </Bullet>
            <Paragraph>{content.content}</Paragraph>
            {content.button_text && content.button_link && (
              <Button onClick={() => window.open(content.button_link)} iconRight="ArrowUpRight">
                {content.button_text}
              </Button>
            )}
          </Item>
        ))}
      </>
    )
  );
};
