import React from 'react';

import { H5, Tooltip } from 'DesignLibrary/atoms';

import { GroupContainer, GroupHeader } from './styled';

export interface ReviewGroupProps {
  title: JSX.Element | string;
  tooltip: JSX.Element | string;
  header: React.ReactNode;
  children: React.ReactNode;
}

export const ReviewGroup = ({ title, tooltip, header, children }: ReviewGroupProps) => (
  <GroupContainer data-testid={title}>
    <GroupHeader>
      <div className="title">
        <H5 as="h2">{title}</H5>
        <div className="no-print">
          <Tooltip id={`review-group-${title}`} label={{ title, text: tooltip }} />
        </div>
      </div>
      {header}
    </GroupHeader>
    {children}
  </GroupContainer>
);
