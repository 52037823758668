import React from 'react';

import { InsuranceType } from 'Containers/ViewBenefitPage/types';
import { useTextContext } from 'Contexts/textContext';
import { Paragraph } from 'DesignLibrary/atoms';
import { Button } from 'DesignLibrary/atoms/Button';
import { PicwellScore } from 'DesignLibrary/atoms/PicwellScore';
import { PremiumPerDeduction } from 'DesignLibrary/atoms/PremiumPerDeduction';
import { useViewport } from 'DesignLibrary/context';
import { SupplementalIcon } from 'Shared/SupplementalIcon';
import Text from 'Shared/Text';

import { AutoTag, CardContainer } from './styled';

export interface CardProps {
  planType: InsuranceType | 'health' | 'waived_coverage';
  carrier: string;
  healthPlanName: string | JSX.Element;
  premium?: number;
  annualPremium?: number;
  deductionsPerYear?: number;
  autoEnrolled?: boolean;
  planCount?: number;
  isEmployerPaid?: boolean;
  handleChangeButton?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

export const Card = ({
  planType,
  carrier,
  healthPlanName,
  premium,
  annualPremium,
  deductionsPerYear = 12,
  autoEnrolled = false,
  planCount,
  isEmployerPaid,
  handleChangeButton,
}: CardProps) => {
  const { device } = useViewport();
  const { retrieveContentfulData } = useTextContext();

  const changeText = retrieveContentfulData<string>('review_section.button_text.change');

  // A11y
  const changeButtonLabelText = `${changeText} ${carrier} ${healthPlanName}`;
  const changeButtonLabelId = `${planType}-label`;

  const learnMoreText = retrieveContentfulData<string>('review_section.learn_more_text');
  const buyUpText = retrieveContentfulData<string>('review_section.buy_up_text');
  const waivedText = retrieveContentfulData<string>('review_section.waived_coverage_text');

  const renderRightSideContent = () => {
    if (planType === 'waived_coverage') {
      return <Paragraph size="mini">{waivedText}</Paragraph>;
    }
    if (isEmployerPaid) {
      return (
        <AutoTag>
          <Text field="benefits_section.auto_enrolled_info.tagText" />
        </AutoTag>
      );
    }
    if (autoEnrolled) {
      if (premium) {
        return <Paragraph size="mini">{buyUpText}</Paragraph>;
      }
      return (
        <AutoTag>
          <Text field="benefits_section.auto_enrolled_info.tipTitle" />
        </AutoTag>
      );
    }
    if (premium || planType === 'hra') {
      return (
        <PremiumPerDeduction
          annualPremium={annualPremium || 0}
          deductions={deductionsPerYear}
          currency
          precision={2}
        />
      );
    }

    return <Paragraph size="mini">{learnMoreText}</Paragraph>;
  };

  const renderIcon = () => {
    switch (planType) {
      case 'waived_coverage':
        return <PicwellScore size="medium" waived />;
      default:
        return <SupplementalIcon type={planType as InsuranceType | 'health'} />;
    }
  };

  return (
    <CardContainer>
      <section className="card-left">
        <div className="title">
          <div className="icon">{renderIcon()}</div>
          <div id={changeButtonLabelId} className="plan-title" data-testid={changeButtonLabelId}>
            <Paragraph size="small">{carrier}</Paragraph>
            <Paragraph color="--text-black" weight="bold">
              {healthPlanName}
            </Paragraph>
          </div>
        </div>
        {device === 'mobile' && handleChangeButton && (
          <Button
            aria-label={changeButtonLabelText}
            aria-describedby={autoEnrolled ? 'auto-enrolled-tag' : null}
            size="small"
            iconRight="Pencil"
            onClick={handleChangeButton}
            // disable when autoEnrolled w/o buyup
            isDisabled={autoEnrolled && planCount === 1}
          />
        )}
      </section>
      <section className="card-right">
        <div className="secondary-content">
          {device !== 'mobile' && handleChangeButton && planType !== 'hra' && (
            <Button
              aria-label={changeButtonLabelText}
              aria-describedby={autoEnrolled ? 'auto-enrolled-tag' : null}
              size="small"
              onClick={handleChangeButton}
              // disable when autoEnrolled w/o buyup
              isDisabled={autoEnrolled && planCount === 1}
            >
              <Text field="review_section.button_text.change" />
            </Button>
          )}
        </div>
        <section className="pricing">{renderRightSideContent()}</section>
      </section>
    </CardContainer>
  );
};

export default Card;
