import { AspectRatio } from '@chakra-ui/react';
import { isEmpty } from 'lodash';
import React, { useEffect } from 'react';

import { TextField } from 'Containers/App/types';
import { useTextContext } from 'Contexts/textContext';
import { H3, Paragraph } from 'DesignLibrary/atoms';
import { Drawer } from 'DesignLibrary/atoms/Drawer';
import { IconTypes } from 'DesignLibrary/atoms/Icon';
import Media from 'Shared/Media';
import Text from 'Shared/Text';
import { Recommendation, EducationalDrawerInfoTypes, SAVINGS_ACCOUNT_TYPES } from 'Types/entities';
import { sendInsightsEvent } from 'Utils/insights';

import { EligBars } from './EligBars';
import { FAQ } from './FAQ';
import { HowItWorks } from './HowItWorks';
import { SupplementalContent, Header } from './styled';
import { WhatCanItDo } from './WhatCanItDo';

export interface EducationalDrawerProps {
  isOpen: boolean;
  handleClose: () => void;
  infoType: EducationalDrawerInfoTypes;
  // hsa specific
  individualHsaContributionLimit?: number;
  familyHsaContributionLimit?: number;
  // generic
  employerContribution: number | null;
  selectedPlan: Recommendation | Record<string, never>;
  shouldShowEducationalVideo?: boolean;
}

const EducationalDrawer = ({
  isOpen,
  infoType,
  individualHsaContributionLimit,
  familyHsaContributionLimit,
  employerContribution,
  selectedPlan,
  handleClose,
  shouldShowEducationalVideo,
}: EducationalDrawerProps) => {
  const { retrieveContentfulData } = useTextContext();

  useEffect(() => {
    if (isOpen) {
      sendInsightsEvent(null, 'info_modal_view_started', {
        modal_id: infoType,
      });
    }
  }, [isOpen]);

  const handleOnClose = () => {
    sendInsightsEvent(null, 'info_modal_view_ended', { modal_id: infoType });
    handleClose();
  };

  const contentfulTitle: TextField = `health_section.secondary_content.${infoType}_modal.title`;
  const contentfulIcon: TextField = `health_section.secondary_content.${infoType}_modal.icon`;

  const video_url = `health_section.secondary_content.${infoType}_modal.video_url`;
  const contentfulHeader: TextField = `health_section.secondary_content.${infoType}_modal.header`;

  const isSelectedPlanEligibleForBenefit = () => {
    if (isEmpty(selectedPlan)) {
      return false;
    }

    if (infoType === SAVINGS_ACCOUNT_TYPES.HSA) {
      return selectedPlan?.plan.hsa_eligible;
    }
    if (infoType === SAVINGS_ACCOUNT_TYPES.HRA) {
      return selectedPlan?.plan.hra_eligible;
    }

    return false;
  };

  return (
    <Drawer
      isOpen={isOpen}
      onClose={handleOnClose}
      title={retrieveContentfulData<string>(contentfulTitle)}
      titleIcon={retrieveContentfulData<IconTypes>(contentfulIcon)}
    >
      <div data-testid={`educational-modal-${infoType}`}>
        <SupplementalContent>
          {infoType !== 'no_deductible' && (
            <EligBars
              infoType={infoType}
              selectedPlan={selectedPlan}
              isSelectedEligible={isSelectedPlanEligibleForBenefit()}
              employerContribution={employerContribution}
            />
          )}

          <article>
            <Header>
              <H3 as="h1">
                <Text
                  field={`${contentfulHeader}.text`}
                  vars={infoType === 'no_deductible' ? { x: selectedPlan?.plan?.carrier_name } : undefined}
                />
              </H3>
              <Paragraph>
                <Text
                  field={`${contentfulHeader}.subtext`}
                  vars={infoType === 'no_deductible' ? { x: selectedPlan?.plan?.carrier_name } : undefined}
                />
              </Paragraph>

              {shouldShowEducationalVideo && video_url && (
                <AspectRatio className="video" maxW="600px" ratio={16 / 9}>
                  <Media field={video_url} title={`${infoType} video`} form="video" />
                </AspectRatio>
              )}
            </Header>
            {infoType !== 'no_deductible' && (
              <>
                <section>
                  <HowItWorks
                    infoType={infoType}
                    employerContribution={employerContribution}
                    individualHsaContributionLimit={individualHsaContributionLimit}
                    familyHsaContributionLimit={familyHsaContributionLimit}
                  />
                </section>
                <section>
                  <WhatCanItDo infoType={infoType} />
                </section>
                <section>
                  <FAQ infoType={infoType} />
                </section>
              </>
            )}
          </article>
        </SupplementalContent>
      </div>
    </Drawer>
  );
};

export default EducationalDrawer;
