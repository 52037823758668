import { useDisclosure } from '@chakra-ui/react';
import React from 'react';

import EducationalDrawer from 'Containers/_drawers/EducationalDrawer';
import { Button, H5, Paragraph } from 'DesignLibrary/atoms';
import { Recommendation, SAVINGS_ACCOUNT_TYPES } from 'Types/entities';
import { BuilderGoSettings } from 'Utils/apiTypes';

import { CardContainer, EligibleCardsContainer } from './styled';

interface CardProps {
  account: SAVINGS_ACCOUNT_TYPES;
  employerHsaContribution: number;
  individualHsaContributionLimit: number;
  familyHsaContributionLimit: number;
  selectedPlan: Recommendation;
  shouldShowEducationalVideo: boolean;
}

const Card = ({
  account,
  employerHsaContribution,
  individualHsaContributionLimit,
  familyHsaContributionLimit,
  selectedPlan,
  shouldShowEducationalVideo,
}: CardProps) => {
  const { isOpen: eduOpen, onOpen: eduOnOpen, onClose: eduOnClose } = useDisclosure();
  return (
    <>
      <CardContainer account={account}>
        <div>
          {/* TODO: CONTENTFUL Add Fields */}
          <H5 weight="bold">{account.toLocaleUpperCase()} Eligible</H5>
          <Paragraph size="small">See how much to contribute below.</Paragraph>
        </div>
        <Button onClick={eduOnOpen}>Learn More</Button>
      </CardContainer>
      <EducationalDrawer
        isOpen={eduOpen}
        infoType={account}
        individualHsaContributionLimit={individualHsaContributionLimit}
        familyHsaContributionLimit={familyHsaContributionLimit}
        employerContribution={employerHsaContribution}
        selectedPlan={selectedPlan}
        handleClose={eduOnClose}
        shouldShowEducationalVideo={shouldShowEducationalVideo}
      />
    </>
  );
};

export interface EligibleCardsProps {
  isHsaEligible: boolean;
  isFsaEligible: boolean;
  employerHsaContribution: number;
  individualHsaContributionLimit: number;
  familyHsaContributionLimit: number;
  selectedPlan: Recommendation;
  builderGoSettings: BuilderGoSettings | null;
}

export const EligibleCards = ({
  isHsaEligible,
  isFsaEligible,
  employerHsaContribution,
  individualHsaContributionLimit,
  familyHsaContributionLimit,
  selectedPlan,
  builderGoSettings,
}: EligibleCardsProps) => {
  const cardProps = {
    employerHsaContribution,
    individualHsaContributionLimit,
    familyHsaContributionLimit,
    selectedPlan,
  };
  if (isHsaEligible) {
    return (
      <EligibleCardsContainer>
        <Card
          account={SAVINGS_ACCOUNT_TYPES.HSA}
          {...cardProps}
          shouldShowEducationalVideo={!!builderGoSettings?.show_hsa_video}
        />
        {isFsaEligible && (
          <Card
            account={SAVINGS_ACCOUNT_TYPES.FSA}
            {...cardProps}
            shouldShowEducationalVideo={!!builderGoSettings?.show_fsa_video}
          />
        )}
      </EligibleCardsContainer>
    );
  }
  if (isFsaEligible) {
    return (
      <EligibleCardsContainer>
        <Card
          account={SAVINGS_ACCOUNT_TYPES.FSA}
          {...cardProps}
          shouldShowEducationalVideo={!!builderGoSettings?.show_fsa_video}
        />
      </EligibleCardsContainer>
    );
  }
  return null;
};
