import React, { useState } from 'react';
import { useInView } from 'react-intersection-observer';

import { useCompareContext } from 'Contexts/compareContext';
import { useTextContext } from 'Contexts/textContext';
import { Drawer } from 'DesignLibrary/atoms/Drawer';
import Plan from 'Models/plan';
import { Recommendation } from 'Types/entities';
import { AriaHidden } from 'Utils/accessibility';

import { ChooseButtons } from './ChooseButtons';
import { Copays } from './Copays';
import { DeductiblesLimits } from './DeductiblesLimits';
import { MainDetails } from './MainDetails';
import { PlanHeader } from './PlanHeader';
import { CompareContainer, FloatingHeaderRow } from './styled';

export interface CompareModalProps {
  isOpen: boolean;
  selectedPlan: Plan | Record<string, never>;
  hsaContributionLimit: number;
  maximumAvailableAnnualSihraContribution: number;
  killMedRec: boolean;
  isImprovedRecExplanationEnabled: boolean;
  handleClose: () => void;
  choosePlan: (plan: Recommendation) => void;
  deselectPlan: () => void;
}

const CompareModal = ({
  isOpen,
  selectedPlan,
  hsaContributionLimit,
  maximumAvailableAnnualSihraContribution,
  killMedRec,
  isImprovedRecExplanationEnabled,
  handleClose,
  choosePlan,
  deselectPlan,
}: CompareModalProps) => {
  const { retrieveContentfulData } = useTextContext();
  const { comparablePlans } = useCompareContext();
  const [intersectionRef, inView] = useInView({
    rootMargin: '-24px',
  });

  const [hiddenAlert, setHiddenAlert] = useState<string | null>(null);

  const drawerTitle = retrieveContentfulData<string>(
    'health_section.compare_plan_text.compareModal.modalTitle',
  );

  // A11Y
  const accessibilityContent = retrieveContentfulData<Record<string, string>>(
    'health_section.secondary_content.accessibility',
  );

  const a11yAddPlanAlert = accessibilityContent?.compare_modal_remove_plan_alert.replace(
    /{x}/,
    selectedPlan.planMarketingName,
  );

  const a11yRemovePlanAlert = accessibilityContent?.compare_modal_remove_plan_alert.replace(
    /{x}/,
    selectedPlan.planMarketingName,
  );

  return (
    <Drawer isOpen={isOpen} onClose={handleClose} title={drawerTitle} size="xl">
      <CompareContainer id="intersection-root">
        <div ref={intersectionRef} id="compare-modal-intersection-indicator" />

        <FloatingHeaderRow shadow={!inView}>
          <PlanHeader displaySmallTitle={!inView} plans={comparablePlans} />
        </FloatingHeaderRow>

        <MainDetails
          plans={comparablePlans}
          hsaContributionLimit={hsaContributionLimit}
          maximumAvailableAnnualSihraContribution={maximumAvailableAnnualSihraContribution}
          isImprovedRecExplanationEnabled={isImprovedRecExplanationEnabled}
          killMedRec={killMedRec}
        />

        <DeductiblesLimits plans={comparablePlans} />

        <Copays plans={comparablePlans} />

        <ChooseButtons
          selectedPlan={selectedPlan}
          choosePlan={(plan) => {
            setHiddenAlert(a11yAddPlanAlert);
            choosePlan(plan);
          }}
          deselectPlan={() => {
            setHiddenAlert(a11yRemovePlanAlert);
            deselectPlan();
          }}
        />
      </CompareContainer>

      <AriaHidden aria-live="polite">{hiddenAlert}</AriaHidden>
    </Drawer>
  );
};

export default CompareModal;
