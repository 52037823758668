import { Flex } from '@chakra-ui/react';
import { Flag } from '@phosphor-icons/react';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { GlobalReducerState } from 'app/reducers';
import AlexLogo from 'Assets/alex-logo.svg';
import LanguageToggle from 'Containers/App/Navigation/LanguageToggle';
import PayPeriodSelect from 'Containers/App/Navigation/PayPeriodSelect';
import { useTextContext } from 'Contexts/textContext';
import { FadeUp } from 'DesignLibrary/atoms';
import { PageLayout } from 'DesignLibrary/atoms/PageLayout';
import { useViewport } from 'DesignLibrary/context';
import Media from 'Shared/Media';
import { WELCOME_PATH, INTRO_PATH, PROFILE_PATH, ERROR_PATH, MTM_PATH } from 'Utils/urls';

import { Container, LogoWrapper, NavButton, NavContainer } from './styled';
import AlexIDHeader from '../AlexIDHeader';
import { Page } from '../pages';

interface NavItemsProps {
  path: string;
  pages: Page[];
  unlockedPage?: Page;
}

export interface DesktopProps {
  path: string;
  pages: Page[];
  unlockedPage?: Page;
  canChangePayPeriod: boolean;
  showLanguageToggle: boolean;
  isAlexIdEnabled: boolean;
  hideNavItems: boolean;
  getStore: () => GlobalReducerState;
}

const NavItems = ({ pages, unlockedPage, path }: NavItemsProps) => {
  const navigate = useNavigate();

  return (
    <FadeUp isOpen translateY="-1.5rem" delay={350}>
      <Flex as="nav" className="desktop-links" align="center" gap={4}>
        {pages.map((page, idx) => (
          <NavButton
            id={`${page.url.replace(WELCOME_PATH, '')}-link`}
            onClick={() => navigate(page.url)}
            disabled={unlockedPage && page.idx > unlockedPage.idx}
            key={page.idx}
            active={page.paths.includes(path)}
            aria-current={page.paths.includes(path)}
          >
            {page.step}
            {pages.length - 1 === idx && (
              <span>
                <Flag weight="fill" />
              </span>
            )}
          </NavButton>
        ))}
      </Flex>
    </FadeUp>
  );
};

const Desktop = ({
  path,
  pages,
  unlockedPage,
  canChangePayPeriod,
  showLanguageToggle,
  isAlexIdEnabled,
  hideNavItems,
  getStore,
}: DesktopProps) => {
  const { device } = useViewport();
  const { retrieveContentfulData } = useTextContext();

  const displayName = retrieveContentfulData<string>('display_name');
  const logoAlt = `${displayName || 'Client'} Logo`;

  return (
    <Container isLanding={[WELCOME_PATH, INTRO_PATH].includes(path)}>
      <div className="header-wrapper">
        <PageLayout bg="--primary-white">
          <Flex as="header" align="center" justify="space-between">
            <FadeUp isOpen translateY="-1.5rem" delay={1000} timeout={500}>
              <Flex align="center" gap={4}>
                <LogoWrapper>
                  <img src={AlexLogo} alt="Alex" />
                </LogoWrapper>
                {device !== 'mobile' && (
                  <LogoWrapper second>
                    <Media field="logo" form="image" title={logoAlt} />
                  </LogoWrapper>
                )}
              </Flex>
            </FadeUp>

            <FadeUp isOpen translateY="-1.5rem" delay={1000} timeout={500}>
              <Flex className="right-toggles" gap={2}>
                {showLanguageToggle && (
                  <FadeUp
                    isOpen={[WELCOME_PATH, INTRO_PATH].includes(path)}
                    translateY="-1.5rem"
                    delay={2000}
                  >
                    <LanguageToggle />
                  </FadeUp>
                )}
                {canChangePayPeriod && (
                  <FadeUp
                    isOpen={![WELCOME_PATH, INTRO_PATH, PROFILE_PATH].includes(path)}
                    translateY="-1.5rem"
                    delay={2000}
                  >
                    <PayPeriodSelect />
                  </FadeUp>
                )}
                {isAlexIdEnabled && (
                  <FadeUp isOpen translateY="-1.5rem" delay={2000}>
                    <AlexIDHeader getStore={getStore} />
                  </FadeUp>
                )}
              </Flex>
            </FadeUp>
          </Flex>
        </PageLayout>
      </div>
      {![WELCOME_PATH, INTRO_PATH, ERROR_PATH, MTM_PATH].includes(path) && !hideNavItems && (
        <NavContainer>
          <PageLayout>
            <NavItems path={path} pages={pages} unlockedPage={unlockedPage} />
          </PageLayout>
        </NavContainer>
      )}
    </Container>
  );
};

export default Desktop;
