import { useDisclosure } from '@chakra-ui/react';
import React from 'react';

import { GlobalReducerState } from 'app/reducers';
import AlexLogo from 'Assets/alex-logo.svg';
import { Button } from 'DesignLibrary/atoms';

import MobileModal from './MobileModal';
import { NavContainer } from './styled';
import { LogoWrapper } from '../Desktop/styled';
import { Page } from '../pages';

export interface MobileProps {
  path: string;
  pages: Page[];
  currentPage?: Page;
  unlockedPage?: Page;
  canChangePayPeriod: boolean;
  isAlexIdEnabled: boolean;
  hideNavItems: boolean;
  getStore: () => GlobalReducerState;
}

const Mobile = ({
  getStore,
  path,
  pages,
  unlockedPage,
  currentPage,
  canChangePayPeriod,
  isAlexIdEnabled,
  hideNavItems,
}: MobileProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <NavContainer>
        <LogoWrapper>
          <img src={AlexLogo} alt="Alex" />
        </LogoWrapper>
        <div className="left-nav">
          {currentPage && (
            <Button
              className="menu-btn"
              data-testid="mobile-nav-menu-btn"
              onClick={onOpen}
              size="small"
              iconRight="List"
              iconProps={{
                size: 20,
              }}
            >
              {(currentPage.step as string) || undefined}
            </Button>
          )}
        </div>
      </NavContainer>
      <MobileModal
        getStore={getStore}
        isOpen={isOpen}
        path={path}
        pages={pages}
        unlockedPage={unlockedPage}
        handleClose={onClose}
        canChangePayPeriod={canChangePayPeriod}
        isAlexIdEnabled={isAlexIdEnabled}
        hideNavItems={hideNavItems}
      />
    </>
  );
};

export default Mobile;
