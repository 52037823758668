import React from 'react';

import Text from 'Shared/Text';
import {
  HSA_PLUS_PATH,
  OVERVIEW_PATH,
  PROFILE_PATH,
  RESULT_PATH,
  REVIEW_PATH,
  TAX_SAVINGS_PATH,
  VIEW_BENEFIT_PATH,
  VIEW_PLAN_PATH,
} from 'Utils/urls';

export interface Page {
  idx: number;
  step: React.ReactNode;
  url: string;
  paths: string[];
}

const resultPage = {
  idx: 2,
  step: <Text field="top_level_navigation.desktop_pages.health" />,
  url: RESULT_PATH,
  paths: [RESULT_PATH, `${VIEW_PLAN_PATH}/:planId`],
};

const hsaPage = {
  idx: 3,
  step: <Text field="top_level_navigation.desktop_pages.hsa" />,
  url: HSA_PLUS_PATH,
  paths: [HSA_PLUS_PATH],
};

const taxSavingsPage = {
  idx: 3,
  step: <Text field="top_level_navigation.desktop_pages.taxSavings" />,
  url: TAX_SAVINGS_PATH,
  paths: [TAX_SAVINGS_PATH],
};

const getFullBenefitPage = (showHsaNav) => ({
  idx: showHsaNav ? 4 : 3,
  step: <Text field="top_level_navigation.desktop_pages.supplemental" />,
  url: OVERVIEW_PATH,
  paths: [OVERVIEW_PATH, `${VIEW_BENEFIT_PATH}/:insuranceType`],
});

const optionalInsert = (condition: boolean, value: Page) => (condition ? [value] : []);

const getReviewPageIdx = (showHsaNav: boolean, isFullBenefitEnabled: boolean, showTaxSavings: boolean) => {
  if ((showHsaNav || showTaxSavings) && isFullBenefitEnabled) return 5;
  if (showHsaNav || showTaxSavings || isFullBenefitEnabled) return 4;
  return 3;
};

export const getPages = (
  isFullBenefitEnabled: boolean,
  showHsaNav: boolean,
  showTaxSavings: boolean,
  showResultPage: boolean,
): Page[] => [
  {
    idx: 1,
    step: <Text field="top_level_navigation.desktop_pages.profile" />,
    url: PROFILE_PATH,
    paths: [PROFILE_PATH],
  },
  ...optionalInsert(showResultPage, resultPage),
  ...optionalInsert(showHsaNav && !showTaxSavings, hsaPage),
  ...optionalInsert(showTaxSavings, taxSavingsPage),
  ...optionalInsert(isFullBenefitEnabled, getFullBenefitPage(showHsaNav || showTaxSavings)),
  {
    idx: getReviewPageIdx(showHsaNav, isFullBenefitEnabled, showTaxSavings),
    step: <Text field="top_level_navigation.desktop_pages.review" />,
    url: REVIEW_PATH,
    paths: [REVIEW_PATH],
  },
];
