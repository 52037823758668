import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import { GlobalReducerState } from 'app/reducers';
import AlexIntro from 'Containers/AlexIntro';
import { makeSelectConfigField, makeSelectGlobalField } from 'Containers/App/selectors';
import HsaPage from 'Containers/HsaPage';
import { MomentPage } from 'Containers/MomentPage';
import OverviewPage from 'Containers/OverviewPage';
import ProfilePage from 'Containers/ProfilePage';
import ResultPage from 'Containers/ResultPage';
import ReviewPage from 'Containers/ReviewPage';
import TaxSavingsPage from 'Containers/TaxSavingsPage';
import UnifiedLandingPage from 'Containers/UnifiedLandingPage';
import ViewBenefitPage from 'Containers/ViewBenefitPage';
import ViewPlanPage from 'Containers/ViewPlanPage';
import { useTextContext } from 'Contexts/textContext';
import Error500, { BrowserNavError } from 'Shared/Error500';
import { ErrorContainer } from 'Shared/Error500/styled';
import injectSaga from 'Utils/injectSaga';
import {
  ERROR_PATH,
  HSA_PLUS_PATH,
  INTRO_PATH,
  OVERVIEW_PATH,
  PROFILE_PATH,
  RESULT_PATH,
  REVIEW_PATH,
  TAX_SAVINGS_PATH,
  VIEW_BENEFIT_PATH,
  VIEW_PLAN_PATH,
  WELCOME_PATH,
  MTM_PATH,
} from 'Utils/urls';

import saga from './saga';

const UNIFIED_LANDING_ROUTES: {
  path: string;
  component: any;
}[] = [
  {
    path: WELCOME_PATH,
    component: UnifiedLandingPage,
  },
  {
    path: INTRO_PATH,
    component: AlexIntro,
  },
];

// TODO what on earth is the type of `component` here?
const ROUTES: { path: string; component: any }[] = [
  {
    path: PROFILE_PATH,
    component: ProfilePage,
  },
  {
    path: RESULT_PATH,
    component: ResultPage,
  },
  {
    path: HSA_PLUS_PATH,
    component: HsaPage,
  },
  {
    path: TAX_SAVINGS_PATH,
    component: TaxSavingsPage,
  },
  {
    path: `${VIEW_PLAN_PATH}/:planId`,
    component: ViewPlanPage,
  },
  {
    path: REVIEW_PATH,
    component: ReviewPage,
  },
  {
    path: MTM_PATH,
    component: MomentPage,
  },
];

const FBS_ROUTES: { path: string; component: any; exact: boolean }[] = [
  {
    path: OVERVIEW_PATH,
    component: OverviewPage,
    exact: true,
  },
  {
    path: `${VIEW_BENEFIT_PATH}/:insuranceType`,
    component: ViewBenefitPage,
    exact: true,
  },
];

export interface CommercialV2ParentProps {
  // isUnderConstruction: boolean;
}

interface CommercialV2StateProps {
  isFullBenefitEnabled: boolean;
  // enrollmentEventId?: string;
  // clientSurvey: ClientSurveySchema | null;
  // builderCustomerKey: string | null;
  hasUnsavedProfileChanges: boolean;
}

export type CommercialV2Props = CommercialV2ParentProps & CommercialV2StateProps;

export const CommercialV2 = ({ isFullBenefitEnabled, hasUnsavedProfileChanges }: CommercialV2Props) => {
  const { isError } = useTextContext();
  const [isBnf, setIsBnf] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const path = window.location.pathname;
    if (path !== MTM_PATH && path !== PROFILE_PATH && hasUnsavedProfileChanges) {
      setIsBnf(true);
    } else if (isBnf) {
      setIsBnf(false);
    }
  }, []);

  useEffect(() => {
    if (isError) {
      navigate(ERROR_PATH);
    }
  }, [isError]);

  const getRoutes = () => {
    let tempRoutes = [...UNIFIED_LANDING_ROUTES, ...ROUTES];

    if (isFullBenefitEnabled) {
      tempRoutes = [...tempRoutes, ...FBS_ROUTES];
    }

    return tempRoutes;
  };

  const routes = getRoutes();

  if (isBnf) {
    return (
      <ErrorContainer>
        <BrowserNavError errorRoute={() => navigate(PROFILE_PATH)} />
      </ErrorContainer>
    );
  }

  return (
    <Routes>
      {routes.map((route, index) => (
        <Route key={index} path={route.path} element={<route.component />} />
      ))}
      <Route element={<Error500 />} path={ERROR_PATH} />
    </Routes>
  );
};

const mapStateToProps = createStructuredSelector<GlobalReducerState, CommercialV2StateProps>({
  isFullBenefitEnabled: makeSelectConfigField('is_fbs_enabled'),
  // enrollmentEventId: makeGetSelectedEnrollmentEventId(),
  // clientSurvey: makeSelectClientSurvey(),
  // builderCustomerKey: makeSelectConfigField('builder_customer_key'),
  hasUnsavedProfileChanges: makeSelectGlobalField('hasUnsavedProfileChanges'),
});

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const withSaga = injectSaga<CommercialV2ParentProps>({
  key: 'commercialApp',
  saga,
});

const withConnect = connect(mapStateToProps);

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default compose(withConnect, withSaga)(CommercialV2);
