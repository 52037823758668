import React from 'react';

import { formatDeductibleOrOop } from 'Containers/ResultPage/CompareModal/DeductiblesLimits/_shared';
import { PlanCardRow } from 'Containers/ViewPlanPage/_shared/styled';
import { ViewPlanPageCard } from 'Containers/ViewPlanPage/ViewPlanPageCard';
import { useTextContext } from 'Contexts/textContext';
import { FormattedTooltipProps, Numbers } from 'DesignLibrary/atoms';
import { TooltipPlanDetails } from 'DesignLibrary/molecules';
import { IconBlockProps } from 'DesignLibrary/molecules/IconBlock';
import Plan from 'Models/plan';

export interface CombinedNetworkEssentialsProps {
  plan: Plan;
}

export const CombinedNetworkEssentials = (props: CombinedNetworkEssentialsProps) => {
  const { plan } = props;
  const { combinedNetworkMedDeductible, combinedNetworkOopMax } = plan;

  const { retrieveContentfulData } = useTextContext();

  // Indemnity networks in Builder come through with these fields set
  const combinedNetworkMedDed = formatDeductibleOrOop(combinedNetworkMedDeductible);
  const combinedNetworkOopLimitComp = formatDeductibleOrOop(combinedNetworkOopMax);

  const deductibleTooltip = retrieveContentfulData<FormattedTooltipProps>(
    'tool_tips.combined_network_deductible',
  );
  const oopMaxTooltip = retrieveContentfulData<FormattedTooltipProps>(
    'tool_tips.combined_network_out_of_pocket_limit',
  );

  const title = retrieveContentfulData<string>(
    'health_details_section.plan_details.plan_essentials_card_title',
  );
  const icon: IconBlockProps = {
    icon: 'Sparkle',
    color: 'blue',
  };

  return (
    <ViewPlanPageCard title={title} icon={icon}>
      <PlanCardRow data-testid="combined-network-deductible">
        <TooltipPlanDetails
          tooltip={deductibleTooltip}
          keyHint={`plan-${plan.planId}-combined-network-deductible`}
        >
          {deductibleTooltip.underline as string}
        </TooltipPlanDetails>
        <Numbers className="combined-network-deductible">{combinedNetworkMedDed}</Numbers>
      </PlanCardRow>
      <PlanCardRow data-testid="combined-network-oop-max">
        <TooltipPlanDetails tooltip={oopMaxTooltip} keyHint={`plan-${plan.planId}-combined-network-oop-max`}>
          {oopMaxTooltip.underline as string}
        </TooltipPlanDetails>
        <Numbers className="combined-network-oop-max">{combinedNetworkOopLimitComp}</Numbers>
      </PlanCardRow>
    </ViewPlanPageCard>
  );
};
