import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import * as Sentry from '@sentry/react';
import React from 'react';
import { Provider } from 'react-redux';

import Error500 from 'Shared/Error500';

const chakraThemeOverrides = extendTheme({
  styles: {
    global: {
      body: {
        fontFamily: null,
        lineHeight: null,
        backgroundColor: null,
      },
    },
  },
});

export const withResources = (children, store) => (
  <Provider store={store}>
    <Sentry.ErrorBoundary fallback={Error500}>
      <ChakraProvider resetCSS={false} theme={chakraThemeOverrides}>
        {children}
      </ChakraProvider>
    </Sentry.ErrorBoundary>
  </Provider>
);
